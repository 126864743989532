import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { Link, NavLink } from "react-router-dom";
import Examination_navbar from "./Examination_navbar";
import WingsDropDown from "../Shared/WingsDropDown";
import SessionsDropDown from "../Shared/SessionsDropDown";
import ClassesDropDown from "../Shared/ClassesDropDown";
import SectionDropDown from "../Shared/SectionDropDown";
import ExamsDropDown from "../Shared/ExamsDropDown";
import SubjectsByExamSectionDropDown from "../Shared/SubjectsByExamSectionDropDown";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Pagination from "../General/Pagination";
import { scrollToTop } from "../Shared/ScrollOnTop";

const DataEntry = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );

  const [SessionId, setSessioinId] = useState(
    localStorage.getItem("DefaultSession")
  );

  const [showFilters, setShowFilters] = useState(true);
  const [WingId, setWingId] = useState(0);
  const [ClassId, setClassId] = useState(0);
  const [SectionID, setSectionID] = useState(0);
  const [ExamID, setExamID] = useState(0);
  const [SubjectID, setSubjectID] = useState(0);
  const [isUpdated, setIsUpdated] = useState(false);

  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [validated, setValidated] = useState(false);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [TotalRecords, setTotalRecords] = useState(0);
  const [obtained, setObtained] = useState({});

  useEffect(() => {
    document.title = "Exam Data Entry";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      ExamID: ExamID,
      SectionSubjectID: SubjectID,
      Section_Id: SectionID,
    };
    //console.log(data);
    var api_config = {
      method: "post",
      url: config.base_url + "Exam/GetStudentsBySectionSubject",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          setLedger(response.data.ListofRecords);
          setTotalRecords(response.data.totalRecords);
          setLoading(false);
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        setLoading(false);
        //console.log(error);
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, [ExamID, SectionID, SubjectID, isUpdated]);

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{count++ + pageSize * (pageNo - 1)}</td>
            <td>{item.Class_ID}</td>
            <td>{item.Registration_No}</td>
            <td>{item.Student_Name}</td>
            <td>{item.Father_Name}</td>
            <td>
              <input
                type="number"
                value={item.Obtained_Marks}
                onChange={(e) => handleMarksChange(index, e.target.value)}
                style={{ width: "100px" }}
                data-student-id={item.Class_ID}
                className="form-control marks text-center"
              />
            </td>
            <td>{item.Contact_Phone}</td>
            <td>{item.Absent_Leave}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="8">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  const handleMarksChange = (recordIndex, newMarks) => {
    // Update marks changed in the component's state
    const updatedRecords = [...listOfLedger];
    updatedRecords[recordIndex].Obtained_Marks = newMarks;
    setLedger(updatedRecords);
    //console.log(listOfLedger);
  };
  const handleSaveMarks = (event) => {
    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      ExamID: ExamID,
      SectionSubjectID: SubjectID,
      Section_Id: SectionID,
      ListofStudentRecords: listOfLedger,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Exam/UpdateExamMarks",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });

    setValidated(true);
  };

  return (
    <>
      <Examination_navbar />
      <ToastContainer />
      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/Dashboard">Dashboard</NavLink>
                    </li>
                    <li className="breadcrumb-item">
                      <NavLink to="/ExaminationSystem/ExaminationDashboard">
                        Examinations
                      </NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Data Entry
                    </li>
                  </ol>
                </nav>
                <div className="page-title">Section Wise Exam Data Entry</div>
              </div>
              <div className="col-md-6">
                <div className="page-right-actions">
                  <button
                    onClick={() => {
                      setShowFilters((prevIsActive) => !prevIsActive);
                    }}
                    className="button button-white button-icon"
                  >
                    {showFilters ? "Hide Filters" : "Show Filters"}
                    <i className="ri-filter-3-line"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`collapse ${showFilters ? "show" : ""} `}
          id="collapseFilteres"
        >
          <div className="collapse show" id="collapseFilteres">
            <div className="page-content">
              <div className="container">
                <div className="card mb-4">
                  <div className="card-body p-md-4">
                    <div className="row align-items-center">
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label for="" className="">
                            Select Session:
                          </label>

                          <select
                            className="form-select"
                            onChange={(e) => setSessioinId(e.target.value)}
                            name="SessionID"
                          >
                            <option value="0">---All---</option>
                            <SessionsDropDown selectedSession={SessionId} />
                          </select>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label for="" className="">
                            Select Wing:
                          </label>
                          <select
                            name="Wing_Id"
                            onChange={(e) => setWingId(e.target.value)}
                            className="form-select"
                          >
                            <option value="0">---All---</option>
                            <WingsDropDown />
                          </select>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label for="" className="">
                            Select Class:
                          </label>
                          <select
                            className="form-select"
                            onChange={(e) => setClassId(e.target.value)}
                            name="Class_ID"
                          >
                            <option value="0">Please Select</option>
                            {SessionId != 0 && WingId != 0 && (
                              <ClassesDropDown
                                SessionID={SessionId}
                                WingID={WingId}
                              />
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label for="" className="">
                            Select Section:
                          </label>

                          <select
                            className="form-select"
                            onChange={(e) => setSectionID(e.target.value)}
                            name="Section_Id"
                          >
                            <option value="0">Please Select</option>
                            {ClassId !== 0 && (
                              <SectionDropDown ClassID={ClassId} />
                            )}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label for="" className="">
                            Select Exam:
                          </label>
                          <select
                            className="form-select"
                            onChange={(e) => setExamID(e.target.value)}
                            required
                          >
                            <option value="0">Please Select</option>
                            <ExamsDropDown />
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label for="" className="">
                            Select Subject:
                          </label>
                          <select
                            className="form-select"
                            onChange={(e) => setSubjectID(e.target.value)}
                            name="Subject_Id"
                          >
                            <option value="0">Please Select</option>
                            {SectionID !== 0 && ExamID !== 0 && (
                              <SubjectsByExamSectionDropDown
                                Section_Id={SectionID}
                                ExamID={ExamID}
                              />
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3 text-end offset-md-3">
                        <div className="form-group mb-3">
                          <input
                            type="button"
                            value="Search"
                            className="button button-primary mt-4"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-content">
          <div className="container">
            <div className="card">
              <div className="card-body">
                <table className="table table-theme">
                  <thead>
                    <tr>
                      <th colspan="8">Total {TotalRecords} Records Found!</th>
                    </tr>

                    <tr>
                      <th>Sr. No.</th>
                      <th>ID</th>
                      <th>Registration No</th>
                      <th>Student Name</th>
                      <th>Father Name</th>
                      <th>Obtained Marks</th>
                      <th>Total Marks</th>
                      <th>Pass Marks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td className="text-center" colspan="8">
                          <Spinner animation="border" role="status"></Spinner>
                        </td>
                      </tr>
                    ) : (
                      <>{renderLedger()}</>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="card mt-md-3">
            <div className="card-body">
              <div className="row offset-11">
                <div className="col-md-2  ">
                  <button
                    type="button"
                    onClick={handleSaveMarks}
                    className="button button-primary"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DataEntry;
