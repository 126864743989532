import ReportsMenu from "./ReportsMenu";
import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import SessionsDropDown from "../Shared/SessionsDropDown";
import WingsDropDown from "../Shared/WingsDropDown";
import ClassesDropDown from "../Shared/ClassesDropDown";
import SectionDropDown from "../Shared/SectionDropDown";
import StudentGroupDropDown from "../Shared/StudentGroupDropDown";
import StudentStatusDropDown from "../Shared/StudentStatusDropDown";
import { toast, ToastContainer } from "react-toastify";
import { NavLink, Link } from "react-router-dom";
import moment from "moment";
import Pagination from "../General/Pagination";
import Dropdown from "react-bootstrap/Dropdown";
import { Form, PageItem } from "react-bootstrap";
import MonthsDropDown from "../Shared/MonthsDropDown";
import YearsDropDown from "../Shared/YearsDropDown";
import { calculateColumnTotal, getMonthName } from "../General/Utility";
import { scrollToTop } from "../Shared/ScrollOnTop";

const StudentFineReport = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  //const [DefaultSession, setDefaultSession] = useState(localStorage.getItem("DefaultSession"));

  const [showFilters, setShowFilters] = useState(true);

  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [validated, setValidated] = useState(false);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const [PaidStatus, setPaidStatus] = useState("true");
  const [fineAmount, setFineAmount] = useState(500);
  const [Wing, setWing] = useState("");
  const [Section, setSection] = useState("");
  const [SessionID, setSessionID] = useState("");
  const [Session, setSession] = useState(
    localStorage.getItem("DefaultSession")
  );
  const [Class, setClass] = useState("");
  const [ReportMonth, setReportMonth] = useState(moment().format("M"));
  const [ReportYear, setReportYear] = useState(moment().format("YYYY"));
  const [OrderBy, setOrderBy] = useState(
    "Wing_Name,Class_Name,Section_Name,Student_Name,Registration_No"
  );

  document.title = "Student Fine Report";

  // for filters

  useEffect(() => {
    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: pageSize,
      ReportMonth: ReportMonth,
      FineAmount: fineAmount,
      Wing_Id: Wing,
      Class_Id: Class,
      Section_Id: Section,
      PaidStatus: PaidStatus,
      OrderBy: OrderBy,
    };
    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "/Reports/GetStudentsFineReport",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    //console.log(data);

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [
    pageNo,
    pageSize,
    Session,
    ReportMonth,
    Wing,
    Class,
    Section,
    fineAmount,
    PaidStatus,
    OrderBy,
  ]);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setReportMonth(event.target.ReportMonth.value);
      setWing(event.target.Wing.value);
      setClass(event.target.Class.value);
      setSection(event.target.Section.value);
    }

    setValidated(true);
  };

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.Student_ID}</td>
            <td>{item.Registration_No}</td>
            <td>{item.Student_Name}</td>
            <td>{item.Father_Name}</td>
            <td>{item.Current_Balance}</td>
            <td>{item.Current_Balance_Type}</td>
            <td>{item.Wing_Name}</td>
            <td>{item.Class_Name}</td>
            <td>{item.Section_Name}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="12">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };
  return (
    <>
      <ReportsMenu />
      <ToastContainer />
      <div className="page-head">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/Dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/Reports/ReportsDashboard">Reports</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Student Fine Report
                  </li>
                </ol>
              </nav>
              <div className="page-title">Student Fine Report</div>
            </div>
            <div className="col-md-6">
              <div className="page-right-actions">
                <button
                  onClick={() => {
                    setShowFilters((prevIsActive) => !prevIsActive);
                  }}
                  className="button button-white button-icon"
                >
                  {showFilters ? "Hide Filters" : "Show Filters"}
                  <i className="ri-filter-3-line"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`collapse ${showFilters ? "show" : ""} `}
        id="collapseFilteres"
      >
        <div className="page-content">
          <div className="container">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className="card mb-2">
                <div className="card-body p-md-4">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label for="" className="">
                          Select Session:
                        </label>
                        <select
                          className="form-select"
                          name="Session"
                          onChange={(e) => {setSession(e.target.value);setWing(0);setClass(0);setSection(0);}}
                        >
                          <option value="0">---All---</option>
                          <SessionsDropDown selectedSession={Session} />
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label>Select Wing:</label>
                        <select
                          name="Wing"
                          className="form-select"
                          onChange={(e) => {setWing(e.target.value);setClass(0);setSection(0);}}
                        >
                          <option value="">---All---</option>
                          <WingsDropDown />
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label for="" className="">
                          Select Class:
                        </label>

                        <select
                          className="form-select"
                          required
                          name="Class"
                          onChange={(e) => {setClass(e.target.value);setSection(0);}}
                        >
                          <option value="">Please Select</option>
                          {Session != 0 && Wing != 0 && (
                            <ClassesDropDown
                              SessionID={Session}
                              WingID={Wing}
                            />
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label for="" className="">
                          Select Section:
                        </label>
                        <select
                          className="form-select"
                          name="Section"
                          required
                          onChange={(e) => setSection(e.target.value)}
                        >
                          <option value="">Please Select</option>
                          {Class != 0 && <SectionDropDown ClassID={Class} />}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label for="" className="">
                          Select Month:
                        </label>
                        <select
                          name="ReportMonth"
                          defaultValue={ReportMonth}
                          onChange={(e) => setReportMonth(e.target.value)}
                          className="form-select"
                        >
                          <option value="">Please Select</option>
                          <MonthsDropDown />
                        </select>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label for="" className="">
                          Order by:
                        </label>
                        <select
                          name="OrderBy"
                          onChange={(e) => setOrderBy(e.target.value)}
                          className="form-select"
                        >
                          <option Value="Wing_Name,Class_Name,Section_Name,Student_Name,Registration_No">
                            All
                          </option>
                          <option Selected Value="Student_Name">
                            Student Name
                          </option>
                          <option Value="Registration_No">
                            Registration No
                          </option>
                          <option Value="Wing_Name">Wing Name</option>
                          <option Value="Class_Name">Class</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3 d-flex mt-4">
                      <label className=" mt-3" htmlFor="fineType">
                        Fine Type:
                      </label>
                      <div className="d-flex mt-3">
                        <div className="form-check  mx-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            checked={fineAmount === 500}
                            id="fineType500"
                            onChange={() => setFineAmount(500)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="fineType500"
                          >
                            500
                          </label>
                        </div>
                        <div className="form-check mx-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            checked={fineAmount === 50}
                            id="fineType50"
                            onChange={() => setFineAmount(50)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="fineType50"
                          >
                            50
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 mt-4">
                      <div className="form-check mt-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={(e) => setPaidStatus(e.target.checked)}
                          checked={PaidStatus}
                          id="flexCheckDefault"
                        />
                        <label className="form-check-label" for="flexCheckDefault">
                          Show Not Paid:
                        </label>
                      </div>
                    </div>

                    <div className="col-md-2 offset-md-10 text-end">
                      <div className="form-group text-end">
                        <button
                          type="submit"
                          value="Search"
                          className="w-100 button button-primary mt-4"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container">
          <div className="card">
            <div className="card-body">
              <table className="table table-theme">
                <thead>
                  <tr>
                    <th colSpan={4}>Total Records Found: {totalRecords}</th>
                    <th colSpan={5} className="text-end">
                      Total Balance :{" "}
                      {calculateColumnTotal(listOfLedger, "Current_Balance")}
                    </th>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <th>Student ID</th>
                    <th>Registration No.</th>
                    <th>Student Name</th>
                    <th>Father Name</th>
                    <th>Balance</th>
                    <th>Type</th>
                    <th>Wing Name</th>
                    <th>Class</th>
                    <th>Section</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td className="text-center" colspan="12">
                        <Spinner animation="border" role="status"></Spinner>
                      </td>
                    </tr>
                  ) : (
                    <>{renderLedger()}</>
                  )}
                </tbody>
              </table>
              {totalRecords > 10 && (
                <div className="pagination-wrap">
                  <div className="row align-items-center">
                    <div className="col-md-2 form_default">
                      <div className="d-flex align-items-center">
                        <label className="me-2" style={{ fontSize: "15px" }}>
                          Page Size
                        </label>
                        <div className="form-group">
                          <select
                            className="form-select"
                            style={{ width: "100px" }}
                            onChange={(e) => {
                              setPageNo(1);
                              setPageSize(parseInt(e.target.value));
                            }}
                          >
                            <option value="10" selected>
                              10
                            </option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <Pagination
                      pageNo={pageNo}
                      pageSize={pageSize}
                      totalRecords={totalRecords}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="card mt-2">
            <div className="card-body p-md-4">
              <div className="row">
                <div className="col-md-12 text-end">
                  <NavLink
                    className="button button-primary"
                    to={`/Reports/StudentFinePrint?ReportMonth=${ReportMonth}&FineAmount=${fineAmount}&Wing_Id=${Wing}&Class_Id=${Class}&Section_Id=${Section}&PaidStatus=${PaidStatus}&OrderBy=${OrderBy}`}
                    target="_blank"
                  >
                    Print Report
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentFineReport;
