import Examination_navbar from "./Examination_navbar";
import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { NavLink, Link } from "react-router-dom";
import ExamsDropDown from "../Shared/ExamsDropDown";
import { useParams } from "react-router-dom";
import WingsDropDown from "../Shared/WingsDropDown";
import ClassesDropDown from "../Shared/ClassesDropDown";
import EmployeeDropDown from "../Shared/EmployeeDropDown";
import EmployeeDropDownSearch from "../Shared/EmployeeDropDownSearch";
import { Form } from "react-bootstrap";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SessionsDropDown from "../Shared/SessionsDropDown";
import SubjectDropDown from "../Shared/SubjectDropDown";
import Modal from "react-bootstrap/Modal";
import { scrollToTop } from "../Shared/ScrollOnTop";
import Select from "react-select";
import SectionDropDown from "../Shared/SectionDropDown";
import Pagination from "../General/Pagination";

const ManageClassSubjects = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [section, setSection] = useState({});
  const [loading, setLoading] = useState(false);
  const [Total_Marks, setTotal_Marks] = useState(null);
  const [Pass_Marks, setPass_Marks] = useState(null);
  const [Marks, setMarks] = useState(false);

  const [Subject_ID, setSubject_ID] = useState("");
  const [Teacher_ID, setTeacher_ID] = useState("");

  // section related
  const [validatedSection, setvalidatedSection] = useState(false);
  const [loadingSection, setloadingSection] = useState(false);

  const [ExamID, setExamID] = useState(0);
  const [validatedSubject, setvalidatedSubject] = useState(false);
  const [loadingSubjects, setloadingSubjects] = useState(false);
  const [IsSubjectAdded, setIsSubjectAdded] = useState(false);
  const [loadingsubjectButton, setLoadingSubjectButton] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState({});
  const [loadingUpdatesubjectButton, setloadingUpdatesubjectButton] =
    useState(false);
  const [IsSubjectUpdated, setIsSubjectUpdated] = useState(false);
  const [validatedSubjectUpdate, setValidatedSubjectUpdate] = useState(false);

  const [showFilters, setShowFilters] = useState(true);

  // edit modal
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setSelectedSubject({});
  };
  const handleShow = (item) => {
    setShow(true);
    setSelectedSubject(item);
    //console.log(item);
  };

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const [WingId, setWingId] = useState(0);
  const [SessionId, setSessioinId] = useState(
    localStorage.getItem("DefaultSession")
  );
  const [ClassId, setClassId] = useState(0);
  const [SectionID, setSectionID] = useState(0);

  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    setloadingSubjects(true);
    setIsSubjectAdded(false);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      PageNo: 1,
      PageSize: 10,
      Section_Id: SectionID,
      ExamID: ExamID,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Sections/GetSectionSubjects",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code != 0) {
          setSubjects(response.data.ListofRecords);
        }
        setloadingSubjects(false);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [IsSubjectAdded]);

  useEffect(() => {
    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      ID: SectionID,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Sections/GetSectionByID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code != 0) {
          setSection(response.data.Section);
        }

        setLoading(false);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        PageNo: 1,
        PageSize: 10,
        Section_Id: SectionID,
        ExamID: ExamID,
      };
      var api_config = {
        method: "post",
        url: config.base_url + "Sections/GetSectionSubjects",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(api_config)
        .then(function (response) {
          if (response.data.status_code != 0) {
            setSubjects(response.data.ListofRecords);
          }
        })
        .catch(function (error) {
          //console.log(error);
        });
    }

    setvalidatedSection(true);
  };

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const handleUpdateSubject = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setloadingUpdatesubjectButton(true);
      const data = {
        UserID: UserID,
        AccessKey: AccessKey,
        MemberType: MemberType,
        ID: selectedSubject.ID,
        SectionID: SectionID,
        Subject_ID: event.target.Subject_ID.value,
        Teacher_ID: EmployeeID,
        Exam_ID: ExamID,
        Total_Marks: event.target.Total_Marks.value,
        Pass_Marks: event.target.Pass_Marks.value,
      };

      //console.log(data);

      var api_config = {
        method: "post",
        url: config.base_url + "Sections/UpdateSectionSubject",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }

          setIsSubjectAdded(true);
          setloadingUpdatesubjectButton(false);
          handleClose();
        })
        .catch(function (error) {
          setLoading(false);
          toast.error("Network Error..", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }

    setValidatedSubjectUpdate(true);
  };

  const renderSubjects = () => {
    if (subjects != null && subjects.length > 0) {
      return subjects.map((item, index) => {
        return (
          <>
            <tr key={index}>
              <td>{item.SubjectName}</td>
              <td>{item.Total_Marks}</td>
              <td>{item.Pass_Marks}</td>
              <td>{item.Name}</td>
              <td>
                <ul
                  className="inline-action"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <button
                      type="button"
                      className="green"
                      onClick={(e) => handleShow(item)}
                    >
                      <i className="ri-edit-line"></i>
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      onClick={(e) => deleteSubject(item.ID)}
                      className="red"
                    >
                      <i className="ri-delete-bin-line"></i>
                    </button>
                  </li>
                </ul>
              </td>
            </tr>
          </>
        );
      });
    }
  };

  const checkmark = () => {};

  const handleAddSubject = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setMarks(false);
    } else {
      if (parseInt(Total_Marks) > parseInt(Pass_Marks)) {
        setMarks(true);
      } else {
        setMarks(false);
        toast.error("Passing marks should be less than total marks", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return false;
      }
      setLoadingSubjectButton(true);
      const data = {
        UserID: UserID,
        AccessKey: AccessKey,
        MemberType: MemberType,
        SectionID: SectionID,
        Subject_ID: Subject_ID,
        Teacher_ID: EmployeeID,
        Exam_ID: ExamID,
        Total_Marks: Total_Marks,
        Pass_Marks: Pass_Marks,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "Sections/AddSectionSubject",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setTeacher_ID("");
            setSubject_ID("");
          } else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }

          setIsSubjectAdded(true);
          setLoadingSubjectButton(false);
        })
        .catch(function (error) {
          setLoading(false);
          toast.error("Network Error..", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
    setvalidatedSubject(true);
  };

  const deleteSubject = (ID) => {
    const confirmed = window.confirm("Are you sure you want to delete?");
    if (confirmed) {
      const data = {
        UserID: UserID,
        AccessKey: AccessKey,
        MemberType: MemberType,
        ID: ID,
      };

      //console.log(data);

      var api_config = {
        method: "post",
        url: config.base_url + "Sections/DeleteSectionSubject",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }

          setIsSubjectAdded(true);
        })
        .catch(function (error) {
          setLoading(false);
          toast.error("Network Error..", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const TotalMarks = () => {
    let total = 0;
    subjects.forEach((item) => {
      total += item.Total_Marks;
    });
    return <span>{total}</span>;
  };

  const PassMarks = () => {
    let total = 0;
    subjects.forEach((item) => {
      total += item.Pass_Marks;
    });
    return <span>{total}</span>;
  };

  useEffect(() => {
    setLoading(true);
    const apiData = {
      AccessKey: AccessKey,
      Is_Resigned: false,
      UserID: UserID,
      MemberType: MemberType,
      SchoolID: 1,
      PageNo: 1,
      PageSize: 10000,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Payroll/SearchEmployees",
      headers: {
        "Content-Type": "application/json",
      },
      data: apiData,
    };

    axios(api_config)
      .then(function (response) {
        setEmployees(response.data.ListofRecords);
        setEmployeeName(response.data.ListofRecords.Name);
        setLoading(false);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  const [employes, setEmployees] = useState([]);
  const [EmployeeID, setEmployeeID] = useState(0);
  const [EmployeeName, setEmployeeName] = useState("");

  const dropdownOptions = employes.map((item) => ({
    value: item.ID,
    label: item.Name + "(" + item.Code + ")",
    code: item.Code,
  }));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%", // Set your desired width here
    }),
  };
  const handleDropdownChange = (selectedOption) => {
    //let label = selectedOption.label;
    setEmployeeID(selectedOption.value);
  };

  const defaultValue = {
    value: selectedSubject.Teacher_ID,
    label: selectedSubject.Name,
  };

  return (
    <>
      <ToastContainer />
      <Examination_navbar />
      <Modal className="theme-modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Section Subject</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validatedSubjectUpdate}
            onSubmit={handleUpdateSubject}
          >
            <div style={{ padding: "20px" }}>
              <div className="form-group mb-3">
                <label>Select Subject</label>
                <select className="form-select" name="Subject_ID" required>
                  <SubjectDropDown selected={selectedSubject.Subject_ID} />
                </select>
              </div>
              <div className="form-group mb-3">
                <label>Teacher Name</label>
                <Select
                  required={true}
                  styles={customStyles}
                  onChange={handleDropdownChange}
                  options={dropdownOptions}
                  defaultValue={defaultValue}
                  // selectedOption={selectedSubject.Teacher_ID}
                />
                {/* <select className="form-select" name="Teacher_ID" required>
                                    <EmployeeDropDown selected={selectedSubject.Teacher_ID} />
                                </select> */}
              </div>
              <div className="form-group mb-3">
                <label>Total Marks</label>
                <input
                  type="number"
                  defaultValue={selectedSubject.Total_Marks}
                  name="Total_Marks"
                  className="form-control"
                />
              </div>
              <div className="form-group mb-3">
                <label>Pass Marks</label>
                <input
                  type="number"
                  defaultValue={selectedSubject.Pass_Marks}
                  name="Pass_Marks"
                  className="form-control"
                />
              </div>
              {loadingUpdatesubjectButton ? (
                <div className="text-center">
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              ) : (
                <button type="submit" className="button button-primary w-100">
                  UPDATE
                </button>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/Dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/ExaminationSystem/ExaminationDashboard">
                        Examinations
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Manage Section Level Subjects
                    </li>
                  </ol>
                </nav>
                <div className="page-title">Manage Section Level Subjects</div>
              </div>
              <div className="col-md-6">
                <div className="page-right-actions">
                  <button
                    onClick={() => {
                      setShowFilters((prevIsActive) => !prevIsActive);
                    }}
                    className="button button-white button-icon"
                  >
                    {showFilters ? "Hide Filters" : "Show Filters"}
                    <i className="ri-filter-3-line"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`collapse ${showFilters ? "show" : ""} `}
          id="collapseFilteres"
        >
          <Form noValidate validated={validatedSection} onSubmit={handleSubmit}>
            <div className="page-content">
              <div className="container">
                <div className="card mb-2">
                  <div className="card-body p-md-4">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label for="" className="">
                            Select Session:
                          </label>
                          <select
                            className="form-select"
                            required
                            onChange={(e) => {setSessioinId(e.target.value);setWingId(0);setClassId(0);setSection(0);}}
                            name="SessionID"
                          >
                            <option value="0">---All---</option>
                            <SessionsDropDown selectedSession={SessionId} />
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label>Select Wing:</label>
                          <select
                            name="WingId"
                            required
                            onChange={(e) => {setWingId(e.target.value);setClassId(0);setSectionID(0);}}
                            className="form-select"
                          >
                            <option value="0">---All---</option>
                            <WingsDropDown />
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label for="" className="">
                            Select Class:
                          </label>
                          <select
                            className="form-select"
                            required
                            onChange={(e) => {setClassId(e.target.value);setSectionID(0);}}
                            name="Class_ID"
                          >
                            <option value="">Please Select</option>
                            {SessionId != 0 && WingId != 0 && (
                              <ClassesDropDown
                                SessionID={SessionId}
                                WingID={WingId}
                              />
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label for="" className="">
                            Select Section:
                          </label>
                          <select
                            className="form-select"
                            required
                            onChange={(e) => setSectionID(e.target.value)}
                            name="Section_Id"
                          >
                            <option value="0">Please Select</option>
                            {ClassId !== 0 && (
                              <SectionDropDown ClassID={ClassId} />
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label for="" className="">
                            Select Examination:
                          </label>
                          <select
                            className="form-select"
                            required
                            style={{ width: "400px" }}
                            value={ExamID}
                            onChange={(e) => setExamID(e.target.value)}
                          >
                            <option value="">Please Select</option>
                            <ExamsDropDown
                              SessionID={
                                section.SelectedClass?.Selected_Session
                                  ?.Session_ID
                              }
                            />
                          </select>
                        </div>
                      </div>
                      <div className="col-md-2 offset-md-7">
                        <div className="form-group mt-md-4">
                          {loadingSection ? (
                            <Spinner animation="border" role="status"></Spinner>
                          ) : (
                            <button
                              type="submit"
                              className="button button-primary w-100"
                            >
                              Search
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
        <div className="page-content">
          <div className="container">
            <div className="card">
              <div className="card-body">
                <Form
                  noValidate
                  validated={validatedSubject}
                  onSubmit={handleAddSubject}
                >
                  <table className="table table-theme">
                    <thead>
                      <tr>
                        <th className="" style={{ width: "250px" }}>
                          Subject
                        </th>
                        <th className="" style={{ width: "150px" }}>
                          Total Marks
                        </th>
                        <th className="" style={{ width: "150px" }}>
                          Pass Marks
                        </th>
                        <th>Teacher Name</th>
                        <th className="text-center text-nowrap">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <select
                            className="form-select"
                            name="Subject_ID"
                            value={Subject_ID}
                            onChange={(e) => setSubject_ID(e.target.value)}
                            required
                          >
                            <option value="">Please Select</option>
                            <SubjectDropDown />
                          </select>
                        </td>
                        <td>
                          <input
                            type="number"
                            onChange={(e) => setTotal_Marks(e.target.value)}
                            value={Total_Marks}
                            name="Total_Marks"
                            className="form-control"
                            required
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            onChange={(e) => setPass_Marks(e.target.value)}
                            value={Pass_Marks}
                            name="Pass_Marks"
                            className="form-control"
                            required
                          />
                        </td>
                        <td>
                          <Select
                            required={true}
                            styles={customStyles}
                            onChange={handleDropdownChange}
                            options={dropdownOptions}
                          />
                          {/* <select name="Teacher_ID" onChange={(e) => setTeacher_ID(e.target.value)} value={Teacher_ID} className="form-select" required>
                                                        <option value="">Please Select</option>
                                                        <EmployeeDropDown />

                                                    </select> */}
                        </td>
                        <td className="text-end">
                          <ul
                            className="inline-action"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              {loadingsubjectButton ? (
                                <Spinner
                                  animation="grow"
                                  size="sm"
                                  variant="danger"
                                />
                              ) : (
                                <button type="submit" className="blue">
                                  <i className="ri-add-fill fs-4"></i>
                                </button>
                              )}
                            </li>
                          </ul>
                        </td>
                      </tr>
                      {loadingSubjects ? (
                        <tr>
                          <td colSpan={5} className="text-center">
                            <Spinner animation="border" role="status"></Spinner>
                          </td>
                        </tr>
                      ) : (
                        <>
                          {renderSubjects()}
                          <tr>
                            <th className="text-end">Total:</th>
                            <th>{TotalMarks()}</th>
                            <th>{PassMarks()}</th>
                            <td></td>
                            <td></td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageClassSubjects;
