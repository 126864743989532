import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import moment from "moment";
import Pagination from "../General/Pagination";
import PayrollMenu from './PayrollMenu';
import ShowStaff from "../Shared/ShowStaff";
import { Form } from 'react-bootstrap';
import { scrollToTop } from '../Shared/ScrollOnTop';

function ChangeStatus(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [loading, setLoading] = useState(false);

    const [IS_Resigned, setIS_Resigned] = useState(false);
    const [Is_Active, setIs_Active] = useState(false);
    const [Resignation_Date, setResignation_Date] = useState(null);
    const [validated, setValidated] = useState(false);

    let params = useParams();
    const [EmployeeID, setEmployeeID] = useState(params.id);


    useEffect(() => {

        document.title = "Manage Employees";


        setLoading(true);

        const data = {
           AccessKey: AccessKey,
            UserID: UserID,
MemberType: MemberType,
            ID: params.id
        };



        var api_config = {
            method: 'post',
            url: config.base_url + 'Payroll/GetEmployeeStatus',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setIS_Resigned(response.data.IS_Resigned);
                setIs_Active(response.data.Is_Active);
                setResignation_Date(response.data.Resignation_Date);
                setLoading(false);
            })
            .catch(function (error) {
                //console.log(error);
            });

    }, []);



    const handleSubmit =(event)=>{

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setLoading(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
MemberType: MemberType,
                ID: params.id,
                IS_Resigned : IS_Resigned,
	            Resignation_Date: Resignation_Date,
	            Is_Active: Is_Active 
            };

            var api_config = {
                method: 'post',
                url: config.base_url + 'Payroll/ChangeEmployeeStatus',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };



            axios(api_config)
                .then(function (response) {
                    //console.log(response.data);
                    if (response.data.status_code == 1) {
                       
                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });

                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setLoading(false);


                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });



        }

        setValidated(true);
    };
    return (
        <>
            <PayrollMenu />
            <ToastContainer />
            <div className="page-head">

                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                    <li className="breadcrumb-item"><NavLink to="/PayRoll/PayRollDashboard">Payroll</NavLink></li>
                                    <li className="breadcrumb-item active" aria-current="page">Change Status</li>
                                </ol>
                            </nav>
                            <div className="page-title">Change Status</div>
                        </div>
                        <div className="col-md-6">
                            <div className="page-right-actions">

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-content">
                <ShowStaff EmployeeID={EmployeeID} />
                <div className="container">
                    <div className="card">
                        <div className="card-body card-form-wrap">
                            {
                                loading ?
                                    <div className='text-center'>
                                        <Spinner animation="border" role="status"></Spinner>
                                    </div>
                                    :
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                    <div className="form-wrap">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label className="col-form-label col-form-label-sm">Date of Resignation:</label>
                                                    <input type="date" className="form-control"
                                                    value={Resignation_Date == null ? null : moment(Resignation_Date).format(config.date_format_input)} 
                                                    onChange={(e) => setResignation_Date(e.target.value)}
                                                    required={IS_Resigned}
                                                     />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label for="" className="col-form-label col-form-label-sm">Resigned</label>
                                                    <div className="">
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="Resigned"
                                                                checked={IS_Resigned}
                                                                value={true}
                                                                onChange={(e) => setIS_Resigned(e.target.value === 'true')}
                                                                id="inlineRadio1"
                                                            />
                                                            <label className="form-check-label" for="inlineRadio1">Yes</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="Resigned"
                                                                checked={!IS_Resigned}
                                                                value={false}
                                                                onChange={(e) => setIS_Resigned(e.target.value === 'true')}
                                                                id="inlineRadio2"
                                                            />
                                                            <label className="form-check-label" for="inlineRadio2">No</label>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label for="" className=" col-form-label col-form-label-sm">Active</label>
                                                    <div>

                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="Active"
                                                                checked={Is_Active}
                                                                value={true}
                                                                onChange={(e) => setIs_Active(e.target.value === 'true')}
                                                                id="inlineRadio3"
                                                            />
                                                            <label className="form-check-label" for="inlineRadio3">Yes</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="Active"
                                                                checked={!Is_Active}
                                                                value={false}
                                                                onChange={(e) => setIs_Active(e.target.value === 'true')}
                                                                id="inlineRadio4"
                                                            />
                                                            <label className="form-check-label" for="inlineRadio4">No</label>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3 text-end">
                                                <div className="form-group">
                                                    <input type="submit" value="Save" className="button button-primary" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </Form>

                            }

                        </div>
                    </div>
                </div>
            </div>



        </>
    );
}

export default ChangeStatus;