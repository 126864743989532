import ReportsMenu from "./ReportsMenu";
import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { toast, ToastContainer } from "react-toastify";
import { NavLink, Link } from "react-router-dom";
import moment from "moment";
import MonthsDropDown from "../Shared/MonthsDropDown";
import YearsDropDown from "../Shared/YearsDropDown";
import { Form, PageItem } from "react-bootstrap";

const AccountHeadwiseReport = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  //const [DefaultSession, setDefaultSession] = useState(localStorage.getItem("DefaultSession"));

  const [showFilters, setShowFilters] = useState(true);

  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [validated, setValidated] = useState(false);
  const [IsUpdated, setIsUpdated] = useState(false);
  // pagination

  const [totalRecords, setTotalRecords] = useState(0);
  const [totalamount, settotalamount] = useState(0);
  const [totalinScroll, settotalinScroll] = useState(0);
  const [ReportMonth, setReportMonth] = useState(moment().format("MM"));
  const [ReportYear, setReportYear] = useState(moment().format("YYYY"));
  const [ReCreate, setReCreate] = useState(false);



  const fetchdata = () => {
   

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      ReportMonth: ReportMonth,
      ReportYear: ReportYear,
      ReCreate: ReCreate,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Reports/GetAccountHeadWiseReport",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.Listofrecords);
        settotalamount(response.data.totalamount);
        settotalinScroll(response.data.totalinScroll);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  useEffect (() => {
    document.title = "Head Wise Accounts Report";


  }, [ReportMonth, ReportYear,])

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.Serial_No}</td>
            <td>{item.Fee_Type_Code}</td>
            <td>{item.Fee_Type_Name}</td>
            <td>{item.TotalStudents}</td>
            <td>{item.TotalAmountDeposit}</td>
            <td>
              <ul className="inline-action">
                <li>
                  <NavLink
                    to={`/Reports/FeeDetails?ReportYear=${ReportYear}&ReportMonth=${ReportMonth}&FeeTypeId=${item.Fee_Type_ID} `}
                    className="red"
                    target="_blank"
                  >
                    <i className="ri-pages-line"></i>
                  </NavLink>
                </li>
              </ul>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="6">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  const handleCheckboxChange = (e) => {
    setReCreate(e.target.checked); // Update ReCreate state based on checkbox value
  };

  return (
    <>
      <ReportsMenu />
      <ToastContainer />
      <div className="page-head">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/Dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/Reports/ReportsDashboard">Reports</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Head Wise Accounts Report
                  </li>
                </ol>
              </nav>
              <div className="page-title">Head Wise Accounts Report</div>
            </div>
            <div className="col-md-6">
              <div className="page-right-actions">
                <button
                  onClick={() => {
                    setShowFilters((prevIsActive) => !prevIsActive);
                  }}
                  className="button button-white button-icon"
                >
                  {showFilters ? "Hide Filters" : "Show Filters"}
                  <i className="ri-filter-3-line"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`collapse ${showFilters ? "show" : ""} `}
        id="collapseFilteres"
      >
        {/* <Form noValidate validated={validated} onSubmit={handleSubmit}> */}
        <div className="page-content">
          <div className="container">
            <div className="card mb-2">
              <div className="card-body p-md-4">
                <div className="row gy-md-3 align-items-center">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="" className="">
                        Select Month:
                      </label>
                      <select
                        name="ReportMonth"
                        value={ReportMonth}
                        defaultValue={ReportMonth}
                        onChange={(e) => setReportMonth(e.target.value)}
                        className="form-select"
                      >
                        <option value="">Please Select</option>
                        <MonthsDropDown />
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="" className="">
                        Select Year:
                      </label>
                      <select
                        name="ReportYear"
                        onChange={(e) => setReportYear(e.target.value)}
                        className="form-select"
                      >
                        <option value="">Please Select</option>
                        <YearsDropDown currentYearSelectd={ReportYear} />
                      </select>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <Form.Group className="mt-4">
                      <Form.Check
                        type="checkbox"
                        label="Re Create Report"
                        checked={ReCreate}
                        onChange={handleCheckboxChange} // Handle checkbox change
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-3 text-end">
                    <div className="form-group text-end">
                      <button
                        // type="submit"
                        // value="Search"
                        className="w-100 button button-primary mt-4"
                        onClick={fetchdata}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container">
          <div className="card">
            <div className="card-body">
              <table className="table table-theme">
                <thead>
                  <tr>
                    <th colSpan={2}>Total Amount:{totalamount}</th>
                    <th colSpan={2}>Total As Per Scroll:{totalinScroll}</th>
                    <th colSpan={2} className="text-end">
                      Difference:{totalamount - totalinScroll}
                    </th>
                  </tr>

                  <tr>
                    <th>Serial No</th>
                    <th>Fee Code</th>
                    <th>Fee Type</th>
                    <th>No of Students</th>
                    <th>Total Amount Deposit</th>
                    <th>Detail</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td className="text-center" colspan="6">
                        <Spinner animation="border" role="status"></Spinner>
                      </td>
                    </tr>
                  ) : (
                    <>{renderLedger()}</>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="card mt-2">
            <div className="card-body p-md-4">
              <div className="row">
                <div className="col-md-12 text-end">
                  <div className="form-group text-end">
                  <NavLink
                    className="button button-primary"
                    to={`/Reports/AccountHeadwiseReportPrint?ReportYear=${ReportYear}&ReportMonth=${ReportMonth}
                                       `}
                    target="_blank"
                  >
                    Print Report
                  </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountHeadwiseReport;
