import TeacherMenu from './TeacherMenu';
import ShowStaff from "../Shared/ShowStaff";
import { scrollToTop } from '../Shared/ScrollOnTop';
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
function UpdatePhoto(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(
        localStorage.getItem("MemberType")
    );

    // photo 
    const [isUpdated, setIsUpdated] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [image, setImage] = useState('');
    const [cropData, setCropData] = useState("");
    const [cropper, setCropper] = useState(null);
    const [imageSelected, setImageSelected] = useState(false);
    const [imageCropped, setImageCropped] = useState(false);
    const [imgData, setImageData] = useState("");
    const [EmployeeData, setEmployeeData] = useState([]);

    const [loading, setLoading] = useState(false);

    const handleShowModal = () => setShowModal(true);


    const [photoStatus, setPhotoStatus] = useState(false);

    const handleRadioChange = (event) => {
        const newValue = event.target.value === 'true';
        setPhotoStatus(newValue);
    };


    useEffect(() => {
        // Teacher/GetEmployeeByID

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Teacher/GetEmployeeByID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                    setEmployeeData(response.data.Employee)
                    setPhotoStatus(response.data.Employee?.Photo_Status)
                }
                else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }

            })
            .catch(function (error) {

            });




    }, [])
    const handleCloseModal = () => {
        setShowModal(false);
        setImageSelected(false);
    };

    const onChange = (e) => {
        e.preventDefault();
        setImageSelected(true);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
    };

    const getCropData = () => {

        if (cropper) {
            const cropData = cropper.getCroppedCanvas().toDataURL();
            var cleanerBase64 = cropData.substring(22);
            setImageCropped(true);
            setCropData(cleanerBase64);
            setImageData(cropData); // Update imgData state with cropped image data
        }

        handleCloseModal();
    };

    const renderButtons = () => {
        if (imageSelected === true) {
            return (
                <>
                    <div className="modal-footer justify-content-center">
                        <button className="button button-outline-primary me-2" onClick={handleCloseModal}>Close</button>
                        <button className="button button-primary" onClick={getCropData}>Save Photo</button>
                    </div>
                </>
            )
        }
        else if (imageSelected === false && isUpdated === true) {
            return (
                <>
                    <div className="modal-footer justify-content-center">
                        <button className="button button-outline-primary me-2" onClick={handleCloseModal}>Close</button>
                    </div>
                </>
            )
        }
    }
    const handleSubmit = () => {
        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            StaffImage: cropData,
            Photo_Status: photoStatus,

        };
        //console.log(data)
        var api_config = {
            method: 'post',
            url: config.base_url + 'Teacher/UpdatePhoto',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                    toast.success(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setLoading(false);
                }
                else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setLoading(false);
                }

            })
            .catch(function (error) {

            });

    }

    return (
        <>
            <TeacherMenu />
            <ToastContainer />
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Profile Photo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isUpdated ? (
                        <>
                            <div className="text-center">
                                <p>Photo Updated Successfully</p>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="upload-photo-wrap">
                                {imageSelected ? (
                                    <Cropper
                                        zoomTo={0.5}
                                        aspectRatio={4 / 4}
                                        preview=".img-preview"
                                        src={image}
                                        dragMode="move"
                                        viewMode={2}
                                        minCropBoxHeight={10}
                                        minCropBoxWidth={10}
                                        background={false}
                                        responsive={true}
                                        autoCropArea={1}
                                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                        onInitialized={(instance) => {
                                            setCropper(instance);
                                        }}
                                        guides={true}
                                    />
                                ) : (
                                    <div className="choose-photo">
                                        <label htmlhtmlFor="ChoosePhoto">
                                            <i className="ri-upload-cloud-line"></i>
                                            <span>Browse Photo</span>
                                            <input
                                                id="ChoosePhoto"
                                                accept="image/jpeg,image/png"
                                                type="file"
                                                onChange={onChange}
                                            />
                                        </label>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </Modal.Body>

                {renderButtons()}
            </Modal>

            <div className="container body-content">
                <div className="page-head">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><NavLink to="/teacher/Dashboard">Home</NavLink></li>
                                        <li className="breadcrumb-item active" aria-current="page">Update Photo</li>
                                    </ol>
                                </nav>
                                <div className="page-title">Update Photo</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-content">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-3">
                                <div className="card">
                                    <div className="card-body p-md-4">
                                        <div className="form-group">
                                            <label className="">Select Photo:</label>
                                        </div>
                                        <div className="col-md-3 w-100 text-center" style={{}}>
                                            <div className='m-auto mb-3' style={{ width: "150px", height: "150px", borderRadius: "50%" }}>
                                                <img className="mb-3" src={imgData} style={{ width: "150px", height: "150px", borderRadius: "50%", border: '1px dashed black' }} />
                                            </div>
                                            <button
                                                type="button"
                                                onClick={handleShowModal}
                                                className="button w-100 button-small"
                                            >
                                                Choose Photo
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="card">
                                    <div className="card-body p-md-4">
                                        <div className="form-group">
                                            <label className="">Current Photo:</label>
                                        </div>
                                        <div className="col-md-3 w-100 text-center" style={{}}>
                                            <div className='m-auto mb-3' style={{ width: "150px", height: "150px", borderRadius: "50%", border: '1px dashed black' }}>
                                                <img className="mb-3" src={config.StaffImagePath + EmployeeData.Code + '.jpg'} style={{ width: "150px", height: "150px", borderRadius: "50%" }} />
                                            </div>
                                            <button
                                                className="button w-100 button-small"
                                            >
                                                **....... Current Photo .......**
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card" style={{ height: "285px" }}>
                                    <div className="card-body user-profile">
                                        <div className="row">
                                            <div className="col-md-12 mt-4">
                                                <h4 className="" style={{ color: "#003970", fontWeight: "bold" }}>
                                                    Photo Privacy Settings
                                                </h4>
                                            </div>

                                            <div className="col-md-12 mt-3">
                                                <Form.Check
                                                    type='radio'
                                                    name="flexRadioDefault"
                                                    id="1"
                                                    onChange={handleRadioChange}
                                                    checked={photoStatus === false}
                                                    value={false}
                                                    label={<span dangerouslySetInnerHTML={{ __html: '<b>Private:</b> Will only be shown within School' }} />}
                                                />
                                            </div>
                                            <div className="col-md-12 mb-4">
                                                <Form.Check
                                                    type='radio'
                                                    name="flexRadioDefault"
                                                    onChange={handleRadioChange}
                                                    checked={photoStatus === true}
                                                    value={true}
                                                    label={<span dangerouslySetInnerHTML={{ __html: '<b>Public:</b> Will be shown on School Website' }} />}
                                                    id="2"
                                                />
                                            </div>
                                            <div className="col-md-4 offset-md-8 mt-5">
                                                <div className="form-group ">
                                                    {loading ? (
                                                        <Spinner animation="border" role="status"></Spinner>
                                                    ) : (
                                                        <button
                                                            type="submit"
                                                            value="Save"
                                                            className="button button-primary w-100"
                                                            onClick={handleSubmit}
                                                        >
                                                            Update Photo
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </>
    );
}

export default UpdatePhoto;