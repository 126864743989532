import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import SessionsDropDown from "../Shared/SessionsDropDown";
import WingsDropDown from "../Shared/WingsDropDown";
import ClassesDropDown from "../Shared/ClassesDropDown";
import SectionDropDown from "../Shared/SectionDropDown";
import StudentGroupDropDown from "../Shared/StudentGroupDropDown";
import StudentStatusDropDown from "../Shared/StudentStatusDropDown";
import StudentMenu from "./StudentMenu";
import { toast, ToastContainer } from "react-toastify";
import { Link, NavLink } from "react-router-dom";
import moment from "moment";
import Pagination from "../General/Pagination";
import Dropdown from "react-bootstrap/Dropdown";
import { Form, PageItem } from "react-bootstrap";
import { scrollToTop } from "../Shared/ScrollOnTop";
import RightsCheck from "../General/RightsCheck";

function NewStudentList(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );

  const [showFilters, setShowFilters] = useState(true);

  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [validated, setValidated] = useState(false);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const [NewWingId, setNewWingId] = useState(0);
  const [NewClassId, setNewClassId] = useState(0);
  const [Form_No, setForm_No] = useState(0);
  const [WingId, setWingId] = useState(0);
  const [SessionId, setSessioinId] = useState(
    localStorage.getItem("DefaultSession")
  );
  const [ClassId, setClassId] = useState(0);
  const [SectionID, setSectionID] = useState(0);
  const [GroupId, setGroupId] = useState(0);
  const [isUpdated, setIsUpdated] = useState(false);
  const [StudentID, setStudentID] = useState(0);
  const [Keywords, setKeywords] = useState("");
  const [OrderBy, setOrderBy] = useState(0);

  useEffect(() => {
    document.title = "New Students List";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: pageSize,
      SessionID: SessionId,
      Wing_Id: WingId,
      Class_Id: ClassId,
      Section_Id: SectionID,
      Form_No: Form_No,
      Student_ID: StudentID,
      Keywords: Keywords,
      Group_Id: GroupId,
      OrderBy: OrderBy,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Student/SearchNewStudents",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          setLedger(response.data.ListofRecords);
          setLoading(false);
          setTotalRecords(response.data.totalRecords);
        } else {
          setLedger(null);
          setTotalRecords(0);
          // toast.error(response.data.status_message, {
          //     position: toast.POSITION.TOP_RIGHT
          // });
          setLoading(false);
        }
      })
      .catch(function (error) {});
  }, [
    pageNo,
    pageSize,
    SessionId,
    WingId,
    ClassId,
    SectionID,
    Form_No,
    StudentID,
    Keywords,
    GroupId,
    OrderBy,
  ]);

  const handleSubmit = (event) => {
    //console.log(event);
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoading(true);
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        pageNo: pageNo,
        pageSize: pageSize,
        SessionID: SessionId,
        Wing_Id: WingId,
        Class_Id: ClassId,
        Section_Id: SectionID,
        Form_No: Form_No,
        Student_ID: StudentID,
        Keywords: Keywords,
        Group_Id: GroupId,
        OrderBy: OrderBy,
      };

      //console.log(data);

      var api_config = {
        method: "post",
        url: config.base_url + "Student/SearchNewStudents",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          if (response.data.status_code == 1) {
            setLedger(response.data.ListofRecords);
            setLoading(false);
            setTotalRecords(response.data.totalRecords);
          } else {
            setLedger(null);
            setTotalRecords(0);
            // toast.error(response.data.status_message, {
            //     position: toast.POSITION.TOP_RIGHT
            // });
            setLoading(false);
          }
        })
        .catch(function (error) {});
    }
  };

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>
              <div className="form-check">
                <input
                  key={item.Student_ID}
                  data-type-id={item.Student_ID}
                  className="form-check-input checkStudent"
                  type="checkbox"
                  value={item.Student_ID}
                />
              </div>
            </td>
            <td>{item.Student_ID}</td>
            <td>{item.Student_Name}</td>
            <td>{item.Father_Name}</td>
            <td>{moment(item.Date_Of_Birth).format("DD/MM/YYYY")}</td>
            <td>{moment(item.Date_Of_Joining).format("DD/MM/YYYY")}</td>
            {/* <td>{item.Contact_Mobile}</td> */}
            <td>{item.SelectedClass.SelectedWing.Wing_Name}</td>
            <td>{item.SelectedClass.Class_Name}</td>
            <td>{item.SelectedSection.Section_Name}</td>
            <td>
              <div className="status">
                {item.Concession_Rule_ID > 1 && (
                  <span className="green">
                    <i className="ri-percent-fill"></i>
                  </span>
                )}
                {item.Current_Route_Id > 0 && (
                  <span className="red">
                    <i className="ri-bus-2-fill"></i>
                  </span>
                )}
                <span className="blue">
                  <i className="ri-emotion-normal-line"></i>
                </span>
              </div>
            </td>
            <td>
              <ul className="inline-action">
                <li>
                  <NavLink
                    to={"/Students/EditStudent/" + item.Student_ID}
                    className=" green"
                  >
                    <i className="ri-edit-line"></i>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/Students/StudentLedger/" + item.Student_ID}
                    className="red"
                  >
                    <i className="ri-pages-line"></i>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    target="_blank"
                    to={
                      "/Students/PrintVoucher/?Class_Id=0&ConcessionRuleID=0&OrderBy=Section_Name&Section_Id=0&SessionID=0&Wing_Id=0&Description=&AdvanceMonths=0&ShowHolidayImage=false&noRedCresent=false&amount=0&StudentType=New&Student_ID=" +
                      item.Student_ID
                    }
                    className="blue"
                  >
                    <i className="ri-printer-line"></i>
                  </NavLink>
                </li>
                <li>
                  <button
                    onClick={(e) => deleteStudent(item.Student_ID)}
                    className="red"
                  >
                    <i className="ri-delete-bin-line"></i>
                  </button>
                </li>
              </ul>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="12">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  const deleteStudent = (ID) => {
    const confirm = window.confirm("Are you sure you want to proceed ?");
    if (confirm) {
      setIsUpdated(false);
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        Student_ID: ID,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "Student/DeleteStudent",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setIsUpdated(true);
          } else {
            setIsUpdated(false);
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch(function (error) {
          //console.log(error);
        });
    }
  };

  return (
    <>
      <RightsCheck />
      <StudentMenu />
      <ToastContainer />
      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/Dashboard">Dashboard</NavLink>
                    </li>
                    <li className="breadcrumb-item">
                      <NavLink to="/students/StudentDashboard">
                        Students
                      </NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Search Students
                    </li>
                  </ol>
                </nav>
                <div className="page-title">New Students List</div>
              </div>
              <div className="col-md-6">
                <div className="page-right-actions">
                  <NavLink
                    to="/students/AddStudent"
                    className="button button-white button-icon"
                  >
                    Add Students
                    <i className="ri-add-fill"></i>
                  </NavLink>
                  <button
                    onClick={() => {
                      setShowFilters((prevIsActive) => !prevIsActive);
                    }}
                    className="button button-white button-icon"
                  >
                    {showFilters ? "Hide Filters" : "Show Filters"}
                    <i className="ri-filter-3-line"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`collapse ${showFilters ? "show" : ""} `}
          id="collapseFilteres"
        >
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="page-content">
              <div className="container">
                <div className="card mb-2">
                  <div className="card-body p-md-4">
                    <div className="row align-items-end gy-3">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Select Session:
                          </label>
                          <select
                            className="form-select"
                            onChange={(e) => setSessioinId(e.target.value)}
                            name="SessionID"
                          >
                            <option value="0">---All---</option>
                            <SessionsDropDown selectedSession={SessionId} />
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Select Wing:</label>
                          <select
                            name="Wing_Id"
                            onChange={(e) => setWingId(e.target.value)}
                            className="form-select"
                          >
                            <option value="0">---All---</option>
                            <WingsDropDown />
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Select Class:
                          </label>
                          <select
                            className="form-select"
                            onChange={(e) => setClassId(e.target.value)}
                            name="Class_ID"
                          >
                            <option value="">Please Select</option>
                            {SessionId != 0 && WingId != 0 && (
                              <ClassesDropDown
                                SessionID={SessionId}
                                WingID={WingId}
                              />
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Select Section:
                          </label>
                          <select
                            className="form-select"
                            onChange={(e) => setSectionID(e.target.value)}
                            name="Section_Id"
                          >
                            <option value="0">Please Select</option>
                            {ClassId !== 0 && (
                              <SectionDropDown ClassID={ClassId} />
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Form No:
                          </label>
                          <input
                            type="text"
                            name="Form_No"
                            onChange={(e) => setForm_No(e.target.value)}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Student Id:
                          </label>
                          <input
                            type="text"
                            name="Student_ID"
                            onChange={(e) => setStudentID(e.target.value)}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Keywords:
                          </label>
                          <input
                            type="text"
                            name="Keywords"
                            onChange={(e) => setKeywords(e.target.value)}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Select Group:
                          </label>
                          <select
                            className="form-select"
                            onChange={(e) => setGroupId(e.target.value)}
                            name="groupName"
                          >
                            <option value="">---All---</option>
                            {<StudentGroupDropDown />}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Order by:
                          </label>
                          <select
                            name="OrderBy"
                            onChange={(e) => setOrderBy(e.target.value)}
                            className="form-select"
                          >
                            <option Selected Value="Student_Name">
                              Student Name
                            </option>
                            <option Value="Registration_No">
                              Registration No
                            </option>
                            <option Value="Wing_Name">Wing Name</option>
                            <option Value="Class_Name">Class</option>
                            <option Value="Student_ID">Student ID Asc</option>
                            <option Value="Student_ID desc">
                              Student ID Desc
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-2 offset-md-7">
                        <div className="form-group">
                          <button
                            type="submit"
                            value="Search"
                            className="button button-primary w-100"
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
        <div className="page-content">
          <div className="container">
            <div className="card">
              <div className="card-body">
                {/* <div className="text-end mb-2 me-3">
                                    <b>{{totalRecords}} &nbsp; Records Found!</b>

                                </div> */}
                <table className="table table-theme">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Student ID</th>
                      <th>Name</th>
                      <th>Father Name</th>
                      <th>DOB</th>
                      <th>DOJ</th>
                      {/* <th>Mobile</th> */}
                      <th>Wing</th>
                      <th>Class</th>
                      <th>Section</th>
                      <th>Tags</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td className="text-center" colspan="12">
                          <Spinner animation="border" role="status"></Spinner>
                        </td>
                      </tr>
                    ) : (
                      <>{renderLedger()}</>
                    )}
                  </tbody>
                </table>
                {totalRecords > 10 && (
                  <div className="pagination-wrap">
                    <div className="row align-items-center">
                      <div className="col-md-2 form_default">
                        <div className="d-flex align-items-center">
                          <label className="me-2" style={{ fontSize: "15px" }}>
                            Page Size
                          </label>
                          <div className="form-group">
                            <select
                              className="form-select"
                              style={{ width: "100px" }}
                              onChange={(e) => {
                                setPageNo(1);
                                setPageSize(parseInt(e.target.value));
                              }}
                            >
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              <option value="500">500</option>
                              <option value="1000">1000</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <Pagination
                        pageNo={pageNo}
                        pageSize={pageSize}
                        totalRecords={totalRecords}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="container">
            <div className="card mt-2">
              <div className="card-body p-md-4">
                <div className="row">
                  <div className="col-md-2 offset-md-10 text-end">
                    <div className="form-group text-end">
                    <Link
                        target="_black"
                        to={`/Students/PrintStudentList?SessionID=${SessionId}&Wing_Id=${WingId}&classid=${ClassId}&sectionid=${SectionID}&order-by=${OrderBy}&groupid=${GroupId}&form-no=${Form_No}&keyword=${Keywords}&Student-ID=${StudentID}`}
                        value=""
                        className="w-100 button button-primary"
                      >
                        Print This List
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewStudentList;
