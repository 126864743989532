import moment from 'moment';
import React from 'react';

function HopeCertificate(props) {
    return (
        <>
            <div style={{ width: '624px', fontSize: '14px', height: 'auto', margin: '336px auto 0 auto', lineHeight: '25px' }} align="left">
                <div align="center">
                    <h3 className="style1" style={{ marginBottom: '20px', textDecoration: "underline" }}>
                    TO WHOM IT MAY CONCERN</h3>
                </div>
                <div align="right">
                    Student Code: {props.code}
                </div>
                <br />
                <br />
                <div>
                    This is certified that <strong>{props.name}</strong> {props.gender ? 'S/O':'D/O'} <strong>{props.fathername}</strong> is a bonafide student of this Institution with effect from {moment(props.doj).format("MMM DD, YYYY")}. He  is studying in the {props.class1}-{props.section}. 
                    
                    His date of birth according to the admission
                        and withdrawal register is <strong>{moment(props.dob).format("MMM DD, YYYY")}</strong>.
                    <br />
                    <br />
                    On the basis of  academic performance during the year, I feel confident that he is likely to obtain at least {props.marks}% marks in the {props.class}.
                    
                    <br />
                    <div>
                        .</div>
                    <span>{props.extraline}</span>
                    <br />
                    <br />
                </div>
                We wish his best of luck and success.
                <br />
                <br />
                <div align="left">
                    Dated: {moment().format("MMM DD, YYYY")}
                </div>
                <br />
                <div style={{ marginLeft: '400px', lineHeight: '18px' }}>
                    <b>Principal</b><br />
                    District Public School <br />
                    &amp; College, Kasur
                </div>
            </div>
        </>
    );
}

export default HopeCertificate;