import React, { useState } from "react";
import PayrollMenu from './PayrollMenu';
import { NavLink } from 'react-router-dom';
import MonthsDropDown from '../Shared/MonthsDropDown.js';
import YearsDropDown from '../Shared/YearsDropDown.js';
import DesignationDropDown from '../Shared/DesignationDropDown.js';
import WingsDropDown from '../Shared/WingsDropDown.js';
import EmployeeTypeDropDown from "../Shared/EmployeeTypeDropDown";
import moment from "moment";
import { scrollToTop } from '../Shared/ScrollOnTop';

function SummarySheet(props) {

    const [option1Month, setOption1Month] = useState(moment().format("M"));
    const [option1Year, setOption1Year] = useState(moment().format("YYYY"));

    const [option2Month, setOption2Month] = useState(moment().format("M"));
    const [option2Year, setOption2Year] = useState(moment().format("YYYY"));
    const [WingID, setWingID] = useState(0);

    const [option3Month, setOption3Month] = useState(moment().format("M"));
    const [option3Year, setOption3Year] = useState(moment().format("YYYY"));
    const [designationID, setDesignationID] = useState(0);

    const [option4Month, setOption4Month] = useState(moment().format("M"));
    const [option4Year, setOption4Year] = useState(moment().format("YYYY"));
    const [bpsScale, setbpsScale] = useState(0);
    //console.log(designationID);

    document.title = 'Summary Sheet'


    return (
        <>
            <PayrollMenu />
            <div className="container body-content">
                <div className="page-head">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                        <li className="breadcrumb-item"><NavLink to="/PayRoll/PayRollDashboard">PayRoll</NavLink></li>
                                        <li className="breadcrumb-item active" aria-current="page">Summary Sheet</li>
                                    </ol>
                                </nav>
                                <div className="page-title">Summary Sheet</div>
                            </div>
                            <div className="col-md-6">
                                <div className="page-right-actions">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-content">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-12">

                                <div className="card mb-4">
                                    <div className="card-body card-form-wrap">
                                        <div className="form-wrap">
                                            <div className="form-legend">Option 1</div>
                                            <div className="row align-items-center">
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label for="" className="">Month:</label>
                                                        <select name="month1" className="form-select" onChange={(e) => setOption1Month(e.target.value)} required>
                                                            <option value="" disabled selected>Please Select</option>
                                                            <MonthsDropDown />
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label for="" className="">Year:</label>
                                                        <select name="year1" className="form-select" onChange={(e) => setOption1Year(e.target.value)} required>
                                                            <option value="" disabled selected>Please Select</option>
                                                            <YearsDropDown />

                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group mt-3">
                                                        <NavLink
                                                            className={`button button-primary`}
                                                            to={`/payroll/summarysheet/print`}
                                                            target="_blank"
                                                        >Print</NavLink>

                                                    </div>
                                                </div>
                                                <div className="col-md-3 text-end">
                                                    <div className="form-group mt-3">
                                                        <NavLink
                                                            className={`button button-primary ${option1Month === null || option1Year === null ? 'disabled' : ''}`}
                                                            to={`/PayRoll/PrintSummarySheet/?month=${option1Month}&year=${option1Year}`}
                                                            target="_blank"
                                                        >Summary Sheet</NavLink>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>

                                            <div className="form-wrap">
                                                <div className="form-legend">Option 2</div>
                                                <div className="row align-items-center">
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label for="" className="">
                                                                Select Wing:
                                                            </label>
                                                            <select name="Wing" className="form-control"
                                                                onChange={(e) => setWingID(e.target.value)} required>
                                                                <option value="">Please Select</option>
                                                                <EmployeeTypeDropDown />
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label for="" className="">Month:</label>
                                                            <select name="month2" className="form-select" onChange={(e) => setOption2Month(e.target.value)} required>
                                                                <option value="" disabled selected>Please Select</option>
                                                                <MonthsDropDown />
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label for="" className="">Year:</label>
                                                            <select name="year2" className="form-select" onChange={(e) => setOption2Year(e.target.value)} required>
                                                                <option value="" disabled selected>Please Select</option>
                                                                <YearsDropDown />

                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 text-end">
                                                        <div className="form-group mt-3">
                                                            <NavLink
                                                                className={`button button-primary ${option2Month === null || option2Year === null || WingID === 0 ? 'disabled' : ''}`}
                                                                to={`/PayRoll/PrintSummarySheet2?month=${option2Month}&year=${option2Year}&EmployeeTypeId=${WingID}`}
                                                                target="_blank"
                                                            >Summary Sheet</NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div>

                                            <div className="form-wrap">
                                                <div className="form-legend" >Option 3</div>
                                                <div className="row align-items-center">
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label for="" className="">
                                                                Select
                                                                Designation:
                                                            </label>
                                                            <select name="Designation" className="form-control"
                                                                required onChange={(e) => setDesignationID(e.target.value)} >
                                                                <option value="" disabled selected>Please Select</option>
                                                                <DesignationDropDown />
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label for="" className="">Month:</label>
                                                            <select name="month3" className="form-select" onChange={(e) => setOption3Month(e.target.value)} required>
                                                                <option value="" disabled selected>Please Select</option>
                                                                <MonthsDropDown />
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label for="" className="">Year:</label>
                                                            <select name="year3" className="form-select" onChange={(e) => setOption3Year(e.target.value)} required>
                                                                <option value="" disabled selected>Please Select</option>
                                                                <YearsDropDown />
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 text-end">
                                                        <div className="form-group mt-3">
                                                            <NavLink
                                                                className={`button button-primary ${option3Month === null || option3Year === null || designationID === 0 ? 'disabled' : ''}`}
                                                                to={`/PayRoll/PrintSummarySheet2?month=${option3Month}&year=${option3Year}&designationId=${designationID}`}
                                                                target="_blank"
                                                            >Summary Sheet</NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div>

                                            <div className="form-wrap">
                                                <div className="form-legend" >Option 4</div>
                                                <div className="row align-items-center">
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label for="" className="">
                                                                Select
                                                                Grade:
                                                            </label>
                                                            <select name="Grade" className="form-select" onChange={(e) => setbpsScale(e.target.value)} required>
                                                                <option value="" disabled selected>
                                                                    Please Select
                                                                </option>
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                                <option value="6">6</option>
                                                                <option value="7">7</option>
                                                                <option value="8">8</option>
                                                                <option value="9">9</option>
                                                                <option value="10">10</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label for="" className="">Month:</label>
                                                            <select name="month4" className="form-select" onChange={(e) => setOption4Month(e.target.value)} required>
                                                                <option value="" disabled selected>
                                                                    Please Select
                                                                </option>
                                                                <MonthsDropDown />
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label for="" className="">Year:</label>
                                                            <select className="form-select" onChange={(e) => setOption4Year(e.target.value)} required>
                                                                <option value="" disabled selected>
                                                                    Please Select
                                                                </option>
                                                                <YearsDropDown />
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 text-end">
                                                        <div className="form-group mt-3">
                                                            <NavLink
                                                                className={`button button-primary ${option4Month === null || option4Year === null || designationID === 0 ? 'disabled' : ''}`}
                                                                to={`/PayRoll/PrintSummarySheet2?month=${option4Month}&year=${option4Year}&bpsScale=${bpsScale}`}
                                                                target="_blank"
                                                            >Summary Sheet</NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>



            </div>
        </>
    );
}

export default SummarySheet;