import React, { useState, useEffect } from 'react';
import config from "../../Config";
import axios from "axios";


function SupportTicketDropDown(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [TicketsLoading, setTicketsLoading] = useState(false);
    const [tickets, setTickets] = useState([]);

    useEffect(() => {

        setTicketsLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            Status: "Open",

        };

        var api_config = {
            method: 'post',
            url: config.base_url + '/Support/GetTicketTypesByStatusMemberID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {
                if (response.data.status_code == 1) {
                    setTickets(response.data.ListofTickets);
                }

                setTicketsLoading(false);
            })
            .catch(function (error) {
                //console.log(error);
            });



    }, []);


    if (TicketsLoading) {
        return <option selected disabled>Loading...</option>
    }
    else {
        if (tickets != null) {

            return (
                tickets.map((item, index) => {

                    return (
                        <option value={item.TicketType} key={index}>{item.TicketType}</option>
                    )
                })
            )
        }
    }


}

export default SupportTicketDropDown;