import React from 'react';

const PayScaleBasic = (props) => {
  const options = [];

  for (let i = 0; i <= 22; i++) {
    options.push(<option key={i} value={i} selected={i === props.selected}>{i}</option>);
  }

  return options;
};

export default PayScaleBasic;
