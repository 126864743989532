import React, { useState, useEffect } from 'react';
import config from "../../Config";
import axios from "axios";



function EmployeeDropDownSearch(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [employeetype, setEmployeeTypeID] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const apiData = {
            AccessKey: AccessKey,
            Is_Resigned: false,
            UserID: UserID,
            MemberType: MemberType,
            SchoolID: 1,
            PageNo: 1,
            PageSize: 10000
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Payroll/SearchEmployees',
            headers: {
                'Content-Type': 'application/json'
            },
            data: apiData
        };

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);

                setEmployeeTypeID(response.data.ListofRecords);
                setLoading(false);
            })
            .catch(function (error) {
                //console.log(error);
            });

    }, []);

    const dropdownOptions = employeetype.map(item => ({
        value: item.ID,
        label: item.Name + '(' + item.Code + ')',
        code: item.Code
    }));





    if (loading) {
        return false;
    }
    else {
        if (employeetype) {
            const result = employeetype.map((item) => ({
                value: item.ID,
                label: item.Name + '(' + item.Code + ')',
                code: item.Code
            }));
            return result;
        }
    }
}

export default EmployeeDropDownSearch;