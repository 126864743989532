import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import QRCode from "react-qr-code";
import StudentInfo from '../FeeSystem/StudentInfo';
import moment from "moment";
import PrintHeader from '../General/PrintHeader';
import Page from "../General/Page";
import { calculateColumnTotal, getMonthName } from '../General/Utility';
import { scrollToTop } from '../Shared/ScrollOnTop';

const PrintSummarySheet = () => {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [listOfLedger, setLedger] = useState([]);
    const [selectedYear, setselectedYear] = useState("");
    const [selectedMonth, setselectedMonth] = useState("");


    useEffect(() => {
        document.title="Print Summary Sheet"

        setLoading(true);
        // Get the URL parameters
        const urlParams = new URLSearchParams(window.location.search);

        // Retrieve individual parameter values
        const year = urlParams.get('year');
        const Month = urlParams.get('month');

        setselectedYear(year);
        setselectedMonth(Month);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            Fyear: year,
            SelectedMonth: Month,
        };



        //console.log(data);
        var api_config = {
            method: 'post',
            url: config.base_url + 'Payroll/GetSalarySheetSummary',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        //console.log(data);

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setLedger(response.data.ListofRecords);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);

            })
            .catch(function (error) {
                //console.log(error);
            });

    }, []);

    const renderLedger = () => {

        if (listOfLedger!=null && listOfLedger.length > 0) {
            let count = 1;
            let countbreak = 1;
            return (
                listOfLedger.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>
                                {count++}
                            </td>
                            <td>{item.Department_Name}</td>
                            <td>{item.Total_Employees}</td>
                            <td>{item.Basic_Salary}</td>
                            <td>{item.House_Rent}</td>
                            {/* <td>{item.Inc01072017}</td>
                            <td>{item.Inc01072018}</td>
                            <td>{item.Inc01072019}</td>
                            <td>{item.Inc01072021}</td>
                            <td>{item.Inc01072022}</td> */}
                            <td>{item.Inc01072023}</td>
                            <td>{item.Inc01072024}</td>
                            <td>{item.Misc_Allowances}</td>
                            <td>{item.Arrears}</td>
                            <td>{item.Gross}</td>
                            <td>{item.Tax}</td>
                            <td>{item.EOBI}</td>
                            <td>{item.SWF}</td>
                            <td>{item.Leave_Deduction}</td>
                            <td>{item.Misc_Deductions}</td>
                            <td>{item.Deduction_Total}</td>
                            <td>{item.Net}</td>
                        </tr>

                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colspan="18"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }


    return (
        <>
            <div className="h200">
                <Page orientation="landscape" size="legal">
                    <table className="table print-main-table">
                        <thead>
                            <tr>
                                <td >
                                    <PrintHeader title={`Salary Sheet For the Month of ${getMonthName(selectedMonth)} ${selectedYear} `} />
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                <table className="table table-sm table-bordered table-print">
                                    <tbody>
                            {
                                loading ?
                                    <tr>
                                        <td className="text-center" colspan="8">
                                            <Spinner animation="border" role="status"></Spinner>
                                        </td>
                                    </tr>
                                    :
                                    <>
                                        <tr>
                                            <th>Sr.#</th>
                                            <th>Department</th>
                                            <th>Total Emp.</th>
                                            <th>Basic</th>
                                            <th>H.R.</th>
                                            {/* <th>Incr. 1-7-17</th>
                                        <th>Incr. 1-7-18</th>
                                        <th>Incr. 1-7-19</th>
                                        <th>Incr. 1-7-21</th>
                                        <th>Incr. 1-7-22</th> */}
                                            <th>Incr. 1-7-23</th>
                                            <th>Incr. 1-7-24</th>
                                            <th>Misc.All.</th>
                                            <th>Arrears</th>
                                            <th>Gross</th>
                                            <th>Tax</th>
                                            <th>EOBI</th>
                                            <th>SWF</th>
                                            <th>Leave Ded.</th>
                                            <th>Misc Ded.</th>
                                            <th>Total</th>
                                            <th>Net Pay</th>
                                        </tr>
                                        {renderLedger()}
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <th>{calculateColumnTotal(listOfLedger, 'Total_Employees')}</th>
                                            <th>{calculateColumnTotal(listOfLedger, 'Basic_Salary')}</th>
                                            <th>{calculateColumnTotal(listOfLedger, 'House_Rent')}</th>
                                            {/* <th>{calculateColumnTotal(listOfLedger, 'Inc01072017')}</th>
                                        <th>{calculateColumnTotal(listOfLedger, 'Inc01072018')}</th>
                                        <th>{calculateColumnTotal(listOfLedger, 'Inc01072019')}</th>
                                        <th>{calculateColumnTotal(listOfLedger, 'Inc01072021')}</th>
                                        <th>{calculateColumnTotal(listOfLedger, 'Inc01072022')}</th> */}
                                            <th>{calculateColumnTotal(listOfLedger, 'Inc01072023')}</th>
                                            <th>{calculateColumnTotal(listOfLedger, 'Inc01072024')}</th>
                                            <th>{calculateColumnTotal(listOfLedger, 'Misc_Allowances')}</th>
                                            <th>{calculateColumnTotal(listOfLedger, 'Arrears')}</th>
                                            <th>{calculateColumnTotal(listOfLedger, 'Gross')}</th>
                                            <th>{calculateColumnTotal(listOfLedger, 'Tax')}</th>
                                            <th>{calculateColumnTotal(listOfLedger, 'EOBI')}</th>
                                            <th>{calculateColumnTotal(listOfLedger, 'SWF')}</th>
                                            <th>{calculateColumnTotal(listOfLedger, 'Leave_Deduction')}</th>
                                            <th>{calculateColumnTotal(listOfLedger, 'Misc_Deductions')}</th>
                                            <th>{calculateColumnTotal(listOfLedger, 'Deduction_Total')}</th>
                                            <th>{calculateColumnTotal(listOfLedger, 'Net')}</th>
                                        </tr>
                                    </>

                            }

                        </tbody>
                    </table>
                    </td>
                        </tr>


                    </tbody>
                </table>


                    <div className="my-2"> Following ___ no(s) of cheques may please be signed for the above mentioned month.</div>

                    <div style={{ clear: "both", float: "left", width: "100%" }}>

                        <table className="table table-sm table-bordered table-print" style={{ borderCollapse: "collapse", border: "transparent" }}>
                            <tbody>
                                <tr>
                                    <td style={{ width: "5%" }}>
                                        1
                                    </td>
                                    <td style={{ width: "30%" }} align="left">
                                        Salary to Staff DPS Amount
                                    </td>
                                    <td style={{ width: "5%" }}>
                                        Rs.</td>
                                    <td style={{ width: "10%" }} align="left">
                                        {calculateColumnTotal(listOfLedger, 'Net')}
                                    </td>
                                    <td style={{ width: "10%" }} align="right">
                                        Cheque No.</td>
                                    <td style={{ width: "10%" }}>

                                        _________________</td>
                                    <td style={{ width: "30%" }}>

                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: "5%" }}>
                                        2</td>
                                    <td style={{ width: "30%" }} align="left">
                                        Income Tax Deduction</td>
                                    <td style={{ width: "5%" }}>
                                        Rs.
                                    </td>
                                    <td style={{ width: "10%" }} align="left">
                                        {Math.abs(calculateColumnTotal(listOfLedger, 'Tax'))}
                                    </td>
                                    <td style={{ width: "10%" }} align="right">
                                        Cheque No.</td>
                                    <td style={{ width: "10%" }}>

                                        _________________</td>
                                    <td style={{ width: "30%" }}>

                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: "5%" }}>
                                        3</td>
                                    <td style={{ width: "30%" }} align="left">
                                        E.O.B.I. Employees Contribution</td>
                                    <td style={{ width: "5%" }}>
                                        Rs.</td>
                                    <td style={{ width: "10%" }} align="left">
                                        {Math.abs(calculateColumnTotal(listOfLedger, 'EOBI'))}
                                    </td>
                                    <td style={{ width: "10%" }} align="right">
                                        Cheque No.</td>
                                    <td style={{ width: "10%" }}>

                                        _________________</td>
                                    <td style={{ width: "30%" }}>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: "5%" }}>
                                        4</td>
                                    <td style={{ width: "30%" }} align="left">
                                        Welfare Fund Contribution</td>
                                    <td style={{ width: "5%" }}>
                                        Rs.</td>
                                    <td style={{ width: "10%" }} align="left">
                                        {Math.abs(calculateColumnTotal(listOfLedger, 'SWF'))}
                                    </td>
                                    <td style={{ width: "10%" }} align="right">
                                        Cheque No.</td>
                                    <td style={{ width: "10%" }}>

                                        _________________</td>
                                    <td style={{ width: "30%" }}>
                                    </td>
                                </tr>

                                <tr>
                                    <td style={{ width: "5%" }}>
                                        5</td>
                                    <td style={{ width: "30%" }} align="left">
                                        E.O.B.I. Employers Contribution</td>
                                    <td style={{ width: "5%" }}>
                                        Rs.</td>
                                    <td style={{ width: "10%" }}>
                                        _________________</td>
                                    <td style={{ width: "10%" }} align="right">
                                        Cheque No.</td>
                                    <td style={{ width: "10%" }}>

                                        _________________</td>
                                    <td style={{ width: "30%" }}>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: "5%" }}>
                                        6</td>
                                    <td style={{ width: "30%" }} align="left">
                                        Social Security Contribution</td>
                                    <td style={{ width: "5%" }}>
                                        Rs.</td>
                                    <td style={{ width: "10%" }}>
                                        _________________</td>
                                    <td style={{ width: "10%" }} align="right">
                                        Cheque No.</td>
                                    <td style={{ width: "10%" }}>

                                        _________________</td>
                                    <td style={{ width: "30%" }}>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style={{ clear: "both", float: "left", marginTop: "20px", width: "100%" }}>
                        <table className="table table-sm table-bordered table-print" style={{ borderCollapse: "collapse", border: "transparent" }}>
                            <tbody>
                                <tr className="my-5">
                                    <th style={{ width: "20%", textDecoration: "underline" }} >
                                    </th>
                                    <th style={{ width: "20%" }}>
                                        Principal
                                    </th>
                                    <th style={{ width: "20%" }}>
                                        Admin Officer
                                    </th>
                                    <th style={{ width: "20%" }}>
                                        Bursur
                                    </th>
                                    <th style={{ width: "20%" }}>
                                        Accountant
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style={{ clear: "both", float: "left", marginTop: "10px", width: "100%" }}>
                        <table className="table table-sm table-bordered table-print" style={{ borderCollapse: "collapse", border: "transparent" }}>
                            <tbody>
                                <tr className="">
                                    <th>DC/CHAIRMAN BOG'S PLEASE:</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Page>
            </div>
        </>
    );
}

export default PrintSummarySheet;
