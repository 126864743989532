import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import moment from "moment";
import PrintHeader from '../General/PrintHeader';
import { useNavigate, useParams } from 'react-router-dom';
import Page from "../General/Page";

const OralAssessmentReport2 = (props) => {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [listOfLedger, setLedger] = useState([]);
    const [isUpdated, setIsUpdated] = useState(false);
    const [selectedMonth, setselectedMonth] = useState('');


    const [WingName, setWingName] = useState("");
    const [ClassName, setClassName] = useState("");
    const [Section_Name, setSection_Name] = useState("");

    let params = useParams();

    const urlParams = new URLSearchParams(window.location.search);

    const lang = urlParams.get('lang');


    useEffect(() => {

        document.title = "Oral Assessment Report";

        setIsUpdated(false);
        setLoading(true);



        setselectedMonth(moment().format('MMMM'));

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            pageNo: 1,
            pageSize: 2000,
            Section_Id:  props.Section_ID ? props.Section_ID : params.id,
        };

       //console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Student/SearchStudents',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        //console.log(data)
        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setLedger(response.data.ListofRecords);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);

            })
            .catch(function (error) {
                //console.log(error);
            });

    }, []);


    const renderLedger = () => {

        if (listOfLedger != null && listOfLedger.length > 0) {
            let count = 1;
            let countbreak = 1;
            return (
                listOfLedger.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>
                                {count++}
                            </td>
                            <td>{item.Registration_No}</td>
                            <td>{item.Student_Name}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                    

                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colspan="7"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }

    return (
        <>
            <Page orientation="portrait" size="a4">
                <table className="table table-sm table-bordered table-print white-space-wrap">
                    <thead>
                        <tr>
                            <th colSpan={11}>
                                <PrintHeader title={`Oral Assessment Report ____________________ 
                                ${listOfLedger[0]?.SelectedClass?.SelectedWing?.Wing_Name}-
                                ${listOfLedger[0]?.SelectedClass?.Class_Name}-
                                ${listOfLedger[0]?.SelectedSection?.Section_Name}`} />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={11}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td><br />Teacher Name: __________________________________________</td>
                                            <td><br />Subject: ____________________________________________</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <th>Sr#</th>
                            <th style={{ width: "70px" }}>Code</th>
                            <th style={{ width: "150px" }}>Student's Name</th>
                            <th className="text-center"> <span className="font-ur">تعوذ، تسمیہ 1</span> </th>
                            <th className="text-center"> <span className="font-ur">زیر زبر تشدید کی پہچان 1</span> </th>
                            <th className="text-center"> <span className="font-ur">تجوید 1</span> </th>
                            <th className="text-center"> <span className="font-ur">روانی 1</span> </th>
                            <th className="text-center"> <span className="font-ur">رموز اوقاف 1</span> </th>
                            <th className="text-center"> <span className="font-ur">حفظ 5</span> </th>
                            <th className="text-center"> <span className="font-ur">ترجمہ 5</span></th>
                            <th className="text-center"> <span className="font-ur">قل نمبرز</span> </th>
                            

                        </tr>

                        {renderLedger()}



                        {
                            loading ?
                                <tr>
                                    <td className="text-center" colspan="11">
                                        <Spinner animation="border" role="status"></Spinner>
                                    </td>
                                </tr>
                                :
                                <>
                                 
                                </>

                        }

                    </tbody>
                </table>
            </Page >
        </>
    );
}

export default OralAssessmentReport2;
