import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import moment from "moment";
import SupportMenu from "./SupportMenu";
import { Link, NavLink, useParams } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import Pagination from "../General/Pagination";
import SupportTopMenu from "./SupportTopMenu";
function TicketsPending(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [tickets, setTickets] = useState([]);
    const [ticketType, setTicketType] = useState(null);
    const [loading, setLoading] = useState(false);


    // pagination
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);


    let params = useParams();


    useEffect(() => {

        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize

        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Support/GetSupportTicket',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                if (response.data.status_code == 1) {
                    setTickets(response.data.ListofTickets);
                    setTotalRecords(response.data.totalRecords);
                    //console.log(response.data.ListofTickets)
                }

                setLoading(false);
            })
            .catch(function (error) {
                //console.log(error);
            });



    }, [pageNo, pageSize]);


    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const renderLogs = () => {
        if (tickets != null) {


            let count = 1;
            return (
                tickets.map((item, index) => {

                    return (
                        <>
                            <tr key={index}>
                                {/* <td>{count++ + (pageSize * (pageNo - 1))}</td> */}
                                <td>{item.TicketID}</td>
                                <td>{moment(item.Dated).format('DD MMM, YYYY HH:mm:ss')}</td>
                                <td>{item.TicketType}</td>
                                <td>
                                    {item.Subject}
                                </td>
                                {/* <td>
                                    {item.Subject.length > 30
                                        ? `${item.Subject.substring(0, 30)}...`
                                        : item.Subject}
                                </td>
                                 
                                <td>{item.Status}</td>
                                 <td><span className="count">{item.NoofReplies}</span></td> */}

                                <td>
                                    <NavLink to={`/support/support/detail/${item.ID}`} className="button-detail support-action">
                                        Check <span><i className="material-symbols-outlined">chevron_right</i>  </span>
                                    </NavLink>
                                </td>
                            </tr>
                        </>
                    )
                })
            )
        }
    }


    return (
        <>
            <SupportTopMenu />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item"> <Link to="/support/raise-ticket">Support</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Add New Class</li>
                                </ol>
                            </nav>
                            <div className="page-title">Add New Class</div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="container">
                <div className="card">
                    <div className="card-body p-md-4">
                        <SupportMenu />
                        <div className="d-title">Support Tickets</div>
                        <div className="card-table  mb-5">
                            <div className="card-inner">

                                {
                                    loading ?
                                        <div className="p-5">
                                            <Spinner animation="border" role="status"></Spinner>
                                        </div>
                                        :
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Ticket ID</th>
                                                    <th>Date</th>
                                                    <th>Department</th>
                                                    <th>Subject</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {renderLogs()}
                                            </tbody>
                                        </table>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {totalRecords > 10 && (
                <div className="pagination-wrap">
                    <div className="row align-items-center">
                        <div className="col-md-2 form_default">
                            <div className="d-flex align-items-center">
                                <label className="me-2" style={{ fontSize: "15px" }}>
                                    Page size
                                </label>
                                <div className="form-group">
                                    <select
                                        className="form-select"
                                        style={{ width: "100px" }}
                                        onChange={(e) => {
                                            setPageNo(1);
                                            setPageSize(parseInt(e.target.value));
                                        }}
                                    >
                                        <option value="10" selected>
                                            10
                                        </option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="500">500</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <Pagination
                            pageNo={pageNo}
                            pageSize={pageSize}
                            totalRecords={totalRecords}
                            onPageChange={handlePageChange}
                        />
                    </div>
                </div>
            )}

        </>
    );
}

export default TicketsPending;