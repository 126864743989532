import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { NavLink , Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from 'react-toastify';
import LibraryMenu from './LibraryMenu';
import book_issued from '../../assets/images/dashboard_icon/book_issued.svg';
import total_book from '../../assets/images/dashboard_icon/total_book.svg';
import wating_book from '../../assets/images/dashboard_icon/wating_book.svg';
import { scrollToTop } from '../Shared/ScrollOnTop';

function LibraryDashboard(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [hasError, setHasError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [TotalBooks, setTotalBooks] = useState(0);
    const [BooksWaitingReturn, setBooksWaitingReturn] = useState(0);
    const [BooksIssued, setBooksIssued] = useState(0);
    const [ListOfBooksbyWriter, setListOfBooksbyWriter] = useState({});
    const [ListOfBooksbyPublisher, setListOfBooksbyPublisher] = useState({});

    let params = useParams();

    let navigate = useNavigate();

    useEffect(() => {
        document.title = "Library Dashboard";

        const data = {
            AccessKey: AccessKey,
            UserID: UserID
        };
        //console.log(data);
        var api_config = {
            method: 'post',
            url: config.base_url + 'Library/GetLibraryDashboard',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                    //console.log(response.data);
                    setBooksIssued(response.data.DashboardData.BooksIssued);
                    setBooksWaitingReturn(response.data.DashboardData.BooksWaitingReturn);
                    setTotalBooks(response.data.DashboardData.TotalBooks);
                    setListOfBooksbyWriter(response.data.DashboardData.TopBooksByWriter);
                    setListOfBooksbyPublisher(response.data.DashboardData.TopBooksPublisher);
                }


                setLoading(false);


            })
            .catch(function (error) {
                setLoading(false);
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });


    }, []);

    const renderBooksByWriter = () => {

        if (ListOfBooksbyWriter.length > 0) {
            return (
                ListOfBooksbyWriter.map((item, index) => {
                    return (
                        <li key={index}>
                            <div className="left">
                                <div className="icon">
                                    <span>{item.Initials}</span>
                                </div>
                                <div className="lable">
                                    <h5>{item.Name}</h5>
                                </div>
                            </div>
                            <div className="right">
                                <h4>{item.Total}</h4>
                            </div>
                        </li>

                    )
                })
            )
        }
    }

    const renderBooksByPublisher = () => {

        if (ListOfBooksbyPublisher.length > 0) {
            return (
                ListOfBooksbyPublisher.map((item, index) => {
                    return (
                        <li key={index}>
                            <div className="left">
                                <div className="icon">
                                    <span>{item.Initials}</span>
                                </div>
                                <div className="lable">
                                    <h5>{item.Name}</h5>
                                </div>
                            </div>
                            <div className="right">
                                <h4>{item.Total}</h4>
                            </div>
                        </li>

                    )
                })
            )
        }
    }

    return (
        <>
            <LibraryMenu />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Library</li>
                                </ol>
                            </nav>
                            <div className="page-title">Library</div>
                        </div>
                        <div className="col-md-6">
                            <div className="page-right-actions">
                                <Link to="/Library/AddBook" className="button button-white button-icon">
                                    Add Book
                                    <i className="ri-book-open-line"></i>
                                </Link>
                                <Link to="/Library/ViewLibrary" className="button button-white button-icon">
                                    View Library
                                    <i className="ri-book-read-line"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-content">
                <div className="container">
                    <div className="row gy-3">

                        <div className="col-md-4 col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Summary</h4>
                                    <div className="row gy-4">
                                        <div className="col-md-12">
                                            <div className="icon-box">
                                                <div className="icon">
                                                    <span>
                                                        <img className="img-fluid" src={total_book} alt="" />
                                                    </span>
                                                </div>
                                                <div className="content">
                                                    <h5>Total Books</h5>
                                                    <p>
                                                        {TotalBooks}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="icon-box">
                                                <div className="icon">
                                                    <span><img className="img-fluid" src={book_issued} alt=""
                                                    /></span>
                                                </div>
                                                <div className="content">
                                                    <h5>Books Issued</h5>
                                                    <p>
                                                        {BooksIssued} </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-1">
                                            <div className="icon-box">
                                                <div className="icon">
                                                    <span><img className="img-fluid" src={wating_book} alt=""
                                                    /></span>
                                                </div>
                                                <div className="content">
                                                    <h5>Waiting For Return</h5>
                                                    <p>{BooksWaitingReturn}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Top Books by Writer</h4>
                                    <div>
                                        <ul className="library-list scrollbar">
                                            {
                                            loading ?
                                            <Spinner animation="border" role="status"></Spinner>
                                            :
                                            <>
                                                {renderBooksByWriter()}
                                            </>

                                        }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Publisher Wise List</h4>
                                    <div>
                                        <ul className="library-list scrollbar">
                                        {
                                            loading ?
                                            <Spinner animation="border" role="status"></Spinner>
                                            :
                                            <>
                                                {renderBooksByPublisher()}
                                            </>

                                        }
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default LibraryDashboard;