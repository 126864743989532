import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import QRCode from "react-qr-code";
import StudentInfo from '../FeeSystem/StudentInfo';
import moment from "moment";
import PrintHeader from '../General/PrintHeader';
import Page from "../General/Page";
import { calculateColumnTotal, getMonthName } from '../General/Utility';
import RightsCheck from "../General/RightsCheck";
const PrintBiodataLists1 = () => {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);


    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(2000);
    const [totalRecords, setTotalRecords] = useState(0);
    const [TotalAmount, setTotalAmount] = useState(0);



    const [WingName, setWingName] = useState("");
    const [ClassName, setClassName] = useState("");
    const [SessionName, setSessionName] = useState("");

    useEffect(() => {
        document.title="Print Bio Data List 1"

        setLoading(true);
        // Get the URL parameters
        const urlParams = new URLSearchParams(window.location.search);

        // Retrieve individual parameter values
        const SessionId = urlParams.get('SessionId');
        const Wing_Id = urlParams.get('Wing_Id');
        const Class_Id = urlParams.get('ClassId');
        const SectionID = urlParams.get('SectionID');
        const OrderBy = urlParams.get('OrderBy');
        const GroupId = urlParams.get('GroupId');
        const Status_Type_I = urlParams.get('Status_Type_Id');
        setWingName(urlParams.get('WingName'));
        setClassName(urlParams.get('ClassName'));
        setSessionName(urlParams.get('SessionName'));


        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            pageNo: pageNo,
            pageSize: pageSize,
            SessionID: SessionId,
            Wing_Id: Wing_Id,
            Class_Id: Class_Id,
            Section_Id: SectionID,
            Registration_No: "",
            Student_ID: "",
            Keywords: "",
            Status_Type_Id: Status_Type_I,
            Group_Id: GroupId,
            OrderBy: OrderBy,
        };

        //console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + '/Student/SearchStudents',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        //console.log(data);
        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setLedger(response.data.ListofRecords);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);
                setTotalAmount(response.data.TotalAmount);

            })
            .catch(function (error) {
                //console.log(error);
            });

    }, []);

    const renderLedger = () => {

        if (listOfLedger != null && listOfLedger.length > 0) {
            let count = 1;
            let countbreak = 1;
            return (
                listOfLedger.map((item, index) => {
                    return (
                        <tr key={index} 
                        // className={countbreak++ == 35 ? 'page-break' : ''}
                        >
                            <td>{count++}</td>
                            <td>{item.Registration_No}</td>
                            <td>{item.Student_Name}</td>
                            <td>{item.Father_Name}</td>
                            <td>{moment(item.Date_Of_Birth).format('DD/MM/YYYY')}</td>
                            <td>{item.Contact_Mobile}</td>
                            <td>{item.Permanent_Address}</td>
                            <td></td>
                            <td></td>
                        </tr>

                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colspan="20"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }


    return (
        <>
         <RightsCheck />
            <Page orientation="landscape" size="a4">
                <table className="table table-sm table-bordered table-print" >
                    <thead>
                        <tr>
                            <th colspan={20}>
                                <PrintHeader title={`List of Students:  ${WingName}-${ClassName}-${SessionName}`} />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading ?
                                <tr>
                                    <td className="text-center" colspan="8">
                                        <Spinner animation="border" role="status"></Spinner>
                                    </td>
                                </tr>
                                :
                                <>
                                    <tr>
                                        <th className="text-nowrap">Sr.#</th>
                                        <th className="text-nowrap">Code #</th>
                                        <th style={{ width: "150px" }}>Student Name</th>
                                        <th style={{ width: "150px" }}>Father Name</th>
                                        <th className="text-nowrap">DOB</th>
                                        <th className="text-nowrap">Phone</th>
                                        <th>Address</th>
                                        <th>Vaccination Status:(yes/no)</th>
                                        <th className="text-nowrap">Date:</th>
                                    </tr>
                                    {renderLedger()}
                                </>

                        }

                    </tbody>
                </table>
            </Page >
        </>
    );
}

export default PrintBiodataLists1;
