import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import RightsCheck from "../General/RightsCheck";
import { scrollToTop } from "../Shared/ScrollOnTop";

function SMSMenu(props) {
  return (
    <>
      <RightsCheck />
      <nav className="navbar navbar-expand-lg navbar-module MenuSticky">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#NavbarSecondary"
            aria-controls="NavbarSecondary"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="NavbarSecondary">
            <ul className="navbar-nav m-auto">
              <li className="nav-item">
                <NavLink
                  onClick={scrollToTop}
                  className="nav-link"
                  to="/SMSAlert/SendAlertToStaff"
                >
                  Send Alert To Staff
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  onClick={scrollToTop}
                  className="nav-link"
                  to="/SMSAlert/SendtoSinglePerson"
                >
                  Send to Single Person
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  onClick={scrollToTop}
                  className="nav-link"
                  to="/SMSAlert/SendAlertToParents"
                >
                  Send Alert to Parents
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default SMSMenu;
