import React, { useState, useEffect, useRef } from "react";
import config from "../../Config";
import axios from "axios";
import moment from "moment";
import { Link, NavLink, useParams } from "react-router-dom";
import { Cropper } from "react-cropper";
import { Form } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Logo from "../../assets/images/logo/support_logo.png";
import SupportControlMenu from "./SupportControlMenu";
import imgUser from "../../assets/images/user.png";
function AdminTicketsDetail(props) {
  const navigate = useNavigate();

  let params = useParams();
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [replies, setReplies] = useState([]);
  const [ticketType, setTicketType] = useState(null);
  const [ticket, setTicket] = useState({});
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [CloseButton, setCloseButton] = useState(false);
  const [nextId, setNextId] = useState(0);
  const [firstId, setFirstId] = useState(params.id);
  //console.log(params.id);

  const [imageSelected, setImageSelected] = useState(false);
  const [AttachmentString, setAttachmentString] = useState("");

  const [image, setImage] = useState("");
  const [cropper, setCropper] = useState();
  const [imageCropped, setImageCropped] = useState(false);

  const [replySaved, setReplySaved] = useState(false);

  const [loadingTickets, setLoadingTickets] = useState(false);
  const [attachmentImg, setAttachmentImg] = useState(null);

  const [tick, settick] = useState(false);

  const [description, setdescription] = useState("");

  const status = useRef(null);
  const [statuss, setStatuss] = useState();
  const [update, setUpdate] = useState(false);

  const [ClosedByAdmin, SetClosedByAdmin] = useState("");
  const [ClosedByUser, SetClosedByUser] = useState("");

  // const firstId = params.id;

  // modals

  const [showModalAttachment, setShowModalAttachment] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleShowModalAttachment = (item) => {
    setAttachmentImg(item);
    setShowModalAttachment(true);
  };

  const handleCloseModalAttachment = () => {
    setShowModalAttachment(false);
  };

  const CloseTicket = () => {

    setCloseButton(true)

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: params.id,
      MemberType: "Admin",
      Description: description,
      FileAttached: AttachmentString,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "Support/CloseTicketAdmin",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          toast.success("Ticket is resolved", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setCloseButton(false)
          window.location.reload();
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setCloseButton(false)
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
    setCloseButton(false)
  };
  const TicketResolved = () => {
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: params.id,
      MemberType: "Admin",
      Description: description,
      FileAttached: AttachmentString,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "Support/ResolveTicketAdmin",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          toast.success("Ticket is resolved", {
            position: toast.POSITION.TOP_RIGHT,
          });
          window.location.reload();
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  useEffect(() => {
    document.title = "Ticket Detail";
    setReplySaved(false);
    setUpdate(false);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: params.id,
      MemberType: "Admin",
    };
    var api_config = {
      method: "post",
      url: config.base_url + "Support/GetTicketByIDAdmin",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setReplies(response.data.ListofReplies);
        setTicket(response.data);
        setNextId(response.data.nextTicketId);
        setStatuss(response.data.Status);
        //console.log("Statuss", response.data.Status);
        SetClosedByAdmin(response.data.ClosedByAdmin);
        SetClosedByUser(response.data.ClosedByUser);

        const element = document.getElementById("chat-thread");
        element.scrollTop = element.scrollHeight;
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [replySaved, update, statuss, loadingTickets]);


  const handleShowModal = () => setShowModal(true);

  const handleCloseModal = () => {
    setShowModal(false);
    setImageSelected(false);
    setLoading(false);
  };

  const onChange = (e) => {
    e.preventDefault();
    setImageSelected(true);
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      var cropData1 = cropper.getCroppedCanvas().toDataURL();
      setImageCropped(true);
      var cleanerBase64 = cropData1.substring(22);
      setAttachmentString(cleanerBase64);
      handleCloseModal();
      settick(true);
    }
  };

  const SubmitReply = (event) => {
    setLoadingTickets(true);
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      const data = {
        UserID: UserID,
        AccessKey: AccessKey,
        ID: params.id,
        Description: description,
        Status: "",
        FileAttached: AttachmentString,
        MemberType: "Admin",
      };

      var api_config = {
        method: "post",
        url: config.base_url + "Support/AdminReplyTicket",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          if (response.data.status_code == 1) {
            setReplySaved(true);
            setLoadingTickets(false);
            setdescription("");
            settick(false);
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            const chatThread = document.getElementById("chat-thread");
            chatThread.scrollTop = chatThread.scrollHeight;
          } else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoadingTickets(false);
          }
        })
        .catch(function (error) {
          toast.error("Network Error..", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
    setValidated(true);
  };
  const handleNext = async () => {
    if (firstId > 0) {
      setFirstId(firstId);
    }

    if (nextId > 0 && nextId != firstId) {
      navigate(`/admin-support/ticket-detail/${nextId}`);
      setUpdate(true);
    }
    if (nextId == firstId) {
      //   //console.log("error");
      toast.error("No More Request Found", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const renderReplies = () => {
    if (replies != null) {
      return replies.map((item, index) => {
        return (
          <li
            key={index}
            className={item.Member_ID == 0 ? "admin-reply" : "user-reply"}
          >
            <div className="comment-item">
              <div className="thumb">
                {
                  item.Member_ID == 0 ?
                    <img src={Logo} alt="" className="img-fluid" />
                    :
                    (item.Img_File == null ?
                      <img src={imgUser} alt="" className="img-fluid" />
                      :
                      <img src={config.img_path + item.Img_File} alt="" />
                    )
                }
              </div>
              <div className="comment">
                <div className="comment-message">
                  <div className="inner">
                    <h5>
                      {item.Member_ID > 0 ? item.Member_Name : item.Admin_Name}
                    </h5>
                    <div
                      dangerouslySetInnerHTML={{ __html: item.Description }}
                    ></div>
                  </div>
                </div>
                <div className="comment-actions">
                  {
                    item.FileAttached != null &&
                    <>
                      <button className="button button-view-attachment" onClick={(e) => handleShowModalAttachment(item.FileAttached)}>
                        <i className="ri-attachment-line"></i> View Attachment
                      </button>
                    </>
                  }
                  <span className="date">{moment(item.Created_Date).format('ddd, DD MMM YYYY HH:mm:ss')}</span>
                </div>
              </div>
            </div>
          </li>
        );
      });
    }
  };
  const renderCloseButton = () => {
    if (statuss === "Closed") {
      return (
        <>
          <div className="col-md-2 offset-md-10">
            <button
              disabled={nextId == 0}
              onClick={handleNext}
              className="button button-gray w-100"
            >
              Next
            </button>
          </div>
        </>
      )
    }
    else if (ClosedByAdmin == true && ClosedByUser == false) {
      return (
        <>
          <div className="col-md-2 offset-md-5">
            <button
              onClick={CloseTicket}
              className="button button-gray w-100">Close Ticket</button>
          </div>
          <div className="col-md-2 offset-md-3">
            <button
              disabled={nextId == 0}
              onClick={handleNext}
              className="button button-gray w-100"
            >
              Next
            </button>
          </div>
        </>
      )
    }
    else if (ClosedByAdmin == false && ClosedByUser == false) {
      return (
        <>
          <div className="col-md-2">
            <button
              onClick={TicketResolved}
              className={`button button-gray w-100`}
            >
              Ticket resloved
            </button>
          </div>
          <div className="col-md-2 offset-md-3">
            <button
              onClick={CloseTicket}
              className="button button-gray w-100">Close Ticket</button>
          </div>
          <div className="col-md-2 offset-md-3">
            <button
              disabled={nextId == 0}
              onClick={handleNext}
              className="button button-gray w-100"
            >
              Next
            </button>
          </div>
        </>
      )
    }
    else if (ClosedByAdmin == true && ClosedByUser == true) {
      return (
        <>
          <div className="col-md-2 offset-md-10">
            <button
              disabled={nextId == 0}
              onClick={handleNext}
              className="button button-gray w-100"
            >
              Next
            </button>
          </div>
        </>
      )
    }
  }

  return (
    <>
      <div className="admin-content">
        <ToastContainer />
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Upload Attachment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="upload-photo-wrap">
              {imageSelected ? (
                <Cropper
                  zoomTo={0.5}
                  preview=".img-preview"
                  src={image}
                  dragMode="move"
                  viewMode={2}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                  guides={true}
                />
              ) : (
                <div className="choose-photo">
                  <label htmlFor="ChoosePhoto">
                    <i className="material-symbols-outlined">upload</i>
                    <span>Browse Photo</span>
                    <input
                      id="ChoosePhoto"
                      accept="image/jpeg,image/png"
                      type="file"
                      onChange={onChange}
                    />
                  </label>
                </div>
              )}
            </div>
            <div className="modal-footer justify-content-center">
              <button
                className="button button-outline-primary me-2"
                onClick={handleCloseModal}
              >
                Close
              </button>
              <button className="button button-gray" onClick={getCropData}>
                Save Photo
              </button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          className="admin-support-modal"
          show={showModalAttachment}
          onHide={handleCloseModalAttachment}
        >
          <Modal.Header closeButton>
            <Modal.Title>View Attachment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img
              src={config.root + `SupportTicket/SupportFiles/${attachmentImg}`}
              className="img-fluid"
              alt="Attachment"
            />
          </Modal.Body>
        </Modal>

        <SupportControlMenu />
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                    <li className="breadcrumb-item"> <Link to="/support/support-dashboard">Support</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Tickets Details</li>
                  </ol>
                </nav>
                <div className="page-title">Tickets Details ({ticket.TicketType})</div>
              </div>
              <div className="col-md-6 text-md-end text-center">
                <NavLink
                  to="/admin-support/support-dashboard"
                >
                  <button type="button" onClick={() => window.history.back()} className="button button-primary">
                    Back
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="card">
            <div className="card-body p-md-4">

              <div className="card-gradient">
                <div className="card-inner bg-white p-0">
                  <div>
                    <div className={`comment-posts`}>
                      <div className="post-item">
                        <ul className="post-info-actions">
                          <li> <i className="ri-coupon-line"></i> Ticket ID : {ticket.TicketID}
                          </li>
                          <li>
                            <i className="ri-calendar-2-fill"></i>
                            {moment(ticket.Dated).format("ddd, DD MMM YYYY HH:mm:ss")}
                          </li>
                          <li>
                            {ticket.FileAttached != null ? (
                              <>
                                <li className="laptop-view">
                                  <i className="ri-attachment-line"></i>
                                  <a
                                    href="#"
                                    className="text-white"
                                    onClick={(e) =>
                                      handleShowModalAttachment(ticket.FileAttached)
                                    }
                                  >
                                    View Attachment
                                  </a>
                                </li>
                                <li className="mobile-view">
                                  <i
                                    onClick={(e) =>
                                      handleShowModalAttachment(ticket.FileAttached)
                                    }
                                    className="ri-attachment-line"
                                  ></i>
                                </li>
                              </>
                            ) : (
                              <>
                                <li className="laptop-view">
                                  <i className="ri-attachment-line"></i>
                                  View Attachment
                                </li>
                                <li className="mobile-view">
                                  <i className="ri-attachment-line">
                                    attachment
                                  </i>
                                </li>
                              </>
                            )}
                            {/* <i className="material-symbols-outlined">attachment</i> <a href="#" onClick={(e) => handleShowModalAttachment(ticket.FileAttached)}>View Attachment</a> */}
                          </li>
                          <li>
                            <i className="ri-chat-poll-line"></i>
                            {ticket.NoofReplies} Comments
                          </li>
                        </ul>
                        <ul className="post-comments" id="chat-thread">
                          {renderReplies()}
                        </ul>

                        <Form noValidate validated={validated} onSubmit={SubmitReply}>
                          <div className="reply">
                            <div className="row align-items-center gy-3">
                              <div className="col-md-12">
                                <textarea
                                  rows={3}
                                  value={description}
                                  className="form-control"
                                  onChange={(e) => setdescription(e.target.value)}
                                  placeholder="Type a Reply"
                                  type="text"
                                />
                              </div>

                              <div className="col-2 offset-9">
                                <button
                                  disabled={loadingTickets || description.length == 0}
                                  onClick={SubmitReply}
                                  className="button button-primary submit w-100">
                                  Send {loadingTickets ? '...' : ''}
                                </button>
                              </div>
                              <div className="col-1 text-center">
                                <div
                                  className={`attachment-icon ${tick ? "primary" : "gray"}`}
                                  onClick={handleShowModal}
                                >
                                  <i className="ri-attachment-line"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5 gy-3">
                {renderCloseButton()}
              </div>
            </div >
          </div >
        </div >
      </div >
    </>
  );
}

export default AdminTicketsDetail;
