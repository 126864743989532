import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import PrintHeader from "../General/PrintHeader";
import Page from "../General/Page";
import { scrollToTop } from "../Shared/ScrollOnTop";

function SectionWiseList(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [listOfLedger, setLedger] = useState([]);
  const [sections, setSections] = useState("");
  const [selectClass, setselectClass] = useState("");
  const [selectwing, setselectwing] =useState("");
  const [SectionID, SetSectionID] = useState(0);

  let params = useParams();
  
  useEffect(() => {
    document.title = "Section Wise Students List";

    setIsUpdated(false);
    setLoading(true);

  //   if (props.Section_ID) {
  //     SetSectionID(props.Section_ID);
  // } else {
  //     SetSectionID(params.id);
  // }

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: 1,
      pageSize: 2000,
      Section_Id: props.Section_ID ? props.Section_ID : params.id,
    };

      //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Student/SearchStudents",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
 
         setSections(  response.data.ListofRecords[0].SelectedSection?.Section_Name);
         setselectClass(response.data.ListofRecords[0].SelectedClass?.Class_Name);
         setselectwing(response.data.ListofRecords[0].SelectedClass?.SelectedWing?.Wing_Name);


        
         //console.log(sections);
      
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [props.Section_ID]);

  const renderLedger = () => {
    if (listOfLedger.length > 0) {
      let count = 1;
      let countbreak = 1;
      return listOfLedger.map((item, index) => {
      
        return (
          <tr key={index} >
            <td>{count++}</td>
            <td>{item.Registration_No}</td>
            <td>{item.Student_Name}</td>
            <td>{item.Father_Name}</td>
            <td>{moment(item.Date_Of_Birth).format("DD/MM/YYYY")}</td>
            <td>{moment(item.Date_Of_Joining).format("DD/MM/YYYY")}</td>
            <td></td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="7">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <Page orientation="portrait" size="a4">
        <PrintHeader title={`Section Wise Students List of Section ${selectwing} ${selectClass} - ${sections}  ` } />
        <table className="table table-sm table-bordered table-print">
          <thead>
            <tr>
              <th>SR #</th>
              <th>Reg No</th>
              <th>Name</th>
              <th>Father Name</th>
              <th>DOB</th>
              <th>DOJ</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colspan="8">
                  <Spinner animation="border" role="status"></Spinner>
                </td>
              </tr>
            ) : (
              <>{renderLedger()}</>
            )}
          </tbody>
        </table>
      </Page>
    </>
  );
}

export default SectionWiseList;
