import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import user from '../../assets/images/user.png';
import { useNavigate } from 'react-router-dom';
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Pagination from "../General/Pagination";
import { scrollToTop } from '../Shared/ScrollOnTop';
import SectionDropDown from "../Shared/SectionDropDown";
import ClassesDropDown from "../Shared/ClassesDropDown";
import WingsDropDown from "../Shared/WingsDropDown";
import SessionsDropDown from "../Shared/SessionsDropDown";
import { Form, Spinner } from "react-bootstrap";
import PhotoMenu from "./PhotoMenu";

function ManagePhotoRequest() {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    const [isUpdated, setIsUpdated] = useState(false);
    const [showFilters, setShowFilters] = useState(true);
    const [NewWingId, setNewWingId] = useState(0);
    const [NewClassId, setNewClassId] = useState(0);
    const [SessionId, setSessioinId] = useState(
        localStorage.getItem("DefaultSession")
    );
    const [SectionID, setSectionID] = useState(0);

    const [Remarks, setRemarks] = useState({});
    const [Status, setStatus] = useState({});
    const [Photo, setPhoto] = useState({});

    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {

        document.title = "Manage Photo";

        setIsUpdated(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            pageNo: pageNo,
            pageSize: pageSize,
            SessionID: SessionId,
            Wing_Id: NewWingId,
            Class_Id: NewClassId,
            Section_Id: SectionID,

        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'PhotoUpdateRequest/GetAllRequests',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        // console.log(data)


        axios(api_config)
            .then(function (response) {
                // console.log(response.data)
                if (response.data.status_code == 1) {
                    setLedger(response.data.ListofRecords);
                    setTotalRecords(response.data.totalRecords);
                    setIsUpdated(false);
                }
                else {
                    setLedger(null);
                    setTotalRecords(0)
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setIsUpdated(false);

                }

            })
            .catch(function (error) {
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });

    }, [
        pageNo,
        pageSize,
        SessionId,
        NewWingId,
        SectionID,
        loading

    ]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const navigate = useNavigate();



    const handleStatusChange = (id, status) => {
        setStatus((prevStatus) => ({
            ...prevStatus,
            [id]: status,
        }));
    };

    const handleRemarksChange = (id, remarks) => {
        setRemarks((prevRemarks) => ({
            ...prevRemarks,
            [id]: remarks,
        }));
    };


    const handleUpdate = (id) => {
        setLoading(true); 
        const status = Status[id];
        const remarks = Remarks[id];

        // Find the selected photo URL from the list based on the ID
        const selectedPhotoURL = listOfLedger.find((item) => item.ID === id).Photo;

        if (!status) {
            toast.error("Please select a status before updating.", {
                position: toast.POSITION.TOP_RIGHT,
            });
            setLoading(false);
            return;
        }

        
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            ID: id,
            Status: status,
            Remarks: remarks || "Approved",
            Photo: ''
        };
        // console.log(data, 'data----------');



        const updateRequest = (photoBase64) => {
            data.Photo = status === 'Approved' ? photoBase64 : '';

            let api_config = {
                method: 'post',
                url: config.base_url + 'PhotoUpdateRequest/UpdateRequest',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: data,
            };

            axios(api_config)
                .then(function (response) {
                    if (response.data.status_code === 1) {
                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    } else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                    setLoading(false);
                })
                .catch(function (error) {
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setLoading(false); 
                });
        };

        // If the status is 'Approved', convert the image to a base64 string
        if (status === 'Approved') {
            fetch(`https://api.dpskasur.edu.pk/images/PhotoUpdateRequest/${selectedPhotoURL}`) // Fetch the image
                .then(response => response.blob()) // Convert the response to a Blob
                .then(blob => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob); // Read the Blob as a base64 string
                    reader.onloadend = () => {
                        updateRequest(reader.result); // Call the API request function with the base64 string
                    };
                })
                .catch(error => {
                    // Handle any errors in fetching or converting the image
                    toast.error('Error converting image to base64:', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setLoading(false); // Stop loading state
                });
        } else {
            // If status is not 'Approved', proceed without a photo
            updateRequest('');
        }
    };


    const renderLedger = () => {
        if (listOfLedger && listOfLedger.length > 0) {
            return listOfLedger.map((item, index) => {
                const status = Status[item.ID] || '';
                const remarks = Remarks[item.ID] || '';

                return (
                    <div key={index} className="student--card mt-3">
                        <div className="card-header">
                            <div className="profile">
                                <img
                                    src={`${config.StudentImagePath}${item.RegistrationNo}.jpg`}
                                    onError={(e) => e.target.src = user}
                                    alt="profile"
                                />
                            </div>
                        </div>
                        <div className="card-header">
                            <div className="profile">
                                <img
                                    src={`https://api.dpskasur.edu.pk/images/PhotoUpdateRequest/${item.Photo}`}
                                    onError={(e) => e.target.src = user}
                                    alt="profile"
                                />
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="menu">
                                <div className="outline">
                                    <span>Name</span><b> {item.Name}</b>
                                </div>
                                <div className="outline">
                                    <span>Father Name</span><b> {item.FatherName}</b>
                                </div>
                                <div className="outline">
                                    <span>Registration No</span><b> {item.RegistrationNo}</b>
                                </div>
                                <div className="outline">
                                    <span>Class</span><b> {item.ClassName}-{item.WingName}-{item.SectionName}</b>
                                </div>

                                <div className="d-flex align-items-center justify-content-between gap-4 w-100 mt-3">
                                    <div className="d-flex align-items-center gap-2">
                                        <Form.Check
                                            inline
                                            label="Approved"
                                            name={`radio-${index}`}
                                            type="radio"
                                            id={`approved-${index}`}
                                            onChange={() => handleStatusChange(item.ID, 'Approved')}
                                            checked={status === 'Approved'}
                                        />
                                        <Form.Check
                                            inline
                                            label="Reject"
                                            name={`radio-${index}`}
                                            type="radio"
                                            id={`reject-${index}`}
                                            onChange={() => handleStatusChange(item.ID, 'Reject')}
                                            checked={status === 'Reject'}
                                        />

                                        {status === 'Reject' && (
                                            <div className="form-group">
                                                <select
                                                    className="form-select"
                                                    onChange={(e) => handleRemarksChange(item.ID, e.target.value)}
                                                    value={remarks}
                                                >
                                                    <option value="Photo is Blur">Photo is Blur</option>
                                                    <option value="Photo is Invalid">Photo is Invalid</option>
                                                    <option value="Student is not in uniform">Student is not in uniform</option>
                                                </select>
                                            </div>
                                        )}
                                    </div>

                                    <div className="d-flex gap-2 justify-content-end">
                                        <button
                                            type="button"
                                            className="button button-primary"
                                            onClick={() => handleUpdate(item.ID)}
                                        >
                                            {loading ? (
                                                <Spinner animation="border" size="sm" />
                                            ) : (
                                                "Save"
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
        } else {
            return (
                <div className="no-record-found mt-5">
                    <h5>No records found</h5>
                </div>
            );
        }
    };

    return (
        <>
            <PhotoMenu />
            <ToastContainer />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Update Photo request</li>
                                </ol>
                            </nav>
                            <div className="page-title">Update Photo request</div>
                        </div>
                        <div className="col-md-6 text-end">
                            <button
                                onClick={() => {
                                    setShowFilters((prevIsActive) => !prevIsActive);
                                }}
                                className="button button-white button-icon"
                            >
                                {showFilters ? "Hide Filters" : "Show Filters"}
                                <i className="ri-filter-3-line"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`collapse ${showFilters ? "show" : ""} `}
                id="collapseFilteres"
            >
                <Form
                //  noValidate validated={validated} onSubmit={handleSubmit}
                >
                    <div className="page-content">
                        <div className="container">
                            <div className="card mb-2">
                                <div className="card-body p-md-4">
                                    <div className="row align-items-end gy-3">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Select Session:</label>
                                                <select
                                                    className="form-select"
                                                    onChange={(e) => setSessioinId(e.target.value)}
                                                    name="SessionID"
                                                >
                                                    <option value="">---All---</option>
                                                    <SessionsDropDown selectedSession={SessionId} />
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Select Wing:</label>
                                                <select
                                                    name="Wing_Id"
                                                    onChange={(e) => setNewWingId(e.target.value)}
                                                    className="form-select"
                                                >
                                                    <option value="0">---All---</option>
                                                    <WingsDropDown />
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="" className="">
                                                    Select Class:
                                                </label>
                                                <select
                                                    className="form-select"
                                                    onChange={(e) => setNewClassId(e.target.value)}
                                                    name="Class_ID"
                                                >
                                                    <option value="">Please Select</option>
                                                    {SessionId != 0 && NewWingId != 0 && (
                                                        <ClassesDropDown
                                                            SessionID={SessionId}
                                                            WingID={NewWingId}
                                                        />
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="" className="">
                                                    Select Section:
                                                </label>
                                                <select
                                                    className="form-select"
                                                    onChange={(e) => setSectionID(e.target.value)}
                                                    name="Section_Id"
                                                >
                                                    <option value="0">Please Select</option>
                                                    {NewClassId !== 0 && (
                                                        <SectionDropDown ClassID={NewClassId} />
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
            {
                isUpdated ?
                    <div className="text-center">
                        <Spinner animation="border" role="status" className="mt-5 text-center"></Spinner>
                    </div>
                    :
                    <div className="page-content">
                        <div className="container">
                            {renderLedger()}

                            {
                                totalRecords > 10 &&
                                <div className="card">
                                    <div className="card-body table_card">
                                        <div className="">
                                            <div className="pagination-wrap">

                                                <div className="row align-items-center">
                                                    <div className="col-md-2 form_default">
                                                        <div className="d-flex align-items-center">
                                                            <label className="me-2" style={{ fontSize: "15px" }}>Page Size</label>
                                                            <div className="form-group">
                                                                <select className="form-select"
                                                                    style={{ width: "100px" }}
                                                                    onChange={(e) => {
                                                                        setPageNo(1);
                                                                        setPageSize(parseInt(e.target.value));


                                                                    }}>
                                                                    <option value="10" selected>10</option>
                                                                    <option value="20">20</option>
                                                                    <option value="50">50</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <Pagination
                                                        pageNo={pageNo}
                                                        pageSize={pageSize}
                                                        totalRecords={totalRecords}
                                                        onPageChange={handlePageChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            }

                        </div>
                    </div>
            }
        </>
    );
}

export default ManagePhotoRequest;
