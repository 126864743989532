import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import SessionsDropDown from "../Shared/SessionsDropDown";
import WingsDropDown from "../Shared/WingsDropDown";
import ClassesDropDown from "../Shared/ClassesDropDown";
import { toast, ToastContainer } from "react-toastify";
import { NavLink } from "react-router-dom";
import moment from "moment";
import AnnualMenu from "./AnnualMenu";
import Tooltip from "react-bootstrap/Tooltip";

function MarkAsStudyComplete(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);

  const [WingId, setWingId] = useState(0);
  const [SessionId, setSessioinId] = useState(
    localStorage.getItem("DefaultSession")
  );
  const [ClassId, setClassId] = useState(0);

  document.title = "Mark As Study Complete";

  const handleStudyComplete = () => {
    setLoading(true);
    const apiData = {
      AccessKey: AccessKey,
      UserID: UserID,
      Class_Id: ClassId,
      SessionID: SessionId,
      MemberType: MemberType,
    };
    //console.log(apiData);
    var api_config = {
      method: "post",
      url: config.base_url + "AnnualTask/MarkStudyComplete",
      headers: {
        "Content-Type": "application/json",
      },
      data: apiData,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setLoading(false);
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  return (
    <>
      <AnnualMenu />
      <ToastContainer />
      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/Home/Index">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="/AnnualTasks/AnnualTasksDashboard">
                        Annual Tasks{" "}
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Mark Classes As Study Complete
                    </li>
                  </ol>
                </nav>
                <div className="page-title">Mark Classes As Study Complete</div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-content">
          <div className="container">
            <div className="card">
              <div className="card-body p-md-4">
                <p>
                  This Module will change the status of the students as Study
                  Complete. You have already created the new classes and session
                  and now you are promoting the classes into new one.
                </p>
                <div className="row align-items-center">
                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label for="" className=" col-form-label">
                        Select Session
                      </label>
                      <select
                        className="form-select"
                        onChange={(e) => setSessioinId(e.target.value)}
                        name="SessionID"
                      >
                        <option value="0">---All---</option>
                        <SessionsDropDown selectedSession={SessionId} />
                      </select>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label for="" className="col-form-label">
                        Select Wing:
                      </label>
                      <select
                        name="Wing_Id"
                        onChange={(e) => setWingId(e.target.value)}
                        className="form-select"
                      >
                        <option value="0">---All---</option>
                        <WingsDropDown />
                      </select>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label for="" className="col-form-label">
                        Select Class
                      </label>
                      <select
                        className="form-select"
                        onChange={(e) => setClassId(e.target.value)}
                        name="Class_ID"
                      >
                        <option value="">Please Select</option>
                        {SessionId != 0 && WingId != 0 && (
                          <ClassesDropDown
                            SessionID={SessionId}
                            WingID={WingId}
                          />
                        )}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-3 text-end">
                    <div className="form-group">
                      {/* <button
                        type="button"
                        className="button button-primary mt-md-2"
                        onClick={handleStudyComplete}
                      >
                        Change Status
                      </button> */}

                      {loading ? (
                        <Spinner animation="border" role="status"></Spinner>
                      ) : (
                        <button
                          type="submit"
                          onClick={handleStudyComplete}
                          value="ChangeStatus"
                          className="button button-primary w-100"
                        >
                          Change Status
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MarkAsStudyComplete;
