import React, { useState, useEffect } from 'react';
import config from "../../Config";
import axios from "axios";
import StaffAtMenu from './StaffAtMenu';
import { NavLink } from 'react-router-dom';
import staff from '../../assets/images/dashboard_icon/staff.svg'
import present from '../../assets/images/dashboard_icon/present.svg'
import not_present from '../../assets/images/dashboard_icon/not_present.svg';
import Pagination from "../General/Pagination";
import Spinner from "react-bootstrap/Spinner";

import { scrollToTop } from '../Shared/ScrollOnTop';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import { CategoryScale, Chart } from "chart.js";
import moment from "moment";
import ExamSummaryIcon from '../../assets/images/dashboard_icon/certificate_issued.svg';
import ExamSummaryDone from '../../assets/images/done.png';
import maleprofile from "../../assets/images/maleprofile.png"
import femaleprofile from "../../assets/images/femaleprofile.png"

function StaffAttendanceDashboard(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [ScrollDated, setScrollDated] = useState(moment().format("YYYY-MM-DD"));
    const [today, setToday] = useState(moment().format("YYYY-MM-DD"));

    const [Day, setDay] = useState('1');
    const [BarShape, setBarShape] = useState('Bar');
    const [Exam, setExam] = useState({});

    const [loading, setLoading] = useState(false);

    const [listOfLedger, setLedger] = useState([]);
    const [listofBirthdays, setlistofBirthdays] = useState([]);

    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [Graphdata, setGraphdata] = useState([]);




    const [isUpdated, setIsUpdated] = useState(false);
    const [SessionId, setSessioinId] = useState(
        localStorage.getItem("DefaultSession")
    );





    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            title: {
                display: false,
            },
            legend: {
                display: false,
            },
            tooltip: {
                mode: 'index',
                intersect: false,
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                titleColor: 'white',
                bodyColor: 'white',
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                stacked: true,
                grid: {
                    drawTicks: false,
                    drawBorder: false,
                },
                gridLines: {
                    color: '#e6e6e6',
                    drawBorder: false,
                },
            },
            x: {
                grid: {
                    drawOnChartArea: false,
                    drawTicks: false,
                    drawBorder: false,
                },
            },
        },
    };

    useEffect(() => {
        document.title = "Staff Attendance";

        setIsUpdated(false);
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            Dated: ScrollDated,
        };

        //console.log(data);

        var api_config = {
            method: "post",
            url: config.base_url + "StaffAttendance/GetStaffAttendanceDashboard",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setGraphdata(response.data.GraphData);
                setLedger(response.data.DepartmentWiseAttendance);
                setlistofBirthdays(response.data.TodaysAbsent);
                setLoading(false);
                //setTotalRecords(response.data.totalRecords);
            })
            .catch(function (error) {
                //console.log(error);
            });
    }, [ScrollDated , Day]);

    const renderLedger = () => {
        if (listOfLedger != null && listOfLedger.length > 0) {
            return listOfLedger.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.Department_Name}</td>
                        <td>{item.Total_Employees}</td>
                        <td>{item.Attendance_Available}</td>
                        <td>{item.Present}</td>
                        <td>{item.Absent}</td>
                        <td>{item.Maternity_Leave}</td>
                        <td>{item.Casual_Leave}</td>
                        <td>{item.Leave_Without_Pay}</td>
                        <td>{item.Other_Leave}</td>
                    </tr>
                );
            });
        } else {
            return (
                <tr>
                    <td colspan="12">
                        <h4
                            className="mt-3"
                            style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
                        >
                            No Data Found
                        </h4>
                    </td>
                </tr>
            );
        }
    };

    const renderBirthdays = () => {

        if (listofBirthdays != null && listofBirthdays.length > 0) {
            return listofBirthdays.map((item, index) => {


                return (
                    <li key={index}>
                        <div className="left">

                            <div className="icon">
                                <img
                                    className="rounded-circle"
                                    style={{ width: "60px", height: "60px" }}
                                    src={config.StaffImagePath + item.code + ".jpg"}
                                    alt=""
                                    onError={(e) => {
                                        if (item.Gender === true) {
                                            e.target.src = maleprofile;

                                        }
                                        else
                                            e.target.src = femaleprofile;
                                    }}
                                />
                            </div>

                            <div className="lable">
                                <h5>{item.Name}</h5>
                                <p>
                                    {item.Department_Name}-
                                    {item.Designation_Name}-

                                </p>
                            </div>
                        </div>
                        <div className="right">
                            <h4>{item.Absent_Leave}</h4>
                        </div>
                    </li>
                );
            });
        } else {
            return (
                <tr>
                    <td colspan="8">
                        <h4
                            className="mt-3"
                            style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
                        >
                            No Data Found
                        </h4>
                    </td>
                </tr>
            );
        }
    };

    const handlePageChange = (page) => {
        setPageNo(page);
    };



    const goToPreviousDay = (date) => {
        const yesterday = moment(ScrollDated).subtract(1, 'days').format('YYYY-MM-DD');
        setScrollDated(yesterday);

      };
    const goToNextDay = (date) => {
        const Tomorrow = moment(ScrollDated).add(1, 'days').format('YYYY-MM-DD');
        setScrollDated(Tomorrow);
      };
    




    return (
        <>
            <StaffAtMenu />
            <div className="container body-content">
                <div className="page-head">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                        <li className="breadcrumb-item active" aria-current="page">Staff Attendance</li>
                                    </ol>
                                </nav>
                                <div className="page-title">Staff Attendance</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body staff-attendance">
                        <div className="col-md-12">
                            <div className="row align-middle ">
                                <div className="col-md-6">
                                    <h4 className="card-title" style={{ color: "black" }}>Department Staff Attendance </h4>
                                </div>
                                <div className="col-md-6">
                                    <div className="icon  text-right">
                                        <a href="#" onClick={goToPreviousDay }><i className="ri-arrow-left-s-line"></i></a>
                                        <div className="form-group">
                                            <input
                                                type="date"
                                                className="form-control"
                                                onChange={(e) => setScrollDated(e.target.value)}
                                                value={ScrollDated}
                                                required
                                            />
                                        </div>
                                        <a href="#"
                                         onClick={goToNextDay}
                                         className={ScrollDated == today ? 'disabled' : '' }
                                        style={{ pointerEvents: ScrollDated == today ? 'none' : 'auto', opacity: ScrollDated  == today ? '.6' : '1' }}
                                         ><i className="ri-arrow-right-s-line "></i></a>

                                    </div>


                                </div>
                            </div>
                        </div>
                        <div className="page-content mt-1 exam-summary-card">
                            <table className="table table-theme">
                                <thead className='bg-white'>
                                    <tr>
                                        <th>Sr.No</th>
                                        <th>Department</th>
                                        <th>Total Emp</th>
                                        <th>Attendance</th>
                                        <th>P</th>
                                        <th>A</th>
                                        <th>MAT</th>
                                        <th>CL</th>
                                        <th>LWO</th>
                                        <th>ETC</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? (
                                        <tr>
                                            <td className="text-center" colspan="10">
                                                <Spinner animation="border" role="status"></Spinner>
                                            </td>
                                        </tr>
                                    ) : (
                                        <>{renderLedger()}</>
                                    )}
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>

                <div className='graph mt-5'>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="earning-card dash-bg-card" >
                                <div className='graph-bar'>
                                    <div className="dash-header">
                                        <div className="heading">
                                            Last 10 days Attendance Percentage
                                        </div>
                                        <div className="menu">
                                            <ul className="menu-link">
                                                {/* <li><a className={Day == "4" ? "active" : ""} onClick={(e) => setDay("4", e)}>Today</a></li>
                                                <li><a className={Day == "3" ? "active" : ""} onClick={(e) => setDay("3", e)}>This Week</a></li>
                                                <li><a className={Day == "2" ? "active" : ""} onClick={(e) => setDay("2", e)}>Last 15 Days</a></li>
                                                <li><a className={Day == "1" ? "active" : ""} onClick={(e) => setDay("1", e)}>Last Month</a></li> */}
                                                <li >
                                                    <button className="" onClick={(e) => {
                                                        const picker1 = document.getElementById("header-date");
                                                        picker1.showPicker();
                                                    }}>
                                                        <i className="ri-calendar-todo-line"></i>
                                                    </button>
                                                    <input
                                                        type="date"
                                                        id="header-date"
                                                        name="header-date"
                                                        className="menu-date"
                                                        placeholder="DD/MM/YY"
                                                    />
                                                </li>
                                            </ul>

                                        </div>

                                    </div>
                                    {loading ?
                                        <Spinner />
                                        :
                                        <Bar
                                            type="Bar"
                                            data={{
                                                // labels: Graphdata.map((item, index) => item.Date),
                                                labels: Graphdata.map((item, index) => moment(item.Date).format('DD-MMM-YY')),
                                                datasets: [
                                                    {
                                                        label: false,
                                                        data: Graphdata.map((item, index) => item.AttendancePercentage), // Ensure Graphdata is defined and has expected structure

                                                        backgroundColor: [
                                                            '#003970',
                                                        ],
                                                        fill: false,
                                                        borderWidth: 1,
                                                        barPercentage: 0.28,
                                                        borderRadius: 40,
                                                        borderSkipped: false,
                                                    },
                                                ],
                                            }}
                                            options={options}
                                        />

                                    }
                                </div>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div className="card-body dash-bg-card">
                                <h4 className="card-title">Not Available List</h4>
                                <div>
                                    <ul className="birthday-list scrollbar">

                                        {
                                            loading ?
                                                <div className="row">
                                                    <div className="col-md-6 text-center align-items-center">
                                                        <Spinner animation="border" role="status"></Spinner>
                                                    </div>
                                                </div>
                                                :
                                                <>
                                                    {renderBirthdays()}
                                                </>

                                        }

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row gy-3 align-items-center mt-5">
                    <div className="col-md-4 col-sm-6">
                        <div className="card m-card">
                            <div className="card-body text-center">
                                <img src={staff} className="pb-3" alt="" />
                                <p className="m-2">Total Staff</p>
                                <h4 className="m-2">448</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="card m-card">
                            <div className="card-body text-center">
                                <img src={present} className="pb-3" alt="" />
                                <p className="m-2">Present</p>
                                <h4 className="m-2">235</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="card">
                            <div className="card-body text-center">
                                <img src={not_present} className="pb-3" alt="" />
                                <p className="m-2">Not Present</p>
                                <h4 className="m-2">15</h4>
                            </div>
                        </div>
                    </div>
                </div>

            </div >
        </>
    );
}

export default StaffAttendanceDashboard;