import React, { useState, useEffect } from 'react';
import config from "../../Config";
import axios from "axios";



function WingsDropDown(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [wings, setWings] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const apiData = {
           AccessKey: AccessKey,
            UserID: UserID,
MemberType: MemberType,
            SchoolID: 1,
            PageNo: 1,
            PageSize: 100
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Wings/GetSchoolWings',
            headers: {
                'Content-Type': 'application/json'
            },
            data: apiData
        };

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setWings(response.data.ListofRecords);
                setLoading(false);
            })
            .catch(function (error) {
                //console.log(error);
            });



    }, []);


    if (loading) {
        return (
            <option selected disabled>Loading...</option>
        )
    }
    else {
        return (
            wings.map((item, index) => {
                return (
                    <option
                        value={item.Wing_ID}
                        key={index}
                        selected={item.Wing_ID == props.selectedWingId}
                    >
                        {item.Wing_Name}
                    </option>
                )
            })
        );
    }
}

export default WingsDropDown;