import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import SessionsDropDown from "../Shared/SessionsDropDown";
import WingsDropDown from "../Shared/WingsDropDown";
import ClassesDropDown from "../Shared/ClassesDropDown";
import SectionDropDown from "../Shared/SectionDropDown";
import StudentGroupDropDown from "../Shared/StudentGroupDropDown";
import StudentStatusDropDown from "../Shared/StudentStatusDropDown";
import StudentMenu from "./StudentMenu";
import { toast, ToastContainer } from "react-toastify";
import { Link, NavLink } from "react-router-dom";
import moment from "moment";
import Pagination from "../General/Pagination";
import { Form, PageItem } from "react-bootstrap";
import { scrollToTop } from "../Shared/ScrollOnTop";
import csvDownload from "json-to-csv-export";
import RightsCheck from '../General/RightsCheck';

function BiodataLists(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  //const [DefaultSession, setDefaultSession] = useState(localStorage.getItem("DefaultSession"));

  const [showFilters, setShowFilters] = useState(true);

  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [validated, setValidated] = useState(false);
  const [loaddata, setLoaddata] = useState(false);
  const [listOfLedgerforcvs, setLedgerforcvs] = useState([]);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const [NewWingId, setNewWingId] = useState(0);
  const [NewSessionId, setNewSessioinId] = useState(0);
  const [NewClassId, setNewClassId] = useState(0);
  const [NewSectionID, setNewSectionID] = useState(0);

  const [WingId, setWingId] = useState(0);
  const [SessionId, setSessioinId] = useState(
    localStorage.getItem("DefaultSession")
  );
  const [ClassId, setClassId] = useState(0);
  const [SectionID, setSectionID] = useState(0);
  const [GroupId, setGroupId] = useState(0);
  const [StudentStatusType, setStudentStatusType] = useState(0);
  const [isUpdated, setIsUpdated] = useState(false);

  const [Registration_No, SetRegistrationNo] = useState("");
  const [StudentID, setStudentID] = useState(0);
  const [Keywords, setKeywords] = useState("");
  const [Status_Type_Id, SetStatusTypeId] = useState(0);
  const [OrderBy, setOrderBy] = useState(0);
  const [Vaccination, setVaccination] = useState(0);

  const [WingName, setWingName] = useState("");
  const [ClassName, setClassName] = useState("");
  const [SessionName, setSessionName] = useState("");

  useEffect(() => {
    document.title = "Bio Data List";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: pageSize,
      SessionID: SessionId,
      Wing_Id: WingId,
      Class_Id: ClassId,
      Section_Id: SectionID,
      Registration_No: Registration_No,
      Student_ID: StudentID,
      Keywords: Keywords,
      Status_Type_Id: Status_Type_Id,
      Group_Id: GroupId,
      OrderBy: OrderBy,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Student/SearchStudents",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [
    pageNo,
    pageSize,
    SessionId,
    WingId,
    ClassId,
    SectionID,
    Registration_No,
    StudentID,
    Keywords,
    Status_Type_Id,
    GroupId,
    OrderBy,
  ]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.Student_ID}</td>
            <td>{item.Registration_No}</td>
            <td>{item.Student_Name}</td>
            <td>{item.Father_Name}</td>
            <td>{moment(item.Date_Of_Birth).format("DD/MM/YYYY")}</td>
            <td>{moment(item.Date_Of_Joining).format("DD/MM/YYYY")}</td>
            <td>{item.Contact_Mobile}</td>
            <td>{item.Contact_Phone}</td>
            {/* <td>{item.SelectedClass.SelectedWing.Wing_Name}</td>
                            <td>{item.SelectedClass.Class_Name}</td>
                            <td>{item.SelectedSection.Section_Name}</td> */}
            <td>
              {item.Permanent_Address.length > 40
                ? `${item.Permanent_Address.substring(0, 40)}...`
                : item.Permanent_Address}
            </td>

          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="9">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      //setLoading(true);

      setWingId(event.target.Wing_Id.value);
      setSectionID(event.target.Section_Id.value);

      setClassId(event.target.Class_ID.value);

      setSessioinId(event.target.SessionID.value);
      SetStatusTypeId(event.target.statustype.value);
      setGroupId(event.target.groupName.value);
      setOrderBy(event.target.OrderBy.value);
      // SetRegistrationNo(event.target.Reg_No.value);
      setVaccination(event.target.Vaccination.value);
      // setKeywords(event.target.Keywords.value);
      // setGroupId(event.target.groupName.value);
      // setStudentID(event.target.Student_ID.value);
    }

    setValidated(true);
  };
  const handleSubmitForCvs = (event) => {

    setLoaddata(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: 10000,
      SessionID: SessionId,
      Wing_Id: WingId,
      Class_Id: ClassId,
      Section_Id: SectionID,
      Registration_No: Registration_No,
      Student_ID: StudentID,
      Keywords: Keywords,
      Status_Type_Id: Status_Type_Id,
      Group_Id: GroupId,
      OrderBy: OrderBy,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Student/SearchStudents",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedgerforcvs(response.data.ListofRecords);
        let data = response.data.ListofRecords;
        let count = 0;
        const listOfLedgerNew = data.map(({ ...rest }) => ({
          Serial_No: count++ + pageSize * (pageNo - 1),
          Reg_No: rest.Registration_No,
          Name: rest.Student_Name,
          Father_Name: rest.Father_Name,
          DOB: moment(rest.Date_Of_Birth).format("DD/MM/YYYY"),
          DOJ: moment(rest.Date_Of_Joining).format("DD/MM/YYYY"),
          Mobile: `=T("${rest.Contact_Mobile}")`,
          Wing: rest.SelectedClass.SelectedWing.Wing_Name,
          Class: rest.SelectedClass.Class_Name,
          Section: rest.SelectedSection.Section_Name,
        }));
        //   //console.log(listOfLedgerNew);

        const dataToConvert = {
          data: listOfLedgerNew,
          filename: "BioDataList",
          delimiter: ",",
          headers: [
            "Serial_No",
            "Reg_No",
            "Name",
            "Father_Name",
            "DOB",
            "DOJ",
            "Mobile",
            "Wing",
            "Class",
            "Section",
          ],
        };

        csvDownload(dataToConvert);
        setLoaddata(false);
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  return (
    <>
      <StudentMenu />
      <ToastContainer />
      <RightsCheck />
      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/Dashboard">Dashboard</NavLink>
                    </li>
                    <li className="breadcrumb-item">
                      <NavLink to="/students/StudentDashboard">
                        Students
                      </NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Biodata Lists
                    </li>
                  </ol>
                </nav>
                <div className="page-title">Biodata Lists</div>
              </div>

              <div className="col-md-6">
                <div className="page-right-actions">
                  <NavLink
                    to="/students/AddStudent"
                    className="button button-white button-icon"
                  >
                    Add Students
                    <i className="ri-add-fill"></i>
                  </NavLink>
                  <button
                    onClick={() => {
                      setShowFilters((prevIsActive) => !prevIsActive);
                    }}
                    className="button button-white button-icon"
                  >
                    {showFilters ? "Hide Filters" : "Show Filters"}
                    <i className="ri-filter-3-line"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`collapse ${showFilters ? "show" : ""} `}
          id="collapseFilteres"
        >
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="page-content">
              <div className="container">
                <div className="card mb-2">
                  <div className="card-body p-md-4">
                    <div className="row align-items-end gy-3">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Select Session:
                          </label>
                          <select
                            className="form-select"
                            onChange={(e) => setSessioinId(e.target.value)}

                            name="SessionID"
                          >
                            <option value="0">---All---</option>
                            <SessionsDropDown selectedSession={SessionId} />
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Select Wing:</label>
                          <select
                            name="Wing_Id"
                            className="form-select"
                            onChange={(e) => {
                              setNewWingId(e.target.value); setClassId(0); setSectionID(0);
                              const selectedText =
                                e.target.options[e.target.selectedIndex].text;
                              setWingName(selectedText);
                            }}
                          >
                            <option value="0">---All---</option>
                            <WingsDropDown />
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Select Class:
                          </label>
                          <select
                            className="form-select"
                            name="Class_ID"
                            onChange={(e) => {
                              setNewClassId(e.target.value); setSectionID(0);
                              const selectedText =
                                e.target.options[e.target.selectedIndex].text;
                              setClassName(selectedText);
                            }}
                          >
                            <option value="">Please Select</option>
                            {SessionId != 0 && NewWingId != 0 && (
                              <ClassesDropDown
                                SessionID={SessionId}
                                WingID={NewWingId}
                              />
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Select Section:
                          </label>
                          <select
                            className="form-select"
                            name="Section_Id"
                            onChange={(e) => {
                              setSectionID(e.target.value);
                              const selectedText =
                                e.target.options[e.target.selectedIndex].text;
                              setSessionName(selectedText);
                            }}
                          >
                            <option value="0">Please Select</option>
                            {NewClassId !== 0 && (
                              <SectionDropDown ClassID={NewClassId} />
                            )}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Student Status:
                          </label>
                          <select
                            className="form-select"
                            onChange={(e) => SetStatusTypeId(e.target.value)}
                            name="statustype"
                          >
                            <option value="">---All---</option>
                            {<StudentStatusDropDown />}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Select Group:
                          </label>
                          <select
                            className="form-select"
                            onChange={(e) => setGroupId(e.target.value)}
                            name="groupName"
                          >
                            <option value="">---All---</option>
                            {<StudentGroupDropDown />}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Order by:
                          </label>
                          <select
                            name="OrderBy"
                            onChange={(e) => setOrderBy(e.target.value)}
                            className="form-select"
                          >
                            <option Selected Value="Student_Name">
                              Student Name
                            </option>
                            <option Value="Registration_No">
                              Registration No
                            </option>
                            <option Value="Wing_Name">Wing Name</option>
                            <option Value="Class_Name">Class</option>
                            <option Value="Student_ID">Student ID Asc</option>
                            <option Value="Student_ID desc">
                              Student ID Desc
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Vaccination Status:
                          </label>
                          <select
                            name="Vaccination"
                            onChange={(e) => setVaccination(e.target.value)}
                            className="form-select"
                          >
                            <option selected="selected" value="-1">
                              --All Students--
                            </option>
                            <option value="Not Vaccinated">
                              Not Vaccinated
                            </option>
                            <option value="Partially Vaccinated">
                              Partially Vaccinated
                            </option>
                            <option value="Fully Vaccinated">
                              Fully Vaccinated
                            </option>
                          </select>
                        </div>
                      </div>
                      {loaddata ? (
                        <div className="col-md-2 offset-8 text-end">
                          <Spinner animation="border" role="status"></Spinner>
                        </div>
                      ) : (
                        <div className="col-md-2 offset-8">
                          <div className="form-group text-end mb-1">
                            <a
                              type="button"
                              onClick={handleSubmitForCvs}
                              className="print-icon d-inline"
                            >
                              <span>
                                <i className="ri-file-excel-2-line"></i>
                              </span>
                            </a>
                          </div>
                        </div>
                      )}
                      <div className="col-md-2">
                        <div className="form-group">
                          <button
                            type="submit"
                            className="w-100 button button-primary"
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
        <div className="page-content">
          <div className="container">
            <div className="card">
              <div className="card-body">
                {/* <div className="text-end mb-2 me-3">
                                    <b>{{totalRecords}} &nbsp; Records Found!</b>

                                </div> */}
                <table className="table table-theme">
                  <thead>
                    <tr>
                      <th>Student ID</th>
                      <th>Reg No</th>
                      <th>Name</th>
                      <th>Father Name</th>
                      <th>DOB</th>
                      <th>DOJ</th>
                      <th>Mobile</th>
                      <th>Phone</th>
                      {/* <th>Wing</th>
                                            <th>Class</th>
                                            <th>Section</th> */}
                      <th>Permanent Address</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td className="text-center" colspan="9">
                          <Spinner animation="border" role="status"></Spinner>
                        </td>
                      </tr>
                    ) : (
                      <>{renderLedger()}</>
                    )}
                  </tbody>
                </table>
                {totalRecords > 10 && (
                  <div className="pagination-wrap">
                    <div className="row align-items-center">
                      <div className="col-md-2 form_default">
                        <div className="d-flex align-items-center">
                          <label className="me-2" style={{ fontSize: "15px" }}>
                            Page Size
                          </label>
                          <div className="form-group">
                            <select
                              className="form-select"
                              style={{ width: "100px" }}
                              onChange={(e) => {
                                setPageNo(1);
                                setPageSize(parseInt(e.target.value));
                              }}
                            >
                              <option value="10" selected>
                                10
                              </option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              <option value="500">500</option>
                              <option value="1000">1000</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <Pagination
                        pageNo={pageNo}
                        pageSize={pageSize}
                        totalRecords={totalRecords}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="container">
            <div className="card mt-2">
              <div className="card-body p-md-4">
                <div className="row align-items-center gy-3">
                  <div className="col-md-2 offset-md-7">
                    <div className="form-group">
                      <Link
                        to={`/Students/PrintBiodataLists?SessionId=${SessionId}&WingName=${WingName}&ClassName=${ClassName}&SessionName=${SessionName}&Wing_Id=${WingId}&Class_Id=${ClassId}&SectionID=${SectionID}&OrderBy=${OrderBy}&GroupId=${GroupId}&Status_Type_I=${Status_Type_Id}`}
                        target="_black"
                        className="w-100 button button-primary"
                      >
                        Print This List
                      </Link>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <Link
                        target="_black"
                        to={`/Students/PrintBiodataLists1?SessionId=${SessionId}&WingName=${WingName}&ClassName=${ClassName}&SessionName=${SessionName}&Wing_Id=${WingId}&Class_Id=${ClassId}&SectionID=${SectionID}&OrderBy=${OrderBy}&GroupId=${GroupId}&Status_Type_I=${Status_Type_Id}`}
                        value=""
                        className="w-100 button button-primary"
                      >
                        Print Vaccitional List
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BiodataLists;
