import React from 'react';
import { Link} from "react-router-dom";
import ReportsMenu from './ReportsMenu';
import { scrollToTop } from '../Shared/ScrollOnTop';

const StruckedStudents = () => {
  return (
    <>
      <ReportsMenu/>
    </>
  );
}

export default StruckedStudents;
