import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import { Link } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import moment from "moment";
import { scrollToTop } from '../Shared/ScrollOnTop';

function EditPhotoRequest(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [validated, setValidated] = useState(false);
    const [Status, setStatus] = useState("");
    const [Remarks, setRemarks] = useState('');
    const [loading, setLoading] = useState(false);
    const [Bank, setBank] = useState({});



    let params = useParams();
    const [BankID, setBankID] = useState(params.id);

    let navigate = useNavigate();

    useEffect(() => {
        document.title = "Add/Update Photo Request";

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            ID: BankID

        };
        //console.log(data);
        var api_config = {
            method: 'post',
            url: config.base_url + 'PhotoUpdateRequest/GetRequestByID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };




        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status_code == 1) {
                    setBank(response.data.PhotoRequest);

                }


                setLoading(false);


            })
            .catch(function (error) {
                setLoading(false);
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });


    }, []);

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setLoading(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                ID: BankID,
                Status: 'Approved',
                Remarks: Remarks,
            };

            console.log(data);


            var api_config = {
                method: 'post',
                url: config.base_url + 'PhotoUpdateRequest/UpdateRequest',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };



            axios(api_config)
                .then(function (response) {
                    console.log(response.data);
                    if (response.data.status_code == 1) {

                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });

                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setLoading(false);


                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });

        }

        setValidated(true);
    };
    const deleteBankAcc = () => {
        const confirm = window.confirm("Are you sure to proceed this action ?")

        if (confirm) {
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                ID: BankID
            };

            var api_config = {
                method: 'post',
                url: config.base_url + 'PhotoUpdateRequest/DeleteRequest',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            //console.log(data);
            axios(api_config)
                .then(function (response) {
                    //console.log(response.data);
                    if (response.data.status_code == 1) {
                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        setLoading(true);
                        setTimeout(() => {
                            navigate(`/photo/manage-photo`)
                        }, 3000)
                    }
                    // setIsUpdated(false);

                })
                .catch(function (error) {
                    //console.log(error);
                });
        }
    }
    return (
        <>
            <ToastContainer />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item"> <Link to="/photo/manage-photo">Update Photo request</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Edit Photo Request</li>
                                </ol>
                            </nav>
                            <div className="page-title">Edit Photo Request</div>
                        </div>
                        <div className="col-md-6">
                            <div className="page-right-actions">
                                <Link to="/photo/manage-photo" className="button button-white button-icon">
                                    Manage Photo Request
                                    <i className="ri-list-check"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                loading ?
                    <Spinner animation="border" role="status" className="full-screen-loading"></Spinner>

                    :
                    <div className="page-content">
                        <div className="container">
                            <div className="row align-items-center gy-3">
                                <div className="col-md-12">
                                    <div className="card">

                                        <div className="card-body p-md-4">
                                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                                <div className="row align-items-center gy-3">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="">Name:</label>
                                                            <input type="text" defaultValue={Bank.Name} className="form-control" disabled readOnly />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="" className="">Father Name:</label>
                                                            <input type="text" defaultValue={Bank.FatherName} className="form-control" disabled readOnly />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label htmlFor="" className="">Class Name:</label>
                                                            <input type="text" defaultValue={Bank.ClassName} className="form-control" disabled readOnly />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label htmlFor="" className="">Section Name:</label>
                                                            <input type="text" defaultValue={Bank.SectionName} className="form-control" disabled readOnly />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label htmlFor="" className="">Wing Name:</label>
                                                            <input type="text" defaultValue={Bank.WingName} className="form-control" disabled readOnly />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label htmlFor="" className="">Registration No:</label>
                                                            <input type="text" defaultValue={Bank.RegistrationNo} className="form-control" disabled readOnly />
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Remarks:</label>
                                                            <select
                                                                className="form-select"
                                                                onChange={(e) => setRemarks(e.target.value)}
                                                                value={Remarks}
                                                            >
                                                                <option value="Photo is Blur">Photo is Blur</option>
                                                                <option value="Photo is Invalid">Photo is Invalid</option>
                                                                <option value="Wrong Photo size">Wrong Photo size</option>
                                                                <option value="Photo posture is incorrect">Photo posture is incorrect</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label htmlFor="" className="">Status:</label>
                                                            <input type="text" defaultValue={Bank.Status} className="form-control" disabled readOnly />
                                                        </div>
                                                    </div>



                                                </div>
                                            </Form>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-4 offset-md-3">
                                    <div className="card" style={{ width: '300px' }}>
                                        <div className="card-body">
                                            <img className="img-fluid rounded" src={`https://api.dpskasur.edu.pk/Images/PhotoUpdateRequest/${Bank.Photo}`} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card">
                                        <div className="card-body">

                                            <div className="form-group ">

                                                <button type="submit" value="Save" className="button button-primary w-100" onClick={handleSubmit}>SAVE</button>


                                            </div>
                                            <button onClick={deleteBankAcc} className="button button-danger w-100 mt-3 button-icon">
                                                Reject
                                                <i className="ri-delete-bin-line">
                                                </i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    );
}

export default EditPhotoRequest;
