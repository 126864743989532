import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import SetupMenu from './SetupMenu';
import WingsDropDown from '../Shared/WingsDropDown';
import SessionsDropDown from "../Shared/SessionsDropDown";
import { scrollToTop } from '../Shared/ScrollOnTop';

const EditClass = () => {

    const [loading, setLoading] = useState(false);
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [validated, setValidated] = useState(false);
    const [WingID, setWingId] = useState(0);
    const [SessionID, setSessionId] = useState(0);
    const [listOfLedger, setLedger] = useState([]);
    const [isUpdated, setIsUpdated] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [monthlyFormData, setMonthlyFormData] = useState({});
    const [admissionFormData, setAdmissionFormData] = useState({});
    const [classCode,setclassCode] = useState(0);
    const [classShortName,setclassShortName] = useState(0);
    const [className,setclassName] = useState(0);
    const [dataAdmissionFee, setDataAdmissionFee] = useState([]);  
    const [dataMonthlyFee, setDataMonthlyFee] = useState([]); 
    const [SelectedClass, setSelectedClass] = useState({});

    let params = useParams();

    const [ClassId, setClassId] = useState(params.id);

    
    useEffect(() => {

        document.title = "Update Class";

        setIsUpdated(false);
        setLoading(true);

        const data = {
           AccessKey: AccessKey,
            UserID: UserID,
MemberType: MemberType,
            pageNo: 1,
            pageSize: 1000,
            SchoolID: 1,
            Class_Id: ClassId
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'FeeType/GetFeeTypesByClassID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setLedger(response.data.ListofRecords);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);

            })
            .catch(function (error) {
                //console.log(error);
            });

    }, []);


    useEffect(() => {

       
        setLoading(true);

        const data = {
           AccessKey: AccessKey,
            UserID: UserID,
MemberType: MemberType,
            ID: ClassId
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'WingClass/GetClassByID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        //console.log(data);


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setSelectedClass(response.data.Class);
                setclassCode(response.data.Class.Class_Code);
                setclassName(response.data.Class.Class_Name);
                setWingId(response.data.Class.Wing_Id);
                setclassShortName(response.data.Class.Class_Short_Name);
                setSessionId(response.data.Class.Session_ID);
            })
            .catch(function (error) {
                //console.log(error);
            });

    }, []);


    const renderLedger = (type) => {


        if (listOfLedger.length > 0) {
            return (
                listOfLedger.map((item, index) => {
                    return (
                        <li key={index}>
                            <div className="sr">
                                {item.Fee_Type_ID}
                            </div>
                            <div className="type">
                                {item.Fee_Type_Name}
                            </div>
                            <div className="input">
                                {
                                    type == "monthly" ?
                                    <input type="text" name="monthly" defaultValue={item.MonthlyFee} className="form-control type-monthly" data-type-id={item.Fee_Type_ID}  required />
                                    :
                                    type == "admission" ?
                                    <input type="text" name="admission" defaultValue={item.AnnualFee} className="form-control type-admission" data-type-id={item.Fee_Type_ID} required />
                                    :
                                    ""
                                }
                                
                            </div>
                        </li>
                    )
                })
            )
        }
        else {
            return (
                "No Fee Type Exists, Please Add Fee Types First"
            )
        }

    }

    

    const handleMonthlyChange = (event) => {
        const newItem = {
          FeeTypeID: event.target.getAttribute("data-type-id"),
          FeeValue: event.target.value
        };
        setDataMonthlyFee((prevData) => {
          const newData = [...prevData];
          const index = newData.findIndex((item) => item.FeeTypeID === newItem.FeeTypeID);
          if (index === -1) {
            newData.push(newItem);
          } else {
            newData[index] = newItem;
          }
          return newData;
        });
      };

      const handleAdmissionChange = (event) => {
        const newItem = {
          FeeTypeID: event.target.getAttribute("data-type-id"),
          FeeValue: event.target.value
        };
        setDataAdmissionFee((prevData) => {
          const newData = [...prevData];
          const index = newData.findIndex((item) => item.FeeTypeID === newItem.FeeTypeID);
          if (index === -1) {
            newData.push(newItem);
          } else {
            newData[index] = newItem;
          }
          return newData;
        });
      };


   


   
      const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setLoading(true);

            const inputsMonthly = document.querySelectorAll('input.type-monthly');
            const valuesMonthly = [];
            inputsMonthly.forEach(input => {
                const value = parseInt(input.value);
            
                if (value > 0) {

                    const newItem = {
                        FeeTypeID: input.getAttribute('data-type-id'),
                        FeeValue: value
                      };
                    valuesMonthly.push(newItem);
                }
              });

            const inputsAdmission = document.querySelectorAll('input.type-admission');
            const valuesAdmission = [];
            inputsAdmission.forEach(input => {
                const value = parseInt(input.value);
            
                if (value > 0) {

                    const newItemAdmission = {
                        FeeTypeID: input.getAttribute('data-type-id'),
                        FeeValue: value
                      };
                      valuesAdmission.push(newItemAdmission);
                }
              });


            
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
MemberType: MemberType,
                Wing_Id : WingID,
                Class_Code: classCode,
                Class_Short_Name: classShortName,
                Class_Name: className,
                Session_ID:SessionID,
                ListofAdmissionFee: valuesAdmission,
                ListofMonthlyFee: valuesMonthly,
                Class_Id: params.id
            };

           //console.log(data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'WingClass/UpdateWingClass',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

           //console.log(data);

            axios(api_config)
                .then(function (response) {
                    //console.log(response.data);
                    if (response.data.status_code == 1) {

                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });

                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setLoading(false);


                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });



        }

        setValidated(true);
    };


    return (
        <>
        <ToastContainer />
            <SetupMenu />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item"> <Link to="/Setup/SetupDashboard">Setup</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Add New Class</li>
                                </ol>
                            </nav>
                            <div className="page-title">Add New Class</div>
                        </div>
                        <div className="col-md-6">
                            <div className="page-right-actions">
                                <Link to="/Setup/ManageClasses" className="button button-white button-icon">
                                    Manage Classes
                                    <i className="ri-list-check"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="card">
                    <div className="card-body p-md-4">
                        <div className="row align-items-center">
                            <div className="col-md-3">
                                <div className="form-group mb-3">
                                    <label for="" className="">Select Wing:</label>
                                    <select  onChange={(e) => setWingId(e.target.value)}
                                        className="form-select" required>
                                        <option value="">Please Select</option>
                                        <WingsDropDown selectedWingId={SelectedClass.Wing_Id} />
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group mb-3">
                                    <label>Select Session:</label>
                                    <select onChange={(e) => setSessionId(e.target.value)}
                                        className="form-select" required>
                                        <option value="">Please Select</option>
                                        <SessionsDropDown selectedSession={SelectedClass.Session_ID} />
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group mb-3">
                                    <label>Class Code</label>
                                    <input type="text" value={classCode} onChange={(e) => setclassCode(e.target.value)} className="form-control" placeholder="e.g. 001" name="txtClassCode" required />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group mb-3">
                                    <label>Class Short Name</label>
                                    <input type="text" value={classShortName} onChange={(e) => setclassShortName(e.target.value)} placeholder="e.g. PG" className="form-control" name="txtClassShortName" required />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group mb-3">
                                    <label>Class Name</label>
                                    <input type="text" value={className} onChange={(e) => setclassName(e.target.value)} className="form-control" placeholder="e.g. Play Group" name="txtClassName" required />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row align-items-center mt-2 gx-2">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Admission Fee's Details:</h4>
                                <ul className="add_class_options">
                                    {
                                        loading ?
                                            <tr>
                                                <td className="text-center" colspan="5">
                                                    <Spinner animation="border" role="status"></Spinner>
                                                </td>
                                            </tr>
                                            :
                                            <>
                                                {renderLedger('admission')}
                                            </>

                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Monthly Fee's Details:</h4>
                                <ul className="add_class_options">
                                {
                                        loading ?
                                            
                                                    <Spinner animation="border" role="status"></Spinner>
                                                
                                            :
                                            <>
                                                {renderLedger('monthly')}
                                            </>

                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-2 align-items-center">
                    <div className="col-md-12 text-end">
                        <div className="card">
                            <div className="card-body">
                                <div className="form-group ">
                                    {
                                        loading ?
                                            <Spinner animation="border" role="status"></Spinner>

                                            :
                                            <button type="button" onClick={handleSubmit} value="Save" className="button button-primary w-100 ">SAVE</button>

                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditClass;
