import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import PrintHeader from "../General/PrintHeader";
import Page from "../General/Page";
import moment from "moment";

const FeeDetails = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  //const [DefaultSession, setDefaultSession] = useState(localStorage.getItem("DefaultSession"));

  const [showFilters, setShowFilters] = useState(true);

  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [validated, setValidated] = useState(false);
  const [IsUpdated, setIsUpdated] = useState(false);
  // pagination

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalamount, settotalamount] = useState(0);
  const [totalinScroll, settotalinScroll] = useState(0);
  // const [ReportMonth, setReportMonth] = useState(moment().format("MM"));
  // const [ReportYear, setReportYear] = useState(moment().format("YYYY"));
  const [ReCreate, setReCreate] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);

  const lang = urlParams.get("lang");

  const ReportYear = urlParams.get("ReportYear");
  const ReportMonth = urlParams.get("ReportMonth");
  const FeeTypeId = urlParams.get("FeeTypeId");



  
  
  useEffect(() => {
    
    document.title = "Print Fee Details";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      ReportMonth: ReportMonth,
      ReportYear: ReportYear,
      ReCreate: ReCreate,
      FeeTypeId:FeeTypeId,
      pageNo: pageNo,
      pageSize: pageSize,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Reports/GetFeeCollectionDetails",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords);
        // settotalamount(response.data.totalamount);
        // settotalinScroll(response.data.totalinScroll);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.Serial_No}</td>
            <td>{item.Registration_No}</td>
            <td>{item.Student_ID}</td>
            <td>{item.Fee_Type_Code}</td>
            <td>{item.Fee_Type_Name}</td>
            <td>{item.Student_Name}</td>
            <td>{item.selectedMonth}</td>
            <td>{item.selectedYear}</td>
            <td>{item.Amount}</td>

          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="6">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };
  return (
    <>
      <Page orientation="portrait" size="a4">
        <PrintHeader title={`Print Fee Details`} />
        <table className="table table-sm table-bordered table-print">
          <thead>
            <tr>
              <th>Serial No</th>
              <th>Reg No</th>
              <th>Student ID</th>
              <th>Fee Code</th>
              <th>Fee Type</th>
              <th>Student Name</th>
              <th>Selected Month</th>
              <th>Selected Year</th>
              <th>Amount</th>

            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colspan="8">
                  <Spinner animation="border" role="status"></Spinner>
                </td>
              </tr>
            ) : (
              <>{renderLedger()}</>
            )}
          </tbody>
        </table>
      </Page>
    </>
  );
};

export default FeeDetails;
