import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import ShowStaff from "../Shared/ShowStaff";
import PayrollMenu from "./PayrollMenu";
import { Form } from "react-bootstrap";
import { scrollToTop } from "../Shared/ScrollOnTop";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";

function AddLeave(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const [loadingUpdateBtn, setLoadingUpdateBtn] = useState(false);
  const [validatedLedgerUpdate, setValidatedLedgerUpdate] = useState(false);
  const [selectedlistOfLedger, setSelectedLedger] = useState({});
  const [ID, setID] = useState(0);

  let params = useParams();
  const [EmployeeID, setEmployeeID] = useState(params.id);

  useEffect(() => {
    document.title = "Manage Employees Leaves";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      ID: EmployeeID,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Payroll/GetEmployeeCurrentMonthLeave",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    //console.log(data);

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [isUpdated]);

  const handleClose = () => {
    setShow(false);
    setSelectedLedger({});
    setLoadingUpdateBtn(false);
  };
  const handleShow = (item) => {
    setShow(true);
    setSelectedLedger(item);
    setFromDate(item.From_Date);
    setToDate(item.To_Date);
    setID(item.ID);
    //console.log(item);
    // setEmployeeName(item.Name);
  };

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{count++}</td>
            <td>{item.ID}</td>
            <td>{moment(item.From_Date).format("DD/MM/YYYY")}</td>
            <td>{moment(item.To_Date).format("DD/MM/YYYY")}</td>
            <td>{item.Description}</td>
            <td>{item.No_Of_Leaves}</td>
            <td>{item.Amount}</td>
            <td className="text-center">
              <ul
                className="inline-action"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <button
                    type="button"
                    className={`green `}
                    onClick={(e) => handleShow(item)}
                  >
                    <i className="ri-edit-line"></i>
                  </button>
                </li>

                <li>
                  <button onClick={(e) => deleteLeave(item.ID)} className="red">
                    <i className="ri-delete-bin-line"></i>
                  </button>
                </li>
              </ul>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="7">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };
  const deleteLeave = (ID) => {
    const confirm = window.confirm("Are you sure you want to proceed ?");
    if (confirm) {
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      ID: ID,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Payroll/DeleteEmployeeLeave",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          setIsUpdated(true);
        }
        // setIsUpdated(false);
      })
      .catch(function (error) {
        //console.log(error);
      });
    }
  };
  const handleUpdateLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoadingUpdateBtn(true);
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        Employee_ID: EmployeeID,
        ID: ID,
        Description: event.target.elements.Description.value,
        From_Date: event.target.elements.From_Date.value,
        To_Date: event.target.elements.To_Date.value,
        No_Of_Leaves: event.target.elements.No_Of_Leaves.value,
        Amount: event.target.elements.Amount.value,
      };

      //console.log(data);

      var api_config = {
        method: "post",
        url: config.base_url + "Payroll/UpdateEmployeeLeave",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setIsUpdated(true);
          } else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }

          // setIsSubjectAdded(true);
          setLoadingUpdateBtn(false);
          handleClose();
        })
        .catch(function (error) {
          //setLoading(false);
          toast.error("Network Error..", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }

    setValidatedLedgerUpdate(true);
  };

  const handleAddLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoading(true);
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        Employee_ID: EmployeeID,

        Description: event.target.elements.Description.value,
        From_Date: event.target.elements.From_Date.value,
        To_Date: event.target.elements.To_Date.value,
        No_Of_Leaves: event.target.elements.No_Of_Leaves.value,
        Amount: event.target.elements.Amount.value,
      };

      //console.log(data);

      var api_config = {
        method: "post",
        url: config.base_url + "Payroll/AddEmployeeLeave",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setIsUpdated(true);
          } else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }

          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          toast.error("Network Error..", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }

    setValidated(true);
  };

  const handleAutoCalculate = (event) => {
    event.preventDefault();

    const noOfLeaves = event.target.form.querySelector(
      'input[name="No_Of_Leaves"]'
    ).value;

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      NoofDays: noOfLeaves,
      ID: EmployeeID,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Payroll/AutoCalculatePerDay",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code === 1) {
          const calculatedAmount = response.data.Amount;
          event.target.form.querySelector('input[name="Amount"]').value =
            calculatedAmount;
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <>
      <PayrollMenu />
      <ToastContainer />

      <Modal className="theme-modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Leaves </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validatedLedgerUpdate}
            onSubmit={handleUpdateLeave}
          >
            <div style={{ padding: "20px" }}>
              <div className="form-group mb-3 d-flex flex-column">
                <label>From Date</label>

                <DatePicker
                  selected={moment(fromDate).toDate()}
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  required
                  name="From_Date"
                  onChange={(date) => setFromDate(date)}
                />
              </div>
              <div className="form-group mb-3 d-flex flex-column">
                <label>To Date</label>
                <DatePicker
                  selected={moment(toDate).toDate()}
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  required
                  name="To_Date"
                  onChange={(date) => setToDate(date)}
                />
              </div>
              <div className="form-group mb-3">
                <label>Desicription</label>
                <input
                  type="text"
                  defaultValue={selectedlistOfLedger.Description}
                  name="Description"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label>No of days</label>
                <input
                  type="number"
                  defaultValue={selectedlistOfLedger.No_Of_Leaves}
                  name="No_Of_Leaves"
                  className="form-control"
                  required
                  min={0}
                />
              </div>

              <div className="form-group mb-3">
                <label>Amount</label>
                <input
                  type="number"
                  defaultValue={selectedlistOfLedger.Amount}
                  name="Amount"
                  className="form-control"
                  required
                  min={0}
                />
              </div>

              {loadingUpdateBtn ? (
                <div className="text-center">
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              ) : (
                <button type="submit" className="button button-primary w-100">
                  UPDATE
                </button>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink onClick={scrollToTop} to="/Dashboard">
                        Dashboard
                      </NavLink>
                    </li>
                    <li className="breadcrumb-item">
                      <NavLink
                        onClick={scrollToTop}
                        to="/PayRoll/PayRollDashboard"
                      >
                        Manage Employees
                      </NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Add Employee Leave
                    </li>
                  </ol>
                </nav>
                <div className="page-title">
                  LEAVES FOR INDIVIDUAL EMPLOYEES
                </div>
              </div>
              <div className="col-md-6">
                <div className="page-right-actions"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-content">
          <ShowStaff EmployeeID={EmployeeID} />
          <div className="container">
            <div className="card">
              <div className="card-body card-form-wrap">
                <div className="form-wrap">
                  <div className="form-legend"> Leaves Details </div>
                  <table className="table table-theme">
                    <thead>
                      <tr>
                        <th colspan="8">
                          {listOfLedger.length} &nbsp; Records Found!
                        </th>
                      </tr>
                      <tr>
                        <th>Sr.#</th>
                        <th>ID</th>
                        <th>From Date</th>
                        <th>To Date</th>
                        <th>Desicription</th>
                        <th>No of days</th>
                        <th>Amount</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td className="text-center" colspan="8">
                            <Spinner animation="border" role="status"></Spinner>
                          </td>
                        </tr>
                      ) : (
                        <>{renderLedger()}</>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="form-wrap">
                  <div className="form-legend">Add Leaves </div>
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleAddLeave}
                  >
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            for=""
                            className="col-form-label col-form-label-sm"
                          >
                            Start Date:{" "}
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            name="From_Date"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            for=""
                            className="col-form-label col-form-label-sm"
                          >
                            End Date:
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            name="To_Date"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            for=""
                            className="col-form-label col-form-label-sm"
                          >
                            Description:{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="Description"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            for=""
                            className="col-form-label col-form-label-sm"
                          >
                            No of Days:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="No_Of_Leaves"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label
                            for=""
                            className="col-form-label col-form-label-sm"
                          >
                            Amount:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="Amount"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-3 offset-md-4">
                        <div className="form-group mt-4">
                          <div className="form-group text-end">
                            {loading ? (
                              <Spinner
                                animation="border"
                                role="status"
                              ></Spinner>
                            ) : (
                              <button
                                type="button"
                                className="button button-primary"
                                onClick={(e) => handleAutoCalculate(e)}
                              >
                                Auto Calculate
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group text-end mt-4">
                          <div className="form-group ">
                            {loading ? (
                              <Spinner
                                animation="border"
                                role="status"
                              ></Spinner>
                            ) : (
                              <button
                                type="submit"
                                value="Add Leave"
                                className="button button-primary w-100 "
                              >
                                Add Leave
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddLeave;
