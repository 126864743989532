import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import PayrollMenu from "./PayrollMenu";
import DesignationDropDown from "../Shared/DesignationDropDown";
import EmployeeTypeDropDown from "../Shared/EmployeeTypeDropDown";
import moment from "moment";
import Pagination from "../General/Pagination";
import Dropdown from "react-bootstrap/Dropdown";
import PayScaleBasic from "../Shared/PayScaleBasic";
import { Form } from "react-bootstrap";
import { scrollToTop } from "../Shared/ScrollOnTop";
import csvDownload from "json-to-csv-export";

function ManageEmployee(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [showFilters, setShowFilters] = useState(true);
  const [RetireMentStatus, setRetireMentStatus] = useState("false");
  const [Gender, setGender] = useState(null);
  const [OrderBy, setOrderBy] = useState("");
  // for filters
  const [Code, setCode] = useState("");
  const [DesignationID, setDesignationID] = useState(0);
  const [EmployeeTypeID, setEmployeeTypeID] = useState(0);
  const [GPS_Scale, setGPS_Scale] = useState(0);
  const [Keywords, setKeywords] = useState("");
  const [Staff_ID, setStaff_ID] = useState("");
  const [loaddata, setLoaddata] = useState(false);
  const [listOfLedgerforcvs, setLedgerforcvs] = useState([]);
  const [validated, setValidated] = useState(false);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    document.title = "Manage Employees";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: pageSize,
      SchoolID: 1,
      IS_Resigned: RetireMentStatus,
      Code: Code,
      Gender: Gender,
      DesignationID: DesignationID,
      EmployeeTypeID: EmployeeTypeID,
      Keywords: Keywords,
      GPS_Scale: GPS_Scale,
      OrderBy: OrderBy,
    };


    var api_config = {
      method: "post",
      url: config.base_url + "Payroll/SearchEmployees",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    //console.log(data);

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [
    pageNo,
    pageSize,
    isUpdated,
    Code,
    DesignationID,
    EmployeeTypeID,
    Gender,
    Keywords,
    RetireMentStatus,
    OrderBy,
    GPS_Scale,
  ]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const handleSubmitForCvs = (event) => {

    setLoaddata(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: 10000,
      SchoolID: 1,
      IS_Resigned: RetireMentStatus,
      Code: Code,
      Gender: Gender,
      DesignationID: DesignationID,
      EmployeeTypeID: EmployeeTypeID,
      Keywords: Keywords,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Payroll/SearchEmployees",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          if (response.data.totalRecords > 0) {
            setLedgerforcvs(response.data.ListofRecords);

            let data = response.data.ListofRecords;
            let count = 0;
            const listOfLedgerNew = data.map(({ ...rest }) => ({
              Serial_No: count++ + pageSize * (pageNo - 1),
              ID: rest.ID,
              Name: rest.Name,
              Father_Name: rest.Father_Name,
              Mobile_No: `=T("${rest.Mobile_No}")`,
              Account_No: `=T("${rest.Account_No}")`,
              CNIC_NO: `=T("${rest.CNIC_NO}")`,
              Basic_Pay_Scale: rest.Basic_Pay_Scale,
              DOB: moment(rest.Date_Of_Birth).format("DD/MM/YYYY"),
              DOJ: moment(rest.Date_Of_Joining).format("DD/MM/YYYY"),
              Home_Address: rest.Home_Address,
              Marital_Status: rest.Marital_Status,
              Qualification: rest.Qualification,
            }));

            const dataToConvert = {
              data: listOfLedgerNew,
              filename: "ManageEmployee",
              delimiter: ",",
              headers: [
                "Serial_No",
                "ID",
                "Name",
                "Father_Name",
                "Mobile_No",
                "Account_No",
                "CNIC",
                "Basic_Pay_Scale",
                "DOB",
                "DOJ",
                "Home_Address",
                "Marital_Status",
                "Qualification",
              ],
            };
            csvDownload(dataToConvert);

            setLoaddata(false);
          } else if (response.data.totalRecords == 0) {
            toast.error("There is no data available for download", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoaddata(false);
          }
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoaddata(false);
        }
      })
      .catch(function (error) {
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoaddata(false);
      });
  };
  // check and uncheck
  const handleCheckAll = (e) => {
    const checkboxes = document.getElementsByClassName("checkAll");

    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = e.target.checked;
    }
  };

  const renderLedger = () => {
    if (listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>
              <div className="form-check">
                <input
                  key={item.ID}
                  data-type-id={item.ID}
                  className="form-check-input checkEmployees checkAll"
                  type="checkbox"
                  value={item.ID}
                />
              </div>
            </td>
            <td>{count++}</td>
            <td>{item.ID}</td>
            <td>{item.Code}</td>
            <td>{item.Name}</td>
            <td>{item.Father_Name}</td>
            <td>{moment(item.Date_Of_Birth).format("DD/MM/YYYY")}</td>
            <td>{moment(item.Date_Of_Joining).format("DD/MM/YYYY")}</td>
            <td>{item.Mobile_No}</td>
            <td>{item.Selected_Designation.Name}</td>
            <td className="text-center">
              <div className="dropdown dropdown-table-actions">
                <a
                  href="/ExaminationSystem/ManageExaminations"
                  role="button"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  className=""
                >
                  <i className="ri-more-fill"></i>
                </a>
                <ul
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <NavLink
                      to={"/PayRoll/EditEmployee/" + item.ID}
                      className="dropdown-item"
                    >
                      <i className="ri-edit-line"></i>Edit
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      target="_blank"
                      to={"/PayRoll/PaySlip/?ids=" + item.ID}
                      className="dropdown-item"
                    >
                      <i className="ri-profile-line"></i>Pay Slip
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/PayRoll/EmployeeLedger/" + item.ID}
                      className="dropdown-item"
                    >
                      <i className="ri-file-list-3-line"></i>Employee Ledger
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/PayRoll/ManageSalary/" + item.ID}
                      className="dropdown-item"
                    >
                      <i className="ri-bank-card-line"></i>Manage Salary
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/Payroll/changeStatus/" + item.ID}
                      className="dropdown-item"
                    >
                      <i className="ri-settings-2-line"></i>Change Status
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/Payroll/AddLeave/" + item.ID}
                      className="dropdown-item"
                    >
                      <i className="ri-hospital-line"></i>Add Leave
                    </NavLink>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="10">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };
  const deleteEmployee = (ID) => {
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      ID: ID,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Payroll/DeleteEmployees",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setIsUpdated(true);
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  const handleFilters = (event) => {
    //console.log(event);
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoading(true);
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        pageNo: pageNo,
        pageSize: pageSize,
        SchoolID: 1,
        IS_Resigned: RetireMentStatus,
        Code: Code,
        Gender: Gender,
        DesignationID: DesignationID,
        EmployeeTypeID: EmployeeTypeID,
        Keywords: Keywords,
        GPS_Scale: GPS_Scale,
        OrderBy: OrderBy,
      };

      //console.log(data);

      var api_config = {
        method: "post",
        url: config.base_url + "Payroll/SearchEmployees",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      //console.log(data);

      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          setLedger(response.data.ListofRecords);
          setLoading(false);
          setTotalRecords(response.data.totalRecords);
        })
        .catch(function (error) {
          //console.log(error);
        });
    }
    setValidated(true);
  };

  const handlePrintPayslips = () => {
    const inputsEmployees = document.querySelectorAll("input.checkEmployees");
    const valuesSelected = [];
    inputsEmployees.forEach((input) => {
      if (input.checked == true) {
        const value = parseInt(input.value);
        if (value > 0) {
          valuesSelected.push(value);
        }
      }
    });

    const queryParam = valuesSelected.join(",");
    //history.push(`/other-page?ids=${queryParam}`);

    let url = "/PayRoll/PaySlip/?ids=" + queryParam;
    window.open(url, "_blank");

    //console.log(valuesSelected);
  };

  return (
    <>
      <PayrollMenu />
      <ToastContainer />

      <div className="page-head">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/Dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/PayRoll/PayRollDashboard">Payroll</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Manage Employee
                  </li>
                </ol>
              </nav>
              <div className="page-title">Manage Employee</div>
            </div>

            <div className="col-md-6">
              <div className="page-right-actions">
                <Link
                  to="/PayRoll/AddNewEmployee"
                  className="button button-white button-icon"
                >
                  Add Employee
                  <i className="ri-add-line"></i>
                </Link>
                <button
                  onClick={() => {
                    setShowFilters((prevIsActive) => !prevIsActive);
                  }}
                  className="button button-white button-icon"
                >
                  {showFilters ? "Hide Filters" : "Show Filters"}
                  <i className="ri-filter-3-line"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`collapse ${showFilters ? "show" : ""} `}
        id="collapseFilteres"
      >
        <div className="page-content">
          <Form onSubmit={handleFilters}
            noValidate
            validated={validated}
          >
            <div className="container">
              <div className="card mb-4">
                <div className="card-body p-md-4">
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label>Gender</label>
                        <select
                          name="Gender"
                          onChange={(e) => setGender(e.target.value)}
                          className="form-select"
                        >
                          <option Selected Value="All">
                            All
                          </option>
                          <option Value="true">Male</option>
                          <option Value="false">Female</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label>Designation</label>
                        <select
                          className="form-select"
                          onChange={(e) => setDesignationID(e.target.value)}
                        >
                          <option value="">Please Select</option>
                          <DesignationDropDown />
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label>Department</label>
                        <select
                          className="form-select"
                          onChange={(e) => setEmployeeTypeID(e.target.value)}>
                          <option value="">Please Select</option>
                          <EmployeeTypeDropDown />
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label>Status</label>
                        <select
                          className="form-select"
                          name="status"
                          onChange={(e) => setRetireMentStatus(e.target.value)}
                        >
                          <option Value="null">--All--</option>
                          <option Value="false" selected>
                            Current
                          </option>
                          <option Value="true">Retired</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label>Pay Scale</label>
                        <select
                          className="form-select"
                          onChange={(e) => setGPS_Scale(e.target.value)}
                        >
                          <option Value="0">--All--</option>
                          <PayScaleBasic />
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label>Staff Code</label>
                        <input
                          type="text"
                          onChange={(e) => setCode(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label>Staff ID</label>
                        <input type="text" 
                         onChange={(e) => setStaff_ID(e.target.value)}
                        className="form-control" />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label>Keywords</label>
                        <input
                          type="text"
                          value={Keywords}
                          onChange={(e) => setKeywords(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Order by</label>
                        <select
                          className="form-select"
                          name="OrderBy"
                          onChange={(e) => setOrderBy(e.target.value)}
                        >
                          <option Selected Value="Name">
                            Alphabetic
                          </option>
                          <option Value="Date_Of_Joining ASC">DOJ ASC</option>
                          <option Value="Date_Of_Joining DESC">DOJ DESC</option>
                          <option Value="Code Asc">Code Asc</option>
                          <option Value="Code Desc">Code Desc</option>
                        </select>
                      </div>
                    </div>
                    {loaddata ? (
                      <div className="col-md-2 offset-md-5 text-center mt-4">
                        <div>
                          <div>
                            <Spinner animation="border" role="status"></Spinner>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="col-md-2 offset-md-5">
                        <div className="form-group mt-4">
                          <a
                            type="button"
                            onClick={handleSubmitForCvs}
                            className="print-icon"
                          >
                            <span>
                              <i className="ri-file-excel-2-line"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                    )}

                    <div className="col-md-2 ">
                      <div className="form-group text-end">
                        <button
                          type="submit"
                          value="Search"
                          className="button button-primary mt-4 w-100"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>

      <div className="page-content">
        <div className="container">
          <div className="card">
            <div className="card-body">
              <table className="table table-theme">
                <thead>
                  <tr>
                    <th>
                      <div className="form-check">
                        <input
                          className="form-check-input checkEmployees"
                          type="checkbox"
                          onChange={handleCheckAll}
                        />
                      </div>
                    </th>
                    <th>Sr. No.</th>

                    <th>ID</th>
                    <th>Code</th>
                    <th>Employee Name</th>
                    <th>Father Name</th>
                    <th>DOB</th>
                    <th>DOJ</th>
                    <th>Mobile No.</th>
                    <th>Designation</th>

                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr>
                                        <th colspan="8">
                                            TotalRecords &nbsp; Records Found!
                                        </th>
                                    </tr> */}
                  {loading ? (
                    <tr>
                      <td className="text-center" colspan="10">
                        <Spinner animation="border" role="status"></Spinner>
                      </td>
                    </tr>
                  ) : (
                    <>{renderLedger()}</>
                  )}
                </tbody>
              </table>
              {totalRecords > 10 && (
                <div className="pagination-wrap">
                  <div className="row align-items-center">
                    <div className="col-md-2 form_default">
                      <div className="d-flex align-items-center">
                        <label className="me-2" style={{ fontSize: "15px" }}>
                          Page Size
                        </label>
                        <div className="form-group">
                          <select
                            className="form-select"
                            style={{ width: "100px" }}
                            onChange={(e) => {
                              setPageNo(1);
                              setPageSize(parseInt(e.target.value));
                            }}
                          >
                            <option value="10" selected>
                              10
                            </option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <Pagination
                      pageNo={pageNo}
                      pageSize={pageSize}
                      totalRecords={totalRecords}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="card mt-2">
            <div className="card-body">
              <div className="row">
                <div className="col-md-2 offset-md-10">
                  <button
                    onClick={handlePrintPayslips}
                    className="button button-primary w-100"
                  >
                    Print Payslips
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageEmployee;
