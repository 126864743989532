import React, { useEffect, useState } from 'react'
import axios from 'axios';
import moment from 'moment';
import Dropdown from 'react-bootstrap/Dropdown';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import config from '../../Config';

const Notifications = () => {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [isUpdated, setIsUpdated] = useState(false);
    const [selectedBtnId, setSelectedBtnId] = useState(-1);
    const [ListofNotifcations, setListofNotifcations] = useState([]);
    const [unseenCount, setunseenCount] = useState(0);
    const [loading, setLoading] = useState(false);

    const [loadingReadAll, setloadingReadAll] = useState(false);
    const [loadingDeleteAll, setloadingDeleteAll] = useState(false);

    const [isDeleted, setIsDeleted] = useState(false);
    const [isChanged, setIsChanged] = useState(0);

    useEffect(() => {

        setIsUpdated(false);
        const Params = {
            AccessKey: AccessKey,
            UserID: UserID,
        };


        //console.log(Params)
        var api_config = {
            method: 'post',
            url: config.base_url + 'Notification/GetAllNotifications',
            headers: {
                'Content-Type': 'application/json'
            },
            data: Params
        };

        axios(api_config)
            .then(function (response) {
                //console.log(response.data, 'Notification');
                if (response.data.status_code == 1) {
                    setListofNotifcations(response.data.ListofNotifcations);
                    let list = response.data.ListofNotifcations;
                    const unseenCount = list.filter(notification => !notification.Is_Seen).length;
                    setunseenCount(unseenCount);
                }

            })
            .catch(function (error) {
                //console.log(error);
            });
    }, [isUpdated]);

    const renderNotifications = () => {
        if (ListofNotifcations != null && ListofNotifcations.length > 0) {
            return (
                ListofNotifcations.map((item, index) => {
                    return (
                        <li className={`${item.Is_Seen ? '' : 'active'}`} key={index} onClick={() => MarkRead(item.ID)}>
                            <div className={`indicator ${item.Is_Seen ? '' : 'active'}`}></div>
                            <div className='massage-content'>
                                <h5>{item.Title}</h5>
                                <p>{item.Detail}</p>
                                <span>{moment(item.Created_Date).format("DD MMMM YYYY")}</span>
                            </div>
                            <svg onClick={(e) => MarkDelete(item.ID)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                        </li>
                    )
                })
            )
        }
    }

    const handleDeleteAll = () => {

        setloadingDeleteAll(true);
        const Params = {
            AccessKey: AccessKey,
            UserID: UserID,
        };

        //console.log(Params);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Notification/DeleteAllNotifications',
            headers: {
                'Content-Type': 'application/json'
            },
            data: Params
        };

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                    setIsUpdated(true);
                    toast.success(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setloadingDeleteAll(false);
                }

            })
            .catch(function (error) {
                //console.log(error);
            });
    }

    const handleReadAll = () => {

        setloadingReadAll(true);
        const Params = {
            AccessKey: AccessKey,
            UserID: UserID,
        };

        //console.log(Params);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Notification/ReadAllNotifications',
            headers: {
                'Content-Type': 'application/json'
            },
            data: Params
        };

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                    setIsUpdated(true);
                    toast.success(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setloadingReadAll(false);
                }

            })
            .catch(function (error) {
                //console.log(error);
            });
    }
    const MarkDelete = (NotificationID) => {


        let status = isChanged;
        let newStatus = parseInt(status) + parseInt(1);
        setIsChanged(newStatus);
        setLoading(true);
        setSelectedBtnId(NotificationID);
        const Params = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: NotificationID
        };

        var api_config = {
            method: 'post',
            //url: config.base_url + 'Notification/DeleteNotifications',
            url: config.base_url + 'Notification/DeleteNotification',
            headers: {
                'Content-Type': 'application/json'
            },
            data: Params
        };

        axios(api_config)
            .then(function (response) {
                //   //console.log(response.data);
                if (response.data.status_code == 1) {
                    setIsDeleted(true);
                    setLoading(false);
                    setIsUpdated(true);
                    //let newarray = ListofNotifcations.filter(item => item.ID !== NotificationID);
                    //setListofNotifcations(newarray);
                }

            })
            .catch(function (error) {
                //console.log(error);
            });




    }
    const MarkRead = (NotificationID) => {


        let status = isChanged;
        let newStatus = parseInt(status) + parseInt(1);
        setIsChanged(newStatus);
        setLoading(true);
        setSelectedBtnId(NotificationID);
        const Params = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: NotificationID
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Notification/ReadNotification',
            headers: {
                'Content-Type': 'application/json'
            },
            data: Params
        };

        axios(api_config)
            .then(function (response) {
                //   //console.log(response.data);
                if (response.data.status_code == 1) {
                    setIsDeleted(true);
                    setLoading(false);
                    setIsUpdated(true);
                    //let newarray = ListofNotifcations.filter(item => item.ID !== NotificationID);
                    //setListofNotifcations(newarray);
                }

            })
            .catch(function (error) {
                //console.log(error);
            });




    }
    return (
        <>
            <Dropdown className='notifications'>
                <Dropdown.Toggle>
                    <div>
                        <span className={`notifications-icon ${unseenCount === 0 ? '' : 'active'}`}></span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" />
                        </svg>
                    </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="notifications-wrap" onClick={(e) => e.stopPropagation()}>
                    <div className="noifications-content">
                        <div className="notifications_head">

                            <label htmlFor="">Notifications</label>
                            <ul>
                                <li>{
                                    loadingReadAll ?
                                        '...'
                                        :
                                        <>
                                            <div onClick={handleReadAll}>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                                </svg>
                                                <span >Read All</span>
                                            </div>
                                        </>
                                }
                                </li>
                                <li>
                                    {
                                        loadingDeleteAll ?
                                            '...'
                                            :
                                            <>
                                                <div>
                                                    <span onClick={handleDeleteAll}>Clear All</span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                    </svg>
                                                </div>
                                            </>
                                    }
                                </li>
                            </ul>


                        </div>
                        <ul className='notification-list scrollbar'>
                            {renderNotifications()}
                        </ul>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

export default Notifications