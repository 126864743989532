import React, { useState, useEffect } from "react";
import { NavLink, Link, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import logo_1 from "../../assets/images/logo/logo_1.svg";
import imgUser from "../../assets/images/user.png";
import Offcanvas from "react-bootstrap/Offcanvas";
import imgStudent from "../../assets/images/icons/student.svg";
import imgFee from "../../assets/images/icons/fee.svg";
import sibling from "../../assets/images/icons/sibling.svg";
import imgExamination from "../../assets/images/icons/exam.svg";
import Photo from "../../assets/images/icons/Photo.svg";
import imgStudentAtt from "../../assets/images/icons/student-1.svg";
import Dropdown from "react-bootstrap/Dropdown";
import axios from "axios";
import config from "../../Config";
import { scrollToTop } from "../Shared/ScrollOnTop";
import StudentPanelMenu from "./StudentPanelMenu";
import Notifications from "../Shared/Notifications";

function StudentMain(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [UserName, setUserName] = useState(localStorage.getItem("UserName"));
  const [GroupName, setGroupName] = useState(
    localStorage.getItem("Group_Name")
  );
  const [FYear, setFYear] = useState(localStorage.getItem("DefaultFYear"));
  const [show, setShow] = useState(false);
  const [school, setSchool] = useState({});
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let navigate = useNavigate();

  const handleSignOut = () => {
    localStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "School/GetSchoolInfo",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setSchool(response.data);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  useEffect(() => {
    if (AccessKey == null) {
      navigate("/login");
    } else {
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        //URL: window.location.pathname,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "Student/ValidateAccessKey",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          if (response.data.status_code == 0) {
            localStorage.clear();
            navigate("/login");
          }
        })
        .catch(function (error) {
          //console.log(error);
        });
    }
  }, []);

  return (
    <>
      <header className={`desktop-header sticky`}>
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-2">
              <NavLink onClick={scrollToTop} to="/student-panel/dashboard">
                <img
                  src={config.images + school.School_logo_large}
                  className="img-fluid"
                  alt=""
                />
              </NavLink>
            </div>
            <div className="col-10">
              <ul className="navbar-general">
                <li>
                  <ul className="nav align-items-center">
                    <li className="nav-item">
                      <NavLink
                        onClick={scrollToTop}
                        className="nav-link"
                        to="/student-panel/dashboard"
                      >
                        Dashboard
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <Notifications />
                </li>
                <li className="toggle-side-menu">
                  <a onClick={handleShow} style={{ cursor: "pointer" }}>
                    <i className="ri-menu-3-fill"></i>
                  </a>
                </li>
                <li className="user-logged-in">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="user-dropdown text-start"
                      id="dropdownUserLoggedIn"
                    >
                      <div className="thumb">
                        <img src={imgUser} />
                      </div>
                      <div className="info">
                        <span className="user_name">{UserName}</span>
                        <span className="role">{MemberType}</span>
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <NavLink
                        onClick={scrollToTop}
                        className="dropdown-item"
                        to="/student-panel/change-password"
                      >
                        <i className="ri-lock-2-line me-2"></i>Change Password
                      </NavLink>
                      <button onClick={handleSignOut} className="dropdown-item">
                        <i className="ri-logout-box-line me-2"></i>Logout
                      </button>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
      <Offcanvas
        show={show}
        style={{ width: "300px" }}
        onHide={handleClose}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="offcanvas-main-menu">
            <li>
              <NavLink onClick={scrollToTop} to={`/student-panel/update-photo`}>
                <img src={Photo} alt="" />
                <span>Update Photo</span>
              </NavLink>
            </li>
            <li>
              <NavLink onClick={scrollToTop} to="/student-panel/profile">
                <img src={imgStudent} alt="Student Profile" />
                <span>Student Profile</span>
              </NavLink>
            </li>
            
            <li>
              <NavLink onClick={scrollToTop} to="/student-panel/student-ledger">
                <img src={imgFee} alt="" />
                <span>Fee Details</span>
              </NavLink>
            </li>
            <li>
              <NavLink onClick={scrollToTop} to="#/">
                <img src={imgExamination} alt="" />
                <span>Exam Results</span>
              </NavLink>
            </li>
            <li>
              <NavLink onClick={scrollToTop} to="#/">
                <img src={sibling} alt="imgStudentAtt" />
                <span>View Sibling</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={scrollToTop}
                to="/student-panel/student-summary"
              >
                <img src={imgStudentAtt} alt="" />
                <span>Attendance Record</span>
              </NavLink>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
      <StudentPanelMenu />
      <Outlet></Outlet>
      {/* <Footer /> */}
    </>
  );
}

export default StudentMain;
