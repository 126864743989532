import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import imgStudent from "../../assets/images/icons/student.svg";
import imgFee from "../../assets/images/icons/fee.svg";
import imgExamination from "../../assets/images/icons/exam.svg"
import imgStudentAtt from "../../assets/images/icons/student-1.svg"
import Print from "../../assets/images/icons/print.svg"
import sibling from "../../assets/images/icons/sibling.svg"
import Photo from "../../assets/images/icons/Photo.svg"

import { scrollToTop } from "../Shared/ScrollOnTop";
import StudentRightsCheck from "./StudentRightsCheck";
import axios from "axios";
import config from "../../Config";

function StudentPanelDashboard() {


    const [AccessKey, setAccessKey] = useState("0d99fea7-2b06-4426-9446-5d3843bd34df");
    const [UserID, setUserID] = useState("1");
    const [MemberType, setMemberType] = useState("Admin");
    const UserName = (localStorage.getItem('UserName'));

    const [NewWingId, setNewWingId] = useState(0);
    const [NewClassId, setNewClassId] = useState(0);
    const [SessionId, setSessioinId] = useState(localStorage.getItem("DefaultSession"));
    const [SectionID, setSectionID] = useState(0);
    const [ConcessionRuleID, setConcessionRuleID] = useState(0);

    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);

    const [isUpdated, setIsUpdated] = useState(false);

    const [Registration_No, SetRegistrationNo] = useState("");
    const [OrderBy, setOrderBy] = useState("Section_Name");



    useEffect(() => {

        document.title = "School System Dashboard";

        setIsUpdated(false);
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            pageNo: "1",
            pageSize: "1",
            SessionID: SessionId,
            Wing_Id: NewWingId,
            Class_Id: NewClassId,
            Section_Id: SectionID,
            Concession_Rule_ID: ConcessionRuleID,
            Registration_No: Registration_No,
            OrderBy: OrderBy,
        };

        ////console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Student/SearchStudents',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                ////console.log(response.data);
                setLedger(response.data.ListofRecords);
                setLoading(false);


            })
            .catch(function (error) {
                //console.log(error);
            });

    }, []);


    return (
        <>
            <StudentRightsCheck />
            <div className="container body-content">
                <div className="page-head">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/student-panel/dashboard">Home</Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Student Dashboard
                                        </li>
                                    </ol>
                                </nav>
                                <div className="page-title">Student Dashboard</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="dashboard mt-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <ul className="dashboard-buttons">
                                <li>
                                    <NavLink onClick={scrollToTop} to={`/student-panel/update-photo`}>
                                        <img src={Photo} alt="" />
                                        <span>Update Photo</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={scrollToTop} to="/student-panel/profile">
                                        <img src={imgStudent} alt="Student Profile" />
                                        <span>Student Profile</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={scrollToTop} to="/student-panel/student-ledger">
                                        <img src={imgFee} alt="Fee Details" />
                                        <span>Fee Details</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={scrollToTop} to="#/">
                                        <img src={imgExamination} alt="imgExamination" />
                                        <span>Exam Results</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={scrollToTop} to="/student-panel/student-summary">
                                        <img src={imgStudentAtt} alt="imgStudentAtt" />
                                        <span>Attendance</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={scrollToTop} to="#/">
                                        <img src={sibling} alt="imgStudentAtt" />
                                        <span>View Sibling</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <a style={{ cursor: "pointer" }} target="_blank" href={`https://www.dpskasur.edu.pk/PrintVouchers/PrintChallan/?Registration_No=${UserName}`}>
                                        <img src={Print} alt="imgStudentAtt" />
                                        <span>Print Voucher</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default StudentPanelDashboard;
