const config = {
    
  date_format: "DD/MM/YYYY",
  date_format_input: "YYYY-MM-DD",
  
  // changes in config file
  
   base_url: "https://api.dpskasur.edu.pk/api/",
  StaffImagePath: "https://api.dpskasur.edu.pk/staff_images/",
  StudentImagePath: "https://api.dpskasur.edu.pk/student_images/",
  images: "https://api.dpskasur.edu.pk/images/",
  assets: "https://api.dpskasur.edu.pk/assets/",
  root: "https://api.dpskasur.edu.pk/",

  // changes in config file
  
  // base_url: "http://192.168.1.99:8072/api/",
  // StaffImagePath: "http://192.168.1.99:8072/staff_images/",
  // StudentImagePath: "http://192.168.1.99:8072/student_images/",
  // images: "http://192.168.1.99:8072/images/",
  // assets: "http://192.168.1.99:8072/assets/",
  // root: "http://192.168.1.99:8072/",


 // apis for Local HOst
  // base_url: "http://localhost:52476/api/",
  // StaffImagePath: "http://localhost:52476/staff_images/",
  // StudentImagePath: "http://localhost:52476/student_images/",
  // images: "http://localhost:52476/images/",
  // assets: "http://localhost:52476/assets/",
  // root: "http://localhost:52476/",

// new password :Aabcde@786
  };
  export default config;