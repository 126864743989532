import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { NavLink } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import QRCode from "react-qr-code";
import StudentInfo from "../FeeSystem/StudentInfo";
import moment from "moment";
import PrintHeader from "../General/PrintHeader";
import Page from "../General/Page";
import { calculateColumnTotal, getMonthName } from "../General/Utility";
import RightsCheck from "../General/RightsCheck";

const PrintManageAccount = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [FYear, setFYear] = useState(localStorage.getItem("DefaultFYear"));
  const [isUpdated, setIsUpdated] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10000);
  const [totalRecords, setTotalRecords] = useState(0);

  const urlParams = new URLSearchParams(window.location.search);

  const Level = urlParams.get('level');
  const routecode = urlParams.get('routecode');
  const busno = urlParams.get('busno');

  useEffect(() => {
    document.title = "Print Manage Accounts";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      FYear: FYear,
      pageNo: pageNo,
      pageSize: pageSize,
      AccountLevel: Level,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Accounts/GetAccountsByYear",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [pageNo, pageSize, isUpdated, Level]);

  const renderLedger = () => {
    if (listOfLedger.length > 0) {
      let count = 1;

      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{count++ + pageSize * (pageNo - 1)}</td>
            <td>{item.Account_code}</td>
            <td>{item.Account_desc}</td>
            <td>{item.iLevel}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="8">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <RightsCheck />
      <Page orientation="portrait" size="a4">
        <table className="table table-sm table-bordered table-print">
          <thead>
            <tr>
              <th colspan={20}>
                <PrintHeader title={`List Of Manage Account`} />
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colspan="8">
                  <Spinner animation="border" role="status"></Spinner>
                </td>
              </tr>
            ) : (
              <>
              <tr>
              <th colSpan={10} className="text-end">  Total Records : {totalRecords}</th>
              </tr>
                <tr>
                  <th>Sr#</th>
                  <th>Account Code</th>
                  <th>Account Desc.</th>
                  <th>Level</th>
                </tr>
                {renderLedger()}
              </>
            )}
          </tbody>
        </table>
      </Page>
    </>
  );
};

export default PrintManageAccount;
