import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import config from "../../Config";
import study_complete from "../../assets/images/dashboard_icon/study_complete.svg";
import section from "../../assets/images/dashboard_icon/section.svg";
import classes from "../../assets/images/dashboard_icon/classes.svg";
import axios from "axios";
import AnnualMenu from "./AnnualMenu";
import { scrollToTop } from "../Shared/ScrollOnTop";

function AnnualTasksDashboard(props) {
  document.title = "Annual Tasks";
  return (
    <>
      <AnnualMenu />

      <div className="page-head">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/Dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Annual Tasks
                  </li>
                </ol>
              </nav>
              <div className="page-title">Annual Tasks</div>
            </div>
            <div className="col-md-6">
              <div className="page-right-actions">
                <Link
                  to="/Student/SearchStudents"
                  className="button button-white button-icon"
                >
                  Search Students
                  <i className="ri-search-line"></i>
                </Link>
                <Link
                  to="/Student/AddStudent"
                  className="button button-white button-icon"
                >
                  Add Student
                  <svg
                    width="20"
                    height="19"
                    viewBox="0 0 20 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 10.999L11 11C12.0538 11 12.9181 11.8155 12.9945 12.8507L13 13V14.5C12.999 18 9.284 19 6.5 19C3.77787 19 0.164695 18.044 0.00545406 14.7296L0 14.5V12.999C0 11.9452 0.816397 11.0809 1.85081 11.0045L2 10.999ZM13.22 11H18C19.0538 11 19.9181 11.8164 19.9945 12.8508L20 13V14C19.999 17.062 17.142 18 15 18C14.32 18 13.569 17.904 12.86 17.678C13.196 17.292 13.467 16.851 13.662 16.351C14.205 16.476 14.715 16.5 15 16.5L15.2665 16.494C16.2518 16.4509 18.3529 16.1306 18.4927 14.205L18.5 14V13C18.5 12.7547 18.3222 12.5504 18.0896 12.5081L18 12.5H13.949C13.865 11.9986 13.6554 11.5432 13.3545 11.1598L13.22 11H18H13.22ZM2 12.499L1.89934 12.509C1.77496 12.5343 1.69 12.6018 1.646 12.645C1.6028 12.689 1.53528 12.7733 1.51 12.898L1.5 12.999V14.5C1.5 15.509 1.95 16.222 2.917 16.742C3.74315 17.1869 4.91951 17.4563 6.18258 17.4951L6.5 17.5L6.8174 17.4951C8.08035 17.4563 9.25592 17.1869 10.083 16.742C10.9886 16.2545 11.4416 15.5974 11.4947 14.6849L11.5 14.499V13C11.5 12.7547 11.3222 12.5504 11.0896 12.5081L11 12.5L2 12.499ZM6.5 0C8.985 0 11 2.015 11 4.5C11 6.985 8.985 9 6.5 9C4.015 9 2 6.985 2 4.5C2 2.015 4.015 0 6.5 0ZM15.5 2C17.433 2 19 3.567 19 5.5C19 7.433 17.433 9 15.5 9C13.567 9 12 7.433 12 5.5C12 3.567 13.567 2 15.5 2ZM6.5 1.5C4.846 1.5 3.5 2.846 3.5 4.5C3.5 6.154 4.846 7.5 6.5 7.5C8.154 7.5 9.5 6.154 9.5 4.5C9.5 2.846 8.154 1.5 6.5 1.5ZM15.5 3.5C14.397 3.5 13.5 4.397 13.5 5.5C13.5 6.603 14.397 7.5 15.5 7.5C16.603 7.5 17.5 6.603 17.5 5.5C17.5 4.397 16.603 3.5 15.5 3.5Z"
                      fill="#003970"
                    ></path>
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-content">
        <div className="container">
          <div className="row gy-2">
            <div className="col-md-12">
              <div className="card m-card">
                <div className="card-body p-2">
                  <p className="text-center m-0">
                    <b>Current Month Started 1st Jan - Feb 10</b>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <table className="table table-theme table-condensed pb-md-0">
                    <thead style={{ backgroundColor: "#F8F8F8" }}>
                      <tr className=" mb-1">
                        <th colspan="4" className="text-center">
                          Previous sessions
                        </th>
                      </tr>
                    </thead>
                    <thead style={{ backgroundColor: "#F8F8F8" }}>
                      <tr>
                        <th>Month ID</th>
                        <th>Month</th>
                        <th>Year</th>
                        <th className="text-center">Started On</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>02</td>
                        <td>2023</td>
                        <td className="text-center">2023</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card m-card">
                <div className="card-body text-center">
                  <img src={study_complete} className="pb-3" alt="" />
                  <p className="m-2">No of Student Completed Study.</p>
                  <h4 className="m-2">4553</h4>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card m-card">
                <div className="card-body text-center">
                  <img src={section} className="pb-3" alt="" />
                  <p className="m-2">Total Sections</p>
                  <h4 className="m-2">34</h4>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-body text-center">
                  <img src={classes} className="pb-3" alt="" />
                  <p className="m-2">Total Classes</p>
                  <h4 className="m-2">23</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AnnualTasksDashboard;
