import React, { useState, useEffect, useRef } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import SessionsDropDown from "../Shared/SessionsDropDown";
import WingsDropDown from "../Shared/WingsDropDown";
import ClassesDropDown from "../Shared/ClassesDropDown";
import SectionDropDown from "../Shared/SectionDropDown";
import StudentGroupDropDown from "../Shared/StudentGroupDropDown";
import StudentStatusDropDown from "../Shared/StudentStatusDropDown";
import { toast, ToastContainer } from "react-toastify";
import { NavLink } from "react-router-dom";
import moment from "moment";
import Pagination from "../General/Pagination";
import Dropdown from "react-bootstrap/Dropdown";
import { Form, PageItem } from "react-bootstrap";
import FeeSystemMenu from "./FeeSystemMenu";
import YearsDropDown from "../Shared/YearsDropDown";
import { scrollToTop } from "../Shared/ScrollOnTop";
import MonthsDropDown from "../Shared/MonthsDropDown";
import RightsCheck from "../General/RightsCheck";

function AddAbsentFine(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  //const [DefaultSession, setDefaultSession] = useState(localStorage.getItem("DefaultSession"));

  const [showFilters, setShowFilters] = useState(true);

  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [validated, setValidated] = useState(false);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const [NewWingId, setNewWingId] = useState(0);
  const [NewSessionId, setNewSessioinId] = useState(0);
  const [NewClassId, setNewClassId] = useState(0);
  const [FineRate, setFineRate] = useState(0);
  const [SelectedYear, setSelectedYear] = useState("");
  const [SelectedMonth, setSelectedMonth] = useState("");

  const [WingId, setWingId] = useState(0);
  const [SessionId, setSessioinId] = useState(
    localStorage.getItem("DefaultSession")
  );
  const [ClassId, setClassId] = useState(0);
  const [SectionID, setSectionID] = useState(0);
  const [isUpdated, setIsUpdated] = useState(false);

  const [Registration_No, SetRegistrationNo] = useState("");
  const [OrderBy, setOrderBy] = useState(0);
  const [selectedStudents, setselectedStudents] = useState([]);
  const [validatedPromote, setValidatedPromote] = useState(false);

  const formRef = useRef(null);

  useEffect(() => {
    document.title = "Add Absent Fine";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: pageSize,
      SessionID: SessionId,
      Wing_Id: WingId,
      Class_Id: ClassId,
      Section_Id: SectionID,
      Registration_No: Registration_No,
      OrderBy: OrderBy,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Student/SearchStudents",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    //console.log(data);

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [
    pageNo,
    pageSize,
    SessionId,
    WingId,
    ClassId,
    SectionID,
    Registration_No,
    OrderBy,
    SelectedYear,
    SelectedMonth,
  ]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{count++ + pageSize * (pageNo - 1)}</td>

            <td>{item.Student_ID}</td>
            <td>{item.Registration_No}</td>
            <td>{item.Student_Name}</td>
            <td>{item.Father_Name}</td>
            <td>
              <input
                type="number"
                defaultValue={0}
                style={{ width: "100px" }}
                data-student-id={item.Student_ID}
                className="form-control leaves text-center"
              />
            </td>
            <td>{item.SelectedClass.SelectedWing.Wing_Name}</td>
            <td>{item.SelectedClass.Class_Name}</td>
            <td>{item.SelectedSection.Section_Name}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="10">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        pageNo: pageNo,
        pageSize: pageSize,
        SessionID: SessionId,
        Wing_Id: WingId,
        Class_Id: ClassId,
        Section_Id: SectionID,
        Registration_No: Registration_No,
        OrderBy: OrderBy,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "Student/SearchStudents",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      //console.log("data", data);

      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          setLedger(response.data.ListofRecords);
          setLoading(false);
          setTotalRecords(response.data.totalRecords);
        })
        .catch(function (error) {
          //console.log(error);
        });
    }

    setValidated(true);
  };

  //handlePromote
  const handleAddFine = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      // setLoading(true);

      const selectedValues = [];

      const inputs = document.getElementsByClassName("leaves");

      for (let i = 0; i < inputs.length; i++) {
        const Leaves = parseInt(inputs[i].value);
        if (Leaves > 0) {
          const studentId = inputs[i].dataset.studentId;

          selectedValues.push({ studentId, Leaves });
        }
      }

      //console.log(valuesSelected);
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        ListofStudentLeave: selectedValues,
        Amount: event.target.FineRate.value,
      };

      //console.log(data);

      var api_config = {
        method: "post",
        url: config.base_url + "FeeSystem/AddAbsentFine",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      //console.log(data);

      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setIsUpdated(true);
          } else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }

          setLoading(false);
        })
        .catch(function (error) {
          //console.log(error);
        });
    }

    setValidatedPromote(true);
  };

  const handleCheckAll = (e) => {
    const checkboxes = document.getElementsByClassName("checkStudent");

    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = e.target.checked;
    }
  };

  return (
    <>
      <RightsCheck />
      <FeeSystemMenu />
      <ToastContainer />
      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/Dashboard">Dashboard</NavLink>
                    </li>
                    <li className="breadcrumb-item">
                      <NavLink to="/FeeSystem/FeeSystemDashboard">
                        Fee System
                      </NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Add Absent Fine
                    </li>
                  </ol>
                </nav>
                <div className="page-title">Add Absent Fine</div>
              </div>

              <div className="col-md-6">
                <div className="page-right-actions">
                  <NavLink
                    to="/students/AddStudent"
                    className="button button-white button-icon"
                  >
                    Add Students
                    <i className="ri-add-fill"></i>
                  </NavLink>
                  <button
                    onClick={() => {
                      setShowFilters((prevIsActive) => !prevIsActive);
                    }}
                    className="button button-white button-icon"
                  >
                    {showFilters ? "Hide Filters" : "Show Filters"}
                    <i className="ri-filter-3-line"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`collapse ${showFilters ? "show" : ""} `}
          id="collapseFilteres"
        >
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            ref={formRef}
          >
            <div className="page-content">
              <div className="container">
                <div className="card mb-2">
                  <div className="card-body p-md-4">
                    <div className="row align-items-end gy-3">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Select Session:
                          </label>
                          <select
                            className="form-select"
                            onChange={(e) => {
                              setSessioinId(e.target.value);
                              setNewWingId(0);
                              setSectionID(0);
                              setNewClassId(0);
                            }}
                            name="SessionID"
                          >
                            <option value="0">---All---</option>
                            <SessionsDropDown selectedSession={SessionId} />
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Select Wing:</label>
                          <select
                            name="Wing_Id"
                            onChange={(e) => {
                              setNewWingId(e.target.value);
                              setNewClassId(0);
                              setSectionID(0);
                            }}
                            className="form-select"
                          >
                            <option value="0">---All---</option>
                            <WingsDropDown />
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Select Class:
                          </label>
                          <select
                            className="form-select"
                            onChange={(e) => {
                              setNewClassId(e.target.value);
                              setSectionID(0);
                            }}
                            name="Class_ID"
                          >
                            <option value="">Please Select</option>
                            {SessionId != 0 && NewWingId != 0 && (
                              <ClassesDropDown
                                SessionID={SessionId}
                                WingID={NewWingId}
                              />
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Select Section:
                          </label>
                          <select
                            className="form-select"
                            onChange={(e) => setSectionID(e.target.value)}
                            name="Section_Id"
                          >
                            <option value="0">Please Select</option>
                            {NewClassId !== 0 && (
                              <SectionDropDown ClassID={NewClassId} />
                            )}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Registration No:
                          </label>
                          <input
                            type="text"
                            name="Reg_No"
                            onChange={(e) => SetRegistrationNo(e.target.value)}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Order by:
                          </label>
                          <select
                            name="OrderBy"
                            onChange={(e) => setOrderBy(e.target.value)}
                            className="form-select"
                          >
                            <option Selected Value="Student_Name">
                              Student Name
                            </option>
                            <option Value="Registration_No">
                              Registration No
                            </option>
                            <option Value="Wing_Name">Wing Name</option>
                            <option Value="Class_Name">Class</option>
                            <option Value="Student_ID">Student ID Asc</option>
                            <option Value="Student_ID desc">
                              Student ID Desc
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Select Year:
                          </label>
                          <select
                            className="form-select"
                            onChange={(e) => setSelectedYear(e.target.value)}
                          >
                            <option>Please Select</option>
                            {<YearsDropDown />}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Select Month:
                          </label>
                          <select
                            className="form-select"
                            onChange={(e) => setSelectedMonth(e.target.value)}
                          >
                            <option>Please Select</option>
                            {<MonthsDropDown />}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-2 offset-md-10">
                        <div className="form-group">
                          <button
                            type="submit"
                            value="Search"
                            onClick={handleSubmit}
                            className="button button-primary w-100"
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
        <div className="page-content">
          <div className="container">
            <div className="card">
              <div className="card-body">
                <div className="table_card">
                  {/* <div className="text-end mb-2 me-3">
                                    <b>{{totalRecords}} &nbsp; Records Found!</b>

                                </div> */}
                  <table className="table table-theme">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Student ID</th>
                        <th>Reg No</th>
                        <th>Name</th>
                        <th>Father Name</th>
                        <th>Total Leaves</th>
                        <th>Wing</th>
                        <th>Class</th>
                        <th>Section</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td className="text-center" colspan="11">
                            <Spinner animation="border" role="status"></Spinner>
                          </td>
                        </tr>
                      ) : (
                        <>{renderLedger()}</>
                      )}
                    </tbody>
                  </table>
                  {totalRecords > 10 && (
                    <div className="pagination-wrap">
                      <div className="row align-items-center">
                        <div className="col-md-2 form_default">
                          <div className="d-flex align-items-center">
                            <label
                              className="me-2"
                              style={{ fontSize: "15px" }}
                            >
                              Page Size
                            </label>
                            <div className="form-group">
                              <select
                                className="form-select"
                                style={{ width: "100px" }}
                                onChange={(e) => {
                                  setPageNo(1);
                                  setPageSize(parseInt(e.target.value));
                                }}
                              >
                                <option value="10" selected>
                                  10
                                </option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="500">500</option>
                                <option value="1000">1000</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <Pagination
                          pageNo={pageNo}
                          pageSize={pageSize}
                          totalRecords={totalRecords}
                          onPageChange={handlePageChange}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="card mt-2">
              <div className="card-body">
                <div className="section-title title-sm title-left">
                  Add Fine To The Selected Student
                </div>
                <Form
                  noValidate
                  validated={validatedPromote}
                  onSubmit={handleAddFine}
                >
                  <div className="card-body p-md-4 pt-0">
                    <div className="row gy-3">
                      <label
                        for=""
                        className="col-md-1 col-form-label col-form-label-sm"
                      >
                        Fine Rate:
                      </label>
                      <div className="col-md-3">
                        <input
                          type="number"
                          name="FineRate"
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-2 offset-md-6 text-end">
                        <input
                          type="submit"
                          value="Attach Fine"
                          className="button button-primary w-100"
                        />
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddAbsentFine;
