import React, { useState, useEffect } from "react";
import { NavLink, Link, Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import imgLogo from "../../assets/images/logo.png"
import imgUser from "../../assets/images/user.png"
import Dropdown from 'react-bootstrap/Dropdown';
import axios from "axios";
import Footer from "../Shared/Footer";
import config from "../../Config";
import { scrollToTop } from '../Shared/ScrollOnTop';

function TeacherMain(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [UserName, setUserName] = useState(localStorage.getItem("UserName"));
    const [GroupName, setGroupName] = useState(localStorage.getItem("Group_Name"));
    const [FYear, setFYear] = useState(localStorage.getItem("DefaultFYear"));
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let navigate = useNavigate();

    const handleSignOut = () => {
        localStorage.clear();
        navigate("/login");
    }

    useEffect(() => {

        if (AccessKey == null) {
            navigate("/login");
        }
        else {
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                //URL: window.location.pathname,
            };


            var api_config = {
                method: 'post',
                url: config.base_url + 'Teacher/ValidateAccessKey',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(api_config)
                .then(function (response) {
                    //console.log(response.data);
                    if (response.data.status_code == 0) {
                        localStorage.clear();
                        navigate("/teacher/login");
                    }


                })
                .catch(function (error) {
                    //console.log(error);
                });
        }
    }, []);

    return (
        <>
            <header className="desktop-header sticky">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-2">
                            <NavLink to="/teacher/dashboard">
                                <img src={imgLogo} className="img-fluid" alt="" />
                            </NavLink>
                        </div>
                        <div className="col-md-10">
                            <ul className="navbar-general">
                                <li className="user-logged-in">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="user-dropdown text-start" id="dropdownUserLoggedIn">
                                            <div className="thumb">
                                                <img src={imgUser} />
                                            </div>
                                            <div className="info">
                                                <span className="user_name">
                                                    {UserName}
                                                </span>
                                                <span className="role">{MemberType}</span>
                                            </div>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <NavLink className="dropdown-item" to="/Security/ChangePassword"><i className="ri-lock-2-line me-2"></i>Change Password</NavLink>
                                            <button onClick={handleSignOut} className="dropdown-item"><i className="ri-logout-box-line me-2"></i>Logout</button>
                                        </Dropdown.Menu>
                                    </Dropdown>


                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
            <Outlet></Outlet>
            <Footer />
        </>
    );
}

export default TeacherMain;