import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import moment from "moment";
import PrintHeader from '../General/PrintHeader';
import { useNavigate, useParams } from 'react-router-dom';
import Page from "../General/Page";

const PrintIssuanceReport = () => {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [listOfLedger, setLedger] = useState([]);
    const [isUpdated, setIsUpdated] = useState(false);
    const [selectedMonth, setselectedMonth] = useState('');



    

    const urlParams = new URLSearchParams(window.location.search);

    const lang = urlParams.get('lang');

    const EndDate = urlParams.get('EndDate');
    const StartDate = urlParams.get('startDate');




    useEffect(() => {

        document.title = "Print Issuance Report ";

        setIsUpdated(false);
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            PageNo: 1,
            PageSize: 100000,
            StartDate: StartDate,
            EndDate: EndDate,
        };

        //console.log(data);

        var api_config = {
            method: "post",
            url: config.base_url + "Library/GetIssuanceReport",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        //console.log(data)
        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setLedger(response.data.ListofRecords);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);

            })
            .catch(function (error) {
                //console.log(error);
            });

    }, []);


    const renderLedger = () => {

        if (listOfLedger != null && listOfLedger.length > 0) {
            let count = 1;
            let countbreak = 1;
            return (
                listOfLedger.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>
                                {count++}
                            </td>
                            <td>{item.Name_Of_Book}</td>
                            <td>{moment(item.Date_Of_Issue).format('DD/MM/YYYY')}</td>
                            <td>{item.Student_Name}</td>
                            <td>{item.Employee_Name}</td>
                            <td>{item.Remarks}</td>

                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colspan="7"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }

    return (
        <>
            <Page orientation="portrait" size="a4">
                <table className="table table-sm table-bordered table-print white-space-wrap">
                    <thead>
                        <tr>
                            <th colSpan={10}>
                                <PrintHeader title="Library Issuance Report _____________________________________________________" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr>
                            <th>Sr. No.</th>
                            <th>Book Name</th>
                            <th>Issuance Date</th>
                            <th>Student Name</th>
                            <th>Employee Name</th>
                            <th>Remarks</th>

                        </tr>


                        {
                            loading ?
                                <tr>
                                    <td className="text-center" colspan="9">
                                        <Spinner animation="border" role="status"></Spinner>
                                    </td>
                                </tr>
                                :
                                <>
                                    {renderLedger()}
                                </>

                        }

                    </tbody>
                </table>
            </Page >
        </>
    );
}

export default PrintIssuanceReport;
