import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import QRCode from "react-qr-code";
import StudentInfo from '../FeeSystem/StudentInfo';
import moment from "moment";
import PrintHeader from '../General/PrintHeader';
import Page from "../General/Page";
import { calculateColumnTotal } from '../General/Utility';
import { scrollToTop } from '../Shared/ScrollOnTop';

const PrintSummarySheetTwo = () => {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [listOfLedger, setLedger] = useState([]);
    const [sMonth, setsMonth] = useState(0);
    const [sYear, setsYear] = useState(0);
const [departmentName,setDepartmentName]  = useState()
    useEffect(() => {
        document.title="Print Summary Sheet 2"

        setLoading(true);
        // Get the URL parameters
        const urlParams = new URLSearchParams(window.location.search);

        // Retrieve individual parameter values
        const selectedYear = urlParams.get('year');
        const selectedMonth = urlParams.get('month');
        const EmployeeTypeId = urlParams.get('EmployeeTypeId');
        const designationId = urlParams.get('designationId');
        const bpsScale = urlParams.get('bpsScale');

        setsMonth(selectedMonth);
        setsYear(selectedYear);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            Fyear: selectedYear,
            SelectedMonth: selectedMonth,
            EmployeeTypeId: EmployeeTypeId,
            DesignationID: designationId,
            SelectedGrade: bpsScale,
        };



        //console.log(data);
        var api_config = {
            method: 'post',
            url: config.base_url + 'Payroll/GetSummarySheet',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        //console.log(data);

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setLedger(response.data.ListofRecords);
                setDepartmentName(response.data.ReportTitleName)
                setLoading(false);


            })
            .catch(function (error) {
                //console.log(error);
            });

    }, []);

    const getMonthName = (monthNumber) => {
        const monthNames = [
          'January', 'February', 'March', 'April', 'May', 'June',
          'July', 'August', 'September', 'October', 'November', 'December'
        ];
    
        return monthNames[monthNumber - 1];
      };
      
    const renderLedger = () => {

        if (listOfLedger.length > 0) {
            let count = 1;
            let countbreak = 1;
            return (
                listOfLedger.map((item, index) => {
                    return (
                        <tr key={index}
                        //  className={countbreak++ == 47 ? 'page-break' : ''}
                         >
                            <td>
                                {count++}
                            </td>
                            <td>{item.Selected_Employee?.Code}</td>
                            <td>{item.Selected_Employee?.Name}</td>
                            <td>{item.Selected_Employee?.GPS_Scale}</td>
                            <td>{item.Working_Days}</td>
                            <td>{item.Basic_Salary}</td>
                            <td>{item.House_Rent}</td>
                            {/* <td>{item.Inc01072017}</td>
                            <td>{item.Inc01072018}</td>
                            <td>{item.Inc01072019}</td>
                            <td>{item.Inc01072021}</td>
                            <td>{item.Inc01072022}</td> */}
                            <td>{item.Inc01072023}</td>
                            <td>{item.Inc01072024}</td>
                            <td>{item.Misc_Allowances}</td>
                            <td>{item.Arrears}</td>
                            <td>{item.Gross}</td>
                            <td>{item.Tax}</td>
                            <td>{item.EOBI}</td>
                            <td>{item.SWF}</td>
                            <td>{item.Leave_Deduction}</td>
                            <td>{item.Misc_Deductions}</td>
                            <td>{item.Deduction_Total}</td>
                            <td>{item.Net}</td>
                        </tr>

                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colspan="18"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }


    return (
        <>
            <Page orientation="landscape" size="a4">
                <table className="table table-sm table-bordered table-print">
                    <thead>
                        <tr>
                            <th colSpan={18}>
                                <PrintHeader title={`SALARY SHEET FOR THE MONTH OF ${getMonthName(sMonth) + ' ' + sYear} `} />
                                <label>{` Department/Type: ${departmentName}`}</label>

                            </th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading ?
                                <tr>
                                    <td className="text-center" colspan="18">
                                        <Spinner animation="border" role="status"></Spinner>
                                    </td>
                                </tr>
                                :
                                <>
                                    <tr>
                                        <th>Sr.#</th>
                                        <th>Code</th>
                                        <th>Staff Name </th>
                                        <th>GPS</th>
                                        <th>Days</th>
                                        <th>Basic</th>
                                        <th>H.R. </th>
                                        {/* <th>1-7-17 </th>
                                        <th>1-7-18 </th>
                                        <th>1-7-19</th>
                                        <th>1-7-21 </th>
                                        <th>1-7-22 </th> */}
                                        <th>1-7-23 </th>
                                        <th>1-7-24 </th>
                                        <th>Misc.All.</th>
                                        <th>Arrears</th>
                                        <th>Gross</th>
                                        <th>Tax</th>
                                        <th>EOBI</th>
                                        <th>SWF</th>
                                        <th>Leave Ded.</th>
                                        <th>Misc Ded.</th>
                                        <th>Total</th>
                                        <th>Net Pay</th>
                                    </tr>
                                    {renderLedger()}
                                    <tr>
                                        <td colspan={5}>Total Amount: </td>
                                        <th>{calculateColumnTotal(listOfLedger, 'Basic_Salary')}</th>
                                        <th>{calculateColumnTotal(listOfLedger, 'House_Rent')}</th>
                                        {/* <th>{calculateColumnTotal(listOfLedger, 'Inc01072017')}</th>
                                        <th>{calculateColumnTotal(listOfLedger, 'Inc01072018')}</th>
                                        <th>{calculateColumnTotal(listOfLedger, 'Inc01072019')}</th>
                                        <th>{calculateColumnTotal(listOfLedger, 'Inc01072021')}</th>
                                        <th>{calculateColumnTotal(listOfLedger, 'Inc01072022')}</th> */}
                                        <th>{calculateColumnTotal(listOfLedger, 'Inc01072023')}</th>
                                        <th>{calculateColumnTotal(listOfLedger, 'Inc01072024')}</th>
                                        <th>{calculateColumnTotal(listOfLedger, 'Misc_Allowances')}</th>
                                        <th>{calculateColumnTotal(listOfLedger, 'Arrears')}</th>
                                        <th>{calculateColumnTotal(listOfLedger, 'Gross')}</th>
                                        <th>{calculateColumnTotal(listOfLedger, 'Tax')}</th>
                                        <th>{calculateColumnTotal(listOfLedger, 'EOBI')}</th>
                                        <th>{calculateColumnTotal(listOfLedger, 'SWF')}</th>
                                        <th>{calculateColumnTotal(listOfLedger, 'Leave_Deduction')}</th>
                                        <th>{calculateColumnTotal(listOfLedger, 'Misc_Deductions')}</th>
                                        <th>{calculateColumnTotal(listOfLedger, 'Deduction_Total')}</th>
                                        <th>{calculateColumnTotal(listOfLedger, 'Net')}</th>
                                    </tr>
                                </>

                        }

                    </tbody>
                </table>




                <div style={{ marginTop: "30px", width: "100%" }}>
                    <table className="table table-sm table-bordered table-print" style={{ borderCollapse: "collapse", border: "transparent" }}>
                        <tbody>
                            <tr className="my-5">
                                <th style={{ width: "33.33%", textAlign: "left", textDecoration: "underline" }}>
                                Accountant
                                </th>
                                <th style={{ width: "33.33%", textAlign: "center", textDecoration: "underline" }}>
                                    Burser
                                </th>
                                <th style={{ width: "33.33%", textAlign: "right", textDecoration: "underline" }}>
                                    Head of Wing / Deptt
                                </th>
                            </tr>
                            <tr className="my-5">
                                <br /><br />
                            </tr>
                            <tr className="my-5">
                                <th style={{ width: "33.33%", textAlign: "left", textDecoration: "underline" }}>
                                    Admin Officer
                                </th>
                                <th style={{ width: "33.33%", textAlign: "center", textDecoration: "underline" }}>

                                </th>
                                <th style={{ width: "33.33%", textAlign: "right", textDecoration: "underline" }}>
                                    Principal
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Page>
        </>
    );
}

export default PrintSummarySheetTwo;
