import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import moment, { months } from "moment";
import PrintHeader from "../General/PrintHeader";
import Page from "../General/Page";
import { calculateColumnTotal, getMonthName } from '../General/Utility';

const PrintManageWings = () => {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [listOfLedger, setLedger] = useState([]);
    const [SessionID, setSessionId] = useState(localStorage.getItem("DefaultSession"));
    const [isUpdated, setIsUpdated] = useState(false);
    const [WingName, setWingName] = useState("");

  

    useEffect(() => {
        document.title="List Of Wings"
          
        const urlParams = new URLSearchParams(window.location.search);

       
        const WingID = urlParams.get('wing_id');



        setLoading(true);
   

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            pageNo: 1,
            pageSize: 1000,
            SchoolID: 1,
            SessionID: SessionID,
        };



        //console.log(data);
        var api_config = {
            method: 'post',
            url: config.base_url +  'Wings/GetSchoolWingsClassCountSession',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        //console.log(data);

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code ==1) {
                    setLedger(response.data.ListofRecords);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);
                }
                else {
                    setLedger(null);
                    setTotalRecords(0)
                }
            })
            .catch(function (error) {
                //console.log(error);
            });
            
    }, []);

    const renderLedger = () => {

        if (listOfLedger.length > 0) {
            let count = 1;
           
            return (
                listOfLedger.map((item, index) => {
                    return (
                        <tr key={index} >
                            <td>{count++}</td>
                            <td>{item.Wing_ID}</td>
                            <td>{item.Wing_Code}</td>
                            <td>{item.Wing_Name}</td>
                            <td>{item.Wing_Detail}</td>
                            <td>{item.TotalClasses}</td>

                        </tr>

                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colspan="20"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }


    return (
        <>
            <Page orientation="potrait" size="a4">
                <table className="table table-sm table-bordered table-print">
                    <thead>
                        <tr>
                            <td colspan="8">
                                <PrintHeader title={`List Of Wings`} />
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            
                            <th>
                                            Sr#
                                        </th>
                                        <th>
                                            Wing ID
                                        </th>
                                        <th>
                                            Wing Code
                                        </th>
                                        <th>
                                            Wing Name
                                        </th>
                                        <th>
                                            Wing Detail
                                        </th>
                                        <th>
                                            Total Classes
                                        </th>
                        </tr>
                        {
                            loading ?
                                <tr>
                                    <td className="text-center" colspan="5">
                                        <Spinner animation="border" role="status"></Spinner>
                                    </td>
                                </tr>
                                :
                                <>
                                    {renderLedger()}
                                    
                                </>

                        }

                    </tbody>
                </table>


            </Page>
        </>
    );
}

export default PrintManageWings;
