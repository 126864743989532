import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import PrintHeader from "../General/PrintHeader";
import Page from "../General/Page";

function RPTAwardList(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [listOfLedger, setLedger] = useState([]);
  const [selectWing, setselectWing] = useState("");
  const [selectClass, setselectClass] = useState("");
  const [selectSection, setselectSection] = useState("");

  let params = useParams();

  useEffect(() => {
    document.title = "Award List For Examination";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: 1,
      pageSize: 2000,
      Section_Id: props.Section_ID ? props.Section_ID : params.id,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Student/SearchStudents",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
        setselectWing(
          response.data.ListofRecords[0].SelectedClass?.SelectedWing?.Wing_Name
        );
        setselectClass(
          response.data.ListofRecords[0].SelectedClass?.Class_Name
        );
        setselectSection(
          response.data.ListofRecords[0].SelectedSection?.Section_Name
        );
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [props.Section_ID]);

  const renderLedger = () => {
    if (listOfLedger.length > 0) {
      let count = 1;
      let countbreak = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{count++}</td>
            <td>{item.Registration_No}</td>
            <td>{item.Student_Name}</td>
            <td>{item.Father_Name}</td>
            <td></td>
            <td></td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="7">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <Page orientation="portrait" size="a4">
        <table
          className="table table-sm table-bordered table-print"
          style={{ border: "transparent" }}
        >
          <thead>
            <tr>
              <th>
                <PrintHeader
                  title={`Award List For Examination __________________________ ${selectWing} - ${selectClass} - ${selectSection}`}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div>
                  <table
                    className="table table-sm table-bordered table-print"
                    cellpadding="2"
                    cellspacing="2"
                    style={{
                      borderCollapse: "collapse",
                      border: "transparent",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td>Date _________________________________________</td>
                        <td>Subject __________________________</td>
                      </tr>
                      <tr>
                        <td >
                          Teacher Name
                          ___________________________________
                        </td>
                        <td>Max Marks ____________________</td>
                        <td>Pass Marks ____________________</td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="table table-sm table-bordered table-print">
                    <thead>
                      <tr>
                        <th>SR #</th>
                        <th>Reg No</th>
                        <th>Name</th>
                        <th>Father Name</th>
                        <th>Marks Obtained</th>
                        <th>Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td className="text-center" colspan="8">
                            <Spinner animation="border" role="status"></Spinner>
                          </td>
                        </tr>
                      ) : (
                        <>{renderLedger()}</>
                      )}
                    </tbody>
                  </table>
                  <table
                    className="table table-sm table-bordered table-print"
                    cellpadding="2"
                    cellspacing="2"
                    style={{
                      borderCollapse: "collapse",
                      border: "transparent",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td>
                          Total Students___________________________________
                        </td>
                        <td>Pass______________________________</td>
                        <td>Fail___________________________</td>
                      </tr>
                      <tr>
                        <td>Checked By:____________________________________</td>
                        <td>Re-Checked By:____________________</td>
                        <td>H.M. Signature___________________</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </Page>
    </>
  );
}

export default RPTAwardList;
