import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import StudentMenu from "./StudentMenu";
import { toast, ToastContainer } from "react-toastify";
import { Link, NavLink } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Pagination from "../General/Pagination";
import { Form, PageItem } from "react-bootstrap";
import ShowStudent from "../Shared/ShowStudent";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import RightsCheck from '../General/RightsCheck';


function StudentLedger(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [SessionID, setSessionID] = useState(
    localStorage.getItem("DefaultSession")
  );

  const [loading, setLoading] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [show, setShow] = useState(false);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(1000);
  const [totalRecords, setTotalRecords] = useState(0);

  let params = useParams();

  const [validatedLedgerAdd, setValidatedLedgerAdd] = useState(false);

  const [StudentID, setStudentID] = useState(params.id);
  const [StartDate, setStartDate] = useState("2023-01-01");
  const [EndDate, setEndDate] = useState("2025-06-01");
  const [currentYear, setCurrentYear] = useState("");
  const [currentMonth, setCurrentMonth] = useState("");
  const [checkedCurrentData, setCheckedCurrentData] = useState(false);
  const [selectedlistOfLedger, setSelectedLedger] = useState({});
  const [Datee, setDatee] = useState(new Date());
  const [loadingUpdateBtn, setLoadingUpdateBtn] = useState(false);
  const [validatedLedgerUpdate, setValidatedLedgerUpdate] = useState(false);
  const [showForAttachDues, setShowForAttachDues] = useState(false);
  const [loadingAddDuesBtn, setLoadingAddDuesBtn] = useState(false);
  const [FeeTypes, setFeeTypes] = useState([]);
  const [credit, setCredit] = useState(0);
  const [debit, setDebit] = useState(0);
  const [receiptNo, setReceiptNo] = useState(0);
  const [FeetypeID, setFeetypeID] = useState(0);
  const [showLedgers, setShowLedgers] = useState(true);

  let totalDebit = 0;
  let totalCredit = 0;
  let navigate = useNavigate();


  useEffect(() => {
    document.title = "View Student Ledger";
    const currentDate = moment();

    const year = currentDate.format("YYYY");
    const month = currentDate.format("MM");
    setCurrentYear(year);
    setCurrentMonth(month);
    setIsUpdated(false);
    setLoading(true);
    setLoadings(true)
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: pageSize,
      Student_ID: StudentID,
      StartDate: StartDate,
      EndDate: EndDate,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Student/GetStudentLedger",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data)
        if (response.data.status_code === 1) {
          setLedger(response.data.ListofRecords);
          setLoading(false);
          setLoadings(false)
          setTotalRecords(response.data.totalRecords);
        } else {
          setLedger(null);
          setTotalRecords(0);
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
          setLoadings(false)
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [pageNo, pageSize, isUpdated]);


  const handlePageChange = (page) => {
    setPageNo(page);
  };
  const handleClose = () => {
    setShow(false);
    setSelectedLedger({});
    setLoadingUpdateBtn(false);
  };
  const handleShow = (item) => {
    setShow(true);
    setSelectedLedger(item);
    setDatee(item.Transaction_Date);
    // setEmployeeName(item.Name);
  };
  const handleCloseForAttchDues = () => {
    setShowForAttachDues(false);
    // setSelectedLedger({});
    setLoadingAddDuesBtn(false);
  };
  const handleShowForAttchDues = () => {
    setShowForAttachDues(true);

    // setLoading(true);
    const apiData = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      PageNo: 1,
      PageSize: 100,
      SchoolID: 1,
    };

    //console.log(apiData);

    var api_config = {
      method: "post",
      url: config.base_url + "FeeType/GetFeeTypes",
      headers: {
        "Content-Type": "application/json",
      },
      data: apiData,
    };

    axios(api_config)
      .then(function (response) {
        setFeeTypes(response.data.ListofRecords);
        //setLoading(false);
      })
      .catch(function (error) {
        //console.log(error);
      });

  };

  const dropdownOptions = FeeTypes.map((item) => ({
    value: item.Fee_Type_Code,
    label: item.Fee_Type_Name + "(" + item.Fee_Type_Code + ")",
    code: item.Fee_Type_Code,
  }));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%", // Set your desired width here
    }),
  };
  const [selectedValue, setSelectedValue] = useState("");
  const handleDropdownChange = (selectedOption) => {
    //let label = selectedOption.label;
    setFeetypeID(selectedOption.value);
    setSelectedValue(selectedOption.value);
  };

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      return listOfLedger.map((item, index) => {
        totalDebit += item.Debit;
        totalCredit += item.Credit;

        return (
          <>
            <tr key={index}>
              <td>{item.Transaction_ID}</td>
              <td>{moment(item.Transaction_Date).format("DD/MM/YYYY")}</td>
              <td>{item.Fee_Code}</td>
              <td>{item.Receipt_No}</td>
              <td>{item.Description}</td>
              <td>{item.Debit}</td>
              <td>{item.Credit}</td>
              <td>{item.Balance}</td>
              <td>{item.Balance_Type}</td>
              <td className="text-center">
                {/* <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                    <li><button className="dropdown-item" onClick={(e) => deleteStudentLedger(item.Transaction_ID)}><i className="ri-close-circle-fill"></i> Delete</button></li>
                                </ul> */}
                <ul
                  className="inline-action"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <button
                      type="button"
                      className={`green ${UserID != 3 ? "" : ""}`}
                      onClick={(e) => handleShow(item)}
                    >
                      <i className="ri-edit-line"></i>
                    </button>
                  </li>
                  <li>
                    <Link
                      target="_blank"
                      to={`/Setup/PrintBioDataForms/${item.Student_ID}`}
                      className="blue"
                    >
                      <i className="ri-printer-line"></i>
                    </Link>
                  </li>
                  <li>
                    <button
                      onClick={(e) => deleteStudentLedger(item.Transaction_ID)}
                      className={`red ${UserID != 3 ? "" : ""}`}
                    >
                      <i className="ri-delete-bin-line"></i>
                    </button>
                  </li>
                </ul>
              </td>
            </tr>
          </>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="8">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };
  const deleteStudentLedger = (ID) => {
    const confirm = window.confirm("Are you sure you want to proceed ?");
    if (confirm) {
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        ID: ID,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "Student/DeleteStudentLedger",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });

            setIsUpdated(true);
          }
          // setIsUpdated(false);
        })
        .catch(function (error) {
          //console.log(error);
        });
    }
  };
  const handleUpdateLedger = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoadingUpdateBtn(true);
      const data = {
        UserID: UserID,
        AccessKey: AccessKey,

        ID: selectedlistOfLedger.Transaction_ID,
        Transaction_Date: Datee,
        ReceiptNo: event.target.Receipt_No.value,
        Fee_Code: event.target.Fee_Code.value,
        Description: event.target.Description.value,
        Debit: event.target.Debit.value,
        Credit: event.target.Credit.value,
      };

      //console.log(data);

      var api_config = {
        method: "post",
        url: config.base_url + "Student/UpdateStudentLedger",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setIsUpdated(true);
          } else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }

          // setIsSubjectAdded(true);
          setLoadingUpdateBtn(false);
          handleClose();
        })
        .catch(function (error) {
          //setLoading(false);
          toast.error("Network Error..", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }

    setValidatedLedgerUpdate(true);
  };
  const handleAddStudentLedger = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoadingAddDuesBtn(true);

      const data = {
        UserID: UserID,
        AccessKey: AccessKey,
        Student_ID: params.id,
        Transaction_Date: Datee,
        ReceiptNo: receiptNo,
        Fee_Code: event.target.Fee_code.value,

        Debit: debit,
        Credit: credit,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "Student/AddStudentLedger",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });

            handleCloseForAttchDues();
            setIsUpdated(true);
          } else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          setLoadingAddDuesBtn(false);
          setCredit(0);
          setDebit(0);
          setReceiptNo(0);
          // setIsSubjectAdded(true);
        })
        .catch(function (error) {
          //setLoading(false);
          toast.error("Network Error..", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
      setValidatedLedgerAdd(true);
    }
  };

  // const handleCurrentMonthData = () => {
  //   const currentDate = moment();
  //   const year = currentDate.format("YYYY");
  //   const month = currentDate.format("MM");
  //   setCurrentYear(year);
  //   setCurrentMonth(month);

  //   setIsUpdated(false);
  //   setLoading(true);

  //   const data = {
  //     AccessKey: AccessKey,
  //     UserID: UserID,
  //     MemberType: MemberType,
  //     pageNo: pageNo,
  //     pageSize: pageSize,
  //     Student_ID: StudentID,
  //     StartDate: StartDate,
  //     EndDate: EndDate,
  //   };
  //   //console.log(currentMonth, currentYear);
  //   var api_config = {
  //     method: "post",
  //     url: config.base_url + "Student/GetStudentLedger",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: data,
  //   };

  //   axios(api_config)
  //     .then(function (response) {
  //       //console.log(response.data);
  //       if (response.data.status_code === 1) {

  //         const filtered = response.data.ListofRecords.filter((val) => {
  //           const dateObj = new Date(val.Transaction_Date);
  //           const month = dateObj.getMonth() + 1;
  //           const formattedMonth = month.toString().padStart(2, "0");

  //           const year = dateObj.getFullYear();
  //           //console.log(currentYear);
  //           return formattedMonth == currentMonth && year == currentYear;
  //         });

  //         setLedger(filtered);
  //         setLoading(false);
  //         setTotalRecords(response.data.totalRecords);
  //       } else {
  //         setLedger(null);
  //         setTotalRecords(0);
  //         toast.error(response.data.status_message, {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //         setLoading(false);
  //       }
  //     })
  //     .catch(function (error) {
  //       //console.log(error);
  //     });
  // };

  const handleCurrentMonthData = () => {
    const currentDate = moment();
    const year = currentDate.format("YYYY");
    const month = currentDate.format("MM");
    setCurrentYear(year);
    setCurrentMonth(month);

    setIsUpdated(false);
    //setLoading(true);

    const filtered = listOfLedger.filter((val) => {
      const dateObj = new Date(val.Transaction_Date);
      const month = dateObj.getMonth() + 1;
      const formattedMonth = month.toString().padStart(2, "0");

      const year = dateObj.getFullYear();
      return formattedMonth == currentMonth && year == currentYear;
    });

    setLedger(filtered);
    //setLoading(false);
  };

  useEffect(() => {
    if (!loadings) {
      handleCurrentMonthData();
    }

  }, []);


  return (
    <>
      <RightsCheck />
      <StudentMenu />
      <ToastContainer />
      <Modal className="theme-modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Student Ledger </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validatedLedgerUpdate}
            onSubmit={handleUpdateLedger}
          >
            <div style={{ padding: "20px" }}>
              <div className="form-group mb-3 d-flex flex-column">
                <label>Transaction Date</label>

                <DatePicker
                  selected={moment(Datee).toDate()}
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  required
                  name="Transaction_Date"
                  onChange={(date) => setDatee(date)}
                />

                {/* <input
                  type="date"
                  defaultValue={moment(selectedlistOfLedger.Transaction_Date).format("YYYY-MM-DD")}
                  name="Transaction_Date"
                  className="form-control"
                  required
                /> */}
              </div>
              <div className="form-group mb-3">
                <label>Receipt No</label>
                <input
                  type="number"
                  defaultValue={selectedlistOfLedger.Receipt_No}
                  name="Receipt_No"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label>Fee Code</label>
                <input
                  type="number"
                  defaultValue={selectedlistOfLedger.Fee_Code}
                  name="Fee_Code"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label>Description</label>
                <input
                  type="text"
                  defaultValue={selectedlistOfLedger.Description}
                  name="Description"
                  className="form-control"
                  required
                />
              </div>

              <div className="form-group mb-3">
                <label>Debit</label>
                <input
                  type="number"
                  defaultValue={selectedlistOfLedger.Debit}
                  name="Debit"
                  className="form-control"
                  required
                />
              </div>

              <div className="form-group mb-3">
                <label>Credit</label>
                <input
                  type="number"
                  defaultValue={selectedlistOfLedger.Credit}
                  name="Credit"
                  className="form-control"
                  required
                />
              </div>

              {loadingUpdateBtn ? (
                <div className="text-center">
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              ) : (
                <button type="submit" className="button button-primary w-100">
                  UPDATE
                </button>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        className="theme-modal"
        show={showForAttachDues}
        onHide={handleCloseForAttchDues}
      >
        <Modal.Header closeButton>
          <Modal.Title>Attach Dues </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validatedLedgerAdd}
            onSubmit={handleAddStudentLedger}
          >
            <div style={{ padding: "20px" }}>
              <div className="form-group mb-3">
                <label>Fee Code</label>
                <Select
                  className={`fee_form ${selectedValue.length === 0 ? "red-border" : ""
                    }`}
                  styles={customStyles}
                  onChange={handleDropdownChange}
                  options={dropdownOptions}
                  name="Fee_code"
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label>RECEIPT NO</label>
                <input
                  type="text"
                  value={receiptNo}
                  name="RECEIPT_NO"
                  className="form-control"
                  required
                  onChange={(e) => setReceiptNo(e.target.value)}
                />
              </div>

              <div className="form-group mb-3 d-flex flex-column">
                <label>Dated</label>

                <DatePicker
                  selected={moment(Datee).toDate()}
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  required
                  name="Dated"
                  onChange={(date) => setDatee(date)}
                />
              </div>

              <div className="form-group mb-3">
                <label>Debit</label>
                <input
                  className="form-control"
                  type="number"
                  value={debit}
                  name="Debit"
                  required
                  onChange={(e) => setDebit(e.target.value)}
                  min={0}
                />
              </div>
              <div className="form-group mb-3">
                <label>Credit</label>
                <input
                  className="form-control"
                  type="number"
                  value={credit}
                  name="Credit"
                  required
                  onChange={(e) => setCredit(e.target.value)}
                  min={0}
                />
              </div>

              {loadingAddDuesBtn ? (
                <div className="text-center">
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              ) : (
                <button type="submit" className="button button-primary w-100">
                  Add Dues
                </button>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/Dashboard">Dashboard</NavLink>
                    </li>
                    <li className="breadcrumb-item">
                      <NavLink to="/student/studentdashboard">Students</NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Student Ledger
                    </li>
                  </ol>
                </nav>
                <div className="page-title">Student Ledger</div>
              </div>
              <div className="col-md-6">
                <div className="page-right-actions">
                  {/* <NavLink
                    to="/students/AdvanceSearch"
                    className="button button-white"
                  >
                    Back
                  </NavLink> */}
                  <button
                    onClick={() => {
                      setShowLedgers((prevIsActive) => !prevIsActive);
                    }}
                    className="button button-white button-icon"
                  >
                    {showLedgers ? "Hide Detail" : "Show Detail"}
                    <i className="ri-filter-3-line"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <StudentCard StudentID={StudentID} /> */}
        <div
          className={`collapse ${showLedgers ? "show" : ""} `}
          id="collapseFilteres"
        >
          <ShowStudent StudentID={StudentID} />
        </div>
        <div className="page-content">
          <div className="container">
            <div className="card">
              <div className="card-body">
                <div className="col-md-3">
                  <label> <i className="ri-calendar-2-line" style={{ color: "blue" }}></i> Get Current Month Data</label>
                  <input
                    type="checkbox"
                    checked={checkedCurrentData}
                    className="button button-primary mb-1 ms-2"
                    onChange={(e) => setCheckedCurrentData(e.target.checked)}
                    onClick={() => {
                      if (!checkedCurrentData) {
                        handleCurrentMonthData();
                      } else {
                        setIsUpdated(true);
                      }
                    }}
                  />
                </div>
                <table className="table table-theme">
                  <thead>
                    <tr>
                      <th colspan="10" className="text-end">
                        {totalRecords} Records Found!
                      </th>
                    </tr>
                    <tr>
                      <th>Transaction ID</th>
                      <th>DATED</th>
                      <th>FEE CODE</th>
                      <th>RECEIPT NO.</th>
                      <th>DESCRIPTION</th>
                      <th>DEBIT</th>
                      <th>CREDIT</th>
                      <th>Balance</th>
                      <th>Balance Type</th>
                      <th
                        className={`text-center ${UserID != 3 ? "" : ""}`}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td className="text-center" colspan="10">
                          <Spinner animation="border" role="status"></Spinner>
                        </td>
                      </tr>
                    ) : (
                      <>{renderLedger()}
                        {listOfLedger != null && listOfLedger.length > 0 &&
                          <tr>
                            <th colSpan={5} className="text-end">Total :</th>
                            <th>{totalDebit}</th>
                            <th>{totalCredit}</th>
                            <td colSpan="3"></td>
                          </tr>
                          /* <tr>
                            <th colSpan={6} className="text-end">Total Payable :</th>
                            <th>{totalDebit - totalCredit}</th>
                            <td colSpan="4"></td>
                          </tr> */
                        }
                      </>
                    )}

                    <tr>
                      <td colSpan={10}>
                        <div className="row">
                          <div className="col-md-2 offset-md-10">
                            <button
                              type="button"
                              className="button button-primary w-100"
                              onClick={(e) => handleShowForAttchDues(e)}
                            >
                              Attach Dues
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {totalRecords > 1000 && (
                  <div className="pagination-wrap">
                    <div className="row align-items-center">
                      <div className="col-md-2 form_default">
                        <div className="d-flex align-items-center">
                          <label className="me-2" style={{ fontSize: "15px" }}>
                            Page Size
                          </label>
                          <div className="form-group">
                            <select
                              className="form-select"
                              style={{ width: "100px" }}
                              onChange={(e) => {
                                setPageNo(1);
                                setPageSize(parseInt(e.target.value));
                              }}
                            >
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              <option value="500">500</option>
                              <option value="1000" selected>
                                1000
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <Pagination
                        pageNo={pageNo}
                        pageSize={pageSize}
                        totalRecords={totalRecords}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StudentLedger;
