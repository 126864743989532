import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";

function EmployeeTypeDropDown(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [employeetype, setEmployeeTypeID] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const apiData = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      SchoolID: 1,
      PageNo: 1,
      PageSize: 100,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "/Payroll/GetAllEmployeeTypes",
      headers: {
        "Content-Type": "application/json",
      },
      data: apiData,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);

        if(response.data.status_code == 1)
        {
            setEmployeeTypeID(response.data.ListofRecords);
            setLoading(false);
        }
        else{
            setEmployeeTypeID([]);
            setLoading(true);
        }

       
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  if (loading) {
    return (
      <option selected disabled>
        Loading...
      </option>
    );
  } else {
    
      return employeetype.map((item, index) => {
        return (
          <option
            value={item.ID}
            key={index}
            selected={item.ID === props.selected}
          >
            {item.Name}
          </option>
        );
      });
  }
}

export default EmployeeTypeDropDown;
