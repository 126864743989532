import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Page from "../General/Page";
import PrintHeader from "../General/PrintHeader";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { useLocation } from 'react-router-dom';
import moment from "moment";

function PrintRetiredEmployee(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [RetireMentStatus, setRetireMentStatus] = useState("true");
  // for filters
  const [Code, setCode] = useState("");
  const [Keywords, setKeywords] = useState("");

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10000);
  const [totalRecords, setTotalRecords] = useState(0);

  let params = useParams();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const EmployeeTypeID = searchParams.get('EmployeeTypeId');
  const DesignationID = searchParams.get('DesignationID');
  const Gender = searchParams.get('Gender');
  const OrderBy = searchParams.get('OrderBy');
  const GPS_Scale = searchParams.get('GpsScale');





  useEffect(() => {

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: pageSize,
      SchoolID: 1,
      IS_Resigned: RetireMentStatus,
      Code: Code,
      Gender: Gender,
      DesignationID: DesignationID,
      EmployeeTypeID: EmployeeTypeID,
      GPS_Scale: GPS_Scale,
      Keywords: Keywords,
      OrderBy: OrderBy,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Payroll/SearchEmployees",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    //console.log(data);

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  const renderLedger = () => {
    if (listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{count++}</td>
            <td>{item.Code}</td>
            <td>{item.Name}</td>
            <td>{item.Father_Name}</td>
            <td>{moment(item.Date_Of_Birth).format("DD/MM/YYYY")}</td>
            <td>{moment(item.Date_Of_Joining).format("DD/MM/YYYY")}</td>
            <td>{moment(item.Resignation_Date).format("DD/MM/YYYY")}</td>
            <td>{item.Mobile_No}</td>
            <td>{item.Selected_Designation.Name}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="11">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <Page orientation="portrait">
        <table className="table table-sm table-bordered table-print">
          <thead>
            <tr>
              <th colspan={20}>
                <PrintHeader title={`Retired Employee `} />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Sr. No.</th>
              <th>Code</th>
              <th>Employee Name</th>
              <th>Father Name</th>
              <th>DOB</th>
              <th>DOJ</th>
              <th>DOR</th>
              <th>Mobile No.</th>
              <th>Designation</th>
            </tr>
            {loading ? (
              <tr>
                <td className="text-center" colspan="11">
                  <Spinner animation="border" role="status"></Spinner>
                </td>
              </tr>
            ) : (
              <>{renderLedger()}</>
            )}
          </tbody>
        </table>
      </Page>
    </>
  );
}

export default PrintRetiredEmployee;
