import React from 'react';
import { NavLink } from 'react-router-dom';
import { scrollToTop } from '../Shared/ScrollOnTop';
function SupportControlMenu(props) {
    return (
        <>
            <nav className={`navbar navbar-expand-lg navbar-module MenuSticky`}>
                <div className="container-fluid">
                    <div className="collapse navbar-collapse">
                        <ul className="navbar-nav m-auto">
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/admin-support/new-ticket">New Tickets</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/admin-support/open-ticket">Open Tickets</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/admin-support/pending-ticket">Pending Tickets</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/admin-support/close-ticket">Closed Tickets</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default SupportControlMenu;