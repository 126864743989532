import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import PrintHeader from "../General/PrintHeader";
import Page from "../General/Page";

const PrintStudentList = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );

  const [showFilters, setShowFilters] = useState(true);

  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [validated, setValidated] = useState(false);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10000);
  const [totalRecords, setTotalRecords] = useState(0);

  const [isUpdated, setIsUpdated] = useState(false);


  const urlParams = new URLSearchParams(window.location.search);

  const lang = urlParams.get("lang");


  const SessionId = urlParams.get("SessionID");
  const WingId = urlParams.get("WingID");
  const ClassId = urlParams.get("ClassId");
  const SectionID = urlParams.get("Section_Id");
  const StudentID = urlParams.get("Student_ID");
  const Keywords = urlParams.get("Keywords");
  const Form_No = urlParams.get("BayFormNo");
  const GroupId = urlParams.get("Group_Id");
  const OrderBy = urlParams.get("OrderBy");




  useEffect(() => {
    document.title = "Print New Students List";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: pageSize,
      SessionID: SessionId,
      Wing_Id: WingId,
      Class_Id: ClassId,
      Section_Id: SectionID,
      Form_No: Form_No,
      Student_ID: StudentID,
      Keywords: Keywords,
      Group_Id: GroupId,
      OrderBy: OrderBy,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Student/SearchNewStudents",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          setLedger(response.data.ListofRecords);
          setLoading(false);
          setTotalRecords(response.data.totalRecords);
        } else {
          setLedger(null);
          setTotalRecords(0);
          // toast.error(response.data.status_message, {
          //     position: toast.POSITION.TOP_RIGHT
          // });
          setLoading(false);
        }
      })
      .catch(function (error) {});
  }, [
   
  ]);

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.Student_ID}</td>
            <td>{item.Student_Name}</td>
            <td>{item.Father_Name}</td>
            <td>{moment(item.Date_Of_Birth).format("DD/MM/YYYY")}</td>
            <td>{moment(item.Date_Of_Joining).format("DD/MM/YYYY")}</td>
            <td>{item.SelectedClass.SelectedWing.Wing_Name}</td>
            <td>{item.SelectedClass.Class_Name}</td>
            <td>{item.SelectedSection.Section_Name}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="12">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <Page orientation="portrait" size="a4">
        <PrintHeader title={`List of Students`} />
        <table className="table table-sm table-bordered table-print">
          <thead>
          <tr>
                      <th>Student ID</th>
                      <th>Name</th>
                      <th>Father Name</th>
                      <th>DOB</th>
                      <th>DOJ</th>
                      <th>Wing</th>
                      <th>Class</th>
                      <th>Section</th>
                    </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colspan="8">
                  <Spinner animation="border" role="status"></Spinner>
                </td>
              </tr>
            ) : (
              <>{renderLedger()}</>
            )}
          </tbody>
        </table>
      </Page>
    </>
  );
};

export default PrintStudentList;
