import React, { useState, useEffect } from "react";
import Signature from "../../assets/images/Signature.png";
import moment from "moment";
import Logo from "../../assets/images/Plus_Minua_logo.png";

const InvoicePrint = () => {

  useEffect(() => {
    document.title = "DPS Kasur Invoice";
  }, []);

  return (
    <>

      <div className="page a4 potrait invoice-page">
        <div className="content a4">
          <div className="invoice-header">
            <div className="row align-items-start">
              <div className="col-6">
                <img src={Logo} />
              </div>
              <div className="col-6">
                <ul>
                  <li>website Development</li>
                  <li>Software Development</li>
                  <li>Domain Hosting</li>
                </ul>
              </div>
            </div>
            <div className="row align-items-center my-3">
              <div className="col-6 ps-5">
                <h1>Invoice</h1>
              </div>
              <div className="col-6">
                <div className="date">
                  <span>Date</span>
                  <p>{moment().format("1 MMMM , YYYY")}</p>
                </div>
              </div>
            </div>

          </div>
          <div className="invoice-content">
            <p className="">
              As per service agreement with <strong>M/S DPS Kasur</strong> , I as Service Provider I have launched new software i.e. School Management System with dashboard support which is completed and functional in January 2024. However now, as per agreement monthly service charges @ Rs. 15,000/- are required to be released as below:
            </p>

            <table className="table table-sm table-bordered table-theme">
              <thead>
                <tr>
                  <th>Sr. #</th>
                  <th>Description</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1.</td>
                  <td>Service Charges for the Month of {moment().subtract(1, 'months').format("MMMM YYYY")}</td>
                  <td>15,000/-</td>
                </tr>
                <tr>
                  <th></th>
                  <th>Total Amount.</th>
                  <th>Rs. 15,000/-</th>
                </tr>

              </tbody>
            </table>
            <p>
              Please make arrangement for the release of this invoice in favour of "Muhammad Ali Imran" proprietor of M/S Plus Minus Systems Kasur and oblige.
            </p>
            <div className="text-end">
              <img style={{ width: "60px",marginRight:'50px' }} src={Signature} alt="" />
              <h6 className="text-end mt-1">
                Muhammad Ali Imran
              </h6>
            </div>
          </div>
          <div className="invoice-footer mt-2">
            <ul>
              <li>+92 323 6590065</li>
              <li>info@plusminussystems.com</li>
              <li>www.plusminussystems.com</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoicePrint