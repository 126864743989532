import React from 'react';
import { Link } from 'react-router-dom';
import Struck_Students from '../../assets/images/dashboard_icon/Struck_Students.svg';
import attendece_report from '../../assets/images/dashboard_icon/attendece_report.svg';
import head_wise_report from '../../assets/images/dashboard_icon/head_wise_report.svg';
import student_strength from '../../assets/images/dashboard_icon/student_strength.svg';
import new_student from '../../assets/images/dashboard_icon/new_student.svg';
import no_fee from '../../assets/images/dashboard_icon/no_fee.svg';
import fee_report from '../../assets/images/dashboard_icon/fee_report.svg';
import list from '../../assets/images/dashboard_icon/list.svg';
import status from '../../assets/images/dashboard_icon/status.svg';
import ReportsMenu from './ReportsMenu';
import { ToastContainer } from 'react-toastify';
import { scrollToTop } from '../Shared/ScrollOnTop';

const ReportDashboard = () => {

    document.title='Reports'

    return (
        <>
        <ToastContainer/>
        <ReportsMenu/>
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Reports</li>
                                </ol>
                            </nav>
                            <div className="page-title">Reports</div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="page-content">
                <div className="container">
                    <div className="row align-items-center g-3">
                        <div className="col-md-4 col-sm-6">
                            <Link to="/Reports/StudentStrengthReport" className="card m-card">
                                <div className="card-body text-center">
                                    <img src={Struck_Students} className="pb-3" alt=""/>
                                        <p className="m-2 mt-0">Strucked of Students</p>
                                        <h4 className="m-2">435</h4>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <Link to="/Reports/NewlyAdmittedStudents" className="card a-card">
                                <div className="card-body text-center">
                                    <img src={new_student} className="pb-3" alt=""/>
                                        <p className="m-2 mt-0">Newly Admitted Students</p>
                                        <h4 className="m-2">34</h4>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <Link to="/Reports/NewAdmittedUnPaid" className="card m-card">
                                <div className="card-body text-center">
                                    <img src={no_fee} className="pb-3" alt=""/>
                                        <p className="m-2 mt-0">New Admitted Unpaid</p>
                                        <h4 className="m-2">36</h4>
                                </div>
                            </Link>
                        </div>
                   
                        <div className="col-md-3 col-sm-6">
                            <div className="card m-card">
                                <div className="card-body text-center">
                                    <img src={student_strength} className="pb-3" alt=""/>
                                        <p className="m-2 mt-0">Student Strength Report</p>
                                        <Link to="/Reports/StudentStrengthReport" className="text-icon sky"> View Report <i className="ri-arrow-right-line"></i> </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="card m-card">
                                <div className="card-body text-center">
                                    <img src={status} className="pb-3" alt=""/>
                                        <p className="m-2 mt-0">Status Wise Change</p>
                                        <Link to="/Reports/StatusWiseChange" className="text-icon pink"> View Report <i className="ri-arrow-right-line"></i> </Link>
                                </div>
                            </div>
                        </div>
                       
                        <div className="col-md-3 col-sm-6">
                            <div className="card m-card">
                                <div className="card-body text-center">
                                    <img src={head_wise_report} className="pb-3" alt=""/>
                                        <p className="m-2 mt-0">Account Head Wise Report</p>
                                        <Link to="/Reports/AccountHeadwiseReport" className="text-icon pink"> View Report <i className="ri-arrow-right-line"></i> </Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 col-sm-6">
                            <div className="card m-card">
                                <div className="card-body text-center">
                                    <img src={fee_report} className="pb-3" alt=""/>
                                        <p className="m-2 mt-0">Student Fine Report</p>
                                        <Link to="/Reports/StudentFineReport" className="text-icon sky"> View Report <i className="ri-arrow-right-line"></i> </Link>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="card m-card">
                                <div className="card-body text-center">
                                    <img src={attendece_report} className="pb-3" alt=""/>
                                        <p className="m-2 mt-0">Attendances Wise Report</p>
                                        <Link to="/Reports/AttendenceSummary" className="text-icon pink"> View Report <i className="ri-arrow-right-line"></i> </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="card m-card">
                                <div className="card-body text-center">
                                    <img src={fee_report} className="pb-3" alt=""/>
                                        <p className="m-2 mt-0">Fee Reporting</p>
                                        <Link to="/Reports/FeeReporting" className="text-icon sky"> View Report <i className="ri-arrow-right-line"></i> </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="card m-card">
                                <div className="card-body text-center">
                                    <img src={list} className="pb-3" alt=""/>
                                        <p className="m-2 mt-0">Defaulters List</p>
                                        <Link to="/Reports/DefaultersList" className="text-icon pink"> View Report <i className="ri-arrow-right-line"></i> </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReportDashboard;
