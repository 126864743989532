import React, { useEffect, useState } from 'react';
import config from '../../Config';
import { calculateColumnTotal } from '../General/Utility';
import moment from 'moment';
import { scrollToTop } from '../Shared/ScrollOnTop';
import RightsCheck from '../General/RightsCheck';

function StudentInfo(props) {

    const currentDate = new Date();
    const month = currentDate.toLocaleString('default', { month: 'long' });
    const [CurrentYear, setCurrentYear] = useState('');
    const day = currentDate.getDate();
    const [imageError, setImageError] = useState(false);

    document.title = 'Student Info'

    //const [fee, setFee] = useState([]);

    const RenderHTMLString = (htmlString) => {
        return (
            <div dangerouslySetInnerHTML={{ __html: htmlString }} />
        );
    }

    useEffect(() => {
        //setFee(props.fee);

        const date = new Date();
        const year = date.getFullYear();
        setCurrentYear(year.toString());

    }, []);


    const arrayData = props.fee;


    const renderFeeDetail = () => {
        if (arrayData && arrayData.length > 0) {
            return (
                arrayData.map((item, index) => {
                    if (item.Debit != 0) {
                        return (
                            <tr key={index}>
                                <td style={{ width: "50px", border: "1px solid #000000" }} valign="top" align="left">
                                    {item.Fee_Code}
                                </td>
                                <td style={{ border: "1px solid #000000" }}>
                                    {item.Description}
                                </td>
                                <td style={{ width: "45px", border: "1px solid #000000" }} valign="top" align="right">
                                    {item.Debit}
                                </td>
                            </tr>
                        )
                    }
                })
            )
        }

    }

    return (
        <>
            <RightsCheck />
            <div style={{ padding: '0px 0px 8px 0px', minHeight: '100px' }}>
                <table style={{ width: '100%' }} cellSpacing={0} cellPadding={0}>
                    <tbody>
                        <tr>
                            <td valign="top" align="center">
                                <div style={{ fontSize: '13px' }}>
                                    {props.school}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: '12px', fontWeight: 'bold', padding: '8px 0px 8px 0px' }} align="center">{props.copy}</td>
                        </tr>
                        <tr>
                            <td align="center">
                                <div style={{ fontSize: '12px', paddingBottom: '8px' }}>
                                    {RenderHTMLString(props.bank)}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style={{ padding: '0px 0px 7px 0px', position: 'relative' }}>
                {
                    props.showphoto === true &&
                    // <div style={{ width: '100px', height: '95px', right: 0, border: '1px solid', backgroundColor: '#ffffff', zIndex: 1, position: 'absolute' }}>
                    //     <img src={`${config.StudentImagePath}${props.studentcode}.jpg`} 
                    //       onError={() => {
                    //         setImageError(true);

                    //       }}
                    //       onerror="this.stye.display:none;"
                    //     style={{ height: '100%', width: '100%' }} alt="" />
                    // </div>

                    <div style={{ width: '100px', height: '95px', right: 0, border: '1px solid', backgroundColor: '#ffffff', zIndex: 1, position: 'absolute' }}>
                        <img
                            src={`${config.StudentImagePath}${props.studentcode}.jpg`}
                            onError={(e) => {
                                e.target.parentElement.style.display = 'none';
                            }}
                            style={{ height: '100%', width: '100%' }}
                            alt=""
                        />
                    </div>

                }

                <table style={{ width: '100%', borderCollapse: 'collapse' }} cellSpacing={2} cellPadding={2}>
                    <tbody>
                        <tr>
                            <td style={{ border: '1px solid #000000', width: '80px' }}>Student Code:</td>
                            <td style={{ border: '1px solid #000000', fontWeight: 'bold' }}>{props.studentcode}</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #000000', width: '80px' }}>Class Details:</td>
                            <td style={{ border: '1px solid #000000' }}>
                                {props.wing} - {props.classname} - {props.section}
                            </td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #000000', width: '80px' }}>Month:</td>
                            <td style={{ border: '1px solid #000000' }}>{moment().format("MMM-YYYY")} </td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #000000', width: '80px', whiteSpace: "nowrap" }}>Student Name:</td>
                            <td style={{ border: '1px solid #000000' }}>
                                {props.name}
                            </td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #000000', width: '80px' }}>Father Name:</td>
                            <td style={{ border: '1px solid #000000' }}>{props.father}</td>
                        </tr>
                    </tbody>
                </table>
                {/* <div style={{marginTop: 20}}>
                    {
                        props.copy=="Student Copy" ? 
                        <table>
                            <tr>
                            <td style={{ border: '1px solid #000000' }}>
                                <p style={{margin: 0, direction: "rtl"}}>
                                    <strong className='font-ur'>
                                    والدین سے گزارش ہے کہ ماہ اگست کا فیس چالان اگلے مہینے کی پانچ تاریخ سے آفس سے وصول کر لیں یا ویب سائٹ سے پرنٹ نکال لیں اور شیڈول کے مطابق فیس بروقت جمع کروائیں۔ مسلسل دو ماہ فیس ادا نہ کرنے کی صورت میں 500روپے ری ایڈمیشن ادا کرنا پڑے گا۔
                         </strong>
                         </p>
                        </td>
                        </tr>
                        </table>
                        :""
                    }
                
                </div> */}
                {props.fee.map((val, index) =>
                    index == 0 &&
                        val.Fee_Code == "008" ? (
                        <h6 className='mt-2 text-center mb-0' style={{ fontWeight: "bold", fontSize: "15px" }}>NAME STRUCK OFF DUE TO NON PAYMENT</h6>
                    ) : (
                        ""
                    )
                )}

                {props.status_type == 2 ? <h6 className='mt-2'>NAME STRUCK OFF DUE TO NON PAYMENT</h6> : ""}
            </div>
            <div style={{ padding: '7px 0px 0px 0px' }}>
                <div>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }} cellSpacing={2} cellPadding={2}>
                        <tbody>
                            <tr>
                                <td style={{ width: '50px', border: '1px solid #000000', whiteSpace: "nowrap" }} valign="top" align="left">Fee Code</td>
                                <td style={{ border: '1px solid #000000' }}>Detail</td>
                                <td style={{ width: '45px', border: '1px solid #000000' }} valign="top" align="right">Amount</td>
                            </tr>
                            {renderFeeDetail()}
                        </tbody>
                    </table>
                </div>
            </div>
            <div>
                <table style={{ width: '100%', borderCollapse: 'collapse' }} cellSpacing={2} cellPadding={2}>
                    <tbody>
                        <tr>
                            <td style={{ border: '1px solid #000000' }}>
                                <span>Due Date {props.DueDate != null && props.DueDate ? props.DueDate : `25 ${moment().format("MMM YYYY")}`}</span>
                            </td>
                            <td style={{ width: '45px', border: '1px solid #000000' }} valign="top" align="right">
                                <span style={{ fontWeight: 700 }}>{calculateColumnTotal(arrayData, 'Debit')}</span>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </>
    );
}

export default StudentInfo;