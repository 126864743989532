import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import SecurityMenu from "./SecurityMenu";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import UserGroupDropDown from "../Shared/UserGroupDropDown";
import { scrollToTop } from "../Shared/ScrollOnTop";

function ChangePassword(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password1, setPassword1] = useState(null);
  const [password2, setPassword2] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };
  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  let navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (password1 != password2) {
      toast.error("Both Password doesn't match ", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoading(true);
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        OldPassword: event.target.txtOldPassword.value,
        NewPassword: event.target.txtNewPassword.value,
      };

      //console.log(data);

      var api_config = {
        method: "post",
        url: config.base_url + "Members/ChangePassword",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }

          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          toast.error("Network Error..", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }

    setValidated(true);
  };

  return (
    <>
      <SecurityMenu />
      <ToastContainer />
      <div className="page-head">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink to="/Home/Index">Dashboard</NavLink>
                  </li>
                  <li className="breadcrumb-item">
                    <NavLink to="/Security/SecurityDashboard">Security</NavLink>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Change Password
                  </li>
                </ol>
              </nav>
              <div className="page-title">Change Password</div>
            </div>
            <div className="col-md-6">
              <div className="page-right-actions">
                <NavLink
                  to="/Security/GroupManagement"
                  className="button button-white button-icon"
                >
                  Group Management
                  <i className="ri-file-list-2-line"></i>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container">
          <div className="card">
            <div className="card-body p-md-4 change-password">
              <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
              >
                <div className="row align-items-center">
                  <div className="col-md-3">
                    <div className="form-group input-with-icon">
                      <label htmlFor="">Old Password:</label>
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        required
                        name="txtOldPassword"
                        maxLength={13}
                      />

                      <span
                        className={`${showPassword ? "ri-eye-off-line" : "ri-eye-line"
                          }`}
                        onClick={togglePasswordVisibility}
                      ></span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group input-with-icon">
                      <label htmlFor="" className="">
                        New Password
                      </label>
                      <input
                        type={showPassword1 ? "text" : "password"}
                        className="form-control"
                        required
                        name="txtNewPassword"
                        onChange={(e) => setPassword1(e.target.value)}
                        maxLength={13}

                      />
                      <span
                        className={`${showPassword1 ? "ri-eye-off-line" : "ri-eye-line"
                          }`}
                        onClick={togglePasswordVisibility1}
                      ></span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group input-with-icon">
                      <label htmlFor="" className="">
                        Retype Password
                      </label>
                      <input
                        type={showPassword2 ? "text" : "password"}
                        className="form-control"
                        required
                        name="txtRePassword"
                        onChange={(e) => setPassword2(e.target.value)}
                        maxLength={13}
                      />
                      <span
                        className={`${showPassword2 ? "ri-eye-off-line" : "ri-eye-line"
                          }`}
                        onClick={togglePasswordVisibility2}
                      ></span>
                    </div>
                  </div>
                  <div className="col-md-2 offset-md-1">
                    <div className="form-group">
                      {loading ? (
                        <Spinner animation="border" role="status"></Spinner>
                      ) : (
                        <button
                          type="submit"
                          value="Change"
                          className="button button-primary w-100 mt-4"
                        >
                          Change
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;
