import React from "react";
import { useLocation } from "react-router-dom";

const Page = ({ size, orientation, children }) => {
  const currentLocation = useLocation();

  const pageSize = size || "A4";
  const pageOrientation = orientation || "portrait";

  const PaddingRemove = currentLocation.pathname.startsWith('/Setup/PrintAttendenceSheet');

  const pageClassName = `page ${pageSize.toLowerCase()} ${pageOrientation.toLowerCase()}`;
  const contentClassName = `content ${PaddingRemove ? 'px-0' : ''} ${pageSize.toLowerCase()}`;

  return (
    <div className={pageClassName}>
      <div className={contentClassName}>{children}</div>
    </div>
  );
};

export default Page;