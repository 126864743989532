import React, { useState, useEffect } from 'react';
import config from "../../Config";
import axios from "axios";


function FeeTypesDropDown(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [FeeTypes, setFeeTypes] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        const apiData = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            PageNo: 1,
            PageSize: 100,
            SchoolID: 1
        };

        //console.log(apiData);

        var api_config = {
            method: 'post',
            url: config.base_url + 'FeeType/GetFeeTypes',
            headers: {
                'Content-Type': 'application/json'
            },
            data: apiData
        };

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setFeeTypes(response.data.ListofRecords);
                setLoading(false);
            })
            .catch(function (error) {
                //console.log(error);
            });

    }, []);


    if (loading) {
        return <option selected disabled>Loading...</option>
    }
    else {
        return (
            FeeTypes.map((item, index) => {
                return (
                    <option value={item.ID} key={index}>{item.Fee_Type_Name}</option>
                )
            })
        );
    }
}

export default FeeTypesDropDown;