import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../Config";
import Spinner from "react-bootstrap/Spinner";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import SupportControlMenu from "./SupportControlMenu";
import emoji_add from "../../assets/images/support_icon/emoji_add.svg";
import close from "../../assets/images/support_icon/close.svg";
import new_tab from "../../assets/images/support_icon/new_tab.svg";
import pendings from "../../assets/images/support_icon/pendings.svg";

function SupportDashboard() {


    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [Open, setOpen] = useState("");
    const [NewTickets, setNewTickets] = useState("");
    const [TicketsData, setTicketsData] = useState("");
    const [Close, setClose] = useState("");
    const [Pending, setPending] = useState("");
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    useEffect(() => {
        document.title = "School System Support";
        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,

        };

        var api_config = {
            method: 'post',
            url: config.base_url + '/support/GetSupportDashboard',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        //console.log("data", data)

        axios(api_config)
            .then(function (response) {
                //console.log(response.data)
                if (response.data.status_code == 1) {
                    setLoading(false);
                    setTicketsData(response.data);
                } else if (response.data.status_code === 0 || response.data.status_code === 2) {
                    setLoading(false);
                    toast.error(response.data.status_code, {
                        position: toast.POSITION.TOP_RIGHT,
                    });

                } else if (response.data.status_message === "Invalid Access Key.") {
                    navigate("/");
                } else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })

            .catch(function (error) {
                //console.log(error);
            });

    }, []);



    return (
        <>
            {loading ? (
                <div
                    className="d-flex justify-content-center"
                    style={{ marginTop: "20%" }}
                >
                    <Spinner animation="border" role="status"></Spinner>
                </div>
            ) : (
                <>
                    <ToastContainer />
                    <SupportControlMenu />
                    <div className="page-head">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                            <li className="breadcrumb-item"> <Link to="/admin-support/support-dashboard">Support</Link></li>
                                        </ol>
                                    </nav>
                                    <div className="page-title">Support</div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <ul className="dashboard-buttons">
                            <li>
                                <NavLink to="/admin-support/new-ticket">
                                    <img src={emoji_add} alt="" />
                                    <span>New Tickets ({TicketsData.totalNew})</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/admin-support/Open-ticket">
                                    <img src={new_tab} alt="" />
                                    <span>Open Tickets ({TicketsData.totalOpen})</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/admin-support/pending-ticket">
                                    <img src={pendings} alt="" />
                                    <span>Pending Tickets ({TicketsData.totalPending})</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/admin-support/close-ticket">
                                    <img src={close} alt="" />
                                    <span>Closed Tickets ({TicketsData.totalClosed})</span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </>
            )
            }
        </>
    );
}

export default SupportDashboard;
