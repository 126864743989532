import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import QRCode from "react-qr-code";
import StudentInfo from "../FeeSystem/StudentInfo";
import moment from "moment";
import PrintHeader from "../General/PrintHeader";
import Page from "../General/Page";
import { calculateColumnTotal, getMonthName } from "../General/Utility";
const PrintNewAdmittedUnPaid = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [EndDate, setEndDate] = useState("");
  const [StartDate, setStartDate] = useState("");
  const [Wing, setWing] = useState("");
  const [Status, setStatus] = useState("");
  const [Reappear, setReappear] = useState("false");

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10000);
  const [totalRecords, setTotalRecords] = useState(0);
  const [TotalAmount, setTotalAmount] = useState(0);

  const [Form_No, setForm_No] = useState(0);
  const [StudentID, setStudentID] = useState(0);
  const [Keywords, setKeywords] = useState("");
  const [OrderBy, setOrderBy] = useState(0);
  const [GroupId, setGroupId] = useState(0);

  useEffect(() => {
    document.title = "Print New Admitted UnPaid";

    setLoading(true);
    // Get the URL parameters
    const urlParams = new URLSearchParams(window.location.search);

    // Retrieve individual parameter values
    const WingId = urlParams.get("WingId");
    const ClassId = urlParams.get("ClassId");
    const SectionID = urlParams.get("SectionID");
    const OrderBy = urlParams.get("OrderBy");
    const SessionId = urlParams.get("SessionId");

    // setStartDate(StartDate);
    // setEndDate(EndDate);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: pageSize,
      SessionID: SessionId,
      Wing_Id: WingId,
      Class_Id: ClassId,
      Section_Id: SectionID,
      Form_No: Form_No,
      Student_ID: StudentID,
      Keywords: Keywords,
      Group_Id: GroupId,
      OrderBy: OrderBy,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "/Student/SearchNewStudents",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    //console.log(data);
    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
        setTotalAmount(response.data.TotalAmount);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  const renderLedger = () => {
    if (listOfLedger.length > 0) {
      let count = 1;
      let countbreak = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index} className={countbreak++ == 35 ? "page-break" : ""}>
            <td>{count++ + pageSize * (pageNo - 1)}</td>
            <td></td>
            <td>{item.Student_Name}</td>
            <td>{item.Father_Name}</td>
            <td>{item.SelectedClass.Class_Name}</td>
            <td>{item.SelectedSection?.Section_Name}</td>
            <td>{item.Contact_Phone}</td>
            <td>{item.Current_Balance}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="20">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <Page orientation="portrait" size="a4">
        <table className="table table-sm table-bordered table-print">
          <thead>
            <tr>
              <th colspan={20}>
                <PrintHeader
                  title={`List of Newly Admitted But Not Paid Students:`}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colspan="8">
                  <Spinner animation="border" role="status"></Spinner>
                </td>
              </tr>
            ) : (
              <>
                <tr>
                  <th className="text-end" colSpan={8}>
                    Total Amount:{" "}
                    {calculateColumnTotal(listOfLedger, "Current_Balance")}
                  </th>
                </tr>
                <tr>
                  <th>Sr.#</th>
                  <th>Reg. No</th>
                  <th style={{ width: "161px" }}>Student Name</th>

                  <th style={{ width: "168px" }}>Father Name</th>
                  <th>Class</th>
                  <th>Section</th>
                  <th>Contact</th>
                  <th>Amount</th>
                </tr>

                {renderLedger()}
              </>
            )}
          </tbody>
        </table>
      </Page>
    </>
  );
};

export default PrintNewAdmittedUnPaid;
