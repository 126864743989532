import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Page from "../General/Page";
import PrintHeader from "../General/PrintHeader";
import { scrollToTop } from "../Shared/ScrollOnTop";

function StruckoffList(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [listOfLedger, setLedger] = useState([]);

  let params = useParams();
  //console.log(params.id);
  const [SectionID, setSectionID] = useState(params.id);

  useEffect(() => {
    document.title = "Student Struck off List";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: 1,
      pageSize: 2000,
      Section_Id: SectionID,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Reports/GetStruckoffList",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;

      // let countbreak = 1;
      return listOfLedger.map((item, index) => {
        const isPageBreak = index > 0 && index % 46 === 0;
        return (
          <tr
            key={index}
            // className={isPageBreak ? 'page-break' : ''}
          >
            {/* <td>
                                {count++}
                            </td> */}
            <td>{item.Serial_No}</td>
            <td>{item.Registration_No}</td>
            <td>{item.Student_Name}</td>
            <td>{item.Father_Name}</td>
            <td>{item.Class_Name}</td>
            <td>{item.Section_Name}</td>
            <td></td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="7">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <Page orientation="portrait" size="a4">
                <PrintHeader title="List of Strucked Students:" />
        <table className="table table-sm table-bordered table-print">
          <thead>
          <tr>
                  <th style={{ width: "30px" }}>Sr.#</th>
                  <th style={{ width: "80px" }}>Code #</th>
                  <th style={{ width: "200px" }}>Student's Name</th>
                  <th style={{ width: "200px" }}>Father's Name</th>
                  <th style={{ width: "90px" }}>Class Name</th>
                  <th style={{ width: "90px" }}>Section Name</th>
                  <th style={{ width: "70px" }}>Remarks:</th>
                </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colspan="8">
                  <Spinner animation="border" role="status"></Spinner>
                </td>
              </tr>
            ) : (
              <>
               
                {renderLedger()}
              </>
            )}
          </tbody>
        </table>
      </Page>
    </>
  );
}

export default StruckoffList;
