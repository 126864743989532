import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import QRCode from "react-qr-code";
import StudentInfo from "../FeeSystem/StudentInfo";
import moment from "moment";
import PrintHeader from "../General/PrintHeader";
import Page from "../General/Page";
import { calculateColumnTotal, getMonthName } from "../General/Utility";

import { toast, ToastContainer } from 'react-toastify';

const PrintCollectionReportDetail = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
 
  const [totalDeposit, settotalDeposit] = useState("");
  const [Banch, setBanch] = useState(1);
  const [Startdate, setStartdate] = useState("");


  const [Reappear, setReappear] = useState("false");

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [TotalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    document.title="Print Collection Report Detail"

    setLoading(true);
    // Get the URL parameters
    const urlParams = new URLSearchParams(window.location.search);

    // Retrieve individual parameter values
    const banch = urlParams.get("banchNo");
    const startDate = urlParams.get("startDate");
  setBanch(banch)
  setStartdate(moment(startDate).format(config.date_format))

  

 
    const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        StartDate:startDate,
        BenchNo: banch,
        pageNo: 1,
        pageSize: 10000,
     };
  

    var api_config = {
      method: "post",
      url: config.base_url + "FeeSystem/GetCollectionReportDetail",

      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    //console.log(data);
    axios(api_config)
    .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {

            setLedger(response.data.ListofRecords);
            setTotalRecords(response.data.totalRecords);
            settotalDeposit(response.data.totalDeposit);
            setLoading(false)
            //console.log(listOfLedger);
        }

    })
    .catch(function (error) {

        toast.error('Network Error..', {
            position: toast.POSITION.TOP_RIGHT
        });
    });
  }, []);

  const renderLedger = () => {
  if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      let countbreak = 1;
      return listOfLedger.map((item, index) => {
        
        return (
           
            <tr key={index}>
            <td>{item.Serial_No}</td>
            <td>{item.Entry_Id}</td>
            <td>{moment(item.Dated).format(config.date_format)}</td>
            <td>{item.Registration_No}</td>
            <td>{item.Student_Name}</td>
            <td>{item.Receipt_No}</td>
            <td>{item.Amount_Deposited}</td>
        </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="20">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
            <ToastContainer />

      <Page orientation="portrait" size="a4">
        <table className="table table-sm table-bordered table-print">
          <thead>
            <tr>
              <th colSpan={20}>
                <PrintHeader
                 title={`Collection Report For Bench # ${Banch} and Date ${Startdate}`}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan="8">
                  <Spinner animation="border" role="status"></Spinner>
                </td>
              </tr>
            ) : (
              <>
                <tr>
                  <th>Sr.#</th>
                  <th>Entry ID</th>
                  <th style={{ width: "161px" }}>Dated</th>
                  <th style={{ width: "168px" }}>Registration No</th>
                  <th>Student Name</th>
                  <th>Receipt No.</th>
                  <th>Amount Deposited</th>

                </tr>
                {renderLedger()}
                <tr>
                        <td colSpan={4}></td>
                      <td></td>
                      <td colSpan={1} >
                        <b>Total  </b>
                      </td>
                      <td><b>{totalDeposit}</b></td>
                      
                    </tr>
              </>
            )}
          </tbody>
        </table>
      </Page>
    </>
  );
};

export default PrintCollectionReportDetail;
