import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
// import SetupMenu from './SetupMenu';
import moment from "moment";
import Pagination from "../General/Pagination";
import { scrollToTop } from '../Shared/ScrollOnTop';
import InvoiceMenu from "./InvoiceMenu";

function ManageInvoice(props) {
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [DefaultFYear, setDefaultFYear] = useState(localStorage.getItem("DefaultFYear"));
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    const [isUpdated, setIsUpdated] = useState(false);
    const [InvoiceLoading, setInvoiceLoading] = useState(false);

    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);



    useEffect(() => {

        document.title = "Manage Concession Rules";

        setIsUpdated(false);
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            pageNo: pageNo,
            pageSize: pageSize,
        };
        //console.log(data)

        var api_config = {
            method: 'post',
            url: config.base_url + 'PaymentInvoice/GetAllInvoices',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setLedger(response.data.ListofRecords);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);

            })
            .catch(function (error) {
                //console.log(error);
            });

    }, [pageNo, pageSize, isUpdated, InvoiceLoading]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const renderLedger = () => {

        if (listOfLedger != null && listOfLedger.length > 0) {
            return (
                listOfLedger.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{item.ID}</td>
                            <td>{item.InvoiceMonth}</td>
                            <td>{item.InvoiceYear}</td>
                            <td>{item.InvoiceStatus}</td>
                            <td>{item.PaymentStatus}</td>
                            <td>
                                <ul className="inline-action" aria-labelledby="dropdownMenuButton1">
                                    <li><NavLink to={'/invoice/manage-invoice-print/' + item.ID} target="_blank" className=" green"><i className="ri-printer-line"></i></NavLink></li>
                                </ul>
                            </td>
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colspan="6"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }
    const DeleteConcessionRule = (ID) => {

        const confirm = window.confirm("Are you sure to proceed this action ?")

        if (confirm) {
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                Concession_Rule_ID: ID
            };

            var api_config = {
                method: 'post',
                // url: config.base_url + '/Concession/DeleteConcessionRule',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };


            axios(api_config)
                .then(function (response) {
                    //console.log(response.data);
                    if (response.data.status_code == 1) {
                        setIsUpdated(true);
                    }
                    // setIsUpdated(false);

                })
                .catch(function (error) {
                    //console.log(error);
                });
        }
    }
    const handleGenerateInvoice = () => {
        setInvoiceLoading(true);
        const CurrentMonth = moment().format("M");
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            SelectedMonth: CurrentMonth,
            FYear: DefaultFYear
        };
        //console.log(data)
        var api_config = {
            method: 'post',
            url: config.base_url + 'PaymentInvoice/GenerateInvoice',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {

                    toast.success(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setInvoiceLoading(false);
                }
                else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }

            })
            .catch(function (error) {
                //console.log(error);
            });
    }

    return (
        <>
            <InvoiceMenu />
            <ToastContainer />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item"> <Link to="/invoice/manage-invoice">Invoice</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Manage Invoice</li>
                                </ol>
                            </nav>
                            <div className="page-title">Manage Invoice</div>
                        </div>
                        <div className="col-md-6">
                            <div className="page-right-actions">
                                <button onClick={handleGenerateInvoice} className="button button-white button-icon">
                                    Generate Invoice
                                    <i className="ri-add-line"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="card">
                        <div className="card-body">
                            <table className="table table-theme table-hover">
                                <thead>
                                    <tr>
                                        <th>Sr#</th>
                                        <th>Invoice Month</th>
                                        <th>Invoice Year</th>
                                        <th>Invoice Status</th>
                                        <th>Payment Status</th>
                                        <th className="text-center">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        loading ?
                                            <tr>
                                                <td className="text-center" colspan="6">
                                                    <Spinner animation="border" role="status"></Spinner>
                                                </td>
                                            </tr>
                                            :
                                            <>
                                                {renderLedger()}
                                            </>

                                    }
                                </tbody>
                            </table>
                            {
                                totalRecords > 10 &&
                                <div className="pagination-wrap">

                                    <div className="row align-items-center">
                                        <div className="col-md-2 form_default">
                                            <div className="d-flex align-items-center">
                                                <label className="me-2" style={{ fontSize: "15px" }}>Page Size</label>
                                                <div className="form-group">
                                                    <select className="form-select"
                                                        style={{ width: "100px" }}
                                                        onChange={(e) => {
                                                            setPageNo(1);
                                                            setPageSize(parseInt(e.target.value));
                                                        }}>
                                                        <option value="10" selected>10</option>
                                                        <option value="20">20</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                        <option value="500">500</option>

                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <Pagination
                                            pageNo={pageNo}
                                            pageSize={pageSize}
                                            totalRecords={totalRecords}
                                            onPageChange={handlePageChange}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ManageInvoice;
