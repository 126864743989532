import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import Page from "../General/Page";
import BioDataForms from "../Shared/BioDataForms";
import { ToastContainer, toast } from "react-toastify";

const PrintBioDataForms = () => {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [listOfLedger, setLedger] = useState("");
    const [isUpdated, setIsUpdated] = useState(false);

    let params = useParams();
    const [StudentID, setStudentID] = useState(params.id);


    useEffect(() => {

        document.title = "Print Biodata Form";

        setIsUpdated(false);
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            // pageNo: 1,
            // pageSize: 2000,
            ID: StudentID,
        };

        //console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + '/Student/GetStudentByID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        //console.log(data)
        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                    setLedger(response.data.Student);
                    setLoading(false);
                    setTotalRecords(response.data.totalRecords);
                }
                else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setTotalRecords(0);
                    setLoading(false);
                }

            })
            .catch(function (error) {
                //console.log(error);
            });

    }, []);


    const renderLedger = () => {
        return (
            <>
                <BioDataForms StudentID={StudentID} />
            </>
        )
    }

    return (
        <>
            <ToastContainer />
            <Page orientation="portrait" size="a4">
                {
                    loading ?
                        <Spinner animation="border" role="status"></Spinner>
                        :
                        <>
                            {renderLedger()}
                        </>

                }
            </Page >
        </>
    );
}

export default PrintBioDataForms;
