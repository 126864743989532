import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import moment, { months } from "moment";
import PrintHeader from "../General/PrintHeader";
import Page from "../General/Page";

const PrintBusRoutes = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [listOfLedger, setLedger] = useState([]);

  useEffect(() => {
    document.title = "Print Bus Routes";

    // setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: 1,
      pageSize: 500,
      SchoolID: 1,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Busroute/GetBusRoutes",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  const renderLedger = () => {
    if (listOfLedger.length > 0) {
      let count = 1;

      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{count++}</td>

            <td>{item.Root_Code}</td>
            <td>{item.Root_Name}</td>
            <td>{item.Root_Details}</td>
            <td>{item.Root_Fee}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="20">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <Page orientation="potrait" size="a4">
        <table className="table table-sm table-bordered table-print">
          <thead>
            <tr>
              <td colspan="8">
                <PrintHeader title={`Bus Routes Report`} />
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Sr.</th>

              <th>Route Code</th>
              <th>Route Name</th>
              <th>Route Details</th>
              <th>Route Fee</th>
            </tr>
            {loading ? (
              <tr>
                <td className="text-center" colspan="5">
                  <Spinner animation="border" role="status"></Spinner>
                </td>
              </tr>
            ) : (
              <>
                {renderLedger()}

                {
                  // <tr>
                  //     <th className="text-end" colSpan={3}>Total Amount:</th>
                  //     <th className="" colSpan={1}>{calculateColumnTotal(listOfLedger, 'Salary_Value')}</th>
                  // </tr>
                }
              </>
            )}
          </tbody>
        </table>

        {/* <div style={{ marginTop: "30px", width: "100%" }}>
          <table
            className="table table-sm table-bordered table-print"
            style={{ borderCollapse: "collapse", border: "transparent" }}
          >
            <tbody>
              <tr className="my-5">
                <th
                  style={{
                    width: "33.33%",
                    textAlign: "left",
                    textDecoration: "underline",
                  }}
                >
                  Prepared By
                </th>
                <th
                  style={{
                    width: "33.33%",
                    textAlign: "center",
                    textDecoration: "underline",
                  }}
                >
                  Burser
                </th>
                <th
                  style={{
                    width: "33.33%",
                    textAlign: "right",
                    textDecoration: "underline",
                  }}
                >
                  Head of Wing / Deptt
                </th>
              </tr>
              <tr className="my-5">
                <br />
                <br />
              </tr>
              <tr className="my-5">
                <th
                  style={{
                    width: "33.33%",
                    textAlign: "left",
                    textDecoration: "underline",
                  }}
                >
                  Admin Officer
                </th>
                <th
                  style={{
                    width: "33.33%",
                    textAlign: "center",
                    textDecoration: "underline",
                  }}
                ></th>
                <th
                  style={{
                    width: "33.33%",
                    textAlign: "right",
                    textDecoration: "underline",
                  }}
                >
                  Principal
                </th>
              </tr>
            </tbody>
          </table>
        </div> */}
      </Page>
    </>
  );
};

export default PrintBusRoutes;
