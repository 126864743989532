import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { toast, ToastContainer } from 'react-toastify';
import { Link } from "react-router-dom";
import StockNavbar from './StockNavbar';
import item_list from '../../assets/images/dashboard_icon/item_list.svg';
import items_issued from '../../assets/images/dashboard_icon/items_issued.svg';
import negative_stock from '../../assets/images/dashboard_icon/negative_stock.svg';
import clipboard from '../../assets/images/dashboard_icon/clipboard.svg';
import fixed_money from '../../assets/images/dashboard_icon/fixed_money.svg';
import current_money from '../../assets/images/dashboard_icon/current_money.svg';
import consumable_money from '../../assets/images/dashboard_icon/consumable_money.svg';
import { scrollToTop } from '../Shared/ScrollOnTop';

const StocksDashboard = () => {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [DefaultSession, setDefaultSession] = useState(localStorage.getItem("DefaultSession"));
    const [dData, setdData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        document.title = "Stocks Dashboard";

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            SessionID: DefaultSession

        };
        //console.log(data);
        var api_config = {
            method: 'post',
            url: config.base_url + 'Stock/GetStockDashboard',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
               //console.log(response.data);
                if (response.data.status_code == 1) {
                    setdData(response.data);

                }
                setLoading(false);
            })
            .catch(function (error) {
                setLoading(false);
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });


    }, []);


    return (
        <>
            <StockNavbar />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Stocks</li>
                                </ol>
                            </nav>
                            <div className="page-title">Stocks</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="row align-items-center gy-3">
                        <div className="col-md-3 col-sm-6">
                            <div className="card m-card">
                                <div className="card-body text-center">
                                    <img src={item_list} className="pb-3" alt="" />
                                    <p className="m-2">Total Items in Stock</p>
                                    <h4 className="m-2">{dData.totalItemsinStock}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="card m-card">
                                <div className="card-body text-center">
                                    <img src={items_issued} className="pb-3" alt="" />
                                    <p className="m-2">Items issued in this month</p>
                                    <h4 className="m-2">{dData.itemIssuesinMonth}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="card">
                                <div className="card-body text-center">
                                    <img src={negative_stock} className="pb-3" alt="" />
                                    <p className="m-2">Negative Stock Items</p>
                                    <h4 className="m-2">{dData.negativeStockItems}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="card">
                                <div className="card-body text-center">
                                    <img src={clipboard} className="pb-3" alt="" />
                                    <p className="m-2">Total Value of Available Stock</p>
                                    <h4>{dData.totalValueOfStock}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="card m-card">
                                <div className="card-body">
                                    <div className="row align-items-center gy-3 m-auto">
                                        <p className="m-0">
                                            <b>
                                                Register Type Stock
                                            </b>
                                        </p>
                                        <div className="col-md-4 col-sm-12">
                                            <div className="card" style={{ backgroundColor: "#f9f9f9" }}>
                                                <div className="card-body text-center">
                                                    <img src={fixed_money} className="pb-3" alt="" />
                                                    <p className="m-2"><b>Fixed Assets</b></p>
                                                    <div className="row align-items-center gy-2">
                                                        <div className="col-md-6">
                                                            <p className="m-0 text-left"> No. of Items</p>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <span className="pink-value">{dData.noOfFixedAssets}</span>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <p className="m-0 text-left">Value of Stock</p>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <span className="pink-value">{dData.valueOfFixedAssets}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-12">
                                            <div className="card" style={{ backgroundColor: "#f9f9f9" }}>
                                                <div className="card-body text-center">
                                                    <img src={current_money} className="pb-3" alt="" />
                                                    <p className="m-2"><b>Current Assets</b></p>
                                                    <div className="row align-items-center gy-2">
                                                        <div className="col-md-6">
                                                            <p className="m-0 text-left"> No. of Items</p>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <span className="blue-value">{dData.noOfCurrentAssets}</span>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <p className="m-0 text-left">Value of Stock</p>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <span className="blue-value">{dData.valueofCurrentAssets}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-12">
                                            <div className="card" style={{ backgroundColor: "#f9f9f9" }}>
                                                <div className="card-body text-center">
                                                    <img src={consumable_money} className="pb-3" alt="" />
                                                    <p className="m-2"><b>Consumable / General</b></p>
                                                    <div className="row align-items-center gy-2">
                                                        <div className="col-md-6">
                                                            <p className="m-0 text-left"> No. of Items</p>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <span className="pink-value">{dData.noOfGeneralItems}</span>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <p className="m-0 text-left">Value of Stock</p>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <span className="pink-value"> {dData.valueOfGeneralItems}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4 col-sm-12">
                                            <div className="card" style={{ backgroundColor: "#f9f9f9" }}>
                                                <div className="card-body text-center">
                                                    <img src={consumable_money} className="pb-3" alt="" />
                                                    <p className="m-2"><b>Consumeable Items - Physics</b></p>
                                                    <div className="row align-items-center gy-2">
                                                        <div className="col-md-6">
                                                            <p className="m-0 text-left"> No. of Items</p>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <span className="pink-value">{dData.noOfConsumeablePhysics}</span>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <p className="m-0 text-left">Value of Stock</p>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <span className="pink-value"> {dData.valueofConsumeablePhysics}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4 col-sm-12">
                                            <div className="card" style={{ backgroundColor: "#f9f9f9" }}>
                                                <div className="card-body text-center">
                                                    <img src={consumable_money} className="pb-3" alt="" />
                                                    <p className="m-2"><b>Consumeable Items - Chemistry</b></p>
                                                    <div className="row align-items-center gy-2">
                                                        <div className="col-md-6">
                                                            <p className="m-0 text-left"> No. of Items</p>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <span className="pink-value">{dData.noOfConsumeableChemistry}</span>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <p className="m-0 text-left">Value of Stock</p>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <span className="pink-value"> {dData.valueofConsumeableChemistry}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4 col-sm-12">
                                            <div className="card" style={{ backgroundColor: "#f9f9f9" }}>
                                                <div className="card-body text-center">
                                                    <img src={consumable_money} className="pb-3" alt="" />
                                                    <p className="m-2"><b>Consumeable Items - Biology</b></p>
                                                    <div className="row align-items-center gy-2">
                                                        <div className="col-md-6">
                                                            <p className="m-0 text-left"> No. of Items</p>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <span className="pink-value">{dData.noOfConsumeableBiology}</span>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <p className="m-0 text-left">Value of Stock</p>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <span className="pink-value"> {dData.valueofConsumeableBiology}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4 col-sm-12">
                                            <div className="card" style={{ backgroundColor: "#f9f9f9" }}>
                                                <div className="card-body text-center">
                                                    <img src={consumable_money} className="pb-3" alt="" />
                                                    <p className="m-2"><b>Consumeable Items - Stationary</b></p>
                                                    <div className="row align-items-center gy-2">
                                                        <div className="col-md-6">
                                                            <p className="m-0 text-left"> No. of Items</p>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <span className="pink-value">{dData.noOfConsumeableStationary}</span>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <p className="m-0 text-left">Value of Stock</p>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <span className="pink-value"> {dData.valueofConsumeableStationary}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4 col-sm-12">
                                            <div className="card" style={{ backgroundColor: "#f9f9f9" }}>
                                                <div className="card-body text-center">
                                                    <img src={consumable_money} className="pb-3" alt="" />
                                                    <p className="m-2"><b>TPT Sare Parts</b></p>
                                                    <div className="row align-items-center gy-2">
                                                        <div className="col-md-6">
                                                            <p className="m-0 text-left"> No. of Items</p>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <span className="pink-value">{dData.noOfConsumeableTPTSpareParts}</span>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <p className="m-0 text-left">Value of Stock</p>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <span className="pink-value"> {dData.valueofConsumeableTPTSpareParts}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default StocksDashboard;
