import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import SecurityMenu from "./SecurityMenu";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import UserGroupDropDown from "../Shared/UserGroupDropDown";
import { scrollToTop } from '../Shared/ScrollOnTop';

function AddNewUser(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [ShowPassword, setShowPassword] = useState(false);
    const [ShowConfirmPassword, setShowConfirmPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [GroupID, setGroupId] = useState(0);

    let navigate = useNavigate();

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            let password1 = event.target.txtPassword.value;
            let password2 = event.target.txtRetypePassword.value;
            if (password1 != password2) {
                toast.error('Password and Retype password should be same', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            else {
                setLoading(true);
                const data = {
                    AccessKey: AccessKey,
                    UserID: UserID,
                    MemberType: MemberType,
                    UserName: event.target.txtLogin.value,
                    FullName: event.target.txtFullName.value,
                    Password: event.target.txtPassword.value,
                    User_Group_ID: event.target.ddlUserGroup.value,

                };

                //console.log(data);

                var api_config = {
                    method: 'post',
                    url: config.base_url + 'Members/SignUp',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };



                axios(api_config)
                    .then(function (response) {
                        //console.log(response.data);
                        if (response.data.status_code == 1) {

                            toast.success(response.data.status_message, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            navigate('/Security/ManageUsers');
                        }
                        else {
                            toast.error(response.data.status_message, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        }

                        setLoading(false);


                    })
                    .catch(function (error) {
                        setLoading(false);
                        toast.error('Network Error..', {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    });
            }
        }
        setValidated(true);
    };


    return (
        <>

            <SecurityMenu />
            <ToastContainer />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><NavLink onClick={scrollToTop} to="/Home/Index">Dashboard</NavLink></li>
                                    <li className="breadcrumb-item"><NavLink onClick={scrollToTop} to="/Security/SecurityDashboard">Security</NavLink></li>
                                    <li className="breadcrumb-item active" aria-current="page">Add User</li>
                                </ol>
                            </nav>
                            <div className="page-title">Add New User</div>
                        </div>
                        <div className="col-md-6">
                            <div className="page-right-actions">
                                <NavLink onClick={scrollToTop} to="/Security/ManageUsers" className="button button-white button-icon">
                                    Manage Users
                                    <i className="ri-list-check"></i>
                                </NavLink>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-md-4">
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        <div className="row align-items-center">
                                            <div className="col-md-3">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="">Full Name:</label>
                                                    <input type="text" className="form-control" required name="txtFullName" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="" className="">Login ID</label>
                                                    <input type="text" className="form-control" required name="txtLogin" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group mb-3 position-relative">
                                                    <label htmlFor="" className="">
                                                        Password
                                                    </label>
                                                    <input
                                                        type={ShowPassword ? 'text' : 'password'}
                                                        className="form-control"
                                                        required
                                                        name="txtPassword"
                                                        minLength={4}
                                                        maxLength={13}
                                                    />
                                                    <span
                                                        className={`${ShowPassword ? 'ri-eye-off-line' : 'ri-eye-line'}`}
                                                        style={{
                                                            position: "absolute",
                                                            bottom: "6px",
                                                            right: "12px"
                                                        }}
                                                        onClick={(e) => setShowPassword(!ShowPassword)}
                                                    >
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group mb-3 position-relative">
                                                    <label htmlFor="" className="">
                                                        Retype Password
                                                    </label>
                                                    <input type={ShowConfirmPassword ? 'text' : 'password'}
                                                        className="form-control"
                                                        required
                                                        name="txtRetypePassword"
                                                        minLength={4}
                                                        maxLength={13}
                                                    />
                                                    <span
                                                        className={`${ShowConfirmPassword ? 'ri-eye-off-line' : 'ri-eye-line'}`}
                                                        style={{
                                                            position: "absolute",
                                                            bottom: "6px",
                                                            right: "12px"
                                                        }}
                                                        onClick={(e) => setShowConfirmPassword(!ShowConfirmPassword)} >
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="" className="">Select User Group</label>
                                                    <select className="form-select" required name="ddlUserGroup" >
                                                        <option value="">Please Select</option>
                                                        <UserGroupDropDown />
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3 offset-md-6 text-end">
                                                <div className="form-group">
                                                    <button type="submit" disabled={loading} value="Save" className="button button-primary">SAVE {loading ? '...' : ''}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ >
    );
}

export default AddNewUser;