import React from 'react';
import { Link } from "react-router-dom";
import PayrollMenu from './PayrollMenu.js';
import { scrollToTop } from '../Shared/ScrollOnTop';
const AddSalary = () => {
    return (
        <>
        <PayrollMenu />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="/PayRoll/PayRollDashboard">Salary</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Add SalaryType</li>
                                </ol>
                            </nav>
                            <div className="page-title">Add Salary Type</div>
                        </div>
                        <div className="col-md-6">
                            <div className="page-right-actions">
                                <Link to="/PayRoll/ManageSalary" className="button button-white button-icon">
                                    Manage SalaryType
                                    <i className="ri-list-check"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddSalary;
