import React, { useState } from 'react';
import AnnualMenu from './AnnualMenu';
import { NavLink } from 'react-router-dom';
import { scrollToTop } from '../Shared/ScrollOnTop';
import ClassesDropDown from '../Shared/ClassesDropDown';
import WingsDropDown from '../Shared/WingsDropDown';
import SessionsDropDown from '../Shared/SessionsDropDown';
import SectionDropDown from '../Shared/SectionDropDown';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import RightsCheck from '../General/RightsCheck';
import config from '../../Config';

function PromoteClasses(props) {
    document.title = 'Promote Classes';

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(
        localStorage.getItem("MemberType")
    );

    // variables for promote section
    const [promoteSessionID, setPromoteSessionID] = useState(0);
    const [promoteWingID, setpromoteWingID] = useState(0);
    const [promoteClassID, setPromoteClassID] = useState(0);
    const [promoteSectionID, setPromoteSectionID] = useState(0);
    const [validatedPromote, setValidatedPromote] = useState(false);
    const [IsUpdated, setIsUpdated] = useState(false);

    const [ClassId, setClassId] = useState(0);
    const [SectionID, setSectionID] = useState(0);
    const [WingId, setWingId] = useState(0);
    const [SessionId, setSessioinId] = useState(
        localStorage.getItem("DefaultSession")
    );


    //handlePromote
    const handlePromote = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            setIsUpdated(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                Class_Id: ClassId,
                NewSectionID: promoteSectionID,
                OldSectionID: SectionID,
                SessionID: SessionId,
            };

            //console.log(data, "data")

            var api_config = {
                method: "post",
                url: config.base_url + "AnnualTask/PromoteStudentsSectionWise",
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };

            //console.log(data);

            axios(api_config)
                .then(function (response) {
                    //console.log(response.data);
                    if (response.data.status_code == 1) {
                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        setIsUpdated(false);
                    } else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        setIsUpdated(false);
                    }
                })
                .catch(function (error) {
                    //console.log(error);
                });
        }

        setValidatedPromote(true);
    };


    return (
        <>
            <AnnualMenu />
            <ToastContainer />
            <RightsCheck />
            <div className="container body-content">
                <div className="page-head">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                        <li className="breadcrumb-item"><NavLink to="/AnnualTasks/AnnualTasksDashboard">Annual Tasks</NavLink></li>
                                        <li className="breadcrumb-item active" aria-current="page">Promote Classes</li>
                                    </ol>
                                </nav>
                                <div className="page-title">Promote Classes</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-content">
                    <div className="container">
                        <div className="card">
                            <div className="card-body p-md-4">
                                <div className="bg-light p-md-4">
                                    <p className="m-0">
                                        This Module will give you the facility to promote your classes.
                                        <br />
                                        <b>Pre-Requirements:</b>
                                        You have already created the new classes and session and now you are promoting the classes into new one.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Form
                    noValidate
                    validated={validatedPromote}
                    onSubmit={handlePromote}
                >
                    <div className="page-content">
                        <div className="container">
                            <div className="card mt-2">
                                <div className="card-body p-md-4">
                                    <div className="row align-items-center">
                                        <div className="col-md-12">
                                            <div className="section-title title-sm title-left">Old Session Details</div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label for="" className="">
                                                    Select Session:
                                                </label>
                                                <select
                                                    className="form-select"
                                                    onChange={(e) => { setSessioinId(e.target.value); setWingId(0); setClassId(0); setSectionID(0); }}
                                                >
                                                    <option value="0">---All---</option>
                                                    <SessionsDropDown selectedSession={SessionId} />
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Select Wing:</label>
                                                <select
                                                    name="WingId"
                                                    onChange={(e) => { setWingId(e.target.value); setClassId(0); setSectionID(0); }}
                                                    className="form-select"
                                                >
                                                    <option value="0">---All---</option>
                                                    <WingsDropDown />
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label for="" className="">
                                                    Select Class:
                                                </label>
                                                <select
                                                    className="form-select"
                                                    onChange={(e) => { setClassId(e.target.value); setSectionID(0); }}
                                                    name="Class_ID"
                                                >
                                                    <option value="">Please Select</option>
                                                    {SessionId != 0 && WingId != 0 && (
                                                        <ClassesDropDown
                                                            SessionID={SessionId}
                                                            WingID={WingId}
                                                        />
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label for="" className="">
                                                    Select Section:
                                                </label>
                                                <select
                                                    className="form-select"
                                                    onChange={(e) => setSectionID(e.target.value)}
                                                    name="Section_Id"
                                                >
                                                    <option value="0">Please Select</option>
                                                    {ClassId !== 0 && (
                                                        <SectionDropDown ClassID={ClassId} />
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-content">
                        <div className="container">
                            <div className="card mt-2">
                                <div className="card-body p-md-4">

                                    <div className="row gy-3 align-items-center">
                                        <div className="col-md-12">
                                            <div className="section-title title-sm title-left">New Session Details</div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label for="" className="">
                                                    Select Session:
                                                </label>
                                                <select
                                                    className="form-select"
                                                    onChange={(e) => setPromoteSessionID(e.target.value)}
                                                    required
                                                >
                                                    <option value="">---All---</option>
                                                    <SessionsDropDown />
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Select Wing:</label>
                                                <select
                                                    name="WingId"
                                                    onChange={(e) => setpromoteWingID(e.target.value)}
                                                    className="form-select"
                                                    required
                                                >
                                                    <option value="">---All---</option>
                                                    <WingsDropDown />
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label for="" className="">
                                                    Select Class:
                                                </label>
                                                <select
                                                    className="form-select"
                                                    onChange={(e) => setPromoteClassID(e.target.value)}
                                                    required
                                                >
                                                    <option value="">Please Select</option>
                                                    {promoteSessionID != 0 && promoteWingID != 0 && (
                                                        <ClassesDropDown
                                                            SessionID={promoteSessionID}
                                                            WingID={promoteWingID}
                                                        />
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label for="" className="">
                                                    Select Section:
                                                </label>
                                                <select
                                                    className="form-select"
                                                    onChange={(e) => setPromoteSectionID(e.target.value)}
                                                    required
                                                >
                                                    <option value="">Please Select</option>
                                                    {promoteClassID !== 0 && (
                                                        <SectionDropDown ClassID={promoteClassID} />
                                                    )}
                                                </select>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className="card mt-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group text-md-end text-center">
                                                <button type="submit" className="button button-primary" disabled={IsUpdated}
                                                >Promote Classes {IsUpdated ? '...' : ''}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>

        </>
    );
}

export default PromoteClasses;