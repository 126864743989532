import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate, useParams } from "react-router-dom";

import moment from "moment";
import Page from "../General/Page";
const PrintStockDetail = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));

  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [totalRecords, setTotalRecords] = useState(0);

  let params = useParams();
  const [StockID, setStockID] = useState(params.id);

  useEffect(() => {
    document.title = "Print Stock Item Details";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: StockID,
    };
    //console.log(data);


    var api_config = {
      method: "post",
      url: config.base_url + "Stock/GetStockItemDetailByItemID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };


    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofDetails);
        setLoading(false);
        setTotalRecords(response.data.ListofDetails);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.ID}</td>
            <td>{moment(item.Transaction_Date).format("DD/MM/YYYY")}</td>
            <td>{item.Description}</td>
            <td>{item.Item_In}</td>
            <td>{item.Item_Out}</td>
            <td>{item.Item_Balance}</td>
            <td>{item.Custodian}</td>
            <td className="text-center">
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="8">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <Page orientation="portrait" size="a4">
        <table className="table table-sm table-bordered table-print">
        <thead>
                    <tr>
                      <th> ID</th>
                      <th>DATED</th>
                      <th>DESCRIPTION</th>
                      <th>IN</th>
                      <th>OUT</th>
                      <th>BALANCE</th>
                      <th>CUSTODIAN</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td className="text-center" colSpan="8">
                          <Spinner animation="border" role="status"></Spinner>
                        </td>
                      </tr>
                    ) : (
                      <>{renderLedger()}</>
                    )}
                  </tbody>
        </table >

      </Page >
    </>
  );
};

export default PrintStockDetail;
