import React, { useState, useEffect, useRef } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import Pagination from "../General/Pagination";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import FeeSystemMenu from "./FeeSystemMenu";
import BanksDropDown from "../Shared/BanksDropDown";
import moment from "moment";
import * as XLSX from "xlsx";
import RightsCheck from "../General/RightsCheck";

function AddBankScroll(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [TotalDeposited, setTotalDeposited] = useState("");
  const [ReceiptNo, SetReceiptNo] = useState("");
  const [ScrollDated, setScrollDated] = useState("");
  const [BenchNo, setBenchNo] = useState("");
  const [BarCodeValue, setBarCodeValue] = useState("");
  const [excelData, setExcelData] = useState([]);

  const [Amount_Deposited, setAmount_Deposited] = useState("");
  const [RegistrationNo, setRegistrationNo] = useState("");

  const [LateFeeFineStatus, setLateFeeFineStatus] = useState(false);
  const [LateFineAmount, setLateFineAmount] = useState(0);

  const [loadingPostEntries, setloadingPostEntries] = useState(false);
  const [fileSelected, setfileSelected] = useState(false);

  const [showFilters, setShowFilters] = useState(true);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(2000);
  const [totalRecords, setTotalRecords] = useState(0);

  const [selectedFileName, setSelectedFileName] = useState("");

  useEffect(() => {
    document.title = "Add Bank Scroll";
    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: pageSize,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "ScrollEntry/GetUnPostedEntries",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
        setTotalDeposited(response.data.TotalDeposited);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [pageNo, pageSize, isUpdated]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const renderLedger = () => {
    if (listOfLedger && listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{count++ + pageSize * (pageNo - 1)} </td>
            <td>{item.Registration_No}</td>
            <td>{item.Student_Name}</td>
            <td>{item.Receipt_No}</td>
            <td>{item.BenchNumber}</td>
            <td>{moment(item.Dated).format("DD/MM/YYYY")}</td>
            <td className="text-center">{item.Amount_Deposited}</td>
            <td>{item.Balance_After_This_Entry}</td>
            <td>{item.Balance_Type_After_This_Entry}</td>
            <td className="text-center">
              <ul
                className="inline-action"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <button
                    onClick={(e) => deleteEntry(item.Entry_Id)}
                    className="red"
                  >
                    <i className="ri-delete-bin-line"></i>
                  </button>
                </li>
              </ul>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="10">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };
  const deleteEntry = (ID) => {
    const result = window.confirm("Are you sure you want to delete?");

    if (result) {
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        Entry_Id: ID,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "ScrollEntry/DeleteScrollEntry",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          if (response.data.status_code == 1) {
            setIsUpdated(true);
          }
          // setIsUpdated(false);
        })
        .catch(function (error) {
          //console.log(error);
        });
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet);

      setfileSelected(true);
      setExcelData(jsonData);
      setSelectedFileName(file.name); // Set the selected file name here
    };

    reader.readAsArrayBuffer(file);
  };

  const GetNextReceiptNo = (BenchNo) => {
    setBenchNo(BenchNo);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      BenchNumber: BenchNo,
      Dated: ScrollDated,
    };
    //console.log(data);
    var api_config = {
      method: "post",
      url: config.base_url + "ScrollEntry/GetNextReceiptNumber",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          setIsUpdated(true);
          SetReceiptNo(response.data.receiptNumber);
          setBenchNo(BenchNo);
        }
        // setIsUpdated(false);
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  const ScanQRCode = (e) => {
    // if(e.target.value == 0) {
    //     setBarCodeValue("");
    // }
    // else {
    //     setBarCodeValue(e.target.value);
    // }

    setBarCodeValue(e.target.value);
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      // Perform API call here
      if (BarCodeValue.length > 3) {
        postDataToAPI();
      } else {
        setBarCodeValue("");
      }
    }, 500);

    window.scrollTo(0, document.body.scrollHeight);

    return () => clearTimeout(delay);
  }, [BarCodeValue]);

  const postDataToAPI = () => {
    if (BarCodeValue != "" || BarCodeValue.length > 3) {
      const [reg, amount] = BarCodeValue.split(" ");

      const data = {
        UserID: UserID,
        AccessKey: AccessKey,
        BenchNumber: BenchNo,
        Dated: ScrollDated,
        RegistrationNo: reg,
        Receipt_No: ReceiptNo,
        Amount_Deposited: amount,
        LateFeeFine: LateFeeFineStatus,
        LateFineAmount: LateFineAmount,
      };
      var api_config = {
        method: "post",
        url: config.base_url + "ScrollEntry/AddScrollEntry",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          //  //console.log(response.data);
          if (response.data.status_code == 1) {
            setIsUpdated(true);
            setBarCodeValue("");
            setRegistrationNo("");
            setAmount_Deposited("");
            SetReceiptNo(parseInt(ReceiptNo) + parseInt(1));

            if (response.data.RegistrationNo != "") {
              toast.success(
                "Student has been registered with Registration No " +
                response.data.RegistrationNo,
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
            }
          }
          // setIsUpdated(false);
        })
        .catch(function (error) {
          //console.log(error);
        });
    } else {
      //console.log("here");
      // toast.error("Invalid QR code", {
      //     position: toast.POSITION.TOP_RIGHT
      // });
      setBarCodeValue("");
    }
  };

  const handleSubmit = () => {
    setBarCodeValue(RegistrationNo + " " + Amount_Deposited);
    setLoading(true);

    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      BenchNumber: BenchNo,
      Dated: ScrollDated,
      RegistrationNo: RegistrationNo,
      Receipt_No: ReceiptNo,
      Amount_Deposited: Amount_Deposited,
      LateFeeFine: true,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "ScrollEntry/AddScrollEntry",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          setIsUpdated(true);
          setBarCodeValue("");
          SetReceiptNo(parseInt(ReceiptNo) + parseInt(1));
        } else if (
          response.data.status_code == 0 ||
          response.data.status_code == 2
        ) {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
          setIsUpdated(false);
        }
        // setIsUpdated(false);
      })
      .catch(function (error) {
        //console.log(error);
      });

    setAmount_Deposited("");
    setRegistrationNo("");
  };

  const handleSubmitByFile = () => {
    excelData.forEach((row) => {
      const data = {
        UserID: UserID,
        AccessKey: AccessKey,
        BenchNumber: row.Batch,
        Dated: moment(row.DATED, "D/M/YYYY").format("YYYY-MM-DD"),
        RegistrationNo: row.REGISTRATION,
        Receipt_No: ReceiptNo,
        Amount_Deposited: row.AMOUNT,
        LateFeeFine: true,
      };

      //console.log(data);

      var api_config = {
        method: "post",
        url: config.base_url + "ScrollEntry/AddScrollEntry",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          if (response.data.status_code == 1) {
            setIsUpdated(true);
            setBarCodeValue("");
            SetReceiptNo(parseInt(ReceiptNo) + parseInt(1));
          } else if (
            response.data.status_code == 0 ||
            response.data.status_code == 2
          ) {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoading(false);
            setIsUpdated(false);
          }
        })
        .catch(function (error) {
          //console.log(error);
        });
    });
  };
  const handleLateFee = (e) => {
    setLateFeeFineStatus(e.target.checked);

    if (e.target.checked === false) {
      setLateFineAmount(0);
    }
  };

  const PostEntries = () => {
    setloadingPostEntries(true);
    //console.log(BenchNo);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      BenchNo: parseInt(BenchNo),
      StartDate: ScrollDated,
      LateFeeFine: LateFeeFineStatus,
      LateFineAmount: LateFineAmount,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "ScrollEntry/PostEntriesIntoLedger",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          setIsUpdated(true);
        }
        else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

        setloadingPostEntries(false);
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  const handleLiveData = () => {
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      Dated: ScrollDated,
      BenchNo: '100'
    };
    var api_config = {
      method: "post",
      url: config.base_url + 'FeeOrder/FetchDataFromServer',
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    }
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      })

  };
  return (
    <>
      <RightsCheck />
      <FeeSystemMenu />
      <ToastContainer />
      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/Dashboard">Dashboard</NavLink>
                    </li>
                    <li className="breadcrumb-item">
                      <NavLink to="/FeeSystem/FeeSystemDashboard">
                        Fee System
                      </NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Add BankScroll
                    </li>
                  </ol>
                </nav>
                <div className="page-title">Add BankScroll</div>
              </div>
              <div className="col-md-6">
                <div className="page-right-actions">
                  <button
                    onClick={() => {
                      setShowFilters((prevIsActive) => !prevIsActive);
                    }}
                    className="button button-white button-icon"
                  >
                    {showFilters ? "Hide Filters" : "Show Filters"}
                    <i className="ri-filter-3-line"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-content">
          <div className="container">
            <div
              className={`collapse ${showFilters ? "show" : ""} `}
              id="collapseFilteres"
            >
              <div>
                <div className="card mb-4">
                  <div className="card-body p-4">
                    <div className="row align-items-center">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="">Select Branch:</label>
                          <select className="form-select" required>
                            <BanksDropDown />
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Dated:</label>
                          <input
                            type="date"
                            className="form-control"
                            onChange={(e) => setScrollDated(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label> Bench No:</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => GetNextReceiptNo(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-check mt-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="ChargeFine"
                            checked={LateFeeFineStatus}
                            onChange={(e) => handleLateFee(e)}
                          />
                          <label className="form-check-label" for="ChargeFine">
                            Charge Fine
                          </label>
                        </div>
                      </div>

                      {LateFeeFineStatus && (
                        <div className="col-md-2">
                          <div className="form-group">
                            <label> Fine Amount:</label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) =>
                                setLateFineAmount(e.target.value)
                              }
                              required
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="card">
                <div className="card-body">
                  <table className="table table-theme ">
                    <thead>
                      <tr>
                        <th>S No</th>
                        <th>Registration No</th>
                        <th>Student Name</th>
                        <th>Receipt No</th>
                        <th>Bench No</th>
                        <th>Dated</th>
                        <th>Amount Deposited</th>
                        <th>Balance After Entry</th>
                        <th>Balance Type</th>
                        <th className="text-center">Delete</th>
                      </tr>
                    </thead>
                    <tbody>{renderLedger()}</tbody>
                  </table>
                  {totalRecords > 10 && (
                    <div className="pagination-wrap">
                      <div className="row align-items-center">
                        <div className="col-md-2 form_default">
                          <div className="d-flex align-items-center">
                            <label
                              className="me-2"
                              style={{ fontSize: "15px" }}
                            >
                              Page Size
                            </label>
                            <div className="form-group">
                              <select
                                className="form-select"
                                style={{ width: "100px" }}
                                onChange={(e) => {
                                  setPageNo(1);
                                  setPageSize(parseInt(e.target.value));
                                }}
                              >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="500">500</option>
                                <option value="1000">1000</option>
                                <option value="2000" selected>2000</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <Pagination
                          pageNo={pageNo}
                          pageSize={pageSize}
                          totalRecords={totalRecords}
                          onPageChange={handlePageChange}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="card-footer text-end d-flex align-items-center justify-content-between">
                  <div>
                    <strong>{totalRecords}</strong> Records Found!
                  </div>
                  <div
                    className="d-flex align-items-center"
                    style={{ minHeight: "50px" }}
                  >
                    <h4 className="m-0 me-4">
                      Total Amount: <strong>{TotalDeposited}</strong>
                    </h4>
                    {loadingPostEntries ? (
                      <Spinner animation="border" role="status"></Spinner>
                    ) : (
                      <button
                        type="button"
                        onClick={PostEntries}
                        className="button button-primary"
                      >
                        Post Entries in Ledger
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="card mt-2">
              <div className="card-body p-4">
                <div className="row align-items-center">
                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label>Receipt No:</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => SetReceiptNo(e.target.value)}
                        value={ReceiptNo}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group mb-3 position-relative">
                      {loading ? (
                        <div
                          style={{
                            position: "absolute",
                            right: "20px",
                            top: "37px",
                          }}
                        >
                          <Spinner
                            animation="border"
                            size="sm"
                            role="status"
                          ></Spinner>
                        </div>
                      ) : (
                        ""
                      )}
                      <label>BarCode Entry:</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => ScanQRCode(e)}
                        value={BarCodeValue}
                        disabled={ScrollDated == "" ? true : false}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label>Student Code:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={RegistrationNo}
                        onChange={(e) => setRegistrationNo(e.target.value)}
                        name="RegistrationNo"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label>Amount:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={Amount_Deposited}
                        onChange={(e) => setAmount_Deposited(e.target.value)}
                        name="Amount_Deposited"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row justify-content-end">
                  <div className="col-md-2">
                    <button
                      type="button"
                      onClick={handleSubmit}
                      disabled={
                        Amount_Deposited == "" ||
                        RegistrationNo == "" ||
                        ScrollDated == "" ||
                        BenchNo == ""
                      }
                      className={`button button-primary w-100
                       ${Amount_Deposited == "" ||
                          RegistrationNo == "" ||
                          ScrollDated == "" ||
                          BenchNo == ""
                          ? "disabled"
                          : ""
                        }`}
                    >
                      Add Entry
                    </button>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-3">
                    <button className="button button-danger" onClick={handleLiveData}>Get Live Data</button>
                  </div>
                  <div className="col-md-3 offset-md-4 text-end">
                    {selectedFileName && (
                      <span style={{ fontSize: "13px", marginRight: "10px" }}>
                        Selected file: {selectedFileName}
                      </span>
                    )}
                    <label
                      htmlFor="chose-file"
                      className="button button-white shadow-sm"
                      style={{ cursor: "pointer" }}
                    >
                      <i className="ri-upload-2-line"></i> Chose File
                      <input
                        className=""
                        id="chose-file"
                        style={{
                          opacity: "0",
                          zIndex: "-1",
                          visibility: "hidden",
                          position: "absolute",
                        }}
                        type="file"
                        accept=".xlsx, .xls"
                        onChange={handleFileChange}
                      />
                    </label>
                  </div>
                  <div className="col-md-2 text-end">
                    <button
                      type="button"
                      onClick={handleSubmitByFile}
                      disabled={!fileSelected}
                      className={`button button-primary w-100
                       ${!fileSelected ? "disabled" : ""}`}
                    >
                      Add Enteries
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddBankScroll;
