

function NoAccess() {
  return (
    <div className="dashboard">
    <div className="container">
        <div className="row">
            <div className="col-md-10 offset-md-1">
                <p>You do not have access to that feature</p>
            </div>
        </div>
    </div>
</div>
  );
}

export default NoAccess;
