import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import AnnualMenu from "./AnnualMenu";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { scrollToTop } from "../Shared/ScrollOnTop";

function AddSessions(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    document.title = "Add/Update Academic Session";
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoading(true);
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        Session_Code: event.target.sessionCode.value,
        Session_Name: event.target.sessionName.value,
        Session_Details: event.target.sessionDetail.value,
        Session_Start_Date: event.target.startDate.value,
        Session_End_Date: event.target.endDate.value,
        School_ID: 1,
      };

      //console.log(data);

      var api_config = {
        method: "post",
        url: config.base_url + "AcademicSession/AddSession",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }

          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          toast.error("Network Error..", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }

    setValidated(true);
  };

  return (
    <>
      {/* <LoginCheck /> */}
      <AnnualMenu />
      <ToastContainer />
      <div className="page-head">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/Dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/AnnualTasks/AnnualTasksDashboard">
                      Annual Tasks{" "}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Add Sessions
                  </li>
                </ol>
              </nav>
              <div className="page-title">Add Academic Session</div>
            </div>
            <div className="col-md-6">
              <div className="page-right-actions">
                <Link
                  to="/AnnualTasks/ManageSessions"
                  className="button button-white button-icon"
                >
                  Manage Sessions
                  <i className="ri-list-check"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body p-md-4">
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <div className="row align-items-center">
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label>Session Code:</label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            name="sessionCode"
                          />
                          <div className="invalid-feedback">
                            Please enter Session code
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label>Session Name:</label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            name="sessionName"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label> Start Date:</label>
                          <input
                            type="date"
                            className="form-control"
                            required
                            name="startDate"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label> End Date:</label>
                          <input
                            type="date"
                            className="form-control"
                            required
                            name="endDate"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group mb-3">
                          <label> Session Details:</label>
                          <textarea
                            type="text"
                            className="form-control"
                            required
                            name="sessionDetail"
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-md-12 text-end">
                        <div className="form-group">
                          {loading ? (
                            <Spinner animation="border" role="status"></Spinner>
                          ) : (
                            <button
                              type="submit"
                              value="Save"
                              className="button button-primary"
                            >
                              SAVE
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddSessions;
