import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Examination_navbar from './Examination_navbar';
import moment from "moment";
import Pagination from "../General/Pagination";
import { Button } from "react-bootstrap";
import { scrollToTop } from '../Shared/ScrollOnTop';

const ManageExaminations = (props) => {
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    const [isUpdated, setIsUpdated] = useState(false);

    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {

        document.title = "Manage Examinations";

        setIsUpdated(false);
        setLoading(true);

        const data = {
           AccessKey: AccessKey,
            UserID: UserID,
MemberType: MemberType,
            pageNo: pageNo,
            pageSize: pageSize,
            SchoolID: 1
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Exam/GetAllExams',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setLedger(response.data.ListofRecords);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);

            })
            .catch(function (error) {
                //console.log(error);
            });

    }, [pageNo, pageSize, isUpdated]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const renderLedger = () => {

        if (listOfLedger != null && listOfLedger.length > 0) {
            let count = 1;
            return (
                listOfLedger.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{count++ +  (pageSize * (pageNo - 1))}</td>
                            <td>{item.ID}</td>
                            <td>{item.Name}</td>
                            <td>{item.Print_Title}</td>
                            <td>{moment(item.Start_Date).format('DD/MM/YYYY')}</td>
                            <td>{moment(item.End_Date).format('DD/MM/YYYY')}</td>
                            <td>{item.Current_Status}</td>
                            <td className="text-center">
                                <div className="dropdown dropdown-table-actions">
                                    <Link href="javascript:;" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" className="">
                                        <i className="ri-more-fill"></i>
                                    </Link>
                                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                        <li><Link to={'/ExaminationSystem/EditExam/' + item.ID} className="dropdown-item"><i className="ri-edit-line"></i>Edit</Link></li>
                                        <li><Link to={'/ExaminationSystem/ExamClasses/' + item.ID} className="dropdown-item" ><i className="ri-list-settings-line"></i> Manage Classes</Link></li>
                                        <li><Link to={'/ExaminationSystem/ExamDataEntryOptions/' + item.ID} className="dropdown-item"><i className="ri-printer-line"></i> Print Entry Sheets</Link></li>
                                        <li><Link to={'/ExaminationSystem/ExamDataSO'} className="dropdown-item" ><i className="ri-file-copy-2-line"></i> Print Marks Sheet</Link></li>
                                        {/* <li><button className="dropdown-item" onClick={(e) => deleteExam(item.ID)}><i className="ri-close-circle-fill"></i> Close This Exam</button></li> */}
                                        <li><Link to={'/ExaminationSystem/CloseThisExam'} className="dropdown-item" onClick={(e) => deleteExam(item.ID)}><i className="ri-close-circle-fill"></i> Close This Exam</Link></li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colspan="8"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }
    const deleteExam = (ID) => {

        const data = {
           AccessKey: AccessKey,
            UserID: UserID,
MemberType: MemberType,
            ID: ID
        };

        var api_config = {
            method: 'post',
            url: config.base_url + '/Exam/DeleteExam',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                    setIsUpdated(true);
                }
                // setIsUpdated(false);

            })
            .catch(function (error) {
                //console.log(error);
            });
    }


    return (
        <>
            <Examination_navbar />
            <ToastContainer />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="/ExaminationSystem/ExaminationDashboard">Examinations </Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Manage Examinations</li>
                                </ol>
                            </nav>
                            <div className="page-title">Manage Examinations</div>
                        </div>
                        <div className="col-md-6">
                            <div className="page-right-actions">
                                <Link to="/ExaminationSystem/AddExaminations?Id=0" className="button button-white button-icon">
                                    Add Examinations
                                    <i className="ri-add-line"></i>
                                </Link>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="card">
                        <div className="card-body">
                            <table className="table table-theme table-hover">
                                <thead>
                                    <tr>
                                        <th>Sr. No.</th>
                                        <th>
                                            Exam ID
                                        </th>
                                        <th>
                                            Exam Name
                                        </th>
                                        <th>
                                            Print Title
                                        </th>
                                        <th>
                                            Start Date
                                        </th>
                                        <th>
                                            End Date
                                        </th>
                                        <th>
                                            Current Status
                                        </th>

                                        <th className="text-center">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        loading ?
                                            <tr>
                                                <td className="text-center" colspan="8">
                                                    <Spinner animation="border" role="status"></Spinner>
                                                </td>
                                            </tr>
                                            :
                                            <>
                                                {renderLedger()}
                                            </>
                                    }
                                </tbody>
                            </table>
                            {
                                totalRecords > 10 &&
                                <div className="pagination-wrap">
                                    <div className="row align-items-center">
                                        <div className="col-md-2 form_default">
                                            <div className="d-flex align-items-center">
                                                <label className="me-2" style={{ fontSize: "15px" }}>Page Size</label>
                                                <div className="form-group">
                                                    <select className="form-select"
                                                        style={{ width: "100px" }}
                                                        onChange={(e) => {
                                                            setPageNo(1);
                                                            setPageSize(parseInt(e.target.value));
                                                        }}>
                                                        <option value="10" selected>10</option>
                                                        <option value="20">20</option>
                                                        <option value="50">50</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <Pagination
                                            pageNo={pageNo}
                                            pageSize={pageSize}
                                            totalRecords={totalRecords}
                                            onPageChange={handlePageChange}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ManageExaminations;
