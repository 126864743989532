import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import SessionsDropDown from "../Shared/SessionsDropDown";
import WingsDropDown from "../Shared/WingsDropDown";
import ClassesDropDown from "../Shared/ClassesDropDown";
import SectionDropDown from "../Shared/SectionDropDown";
import StudentGroupDropDown from "../Shared/StudentGroupDropDown";
import StudentStatusDropDown from "../Shared/StudentStatusDropDown";
import StudentMenu from "./StudentMenu";
import { toast, ToastContainer } from "react-toastify";
import { NavLink } from "react-router-dom";
import moment from "moment";
import Pagination from "../General/Pagination";
import Dropdown from "react-bootstrap/Dropdown";
import { Form, PageItem } from "react-bootstrap";
import ConcessionDropDown from "../Shared/ConcessionDropDown";
import BusrouteDropDown from "../Shared/BusrouteDropDown";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { scrollToTop } from "../Shared/ScrollOnTop";
import csvDownload from "json-to-csv-export";
import RightsCheck from '../General/RightsCheck';

function AdvanceSearch(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  //const [DefaultSession, setDefaultSession] = useState(localStorage.getItem("DefaultSession"));

  const [showFilters, setShowFilters] = useState(true);

  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [validated, setValidated] = useState(false);
  const [loaddata, setLoaddata] = useState(false);


  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const [WingId, setWingId] = useState(0);
  const [SessionId, setSessioinId] = useState(
    localStorage.getItem("DefaultSession")
  );
  const [ClassId, setClassId] = useState(0);
  const [SectionID, setSectionID] = useState(0);
  const [GroupId, setGroupId] = useState(0);
  const [ConcessionId, setConcessionId] = useState(0);
  const [BusRouteId, setBusRouteId] = useState(-1);

  const [StudentStatusType, setStudentStatusType] = useState(0);
  const [isUpdated, setIsUpdated] = useState(false);

  const [Registration_No, SetRegistrationNo] = useState("");
  const [StudentID, setStudentID] = useState(0);
  const [Keywords, setKeywords] = useState("");
  const [BayFormNo, setBayFormNo] = useState("");
  const [Gender, setGender] = useState("");
  const [Balance, setBalance] = useState("");
  const [BalanceType, setBalanceType] = useState("-1");
  const [DateOfBirth, setDateOfBirth] = useState("-1");

  const [Status_Type_Id, SetStatusTypeId] = useState(0);
  const [OrderBy, setOrderBy] = useState("Student_Name");

  const [selectedStudents, setselectedStudents] = useState([]);

  // variables for promote section
  const [promoteSessionID, setPromoteSessionID] = useState(0);
  const [promoteWingID, setpromoteWingID] = useState(0);
  const [promoteClassID, setPromoteClassID] = useState(0);
  const [promoteSectionID, setPromoteSectionID] = useState(0);
  const [validatedPromote, setValidatedPromote] = useState(false);
  const [listOfLedgerforcvs, setLedgerforcvs] = useState([]);


  useEffect(() => {
    document.title = "Advance Search";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: pageSize,
      SessionID: SessionId,
      Wing_Id: WingId,
      Class_Id: ClassId,
      Section_Id: SectionID,
      Registration_No: Registration_No,
      Student_ID: StudentID,
      Keywords: Keywords,
      Balance: Balance,
      BalanceType: BalanceType,
      BayFormNo: BayFormNo,
      Gender: Gender,
      DateOfBirth: DateOfBirth,
      Concession_Rule_ID: ConcessionId,
      BusRouteID: BusRouteId,
      Status_Type_Id: Status_Type_Id,
      Group_Id: GroupId,
      OrderBy: OrderBy,
    };


    var api_config = {
      method: "post",
      url: config.base_url + "Student/AdvanceSearch",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    //console.log(data);

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [pageNo, pageSize, SessionId, WingId, ClassId, SectionID, Registration_No, StudentID, Keywords, Balance, BalanceType, BayFormNo, Gender, DateOfBirth, ConcessionId, BusRouteId, Status_Type_Id, GroupId, OrderBy]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>
              <div className="form-check">
                <input
                  key={item.Student_ID}
                  data-type-id={item.Student_ID}
                  className="form-check-input checkStudent"
                  type="checkbox"
                  value={item.Student_ID}
                />
              </div>
            </td>
            <td className="text-nowrap">{item.Registration_No}</td>
            <td className="">{item.Student_Name}</td>
            <td className="">{item.Father_Name}</td>
            <td>{moment(item.Date_Of_Birth).format("DD/MM/YYYY")}</td>
            <td>{moment(item.Date_Of_Joining).format("DD/MM/YYYY")}</td>
            <td>{item.Contact_Mobile}</td>
            <td className="">{item.SelectedClass.SelectedWing.Wing_Name}</td>
            <td>{item.SelectedClass.Class_Name}</td>
            <td>{item.SelectedSection.Section_Name}</td>


            <td>
              <div className="status">
                {item.Concession_Rule_ID > 1 && (
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip>Concession Applied</Tooltip>}
                  >
                    <span className="green">
                      <i className="ri-percent-fill"></i>
                    </span>
                  </OverlayTrigger>
                )}
                {item.Current_Route_Id > 0 && (
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip>Transport</Tooltip>}
                  >
                    <span className="red">
                      <i className="ri-bus-2-fill"></i>
                    </span>
                  </OverlayTrigger>
                )}
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip>
                    {item.Status_Type_Id == 1 ? 'Normal' :
                      item.Status_Type_Id == 2 ? 'Stucked Off' :
                        item.Status_Type_Id == 3 ? 'Left' :
                          item.Status_Type_Id == 4 ? 'Study Complete' :
                            item.Status_Type_Id == 5 ? 'SLC' : 'Freezed'
                    }
                  </Tooltip>}
                >
                  <span className="blue">
                    {item.Status_Type_Id == 1 ? <i className="ri-user-6-line"></i> :
                      item.Status_Type_Id == 2 ? <i className="ri-emotion-unhappy-line"></i> :
                        item.Status_Type_Id == 3 ? <i className="ri-run-line"></i> :
                          item.Status_Type_Id == 4 ? <i className="ri-thumb-up-line"></i> :
                            item.Status_Type_Id == 5 ? <i className="ri-article-line"></i> :
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" fill="currentColor"><path d="M11.9998 3.29814L14.4451 1.66794L15.5545 3.33204L12.9998 5.03517V10.2678L17.5313 7.65149L17.7289 4.58748L19.7247 4.7162L19.5356 7.64899L22.17 8.95159L21.2836 10.7444L18.5313 9.38354L14.0001 11.9996L18.5317 14.616L21.284 13.2551L22.1704 15.0479L19.536 16.3505L19.7251 19.2833L17.7293 19.412L17.5317 16.348L12.9998 13.7315V18.9648L15.5545 20.6679L14.4451 22.332L11.9998 20.7018L9.55446 22.332L8.44506 20.6679L10.9998 18.9648V13.7319L6.46786 16.3484L6.27026 19.4124L4.2744 19.2836L4.46355 16.3508L1.8291 15.0483L2.71555 13.2554L5.46786 14.6163L10.0001 11.9996L5.46824 9.38319L2.71594 10.7441L1.82948 8.95124L4.46393 7.64864L4.27478 4.71585L6.27064 4.58713L6.46824 7.65113L10.9998 10.2674V5.03517L8.44506 3.33204L9.55446 1.66794L11.9998 3.29814Z"></path></svg>
                    }

                  </span>
                </OverlayTrigger>
              </div>
            </td>
            <td>
              <ul className="inline-action">
                <li>
                  <NavLink
                    to={"/Students/EditStudent/" + item.Student_ID}
                    className=" green"
                  >
                    <i className="ri-edit-line"></i>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    target="_blank"
                    to={
                      `/Students/PrintVoucher/?Class_Id=0&ConcessionRuleID=0&OrderBy=Section_Name&Section_Id=0&SessionID=0&Wing_Id=0&Description=&AdvanceMonths=0&ShowHolidayImage=false&noRedCresent=false&amount=0&StudentType=Normal&Registration_No=${item.Registration_No}&Status_Type_Id=${item.Status_Type_Id}`

                    }
                    className="blue"
                  >
                    <i className="ri-printer-line"></i>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/Students/StudentLedger/" + item.Student_ID}
                    className="red"
                  >
                    <i className="ri-pages-line"></i>
                  </NavLink>
                </li>
              </ul>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="12">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        pageNo: pageNo,
        pageSize: pageSize,
        SessionID: SessionId,
        Wing_Id: WingId,
        Class_Id: ClassId,
        Section_Id: SectionID,
        Registration_No: Registration_No,
        Student_ID: StudentID,
        Keywords: Keywords,
        Balance: Balance,
        BalanceType: BalanceType,
        BayFormNo: BayFormNo,
        Gender: Gender,
        DateOfBirth: DateOfBirth,
        Concession_Rule_ID: ConcessionId,
        BusRouteID: BusRouteId,
        Status_Type_Id: Status_Type_Id,
        Group_Id: GroupId,
        OrderBy: OrderBy,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "Student/AdvanceSearch",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      //console.log(data);

      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          setLedger(response.data.ListofRecords);
          setLoading(false);
          setTotalRecords(response.data.totalRecords);
        })
        .catch(function (error) {
          //console.log(error);
        });
    }

    setValidated(true);
  };

  //handlePromote
  const handlePromote = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoading(true);

      const inputsStudents = document.querySelectorAll("input.checkStudent");
      const valuesSelected = [];
      inputsStudents.forEach((input) => {
        if (input.checked == true) {
          const value = parseInt(input.value);
          if (value > 0) {
            valuesSelected.push(value);
          }
        }
      });

      //console.log(valuesSelected);
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        SelectedStudents: valuesSelected,
        SessionID: promoteSessionID,
        Class_Id: promoteClassID,
        Section_Id: promoteSectionID,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "/Student/PromoteStudents",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      //console.log(data);

      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setIsUpdated(true);
          } else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }

          setLoading(false);
        })
        .catch(function (error) {
          //console.log(error);
        });
    }

    setValidatedPromote(true);
  };


  const handleSubmitForCvs = (event) => {
    //console.log(event);

    setLoaddata(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: pageSize,
      SessionID: SessionId,
      Wing_Id: WingId,
      Class_Id: ClassId,
      Section_Id: SectionID,
      Registration_No: Registration_No,
      Student_ID: StudentID,
      Keywords: Keywords,
      Balance: Balance,
      BalanceType: BalanceType,
      BayFormNo: BayFormNo,
      Gender: Gender,
      DateOfBirth: DateOfBirth,
      Concession_Rule_ID: ConcessionId,
      BusRouteID: BusRouteId,
      Status_Type_Id: Status_Type_Id,
      Group_Id: GroupId,
      OrderBy: OrderBy,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Student/AdvanceSearch",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedgerforcvs(response.data.ListofRecords);
        let data = response.data.ListofRecords;
        let count = 0;
        //console.log(data);
        const listOfLedgerNew = data.map(({ ...rest }) => ({
          Serial_No: count++ + pageSize * (pageNo - 1),
          Reg_No: rest.Registration_No,
          Name: rest.Student_Name,
          Father_Name: rest.Father_Name,
          DOB: moment(rest.Date_Of_Birth).format("DD/MM/YYYY"),
          DOJ: moment(rest.Date_Of_Joining).format("DD/MM/YYYY"),
          Mobile: `=T("${rest.Contact_Mobile}")`,
          Wing: rest.SelectedClass.SelectedWing.Wing_Name,
          Class: rest.SelectedClass.Class_Name,
          Section: rest.SelectedSection.Section_Name,
        }));
        //   //console.log(listOfLedgerNew);

        const dataToConvert = {
          data: listOfLedgerNew,
          filename: "AdvanceSeachStudent",
          delimiter: ",",
          headers: [
            "Serial_No",
            "Reg_No",
            "Name",
            "Father_Name",
            "DOB",
            "DOJ",
            "Mobile",
            "Wing",
            "Class",
            "Section",
          ],
        };

        csvDownload(dataToConvert);
        setLoaddata(false);
      })
      .catch(function (error) {
        //console.log(error);
        setLoaddata(false);
      });
  };

  return (
    <>
      <StudentMenu />
      <ToastContainer />
      <RightsCheck />
      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/Dashboard">Dashboard</NavLink>
                    </li>
                    <li className="breadcrumb-item">
                      <NavLink to="/students/StudentDashboard">
                        Students
                      </NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Advance Search
                    </li>
                  </ol>
                </nav>
                <div className="page-title">Advance Search</div>
              </div>

              <div className="col-md-6">
                <div className="page-right-actions">
                  <NavLink
                    to="/students/AddStudent"
                    className="button button-white button-icon"
                  >
                    Add Students
                    <i className="ri-add-fill"></i>
                  </NavLink>
                  <button
                    onClick={() => {
                      setShowFilters((prevIsActive) => !prevIsActive);
                    }}
                    className="button button-white button-icon"
                  >
                    {showFilters ? "Hide Filters" : "Show Filters"}
                    <i className="ri-filter-3-line"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`collapse ${showFilters ? "show" : ""} `}
          id="collapseFilteres"
        >
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="page-content">
              <div className="container">
                <div className="card mb-2">
                  <div className="card-body p-md-4">
                    <div className="row align-items-end gy-3">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Select Session:
                          </label>
                          <select
                            className="form-select"
                            onChange={(e) => setSessioinId(e.target.value)}
                            name="SessionID"
                          >
                            <option value="0">---All---</option>
                            <SessionsDropDown selectedSession={SessionId} />
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Select Wing:</label>
                          <select
                            name="WingId"
                            onChange={(e) => { setWingId(e.target.value); setClassId(0); setSectionID(0); }}
                            className="form-select"
                          >
                            <option value="0">---All---</option>
                            <WingsDropDown />
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Select Class:
                          </label>
                          <select
                            className="form-select"
                            onChange={(e) => { setClassId(e.target.value); setSectionID(0); }}
                            name="Class_ID"
                          >
                            <option value="">Please Select</option>
                            {SessionId != 0 && WingId != 0 && (
                              <ClassesDropDown
                                SessionID={SessionId}
                                WingID={WingId}
                              />
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Select Section:
                          </label>
                          <select
                            className="form-select"
                            onChange={(e) => setSectionID(e.target.value)}
                            name="Section_Id"
                          >
                            <option value="0">Please Select</option>
                            {ClassId !== 0 && (
                              <SectionDropDown ClassID={ClassId} />
                            )}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Registration No:
                          </label>
                          <input
                            type="text"
                            name="RegistrationNo"
                            onChange={(e) => SetRegistrationNo(e.target.value)}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Student Id:
                          </label>
                          <input
                            type="text"
                            name="Student_ID"
                            onChange={(e) => setStudentID(e.target.value)}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Keywords:
                          </label>
                          <input
                            type="text"
                            name="Keywords"
                            onChange={(e) => setKeywords(e.target.value)}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Balance:
                          </label>
                          <input
                            type="text"
                            name="Balance"
                            onChange={(e) => setBalance(e.target.value)}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Balance Type:
                          </label>
                          <select
                            name="BalanceType"
                            onChange={(e) => setBalanceType(e.target.value)}
                            className="form-select"
                          >
                            <option Selected Value="-1">
                              All
                            </option>
                            <option Value="Debit">Debit</option>
                            <option Value="Credit">Credit</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Date Of Birth:
                          </label>
                          <select
                            name="DateOfBirth"
                            onChange={(e) => setDateOfBirth(e.target.value)}
                            className="form-select"
                          >
                            <option Selected Value="-1">
                              All
                            </option>
                            <option Value="Not Available">Not Available</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Bay Form No:
                          </label>
                          <input
                            type="text"
                            name="BayFormNo"
                            onChange={(e) => setBayFormNo(e.target.value)}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Student Status:
                          </label>
                          <select
                            className="form-select"
                            onChange={(e) => SetStatusTypeId(e.target.value)}
                            name="statustype"
                          >
                            <option value="">---All---</option>
                            {<StudentStatusDropDown />}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Select Group:
                          </label>
                          <select
                            className="form-select"
                            onChange={(e) => setGroupId(e.target.value)}
                            name="GroupId"
                          >
                            <option value="-1">---All---</option>
                            {<StudentGroupDropDown />}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Concession Rule:
                          </label>
                          <select
                            className="form-select"
                            onChange={(e) => setConcessionId(e.target.value)}
                            name="ConcessionId"
                          >
                            <option value="">---All---</option>
                            {<ConcessionDropDown />}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            BusRoute:
                          </label>
                          <select
                            className="form-select"
                            onChange={(e) => setBusRouteId(e.target.value)}
                            name="BusRouteId"
                          >
                            <option selected value="-1">
                              ---All---
                            </option>
                            <option value="-2">---All Routes---</option>
                            {<BusrouteDropDown />}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Gender:
                          </label>
                          <select
                            name="Gender"
                            onChange={(e) => setGender(e.target.value)}
                            className="form-select"
                          >
                            <option Selected Value="All">
                              All
                            </option>
                            <option Value="1">Male</option>
                            <option Value="0">Female</option>
                            <option Value="Not Available">Not Available</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Order by:
                          </label>
                          <select
                            name="OrderBy"
                            onChange={(e) => setOrderBy(e.target.value)}
                            className="form-select"
                          >
                            <option Selected Value="Student_Name">
                              Student Name
                            </option>
                            <option Value="Registration_No">
                              Registration No
                            </option>
                            <option Value="Wing_Name">Wing Name</option>
                            <option Value="Class_Name">Class</option>
                            <option Value="Student_ID">Student ID Asc</option>
                            <option Value="Student_ID desc">
                              Student ID Desc
                            </option>
                            <option Value="Wing_Name,Class_Name,Section_Name">
                              Wing Class Section
                            </option>
                          </select>
                        </div>
                      </div>
                      {loaddata ? (
                        <div className="col-md-2 offset-5 text-end">
                          <Spinner animation="border" role="status" ></Spinner>
                        </div>
                      ) : (
                        <div className="col-md-2 offset-5">
                          <div className="form-group text-end mb-1">
                            <a
                              type="button"
                              onClick={handleSubmitForCvs}
                              className="print-icon"
                            >
                              <span>
                                <i className="ri-file-excel-2-line"></i>
                              </span>
                            </a>
                          </div>
                        </div>
                      )}
                      <div className="col-md-2 ">
                        <div className="form-group">
                          <button
                            type="submit"
                            value="Search"
                            onClick={handleSubmit}
                            className="button button-primary w-100"
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
        <div className="page-content">
          <div className="container">
            <div className="card">
              <div className="card-body">
                {/* <div className="text-end mb-2 me-3">
                                    <b>{{totalRecords}} &nbsp; Records Found!</b>

                                </div> */}
                <table className="table table-theme">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Reg No</th>
                      <th>Name</th>
                      <th>Father Name</th>
                      <th>DOB</th>
                      <th>DOJ</th>
                      <th>Mobile</th>
                      <th>Wing</th>
                      <th>Class</th>
                      <th>Section</th>
                      <th>Tags</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td className="text-center" colspan="12">
                          <Spinner animation="border" role="status"></Spinner>
                        </td>
                      </tr>
                    ) : (
                      <>{renderLedger()}</>
                    )}
                  </tbody>
                </table>
                {totalRecords > 10 && (
                  <div className="pagination-wrap">
                    <div className="row align-items-center">
                      <div className="col-md-2 form_default">
                        <div className="d-flex align-items-center">
                          <label className="me-2" style={{ fontSize: "15px" }}>
                            Page Size
                          </label>
                          <div className="form-group">
                            <select
                              className="form-select"
                              style={{ width: "100px" }}
                              onChange={(e) => {
                                setPageNo(1);
                                setPageSize(parseInt(e.target.value));
                              }}
                            >
                              <option value="10" selected>
                                10
                              </option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              <option value="500">500</option>
                              <option value="1000">1000</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <Pagination
                        pageNo={pageNo}
                        pageSize={pageSize}
                        totalRecords={totalRecords}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="container">
            <Form
              noValidate
              validated={validatedPromote}
              onSubmit={handlePromote}
            >
              <div className="card mt-2">
                <div className="card-body p-md-4 text-end">
                  <a href={`/students/AdvanceSearch/print-advance-search?SessionID=${SessionId}&Section_Id=${SectionID}&WingID=${WingId}&ClassId=${ClassId}&Registration-no=${Registration_No}&Keywords=${Keywords}&Balance=${Balance}&BalanceType=${BalanceType}&BayFormNo=${BayFormNo}&Gender=${Gender}&DateOfBirth=${DateOfBirth}&Concession_Rule_ID=${ConcessionId}&BusRouteID=${BusRouteId}&Status_Type_Id=${Status_Type_Id}&Group_Id=${GroupId}&OrderBy=${OrderBy}&Student_ID=${StudentID}`} target="_blank" className="button button-primary"> Print Above List</a>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdvanceSearch;
