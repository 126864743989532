import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import moment from "moment";
import SupportMenu from "./SupportMenu";
import { Link, NavLink, useParams } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import Pagination from "../General/Pagination";
import SupportTopMenu from "./SupportTopMenu";
function Open(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [tickets, setTickets] = useState([]);
    const [ticketType, setTicketType] = useState(null);
    const [loading, setLoading] = useState(false);


    // pagination
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);


    let params = useParams();


    useEffect(() => {

        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
            Status: "Open"
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Support/GetSupportTicket',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                if (response.data.status_code == 1) {
                    setTickets(response.data.ListofTickets);
                    setTotalRecords(response.data.totalRecords);
                    //console.log(response.data.ListofTickets)
                }

                setLoading(false);
            })
            .catch(function (error) {
                //console.log(error);
            });



    }, [pageNo, pageSize]);


    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const renderLogs = () => {
        if (tickets.length > 0) {


            let count = 1;
            return (
                tickets.map((item, index) => {

                    return (
                        <>
                            <tr key={index}>
                                <td>{item.TicketID}</td>
                                <td>{moment(item.Dated).format('DD MMM, YYYY HH:mm:ss')}</td>
                                <td>{item.TicketType}</td>
                                <td>
                                    {item.Subject}
                                </td>
                                <td>
                                    {item.Status}
                                </td>
                                <td>
                                    <NavLink to={`/support/ticket-detail/${item.ID}`} className="button-detail support-action">
                                        <span> Check</span> <i className="ri-arrow-right-s-line"></i>
                                    </NavLink>
                                </td>
                            </tr>
                        </>
                    )
                })
            )
        }

        else {
            return (
                <tr>
                    <td colspan="6"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }
    }


    return (
        <>
            <SupportTopMenu />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item"> <Link to="/support/raise-ticket">Support</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Open</li>
                                </ol>
                            </nav>
                            <div className="page-title">Open</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="card">
                        <div className="card-body table_card">
                            <SupportMenu />
                            <div className="card-table">
                                <div className="card-inner">

                                    {
                                        loading ?
                                            <div className="p-5 text-center">
                                                <Spinner animation="border" role="status"></Spinner>
                                            </div>
                                            :
                                            <table className="table table-theme">
                                                <thead>
                                                    <tr>
                                                        <th>Ticket ID</th>
                                                        <th>Date</th>
                                                        <th>Department</th>
                                                        <th>Subject</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {renderLogs()}
                                                </tbody>
                                            </table>
                                    }

                                    {totalRecords > 10 && (
                                        <div className="pagination-wrap">
                                            <div className="row align-items-center">
                                                <div className="col-md-2 form_default">
                                                    <div className="d-flex align-items-center">
                                                        <label className="me-2" style={{ fontSize: "15px" }}>
                                                            Page size
                                                        </label>
                                                        <div className="form-group">
                                                            <select
                                                                className="form-select"
                                                                style={{ width: "100px" }}
                                                                onChange={(e) => {
                                                                    setPageNo(1);
                                                                    setPageSize(parseInt(e.target.value));
                                                                }}
                                                            >
                                                                <option value="10" selected>
                                                                    10
                                                                </option>
                                                                <option value="20">20</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                                <option value="500">500</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <Pagination
                                                    pageNo={pageNo}
                                                    pageSize={pageSize}
                                                    totalRecords={totalRecords}
                                                    onPageChange={handlePageChange}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Open;