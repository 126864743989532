import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import RightsCheck from '../General/RightsCheck';
import { scrollToTop } from '../Shared/ScrollOnTop';

function InvoiceMenu(props) {

    return (
        <>
            <RightsCheck />
            <nav className={`navbar navbar-expand-lg navbar-module MenuSticky`}>
                <div className="container-fluid">
                    <div className="collapse navbar-collapse">
                        <ul className="navbar-nav m-auto">
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/invoice/manage-invoice">Manage Invoice</NavLink>
                            </li>
                            {/* <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/invoice/add-invoice">Add Invoice</NavLink>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default InvoiceMenu;