import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import QRCode from "react-qr-code";
import StudentInfo from "./StudentInfo";
import { calculateColumnTotal } from "../General/Utility";
import late from "../../assets/images/late_fee_img.jpg";
import jazzcash from "../../assets/images/jazzcash.png";


function PrintChallan(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [voucheres, setVouchers] = useState([]);
  const [SchoolName, setSchoolName] = useState("");
  const [Site, setSite] = useState("");

  const [bank, setBank] = useState("");
  const [DueDate, setDueDate] = useState("");



  const [status_type, setStatus_type] = useState(0);

  useEffect(() => {
    document.title = "Print Challan";
    setLoading(true);
    // Get the URL parameters
    const urlParams = new URLSearchParams(window.location.search);

    // Retrieve individual parameter values
    const classId = urlParams.get("Class_Id");
    const concessionRuleId = urlParams.get("ConcessionRuleID");
    const orderBy = urlParams.get("OrderBy");
    const registrationNo = urlParams.get("Registration_No");
    const sectionId = urlParams.get("Section_Id");
    const SessionId = urlParams.get("SessionID");
    const wingId = urlParams.get("Wing_Id");
    const Description = urlParams.get("Description");
    const valuesSelected = urlParams.get("valuesSelected");
    const AdvanceMonths = urlParams.get("AdvanceMonths");
    const ShowHolidayImage = urlParams.get("ShowHolidayImage");
    const noRedCresent = urlParams.get("noRedCresent");
    const Amount = urlParams.get("amount");
    const Student_ID = urlParams.get("Student_ID");
    const StudentType = urlParams.get("StudentType");
    const Status_Type_ID = urlParams.get("Status_Type_Id");
    const AddConcession = urlParams.get("AddConcession");

    setStatus_type(Status_Type_ID);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      SelectedStudents: valuesSelected,
      SessionID: SessionId,
      Class_Id: classId,
      Section_Id: sectionId,
      ConcessionRuleID: concessionRuleId,
      Registration_No: registrationNo,
      AdvanceMonths: AdvanceMonths,
      ShowHolidayImage: ShowHolidayImage,
      noRedCresent: noRedCresent,
      Description: Description,
      OrderBy: orderBy,
      Amount: Amount,
      Student_ID: Student_ID,
      StudentType: StudentType,
      AddConcession: AddConcession,
    };

    //console.log(data);
    var api_config = {
      method: "post",
      url: config.base_url + "FeeSystem/PrintVouchers",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    //console.log(data);

    axios(api_config)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.status_code == 1) {
          setVouchers(response.data.ListofRecords);
          setSchoolName(response.data.SchoolName);
          setBank(response.data.FeeVoucherStatement);
          setSite(response.data.Website);
          setDueDate(response.data.DueDate);
        }

        setLoading(false);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);


  if (loading) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner animation="border" role="status"></Spinner>
      </div>
    );
  } else if (voucheres.length > 0) {
    return voucheres.map((item, index) => {
      return (
        <>
          <div
            key={index}
            style={{
              backgroundColor: "#FFF",
              color: "#000",
              width: "100%",
              fontSize: "11px",
              //pageBreakAfter: "always"
              // border: "1px solid"
            }}
            align="right"
          //className={index === voucheres.length - 1 ? "" : "page-break-after"}
          >
            <div
              className={index === voucheres.length - 1 ? "" : "page-break-after"}
              style={{
                width: "1140px",
                height: "730px",
                //height: index === voucheres.length - 1 ? "auto" : "730px",
                lineHeight: "18px",
                //pageBreakAfter: index === voucheres.length - 1 ? "" : "always"
                //pageBreakAfter: "always",


              }}
            >
              <table
                style={{ width: "1140px" }}
                cellSpacing={0}
                cellPadding={0}
              >
                <tbody>
                  <tr>
                    <td
                      style={{ width: "340px", paddingRight: "30px" }}
                      valign="top"
                      align="left"
                    >
                      <div>


                        <StudentInfo
                          school={SchoolName}
                          studentcode={
                            item.Registration_No == ""
                              ? item.Student_ID + " (New Registration)"
                              : item.Registration_No
                          }
                          classname={item.Class_Name}
                          section={item.Section_Name}
                          name={item.Student_Name}
                          wing={item.Wing_Name}
                          father={item.Father_Name}
                          bank={bank}
                          copy="Bank Copy"
                          DueDate={DueDate}
                          fee={item.ReducedLedger}
                          status_type={status_type}
                        />





                        <div style={{ padding: "10px 0px 16px 0px" }}>
                          <table
                            style={{ width: "100%" }}
                            cellSpacing={0}
                            cellPadding={0}
                          >
                            <tbody>
                              <tr>
                                <td>NOT TO BE DEPOSITED AFTER DUE DATE</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </td>
                    <td style={{ width: "340px" }} valign="top" align="left">
                      <div>


                        <StudentInfo
                          school={SchoolName}
                          studentcode={
                            item.Registration_No == ""
                              ? item.Student_ID + " (New Registration)"
                              : item.Registration_No
                          }
                          classname={item.Class_Name}
                          section={item.Section_Name}
                          name={item.Student_Name}
                          wing={item.Wing_Name}
                          father={item.Father_Name}
                          bank={bank}
                          DueDate={DueDate}
                          copy="School Copy"
                          fee={item.ReducedLedger}
                          status_type={status_type}
                        />



                        <div style={{ padding: "10px 0px 16px 0px" }}>
                          <table
                            style={{ width: "100%" }}
                            cellSpacing={0}
                            cellPadding={0}
                          >
                            <tbody>
                              <tr>
                                <td>NOT TO BE DEPOSITED AFTER DUE DATE</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div style={{ padding: "0px 0px 20px 0px" }}>
                          <table
                            style={{ width: "100%" }}
                            cellSpacing={0}
                            cellPadding={0}
                          >
                            <tbody>
                              <tr>
                                <td
                                  style={{ height: "50px" }}
                                  valign="top"
                                  align="left"
                                >
                                  Officer
                                </td>
                                <td valign="top" align="center">
                                  <div style={{ padding: "0px 0px 0px 0px" }}>
                                    <div
                                      style={{
                                        height: "auto",
                                        margin: "2px auto 0 auto",
                                      }}
                                    >
                                      <QRCode
                                        size={25}
                                        style={{
                                          height: "auto",
                                          maxWidth: "100%",
                                          width: "100%",
                                        }}
                                        value={`${item.Registration_No == ""
                                          ? item.Student_ID
                                          : item.Registration_No
                                          } ${calculateColumnTotal(
                                            item.ReducedLedger,
                                            "Debit"
                                          )}`}
                                        //value={`${item.Registration_No} ${calculateColumnTotal(item.ReducedLedger, 'Debit')}`}
                                        viewBox={`0 0 256 256`}
                                      />
                                    </div>
                                  </div>
                                </td>
                                <td valign="top" align="right">
                                  Cashier
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </td>
                    <td
                      style={{ width: "340px", paddingLeft: "30px" }}
                      valign="top"
                      align="left"
                    >
                      <div>
                        <div>
                          <StudentInfo
                            school={SchoolName}
                            studentcode={
                              item.Registration_No == ""
                                ? item.Student_ID + " (New Registration)"
                                : item.Registration_No
                            }
                            classname={item.Class_Name}
                            section={item.Section_Name}
                            name={item.Student_Name}
                            wing={item.Wing_Name}
                            father={item.Father_Name}
                            bank={bank}
                            DueDate={DueDate}
                            showphoto={true}
                            copy="Student Copy"
                            fee={item.ReducedLedger}
                            status_type={status_type}
                          />


                          <div style={{ padding: "10px 0px 16px 0px" }}>
                            <table
                              style={{ width: "100%" }}
                              cellSpacing={0}
                              cellPadding={0}
                            >
                              <tbody>
                                <tr>
                                  <td>NOT TO BE DEPOSITED AFTER DUE DATE</td>
                                </tr>
                              </tbody>
                            </table>
                            <table
                              style={{
                                width: "100%",
                                border: "1px solid #000000",
                              }}
                              cellSpacing={2}
                              cellPadding={2}
                            >
                              <tbody>
                                <tr>
                                  <td colSpan={2} align="left">
                                    For Online Services visit{" "}
                                    <strong>{Site}</strong>
                                  </td>

                                </tr>
                                <tr>
                                  <td>Login :
                                     {item.Registration_No}
                                  </td>
                                  <td>Password :
                                    {item.Password}
                                  </td>

                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div>
                            {item.ReducedLedger.map((val) =>
                              val.Fee_Code == "033" ? (
                                <img
                                  style={{ width: "100%" }}
                                  src={late}
                                  alt="late fee"
                                />
                              ) : (
                                ""
                              )
                            )}
                          </div>
                          <div style={{ padding: "0px" }}>
                            <table
                              style={{ width: "100%" }}
                              cellSpacing={0}
                              cellPadding={0}
                            >
                              <tbody>
                                <tr align="center">

                                  <td valign="top" align="center">
                                    <div style={{ padding: "0px 0px 0px 0px" }}>
                                      <div
                                        style={{
                                          height: "auto",
                                          margin: "2px auto 0 auto",
                                        }}
                                      >
                                        <QRCode
                                          size={25}
                                          style={{
                                            height: "auto",
                                            maxWidth: "80px",
                                            width: "80px",
                                          }}
                                          value={`https://www.dpskasur.edu.pk/Home/PrintVouchers/${item.Registration_No}`}
                                          // value={`${item.Registration_No == ""
                                          //   ? item.Student_ID
                                          //   : item.Registration_No
                                          //   } ${calculateColumnTotal(
                                          //     item.ReducedLedger,
                                          //     "Debit"
                                          //   )}`}
                                          //value={`${item.Registration_No} ${calculateColumnTotal(item.ReducedLedger, 'Debit')}`}
                                          viewBox={`0 0 256 256`}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                  <td
                                    valign="top"
                                    align="left"
                                    className="pt-3"
                                  >
                                    Scan QR Code to <br /> Pay Using Mobilink <br /> Jazz Cash Account
                                  </td>
                                  <td valign="top" align="right">
                                    <img src={jazzcash} alt="" width='100px' />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div style={{ padding: "0px" }}>
                            <table
                              style={{ width: "100%" }}
                              cellSpacing={0}
                              cellPadding={0}
                            >
                              <tbody>
                                <tr>
                                  <td align="left">Officer</td>
                                  <td align="right">Cashier</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </>
      );
    });
  }
}

export default PrintChallan;
