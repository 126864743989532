import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import RightsCheck from "../General/RightsCheck";
import { scrollToTop } from "../Shared/ScrollOnTop";

function SetupMenu(props) {
  return (
    <>
      <RightsCheck />
      <nav className="navbar navbar-expand-lg navbar-module MenuSticky">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#NavbarSecondary"
            aria-controls="NavbarSecondary"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="NavbarSecondary">
            <ul className="navbar-nav m-auto">
              <li className="nav-item">
                <NavLink
                  onClick={scrollToTop}
                  className="nav-link"
                  to="/Setup/SchoolInfo"
                >
                  {" "}
                  School Info
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  onClick={scrollToTop}
                  className="nav-link"
                  to="/Setup/ManageWings"
                >
                  {" "}
                  Wings
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  onClick={scrollToTop}
                  className="nav-link"
                  to="/Setup/ManageClasses"
                >
                  {" "}
                  Classes
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  onClick={scrollToTop}
                  className="nav-link"
                  to="/Setup/ManageSection"
                >
                  {" "}
                  Sections
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  onClick={scrollToTop}
                  className="nav-link"
                  to="/Setup/ManageFeeTypes"
                >
                  {" "}
                  Fee Type
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  onClick={scrollToTop}
                  className="nav-link"
                  to="/Setup/ManageBusRoutes"
                >
                  {" "}
                  Routes
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  onClick={scrollToTop}
                  className="nav-link"
                  to="/Setup/ManageConcession"
                >
                  Concession Rules
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  onClick={scrollToTop}
                  className="nav-link"
                  to="/Setup/ManageBankAccounts"
                >
                  Banks
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  onClick={scrollToTop}
                  className="nav-link"
                  to="/Setup/ManageSettings"
                >
                  Settings
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default SetupMenu;
