import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { useParams } from "react-router-dom";
import Pagination from "../General/Pagination";
import SupportControlMenu from "./SupportControlMenu";
import SupportTicketDropDown from "../Shared/SupportTicketDropDown";

function NewTicketList(props) {
  const userstatus = useParams();
  const userstatusVal = userstatus.userstatus;

  //console.log(userstatus.userstatus);

  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );

  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [status, setStatus] = useState("Open");
  const [ticketCategory, setticketCategory] = useState("All");
  const [showFilters, setShowFilters] = useState(true);

  //   //console.log(status);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);


  let navigate = useNavigate();

  useEffect(() => {

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      pageNo: pageNo,
      pageSize: pageSize,
      Status: status,
      TicketType: ticketCategory,
      MemberType: MemberType,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "support/GetSupportTicketAdmin",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    //console.log(data, 'data')

    axios(api_config)
      .then(function (response) {
        //console.log(response.data)
        if (response.data.status_code == 1) {
          setLedger(response.data.ListofTickets);
          setLoading(false);
          setTotalRecords(response.data.totalRecords);
        } else if (
          (response.data.status_code === 0 &&
            response.data.status_message === "Invalid Access Key.") ||
          response.data.status_code === 2
        ) {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [pageNo, pageSize, isUpdated, status, userstatusVal, ticketCategory]);


  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            {/* <td>{count++ + pageSize * (pageNo - 1)}</td> */}
            <td>{item.TicketID}</td>
            <td>{item.UserName}</td>
            <td>{moment(item.Dated).format("DD MMM, YYYY HH:mm:ss")}</td>
            <td>{item.TicketType}</td>
            <td>
              {item.Subject.length > 50
                ? `${item.Subject.substring(0, 50)}...`
                : item.Subject}
            </td>
            <td>
              {item.Status}
            </td>
            <td className="text-center">
              <NavLink
                to={`/admin-support/ticket-detail/${item.ID}`}
                className="button-detail support-action"
              >
                Detail
                <span>
                  <i className="ri-arrow-right-s-line"></i>
                </span>
              </NavLink>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="10">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };


  return (
    <>
      <SupportControlMenu />
      <div className="page-head">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                  <li className="breadcrumb-item"> <Link to="/admin-support/support-dashboard">Support</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">List of Tickets</li>
                </ol>
              </nav>
              <div className="page-title">List of Tickets</div>
            </div>
            <div className="col-md-6 text-md-end text-center">
              <NavLink to="/admin-support/support-dashboard">
                <button type="button" className="button button-primary">
                  Back
                </button>
              </NavLink>
              <button
                onClick={() => {
                  setShowFilters((prevIsActive) => !prevIsActive);
                }}
                className="button button-primary ms-3"
                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
              >
                {showFilters ? "Hide Filters" : "Show Filters"}
                <i className="ri-filter-3-line ms-3"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container">
          <div
            className={`collapse ${showFilters ? "show" : ""} `}
            id="collapseFilteres"
          >
            <div className="card card-table mb-3 mt-4 ">
              <div className="card-inner  ">

                <div className="form_default">
                  <div className="row p-3">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor=""> Select Ticket Type</label>
                        <div className="inputwrap">
                          <select
                            id="dropdown"
                            className="form-select"
                            onChange={(e) => setticketCategory(e.target.value)}
                          >
                            <option value="All" selected>
                              All
                            </option>
                            <SupportTicketDropDown />
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body table_card">
              <table className="table table-theme">
                <thead>
                  <tr>
                    <th>Ticket ID</th>
                    <th>Username</th>
                    <th>Raised On</th>
                    <th>Department</th>
                    <th>Subject</th>
                    <th>Status</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td className="text-center" colSpan="7">
                        <Spinner animation="border" role="status"></Spinner>
                      </td>
                    </tr>
                  ) : (
                    <>{renderLedger()}</>
                  )}
                </tbody>
              </table>

              {totalRecords > 10 && (
                <div className="pagination-wrap">
                  <div className="row align-items-center">
                    <div className="col-md-2 form_default">
                      <div className="d-flex align-items-center">
                        <label className="me-2" style={{ fontSize: "15px" }}>
                          Page size
                        </label>
                        <div className="form-group">
                          <select
                            className="form-select"
                            style={{ width: "100px" }}
                            onChange={(e) => {
                              setPageNo(1);
                              setPageSize(parseInt(e.target.value));
                            }}
                          >
                            <option value="10" selected>
                              10
                            </option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <Pagination
                      pageNo={pageNo}
                      pageSize={pageSize}
                      totalRecords={totalRecords}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewTicketList;
