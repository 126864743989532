import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import DesignationDropDown from "../Shared/DesignationDropDown";
import EmployeeTypeDropDown from "../Shared/EmployeeTypeDropDown";
import moment from "moment";
import Pagination from "../General/Pagination";
import Dropdown from "react-bootstrap/Dropdown";
import PayScaleBasic from "../Shared/PayScaleBasic";
import { Form } from "react-bootstrap";
import SMSMenu from "./SMSMenu";
import { scrollToTop } from "../Shared/ScrollOnTop";

function SendAlertToStaff(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [showFilters, setShowFilters] = useState(true);

  const [validated, setValidated] = useState(false);

  // for filters
  const [Gender, setGender] = useState("");
  const [Designation, setDesignation] = useState(0);
  const [Department, setDepartment] = useState("");
  const [Status, setStatus] = useState("");
  const [PayScale, setPayScale] = useState("");
  const [Code, setCode] = useState("");
  const [StaffID, setStaffID] = useState(0);
  const [Keywords, setKeywords] = useState("");
  const [OrderBy, setOrderBy] = useState("");

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const [value, setValue] = useState("");

  const handleChange = (event) => {
    const newText = event.target.value;
    if (newText.length <= 300) {
      setValue(newText);
    }
  };

  const textLength = value.length;

  useEffect(() => {
    document.title = "Send Alert To Staff";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: pageSize,
      SchoolID: 1,
      IS_Resigned: Status,
      Code: Code,
      DesignationID: Designation,
      EmployeeTypeID: StaffID,
      Keywords: Keywords,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Payroll/SearchEmployees",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    //console.log(data);

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [
    pageNo,
    pageSize,
    isUpdated,
    Gender,
    Designation,
    Department,
    Status,
    PayScale,
    Code,
    StaffID,
    Keywords,
    OrderBy,
  ]);

  const handleSubmit = (event) => {
    //console.log(event);
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoading(true);
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        pageNo: pageNo,
        pageSize: pageSize,
        SchoolID: 1,
        IS_Resigned: Status,
        Code: Code,
        DesignationID: Designation,
        EmployeeTypeID: StaffID,
        Keywords: Keywords,
      };

      //console.log(data);

      var api_config = {
        method: "post",
        url: config.base_url + "Payroll/SearchEmployees",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          setLedger(response.data.ListofRecords);
          setLoading(false);
          setTotalRecords(response.data.totalRecords);
        })
        .catch(function (error) {
          //console.log(error);
        });
    }

    setValidated(true);
  };
  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const renderLedger = () => {
    if (listOfLedger.length != null && listOfLedger.length > 0) {
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.ID}</td>
            <td>{item.Name}</td>
            <td>{item.Father_Name}</td>
            <td>{moment(item.Date_Of_Birth).format("DD/MM/YYYY")}</td>
            <td>{moment(item.Date_Of_Joining).format("DD/MM/YYYY")}</td>
            <td>{item.Mobile_No}</td>
            <td>{item.Selected_Designation.Name}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="10">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };
  const deleteEmployee = (ID) => {
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      ID: ID,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Payroll/DeleteEmployees",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          setIsUpdated(true);
        }
        // setIsUpdated(false);
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  const handlePrintPayslips = () => {
    const inputsEmployees = document.querySelectorAll("input.checkEmployees");
    const valuesSelected = [];
    inputsEmployees.forEach((input) => {
      if (input.checked == true) {
        const value = parseInt(input.value);
        if (value > 0) {
          valuesSelected.push(value);
        }
      }
    });

    const queryParam = valuesSelected.join(",");
    //history.push(`/other-page?ids=${queryParam}`);

    let url = "/PayRoll/PaySlip/?ids=" + queryParam;
    window.open(url, "_blank");

    //console.log(valuesSelected);
  };

  return (
    <>
      <SMSMenu />
      <ToastContainer />
      <div className="page-head">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/Dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/SMSAlert/SMSAlertDashboard">SMS Alert</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Send Alert To Staff
                  </li>
                </ol>
              </nav>
              <div className="page-title">Send Alert To Staff</div>
            </div>
            <div className="col-md-6">
            <div className="page-right-actions">
                  <button
                    onClick={() => {
                      setShowFilters((prevIsActive) => !prevIsActive);
                    }}
                    className="button button-white button-icon"
                  >
                    {showFilters ? "Hide Filters" : "Show Filters"}
                    <i className="ri-filter-3-line"></i>
                  </button>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`collapse ${showFilters ? "show" : ""} `}
        id="collapseFilteres"
      >
        <div className="collapse show" id="collapseFilteres">
          <div className="page-content">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className="container">
                <div className="card mb-2">
                  <div className="card-body p-md-4">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label>Gender</label>
                          <select
                            className="form-select"
                            onChange={(e) => setGender(e.target.value)}
                            value={Gender}
                          >
                            <option Value="">--All--</option>
                            <option Selected Value="1">
                              Male
                            </option>
                            <option Value="0">Female</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label>Designation</label>
                          <select
                            className="form-select"
                            onChange={(e) => setDesignation(e.target.value)}
                            value={Designation}
                          >
                            <option value="">Please Select</option>
                            <DesignationDropDown />
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label>Department</label>
                          <select
                            onChange={(e) => setDepartment(e.target.value)}
                            value={Department}
                            className="form-select"
                          >
                            <option value="">Please Select</option>
                            <EmployeeTypeDropDown />
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label>Status</label>
                          <select
                            className="form-select"
                            onChange={(e) => setStatus(e.target.value)}
                            value={Status}
                          >
                            <option Value="">--All--</option>
                            <option Value="false">Current</option>
                            <option Value="true">Retired</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label>Basic Pay Scale</label>
                          <select
                            onChange={(e) => setPayScale(e.target.value)}
                            value={PayScale}
                            className="form-select"
                          >
                            <option Value="">--All--</option>
                            <PayScaleBasic />
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label>Staff Code</label>
                          <input
                            type="text"
                            onChange={(e) => setCode(e.target.value)}
                            value={Code}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label>Staff ID</label>
                          <input
                            type="text"
                            onChange={(e) => setStaffID(e.target.value)}
                            value={StaffID}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label>Keywords</label>
                          <input
                            type="text"
                            onChange={(e) => setKeywords(e.target.value)}
                            value={Keywords}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label>Order by</label>
                          <select
                            className="form-select"
                            onChange={(e) => setOrderBy(e.target.value)}
                            value={OrderBy}
                          >
                            <option Selected Value="Name">
                              Alphabetic
                            </option>
                            <option Value="Date_Of_Joining ASC">DOJ ASC</option>
                            <option Value="Date_Of_Joining DESC">
                              DOJ DESC
                            </option>
                            <option Value="Code Asc">Code Asc</option>
                            <option Value="Code Desc">Code Desc</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3 offset-md-6">
                        <div className="form-group text-end">
                          <button
                            type="submit"
                            value="Search"
                            className="button button-primary mt-4"
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container">
          <div className="card">
            <div className="card-body">
              <table className="table table-theme">
                <thead>
                  <tr>
                    <th className="text-end" colSpan={7}>
                      {totalRecords} Records Found!
                    </th>
                  </tr>
                  <tr>
                    <th>ID</th>
                    <th>Employee Name</th>
                    <th>Father Name</th>
                    <th>DOB</th>
                    <th>DOJ</th>
                    <th>Mobile No.</th>
                    <th>Designation</th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr>
                                        <th colspan="8">
                                            TotalRecords &nbsp; Records Found!
                                        </th>
                                    </tr> */}
                  {loading ? (
                    <tr>
                      <td className="text-center" colspan="10">
                        <Spinner animation="border" role="status"></Spinner>
                      </td>
                    </tr>
                  ) : (
                    <>{renderLedger()}</>
                  )}
                </tbody>
              </table>
              {totalRecords > 10 && (
                <div className="pagination-wrap">
                  <div className="row align-items-center">
                    <div className="col-md-2 form_default">
                      <div className="d-flex align-items-center">
                        <label className="me-2" style={{ fontSize: "15px" }}>
                          Page Size
                        </label>
                        <div className="form-group">
                          <select
                            className="form-select"
                            style={{ width: "100px" }}
                            onChange={(e) => {
                              setPageNo(1);
                              setPageSize(parseInt(e.target.value));
                            }}
                          >
                            <option value="10" selected>
                              10
                            </option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <Pagination
                      pageNo={pageNo}
                      pageSize={pageSize}
                      totalRecords={totalRecords}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="card mt-2">
            <div className="card-body">
              <div className="col-md-12">
                <div className="form-group mb-3">
                  <label>SMS Text: {textLength}/300</label>
                  <textarea
                    rows={3}
                    name=""
                    className="form-control"
                    value={value}
                    onChange={handleChange}
                  />
                  <label className="mt-2">SMS Charges will be applied</label>
                  <div className="form-group text-end">
                    {loading ? (
                      <Spinner animation="border" role="status"></Spinner>
                    ) : (
                      <button
                        type="submit"
                        value="Send SMS"
                        className="button button-primary"
                      >
                        Send SMS
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SendAlertToStaff;
