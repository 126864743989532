import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';

import { Link } from "react-router-dom";
import Examination_navbar from './Examination_navbar';
import { scrollToTop } from '../Shared/ScrollOnTop';
import ExamSummaryIcon from '../../assets/images/dashboard_icon/certificate_issued.svg';
import Spinner from 'react-bootstrap/Spinner';

import ExamSummaryDone from '../../assets/images/done.png';
import moment from "moment";




const ExaminationDashboard = () => {


    const [Exam, setExam] = useState({});
    const [loading, setLoading] = useState(false);
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [ExamClasses, setExamClasses] = useState([]);
    const [ListOfSections, setListOfSections] = useState([]);
    const [ListOfSubjects, setListOfSubjects] = useState([]);

    const [today, setToday] = useState(moment().format("YYYY-MM-DD"));

    const [ClassId, setClassId] = useState(0);
    const [LoadingSection, setLoadingSection] = useState(false);
    const [SectionID, setSectionID] = useState(0);
    const [subjects, setSubjects] = useState([]);
    const [IsSubjectAdded, setIsSubjectAdded] = useState(false);
    const [loadingSubjects, setloadingSubjects] = useState(false);
    const [ExamID, setExamID] = useState(0);
    const [ExamIDOld, setExamIDOld] = useState(0);

    useEffect(() => {
        document.title = "Examination Dashboard";
        setLoading(true);

        const fetchData = async () => {
            try {
                const response = await axios.post(config.base_url + 'Exam/GetLatestExam', {
                    AccessKey: AccessKey,
                    UserID: UserID,
                });

                if (response.data.status_code === 1) {
                    setExamID(response.data.ID);
                    setExamIDOld(response.data.ID);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (ExamID !== 0) {
            document.title = "Examination Dashboard";
            setLoading(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                ExamID: ExamID
            };

            axios.post(config.base_url + 'Exam/GetExamDashboard', data)
                .then(function (response) {
                    if (response.data.status_code === 1) {
                        setExam(response.data);
                        setExamClasses(response.data.ListofClasses);
                    }
                    setLoading(false);
                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });
        }
    }, [ExamID, ClassId, SectionID]);

    const handleClassClick = (NewClassId) => {

        setClassId(NewClassId)
        setLoadingSection(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            Class_Id: ClassId,

        }

        //console.log(data)

        var api_config = {
            method: 'post',
            url: config.base_url + 'Sections/GetAllSections',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                //console.log("ListOfSections", response.data)
                if (response.data.status_code == 1) {
                    setListOfSections(response.data.ListofRecords);
                    setLoadingSection(false);
                }
                else {
                    setLoadingSection(false);

                }

            })
            .catch(function (error) {
                //console.log(error);
            });

    };

    const handleSectionClick = (SectionID) => {

        setSectionID(SectionID)
        setloadingSubjects(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            Section_Id: SectionID,
            ExamID: ExamID
        };

        //console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Sections/GetSectionSubjects',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code != 0) {
                    setSubjects(response.data.ListofRecords);
                }
                setloadingSubjects(false);

            })
            .catch(function (error) {
                //console.log(error);
            });

    };




    const TotalMarks = () => {
        let total = 0;
        subjects.forEach(item => {
            total += item.Total_Marks;

        });
        return <span>{total}</span>

    }

    const PassMarks = () => {
        let total = 0;
        subjects.forEach(item => {
            total += item.Pass_Marks;

        });
        return <span>{total}</span>

    }


    const goToPreviousExam = () => {
        const Previous = (ExamID - 1);
        setExamID(Previous)

    };
    const goToNextExam = () => {
        const Next = (ExamID + 1);
        setExamID(Next)
    };




    return (
        <>
            <Examination_navbar />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Examination</li>
                                </ol>
                            </nav>
                            <div className="page-title">Examination</div>
                        </div>
                        <div className="col-md-6">
                            <div className="page-right-actions">
                                <Link to="/ExaminationSystem/ManageExaminations" className="button button-white button-icon">
                                    Manage Examinations
                                    <i className="ri-search-line"></i>
                                </Link>
                                <Link to="/ExaminationSystem/AddExaminations" className="button button-white button-icon">
                                    Add Exam
                                    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 10.999L11 11C12.0538 11 12.9181 11.8155 12.9945 12.8507L13 13V14.5C12.999 18 9.284 19 6.5 19C3.77787 19 0.164695 18.044 0.00545406 14.7296L0 14.5V12.999C0 11.9452 0.816397 11.0809 1.85081 11.0045L2 10.999ZM13.22 11H18C19.0538 11 19.9181 11.8164 19.9945 12.8508L20 13V14C19.999 17.062 17.142 18 15 18C14.32 18 13.569 17.904 12.86 17.678C13.196 17.292 13.467 16.851 13.662 16.351C14.205 16.476 14.715 16.5 15 16.5L15.2665 16.494C16.2518 16.4509 18.3529 16.1306 18.4927 14.205L18.5 14V13C18.5 12.7547 18.3222 12.5504 18.0896 12.5081L18 12.5H13.949C13.865 11.9986 13.6554 11.5432 13.3545 11.1598L13.22 11H18H13.22ZM2 12.499L1.89934 12.509C1.77496 12.5343 1.69 12.6018 1.646 12.645C1.6028 12.689 1.53528 12.7733 1.51 12.898L1.5 12.999V14.5C1.5 15.509 1.95 16.222 2.917 16.742C3.74315 17.1869 4.91951 17.4563 6.18258 17.4951L6.5 17.5L6.8174 17.4951C8.08035 17.4563 9.25592 17.1869 10.083 16.742C10.9886 16.2545 11.4416 15.5974 11.4947 14.6849L11.5 14.499V13C11.5 12.7547 11.3222 12.5504 11.0896 12.5081L11 12.5L2 12.499ZM6.5 0C8.985 0 11 2.015 11 4.5C11 6.985 8.985 9 6.5 9C4.015 9 2 6.985 2 4.5C2 2.015 4.015 0 6.5 0ZM15.5 2C17.433 2 19 3.567 19 5.5C19 7.433 17.433 9 15.5 9C13.567 9 12 7.433 12 5.5C12 3.567 13.567 2 15.5 2ZM6.5 1.5C4.846 1.5 3.5 2.846 3.5 4.5C3.5 6.154 4.846 7.5 6.5 7.5C8.154 7.5 9.5 6.154 9.5 4.5C9.5 2.846 8.154 1.5 6.5 1.5ZM15.5 3.5C14.397 3.5 13.5 4.397 13.5 5.5C13.5 6.603 14.397 7.5 15.5 7.5C16.603 7.5 17.5 6.603 17.5 5.5C17.5 4.397 16.603 3.5 15.5 3.5Z" fill="#003970"></path>
                                    </svg>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="card">
                        <div className="card-body exam-summary">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4 className="card-title" style={{ color: "black" }}>Summary</h4>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="icon text-right">
                                            <button
                                                onClick={goToPreviousExam}
                                            ><i className="ri-arrow-left-s-line"></i></button>
                                            <button 
                                                onClick={goToNextExam}
                                               disabled={ExamID == ExamIDOld ? true : false}
                                            ><i className="ri-arrow-right-s-line"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="exam-summary-card">
                                    <div className="row">
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="exam-summary-tittle">
                                                    <div className="icon">
                                                        <img src={ExamSummaryIcon} alt="" />
                                                    </div>
                                                    <div className='exam-summary-content'>
                                                        <p>Exam Name</p>
                                                        <h3>{Exam.ExamBE?.Name}</h3>
                                                        <span>{Exam.ExamBE?.Print_Title}</span>
                                                    </div>
                                                </div>
                                                <ul className='exam-summary-info'>
                                                    <li>
                                                        <i className="ri-calendar-line"></i>
                                                        Start Date: <span>{moment(Exam.ExamBE?.Created_Date).format("DD/MM/YYYY")}</span></li>
                                                    <li>
                                                        <i className="ri-calendar-line"></i>
                                                        End  Date: <span>{moment(Exam.ExamBE?.End_Date).format("DD/MM/YYYY")}</span></li>


                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-md-4 ">
                                            <div className="admission-status">
                                                <p>Current Status:</p>
                                                <h1>{Exam.ExamBE?.Current_Status}</h1>
                                                <img src={ExamSummaryDone} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4 ">
                        <div className="col-md-4">
                            <div className="card Exam-body">
                                <div className="card-body">
                                    <h4 className="card-title exam-heading" >Classes</h4>
                                    <div className="card  border-0">
                                        <div className="card-body p-0">
                                            <table className="table table-theme">
                                                <thead className="bg-light">
                                                    <tr>
                                                        <th>Sr.No</th>
                                                        <th>CN</th>
                                                        <th>Wing</th>
                                                        <th>TS</th>
                                                        <th className='text-center'>VS</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        ExamClasses.map((item, index) => (
                                                            <tr key={index}
                                                                style={{ backgroundColor: ClassId == item.Class_Id ? "rgba(0, 149, 223, 0.1)" : "" }}>

                                                                <td>{index + 1}</td>
                                                                <td>{item.Class_Name}</td>
                                                                <td>{item.Wing_Name}</td>
                                                                <td>{item.Class_Short_Name}</td>
                                                                <td className="text-center">
                                                                    <ul className="inline-action">
                                                                        <li>
                                                                            <button className=" green"
                                                                                onClick={(e) => handleClassClick(item.
                                                                                    Class_Id)}><i className="ri-eye-line" ></i></button>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card Exam-body">
                                <div className="card-body">
                                    <h4 className="card-title exam-heading" >Sections</h4>
                                    <ul className="list-info-boxes">
                                        {
                                            LoadingSection ?
                                                <Spinner animation="border" role="status"></Spinner>

                                                :
                                                <>
                                                    {
                                                        ListOfSections.map((item, index) => (
                                                            <>

                                                                <li className="w-100 align-items-center"

                                                                    style={{
                                                                        backgroundColor: SectionID == item.Section_ID ? "rgba(0, 149, 223, 0.1)" : "",
                                                                        cursor: "pointer",
                                                                        padding: "6px"
                                                                    }}
                                                                    onClick={(e) => handleSectionClick(item.Section_ID)} >
                                                                    <div className="icon" style={{ width: "15%" }}>
                                                                        <a href="">{index + 1}</a>
                                                                    </div>
                                                                    <div className="left" style={{ width: "70%" }}>
                                                                        <div className="exam-section-list">

                                                                            <h3>{item.Section_Name}</h3>
                                                                            <p>{item.Section_Teacher_Name}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="right d-flex align-items-center" style={{ width: "15%" }}>
                                                                        <span> {item.Section_ID} </span>
                                                                    </div>
                                                                </li>
                                                            </>
                                                        ))
                                                    }
                                                </>
                                        }


                                    </ul>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card Exam-body">
                                <div className="card-body">
                                    <h4 className="card-title exam-heading">Subjects</h4>
                                    <ul className="list-info-boxes">
                                        {
                                            loadingSubjects ?
                                                <Spinner animation="border" role="status"></Spinner>
                                                :
                                                <>
                                                    {
                                                        subjects.map((item, index) => (
                                                            <li className="w-100 align-items-center">
                                                                <div className="icon" style={{ width: "15%" }}>
                                                                    <a href="">{index + 1}</a>
                                                                </div>
                                                                <div className="left" style={{ width: "70%" }}>
                                                                    <div className="exam-section-list">
                                                                        <h3>{item.SubjectName}</h3>
                                                                        <p>{item.Name}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="right d-flex align-items-center" style={{ width: "15%" }}>
                                                                    <span> {item.Pass_Marks} </span>
                                                                    <span>/</span>
                                                                    <span> {item.Total_Marks} </span>

                                                                </div>
                                                            </li>


                                                        ))
                                                    }
                                                    <tr className="float-end">
                                                        <th >Total:</th>
                                                        <th>{PassMarks()}</th>
                                                        <td>/</td>
                                                        <td></td>
                                                        <th>{TotalMarks()}</th>
                                                    </tr>
                                                </>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default ExaminationDashboard;
