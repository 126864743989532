import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import moment from "moment";
import SupportMenu from "./SupportMenu";
import Logo from "../../assets/images/logo/support_logo.png";
import { Link, NavLink, useParams } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import SupportTopMenu from "./SupportTopMenu";

function Inbox(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [tickets, setTickets] = useState([]);
    const [ticketType, setTicketType] = useState(null);
    const [loading, setLoading] = useState(false);
    let params = useParams();


    useEffect(() => {

        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            Status: "Pending",

        };

        var api_config = {
            method: 'post',
            url: config.base_url + '/Support/GetTicketTypesByStatusMemberID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                if (response.data.status_code == 1) {
                    setTickets(response.data.ListofTickets);
                }

                setLoading(false);
            })
            .catch(function (error) {
                //console.log(error);
            });



    }, []);


    const getFirstChar = (str) => {
        const firstChars = str
            .split(" ")
            .map((word) => word[0])
            .join("");
        return firstChars;
    };


    const renderLogs = () => {
        if (tickets != null) {

            let type = params.type;

            if (type == "awaiting") {
                type = "Awaiting Reply";
            }

            let newtickets = tickets.filter(ticket => ticket.Status == type);


            let count = 1;
            return (
                newtickets.map((item, index) => {

                    return (
                        <li key={index}>
                            <NavLink to={`/support/inbox-counter/${item.ID}`} className="inbox-menu">
                                <span>{getFirstChar(item.TicketType)}</span>
                                <p className="mb-0">{item.TicketType} <span>({item.NoofTickets})</span></p>
                            </NavLink>
                        </li>
                    )
                })
            )
        }
    }


    return (
        <>
            <SupportTopMenu />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item"> <Link to="/support/raise-ticket">Support</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Inbox</li>
                                </ol>
                            </nav>
                            <div className="page-title">Inbox</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="card">
                    <div className="card-body p-md-4">
                        <SupportMenu />
                        <div className="new-ticket-wrap form_default">
                            <ul className="">
                                {
                                    loading ?
                                        <>
                                            <Spinner animation="border" role="status"></Spinner>
                                        </>
                                        :
                                        <>
                                            {renderLogs()}
                                        </>
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Inbox;