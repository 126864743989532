import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { toast, ToastContainer } from "react-toastify";
import { NavLink } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import StudentPanelMenu from "./StudentPanelMenu";
import StudentRightsCheck from "./StudentRightsCheck";

function StudentPanelLedger(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [SessionID, setSessionID] = useState(
    localStorage.getItem("DefaultSession")
  );
  const grp_id = localStorage.getItem("User_Group_ID");
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );

  const [currentYear, setCurrentYear] = useState("");
  const [currentMonth, setCurrentMonth] = useState("");

  const [loading, setLoading] = useState(false);
  const [loadings, setLoadings] = useState(false);

  const [listOfLedger, setLedger] = useState([]);
  const [show, setShow] = useState(false);
  const [showForAttachDues, setShowForAttachDues] = useState(false);
  const [FeeTypes, setFeeTypes] = useState([]);

  const [isUpdated, setIsUpdated] = useState(false);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(1000);
  const [totalRecords, setTotalRecords] = useState(0);


  let params = useParams();

  const [StartDate, setStartDate] = useState("2023-01-01");
  const [EndDate, setEndDate] = useState("2030-06-01");

  let totalDebit = 0;
  let totalCredit = 0;
  let navigate = useNavigate();

  useEffect(() => {
    document.title = "View Student Ledger";
    ;
    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: pageSize,
      Student_ID: grp_id,
      StartDate: StartDate,
      EndDate: EndDate,
    };
   //console.log(data, 'data');
    var api_config = {
      method: "post",
      url: config.base_url + "Student/GetStudentLedger",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
       //console.log("GetStudentLedger", response.data);
        if (response.data.status_code === 1) {
          setLedger(response.data.ListofRecords);
          setLoading(false);
          setLoadings(false);
          setTotalRecords(response.data.totalRecords);
        } else {
          setLedger(null);
          setTotalRecords(0);
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
          setLoadings(false);
        }
      })

      .catch(function (error) {
        //console.log(error);
      });
  }, [pageNo, pageSize, isUpdated]);




  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      return listOfLedger.map((item, index) => {
        totalDebit += item.Debit;
        totalCredit += item.Credit;

        return (
          <>
            <tr key={index}>
              <td>{item.Transaction_ID}</td>
              <td>{moment(item.Transaction_Date).format("DD/MM/YYYY")}</td>
              <td>{item.Fee_Code}</td>
              <td>{item.Receipt_No}</td>
              <td>{item.Description}</td>
              <td>{item.Debit}</td>
              <td>{item.Credit}</td>
            </tr>
          </>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="8">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };


  return (
    <>
      <StudentPanelMenu />

      <ToastContainer />
      <StudentRightsCheck/>

      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/student-panel/dashboard">Dashboard</NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Student Ledger
                    </li>
                  </ol>
                </nav>
                <div className="page-title">Student Ledger</div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-content mt-3">
          <div className="container">
            <div className="card">
              <div className="card-body">
                <table className="table table-theme">
                  <thead>
                    <tr>
                      <th>Transaction ID</th>
                      <th>DATED</th>
                      <th>FEE CODE</th>
                      <th>RECEIPT NO.</th>
                      <th>DESCRIPTION</th>
                      <th>DEBIT</th>
                      <th>CREDIT</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td className="text-center" colSpan="8">
                          <Spinner animation="border" role="status"></Spinner>
                        </td>
                      </tr>
                    ) : (
                      <>{renderLedger()}</>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StudentPanelLedger;
