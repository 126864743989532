import React from 'react';

function ReportStamps(props) {
    return (
        <>
            <div style={{ width: '100%', fontSize: "12px", marginTop: '50px', textAlign: 'right', fontWeight: 'bold' }}>
                <table style={{ width: '100%' }} cellSpacing={2} cellPadding={2}>
                    <tbody><tr>
                        <td style={{ width: '33%', height: '100px' }} valign="top" align="left">
                            Prepared By
                        </td>
                        <td style={{ width: '34%' }} valign="top" align="center">
                            Bursar
                        </td>
                        <td style={{ width: '33%' }} valign="top" align="right">
                            Head of Wing/Deptt
                        </td>
                    </tr>
                        <tr>
                            <td style={{ width: '33%' }} align="left">
                                Admin Officer</td>
                            <td style={{ width: '34%' }} align="center">
                                &nbsp;</td>
                            <td style={{ width: '33%' }} align="right">
                                Principal</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default ReportStamps;