import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import PrintHeader from "../General/PrintHeader";
import Page from "../General/Page";
import { calculateColumnTotal, getMonthName } from "../General/Utility";
import { scrollToTop } from "../Shared/ScrollOnTop";

const AccountHeadwiseReportPrint = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [listOfLedger, setLedger] = useState([]);
  const [ReportYear, setReportYear] = useState("");
  const [ReportMonth, setReportMonth] = useState("");
  const [totalinScroll, settotalinScroll] = useState(0);
  const [totalamount, settotalamount] = useState(0);

  document.title = "Print Account Headwise Report";

  let totalReceipts = 0;

  useEffect(() => {
    setLoading(true);
    // Get the URL parameters
    const urlParams = new URLSearchParams(window.location.search);

    // Retrieve individual parameter values
    const ReportYear = urlParams.get("ReportYear");
    const ReportMonth = urlParams.get("ReportMonth");
    setReportMonth(ReportMonth);
    setReportYear(ReportYear);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      ReportMonth: ReportMonth,
      ReportYear: ReportYear,
      pageSize: 10000,
    };

    //console.log(data);
    var api_config = {
      method: "post",
      url: config.base_url + "Reports/GetAccountHeadWiseReport",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    //console.log(data);

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.Listofrecords);
        settotalamount(response.data.totalamount);
        settotalinScroll(response.data.totalinScroll);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.Serial_No}</td>
            <td>{item.Fee_Type_Code}</td>
            <td>{item.Fee_Type_Name}</td>
            <td>{item.TotalStudents}</td>
            <td>{item.TotalAmountDeposit}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="5">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <Page orientation="potrait" size="a4">
        <PrintHeader
          title={`Account Headwise Report for ${ReportMonth} ${ReportYear} `}
        />
        <table className="table table-sm table-bordered table-print">
          <thead>
            <tr>
              <th>Serial No</th>
              <th>Fee Code</th>
              <th>Fee Type</th>
              <th>No of Students</th>
              <th>Total Amount Deposit</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colspan="5">
                  <Spinner animation="border" role="status"></Spinner>
                </td>
              </tr>
            ) : (
              <>
                {renderLedger()}
                <tr>
                  <td colspan={3} className="text-end">
                    Total:
                  </td>
                  <td>{calculateColumnTotal(listOfLedger, "TotalStudents")}</td>

                  <td>
                    {calculateColumnTotal(listOfLedger, "TotalAmountDeposit")}
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </Page>
    </>
  );
};

export default AccountHeadwiseReportPrint;
