import React, { useState, useEffect } from 'react';
import config from "../../Config";
import axios from "axios";



function UserGroupDropDown(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [SecurityGroups, setGroups] = useState([]);


    useEffect(() => {

        const dashboardParms = {
           AccessKey: AccessKey,
            UserID: UserID,
MemberType: MemberType,
            PageNo:1,
	        PageSize:100
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Members/GetSecurityGroups',
            headers: {
                'Content-Type': 'application/json'
            },
            data: dashboardParms
        };

        axios(api_config)
            .then(function (response) {
              
                setGroups(response.data.ListofRecords);
               
            })
            .catch(function (error) {
                //console.log(error);
            });

    }, []);



    return (
        SecurityGroups.map((item, index) => {
            return (
                <option value={item.ID} key={index} selected={item.ID === props.selected}>{item.GroupName}</option>
            )
        })
        // <option value="ALI">Ali</option>
    );
}

export default UserGroupDropDown;