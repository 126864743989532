import React from 'react';
import { Link } from "react-router-dom";
import Examination_navbar from './Examination_navbar';
import { scrollToTop } from '../Shared/ScrollOnTop';

const ManageExamClasses = () => {
    return (
        <>
        <Examination_navbar/>
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="/ExaminationSystem/ExaminationDashboard">Examinations</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Manage Exam Classes</li>
                                </ol>
                            </nav>
                            <div className="page-title">Manage Exam Classes</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ManageExamClasses;
