import React from 'react';
import SMSMenu from './SMSMenu';
import { Link } from "react-router-dom";
import { scrollToTop } from '../Shared/ScrollOnTop';
const SMSAlertDashboard = () => {

    document.title='SMS Alert'

    return (
        <>
            <SMSMenu />
            <div className="container body-content">
                <div className="page-head">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">SMS Alert</li>
                                    </ol>
                                </nav>
                                <div className="page-title">SMS Alert</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Summary</h4>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="icon-box-1">
                                            <p>Total limit available</p>
                                            <h4>Rs. 0 | 0 SMS </h4>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="icon-box-1">
                                            <p>Total SMS Sent This Month</p>
                                            <h4>0 SMS </h4>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="icon-box-1">
                                            <p>Total SMS Sent This Week</p>
                                            <h4>0 SMS </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="row">
                    <div className="col-md-12">
                        <div className="card mt-2">
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-md-12 ">
                                        <h5 className="text-center rounded p-2">Send Messages</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-12">
                        <div className="card chat-style">
                            <div className="card-height scrollbar">
                                <div className="row">
                                    <div className="col-md-7 offset-md-5">
                                        <div className="sms-text">
                                            <label className="label-value">
                                                sunt aut facere repellat provident occaecati excepturi optio reprehenderit
                                                quia et suscipit suscipit recusandae consequuntur expedita et cum reprehenderit
                                                molestiae ut ut quas totam nostrum rerum est autem sunt rem eveniet architecto
                                            </label>
                                            <div className="time">
                                                <span>Today at 9:20 PM</span>
                                                <i className="ri-check-double-fill"></i>
                                            </div>
                                        </div>
                                        <div className="sms-text ">
                                            <label className="label-value gray">
                                                sunt aut facere trum rerum est autem sunt rem eveniet architecto
                                            </label>
                                            <div className="time">
                                                <span>Today at 9:20 PM</span>
                                                <i className="ri-check-double-fill"></i>
                                            </div>
                                        </div>
                                        <div className="sms-recode mb-2">
                                            <p>Today</p>
                                        </div>
                                        <div className="sms-text">
                                            <label className="label-value">
                                                sunt aut facere repellat provident occaecati excepturi optio reprehenderit
                                                quia et suscipit suscipit recusandae consequuntur expedita et cum reprehenderit
                                                molestiae ut ut quas totam nostrum rerum est autem sunt rem eveniet architecto
                                            </label>
                                            <div className="time">
                                                <span>Today at 9:20 PM</span>
                                                <i className="ri-check-double-fill"></i>
                                            </div>
                                        </div>
                                        <div className="sms-text ">
                                            <label className="label-value gray">
                                                sunt aut facere trum rerum est autem sunt rem eveniet architecto
                                            </label>
                                            <div className="time">
                                                <span>Today at 9:20 PM</span>
                                                <i className="ri-check-double-fill"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-md-3">
                                <input className="send-input" type="text" placeholder="Type In Your Massage......" aria-label="" />
                                <button className="button button-icon sent-button">
                                    send
                                    <i className="ri-send-plane-2-fill"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div> */}



            </div>

        </>
    );
}

export default SMSAlertDashboard;