import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import moment, { months } from "moment";
import PrintHeader from "../General/PrintHeader";
import Page from "../General/Page";
import { calculateColumnTotal, getMonthName } from "../General/Utility";

import imgLogo from "../../assets/images/logo.png";

import "../../assets/css/result-card.css";

const PrintResultCards = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [listOfLedger, setLedger] = useState([]);

  useEffect(() => {
    document.title = "Print Exam Data Entry Options";

    setLoading(true);
    // Get the URL parameters
    const urlParams = new URLSearchParams(window.location.search);

    // Retrieve individual parameter values
    const FirstExamID = urlParams.get("FirstExamID");
    const SecondExamID = urlParams.get("SecondExamID");
    const ThirdExamID = urlParams.get("ThirdExamID");
    const FourthExamID = urlParams.get("FourthExamID");
    const Wing_Id = urlParams.get("WingId");
    const Class_Id = urlParams.get("ClassId");
    const Section_Id = urlParams.get("SectionId");

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      FirstExamID: FirstExamID,
      SecondExamID: SecondExamID,
      ThirdExamID: ThirdExamID,
      FourthExamID: FourthExamID,
      Wing_Id: Wing_Id,
      Class_Id: Class_Id,
      Section_Id: Section_Id,
    };

    //console.log(data);
    var api_config = {
      method: "post",
      url: config.base_url + "Exam/GetResultCard",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    //console.log(api_config);

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofStudents);
        setLoading(false);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  return (
    <>
      <Page orientation="portrait" size="a4">
        {loading ? (
          <Spinner animation="border" role="status"></Spinner>
        ) : (
          listOfLedger &&
          listOfLedger.map((item, index) => (
            <div className="subpage" key={index}>
              <div className="school-head">
                <table>
                  <tbody>
                    <tr>
                      <td style={{ width: "20%" }}></td>
                      <td style={{ width: "80%" }}>
                        <h2
                          style={{
                            fontFamily: "Haettenschweiler",
                            fontSize: "xx-large",
                          }}
                        >
                          DISTRICT PUBLIC SCHOOL AND COLLEGE, KASUR
                        </h2>
                        <h4>
                          PROVISIONAL RESULT INTIMATION
                          <br />
                          {item.Father_CNIC}
                        </h4>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="student-info-header">
                <table>
                  <tbody>
                    <tr>
                      <td style={{ width: "85%" }}>
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <div className="option">
                                  <span className="label">Name:</span>
                                  <span className="value">
                                    {item.Student_Name}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className="option">
                                  <span className="label">Father’s Name:</span>
                                  <span className="value">
                                    {item.Father_Name}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className="option">
                                  <span className="label">Result:</span>
                                  <span className="value"></span>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <div className="option">
                                  <span className="label">
                                    Class / Section:{" "}
                                  </span>
                                  <span className="value">
                                    {item.Class_Name}/{item.Section_Name}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className="option">
                                  <span className="label">Sr. No:</span>
                                  <span className="value">
                                    {item.Serial_No}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className="option">
                                  <span className="label">Reg. No.</span>
                                  <span className="value">
                                    {item.Registration_No}
                                  </span>
                                </div>
                              </td>
                              <td style={{ width: "150px" }}>
                                <div className="option">
                                  <span className="label">Date:</span>
                                  <span className="value">14 Jan 2024</span>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <div className="option">
                                  <span className="label">Address:</span>
                                  <span className="value">
                                    {item.Permanent_Address}
                                  </span>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td
                        style={{
                          width: "15%",
                          verticalAlign: "top",
                          textAlign: "right",
                        }}
                      >
                        <img
                          src="/student_images/018685-00.jpg"
                          alt=""
                          style={{ width: "80px" }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="result-card-body">
                <table>
                  <tbody>
                    <tr>
                      <td style={{ width: "20%", verticalAlign: "top" }}>
                        <table className="table-bordered">
                          <thead style={{ textAlign: "center" }}>
                            <tr className="greyback">
                              <th colspan="3">First Term</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="greyback">
                              <td className="subject">
                                <strong>Subject</strong>
                              </td>
                              <td>
                                <strong>Total Marks</strong>
                              </td>
                              <td>
                                <strong>Obtained Marks</strong>
                              </td>
                            </tr>

                            {item.ListofFirstTermExamData.map(
                              (firstexam, index) => (
                                <tr>
                                  <td>{firstexam.Name}&nbsp;</td>
                                  <td>
                                    {firstexam.Total_Marks > 0 ? (
                                      firstexam.Total_Marks
                                    ) : (
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: "&nbsp;",
                                        }}
                                      />
                                    )}
                                  </td>
                                  <td>{firstexam.Obtained_Marks}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </td>
                      <td style={{ width: "20%" }} valign="top">
                        <table className="table-bordered">
                          <thead style={{ textAlign: "center" }}>
                            <tr className="greyback">
                              <th colspan="2">Second Term</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="greyback">
                              <td className="marks">
                                <strong>Total Marks</strong>
                              </td>
                              <td>
                                <strong>Obtained Marks</strong>
                              </td>
                            </tr>

                            {item.ListofSecondTermExamData.map(
                              (secondexam, index) => (
                                <tr>
                                  <td style={{ width: "100px !important" }}>
                                    {secondexam.Total_Marks > 0 ? (
                                      secondexam.Total_Marks
                                    ) : (
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: "&nbsp;",
                                        }}
                                      />
                                    )}
                                  </td>
                                  <td>{secondexam.Obtained_Marks}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </td>
                      <td style={{ width: "20%" }} valign="top">
                        <table className="table-bordered">
                          <thead style={{ textAlign: "center" }}>
                            <tr className="greyback">
                              <th colspan="2">Second Mid Term</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="greyback">
                              <td className="marks">
                                <strong>Total Marks</strong>
                              </td>
                              <td>
                                <strong>Obtained Marks</strong>
                              </td>
                            </tr>

                            {item.ListofThirdTermExamData.map(
                              (thirdexam, index) => (
                                <tr>
                                  <td style={{ width: "100px !important" }}>
                                    {thirdexam.Total_Marks > 0 ? (
                                      thirdexam.Total_Marks
                                    ) : (
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: "&nbsp;",
                                        }}
                                      />
                                    )}
                                  </td>
                                  <td>{thirdexam.Obtained_Marks}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </td>
                      <td style={{ width: "20%" }} valign="top">
                        <table className="table-bordered">
                          <thead style={{ textAlign: "center" }}>
                            <tr className="greyback">
                              <th colspan="2">Second Term</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="greyback">
                              <td className="marks">
                                <strong>Total Marks</strong>
                              </td>
                              <td>
                                <strong>Obtained Marks</strong>
                              </td>
                            </tr>

                            {item.ListofFourthTermExamData.map(
                              (fourthexam, index) => (
                                <tr>
                                  <td style={{ width: "100px !important" }}>
                                    {fourthexam.Total_Marks > 0 ? (
                                      fourthexam.Total_Marks
                                    ) : (
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: "&nbsp;",
                                        }}
                                      />
                                    )}
                                  </td>
                                  <td>{fourthexam.Obtained_Marks}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </td>
                      <td style={{ width: "20%" }}>
                        <table className="table-bordered">
                          <thead style={{ textAlign: "center" }}>
                            <tr className="greyback">
                              <th colspan="2">Cumulative</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="greyback">
                              <td className="marks">
                                <strong>Total Marks</strong>
                              </td>
                              <td>
                                <strong>Obtained Marks</strong>
                              </td>
                            </tr>

                            <tr>
                              <td>150&nbsp;</td>
                              <td>127.00</td>
                            </tr>

                            <tr>
                              <td>150&nbsp;</td>
                              <td>132.00</td>
                            </tr>

                            <tr>
                              <td>60&nbsp;</td>
                              <td>53.00</td>
                            </tr>

                            <tr>
                              <td>150&nbsp;</td>
                              <td>133.00</td>
                            </tr>

                            <tr>
                              <td>150&nbsp;</td>
                              <td>120.00</td>
                            </tr>

                            <tr>
                              <td>150&nbsp;</td>
                              <td>137.00</td>
                            </tr>

                            <tr>
                              <td>150&nbsp;</td>
                              <td>116.00</td>
                            </tr>

                            <tr>
                              <td>&nbsp;</td>
                              <td></td>
                            </tr>

                            <tr>
                              <td>960&nbsp;</td>
                              <td>818.00</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="feedback">
                <table>
                  <tbody>
                    <tr>
                      <td style={{ width: "50%", verticalAlign: "top" }}>
                        <table className="table-bordered">
                          <tbody>
                            <tr className="thead greyback">
                              <th colspan="5" style={{ textAlign: "center" }}>
                                Your Child as a Learner
                              </th>
                            </tr>
                            <tr>
                              <td style={{ width: "200px" }}></td>
                              <td>
                                <div className="smiley">
                                  <img src="images/smileys/rarely.png" alt="" />
                                  <span>Rarely</span>
                                </div>
                              </td>
                              <td>
                                <div className="smiley">
                                  <img
                                    src="images/smileys/sometime.png"
                                    alt=""
                                  />
                                  <span>Sometimes</span>
                                </div>
                              </td>
                              <td>
                                <div className="smiley">
                                  <img src="images/smileys/most.png" alt="" />
                                  <span>Mostly</span>
                                </div>
                              </td>
                              <td>
                                <div className="smiley">
                                  <img src="images/smileys/always.png" alt="" />
                                  <span>Always</span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Listens class attentively</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Learns and asks questions</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Responsible with homework</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Works neatly and orderly</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr className="thead greyback">
                              <th colspan="5" style={{ textAlign: "center" }}>
                                Your child's social and personal development
                              </th>
                            </tr>
                            <tr>
                              <td>Remains pleasant in Class</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Behaves well with classmates</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Respects his elders</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Sensitive to other’s feelings</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Hates the wrong doings</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                        <table className="position-table">
                          <tbody>
                            <tr>
                              <td colspan="3">
                                <h4
                                  style={{
                                    textAlign: "center",
                                    margin: "10pt 0 0 0",
                                    fontSize: "12pt",
                                  }}
                                >
                                  Position in Class
                                </h4>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span>1</span>
                                <div className="position">
                                  ({item.FirstTermPercentMarks}%)
                                  {item.FirstTermClass_Position}
                                </div>
                              </td>
                              <td>
                                <span>2</span>
                                <div className="position">
                                  ({item.SecondTermPercentMarks}%)
                                  {item.SecondTermClass_Position}
                                </div>
                              </td>
                              <td>
                                <span>3</span>
                                <div className="position">
                                  ({item.ThirdTermPercentMarks}%)
                                  {item.ThirdTermClass_Position}
                                </div>
                              </td>
                              <td>
                                <span>4</span>
                                <div className="position">
                                  ({item.FourthTermPercentMarks}%)
                                  {item.FourthTermClass_Position}
                                </div>
                              </td>

                              <td>
                                <span>Composite</span>
                                <div className="position">(85%) 13</div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td
                        style={{
                          width: "50%",
                          verticalAlign: "top",
                          padding: "0 20pt",
                        }}
                      >
                        <ul className="stars-list">
                          <li>
                            <i className="ri-star-fill"></i>
                            <span>Experiencing Significent Difficulty</span>
                          </li>
                          <li>
                            <i className="ri-star-fill"></i>
                            <span>Managing Comfortably</span>
                          </li>
                          <li>
                            <i className="ri-star-fill"></i>
                            <span>Capable and Competent</span>
                          </li>
                          <li>
                            <i className="ri-star-fill"></i>
                            <span>Highly Capable and Competent</span>
                          </li>
                        </ul>

                        <table className="table-bordered learning-starts">
                          <thead>
                            <tr className="greyback">
                              <th colspan="5">
                                Your Child's Learning During 1st Semester
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>English / Urdu</td>
                              <td>
                                <i className="ri-star-fill"></i>
                              </td>
                              <td>
                                <i className="ri-star-fill"></i>
                              </td>
                              <td>
                                <i className="ri-star-fill"></i>
                              </td>
                              <td>
                                <i className="ri-star-fill"></i>
                              </td>
                            </tr>
                            <tr>
                              <td>Listening Comprehension</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>

                            <tr>
                              <td>Oral Expression /Speaking</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Reading</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Written Expression</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="remarks">
                          <h4 style={{ margin: "0" }}>Teacher's Remarks</h4>
                          <div>
                            <div className="option">
                              <span className="value">&nbsp;</span>
                            </div>
                            <div
                              className="option"
                              style={{ marginTop: "5px" }}
                            >
                              <span className="value">&nbsp;</span>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="rc-footer">
                <table>
                  <tbody>
                    <tr>
                      <td style={{ width: "33%" }}>
                        <div className="option">
                          <span className="label">Prepared By:</span>
                          <span className="value"></span>
                        </div>
                      </td>
                      <td style={{ width: "33%" }}>
                        <h4>Controller Examination</h4>
                        <img
                          src="/images/signatures/controlexams.png"
                          style={{
                            width: "100px",
                            border: "0px",
                            marginTop: "-86px",
                            marginBottom: "0px",
                            marginRight: "0px",
                          }}
                          alt=""
                        />
                      </td>
                      <td style={{ width: "33%" }}>
                        <h4>Principal</h4>
                        <img
                          src="/images/signatures/principal.png"
                          style={{
                            width: "135px",
                            border: "0px",
                            float: "right",
                            marginTop: "-70px",
                            marginLeft: "-85px",
                            marginBottom: "0px",
                            marginRight: "0px",
                          }}
                          alt=""
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" style={{ fontSize: "9pt" }}>
                        <br />
                        Note: This provisional result intimation is issued as a
                        notice only. Errors and omissions are accepted.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ))
        )}
      </Page>
    </>
  );
};

export default PrintResultCards;
