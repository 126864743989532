import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "cropperjs/dist/cropper.css";
import { Link, useNavigate } from "react-router-dom";
import StudentRightsCheck from "./StudentRightsCheck";
import { Modal, ModalFooter } from "react-bootstrap";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import config from "../../Config";
import axios from "axios";
import user from "../../assets/images/user.png";
import moment from "moment";

const StudentProfile = (props) => {

  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
  const [UserName, setUserName] = useState(localStorage.getItem("UserName"));
  const [loading, setLoading] = useState(false);


  const [Student, setStudent] = useState({});
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {



    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      ID: UserID

    };
    setLoading(true);
    var api_config = {
      method: 'post',
      url: config.base_url + 'Student/GetStudentByID',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(api_config)
      .then(function (response) {
        // console.log(response.data.Student);
        if (response.data.status_code == 1) {
          setStudent(response.data.Student);
        }
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        toast.error('Network Error..', {
          position: toast.POSITION.TOP_RIGHT
        });
      });

  }, [UserID]);



  return (
    <>
      {/* <StudentPanelMenu /> */}
      <ToastContainer />
      <StudentRightsCheck />
     
      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/student-panel/dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Student Profile
                    </li>
                  </ol>
                </nav>
                <div className="page-title">Student Profile</div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="container body-content">
        <div className="student-card">
          <div className="card">
            <div className="card-header">
              <div className="profile">
                <div className="profile_header">
                  <div className="profile_photo">
                    <img
                      src={`${config.StudentImagePath}${UserName}.jpg`}
                      onError={(e) => e.target.src = user}
                      className="img-fluid w-100"
                      alt="profile" />
                    <div className="profile_edit">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className="user-name">
                <h1>{Student.Student_Name}</h1>
                <p>{Student.Father_Name}</p>
              </div>
              <b>Student ID: {Student.Student_ID}</b>
            </div>
            <div className="card-body">
              <div className="menu">
                <div className="outline">
                  <span>Wing:<b> {Student.SelectedClass?.SelectedWing?.Wing_Name}</b></span>
                </div>
                <div className="outline">
                  <span>Class:<b> {Student.SelectedClass?.Class_Name}</b></span>
                </div>
                <div className="outline">
                  <span>Section:<b> {Student.SelectedSection?.Section_Name}</b></span>
                </div>
              </div>
              <ul className="profile-list">
                <li>Registration No:</li>
                <li>{Student.Registration_No && Student.Registration_No.length !== 0 ? Student.Registration_No : "----------------------------"}</li>
                <li>Mobile No:</li>
                <li>{Student.Contact_Mobile && Student.Contact_Mobile.length !== 0 ? Student.Contact_Mobile : "----------------------------"}</li>
                <li>Status:</li>
                <li>{
                  Student.Status_Type_Id === 1 ? "Normal" :
                    Student.Status_Type_Id === 2 ? "Stucked Off" :
                      Student.Status_Type_Id === 3 ? "Left" :
                        Student.Status_Type_Id === 4 ? "Study Complete" :
                          Student.Status_Type_Id === 5 ? "SLC" :
                            Student.Status_Type_Id === 6 ? "Freezed" : "Not Enrolled"

                }</li>
                <li>Gender:</li>
                <li>{Student.Gender === 'male' ? (
                  <>Female</>
                ) : (
                  <>Male</>
                )}</li>
                <li>Bay Form No:</li>
                <li>{Student.Bay_Form_No && Student.Bay_Form_No.length !== 0 ? Student.Bay_Form_No : "----------------------------"}</li>
                <li>Date Of Birth:</li>
                <li>{moment(Student.Date_Of_Birth).format('DD/MM/YYYY')}</li>
                <li>Date of Joining:</li>
                <li>{moment(Student.Date_Of_Joining).format('DD/MM/YYYY')}</li>
              </ul>
              <div className="location shadow-sm">
                <i className="ri-map-pin-fill"></i>
                <p>{Student.Permanent_Address && Student.Permanent_Address.length !== 0 ? Student.Permanent_Address : "----------------------------"}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentProfile;
