import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import config from "../../Config";
import axios from "axios";
import { NavLink } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import ExamsDropDown from "../Shared/ExamsDropDown";
import SessionsDropDown from "../Shared/SessionsDropDown";
import WingsDropDown from "../Shared/WingsDropDown";
import ClassesDropDown from "../Shared/ClassesDropDown";
import SectionDropDown from "../Shared/SectionDropDown";
import Examination_navbar from './Examination_navbar';
import { toast, ToastContainer } from 'react-toastify';


const CloseThisExam = () => {

    const [validated, setValidated] = useState(false);
    const [WingId, setWingId] = useState(0);
    const [SessionId, setSessioinId] = useState(localStorage.getItem("DefaultSession"));
    const [ClassId, setClassId] = useState(0);
    const [SectionID, setSectionID] = useState(0);
    const [FirstExamID, setFirstExamID] = useState(0);
    const [SecondExamID, setSecondExamID] = useState(0);
    const [ThirdExamID, setThirdExamID] = useState(0);
    const [FourthExamID, setFourthExamID] = useState(0);


    let params = useParams();

    const [ExamID, setExamID] = useState(params.id);
    return (
        <>
            <Examination_navbar />
            <ToastContainer />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/Dashboard">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/ExaminationSystem/ExaminationDashboard">
                                            Examinations
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Close This Exam
                                    </li>
                                </ol>
                            </nav>
                            <div className="page-title">
                                Close This Exam
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-content">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-md-4">
                                    <Form
                                        noValidate
                                        validated={validated}
                                    >
                                        <div className="row">

                                                <div className="form-group">
                                                    <div className="row">
                                                        <label htmlFor="" className="col-sm-2 col-form-label">
                                                            Select First Exam:
                                                        </label>
                                                        <div className="col-sm-4">
                                                            <select
                                                                className="form-select"
                                                                name="FirstExam"
                                                                required
                                                                value={FirstExamID}
                                                                onChange={(e) => setFirstExamID(e.target.value)}
                                                            >
                                                                <option value="">Please Select</option>
                                                                <ExamsDropDown />
                                                            </select>
                                                        </div>
                                                        <p className="mt-3 offset-md-2">This option is used to prepare subject wise summary under the exam.</p>
                                                    </div>
                                                    <button type="submit" className="button button-primary offset-md-2">Print Data Sheets</button>
                                                </div>
                                            <div className="form-group mt-4">
                                                <div className="row">
                                                    <label for="secondExamSelect" className="col-sm-2 col-form-label">
                                                        Select Second Exam:
                                                    </label>
                                                    <div className="col-sm-4">
                                                        <select
                                                            className="form-select"
                                                            name="SecondExam"
                                                            required
                                                            value={SecondExamID}
                                                            onChange={(e) => setSecondExamID(e.target.value)}
                                                        >
                                                            <option value="">Please Select</option>
                                                            <ExamsDropDown />
                                                        </select>
                                                    </div>
                                                    <p className="mt-3 offset-md-2">This option is used to prepare subject wise summary under the exam.</p>
                                                </div>
                                                <button type="submit" className="button button-primary offset-md-2">Prepare Result Summary</button>
                                                <button type="submit" className="button button-primary ms-3">Temporary Link</button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default CloseThisExam
