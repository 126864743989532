import React, { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import config from "../../Config";
import axios from "axios";
import { SUPORT_15, SUPORT_16, SUPORT_17, SUPORT_18 } from '../UserControls/Icons';
import { useLocation } from 'react-router-dom';

function SupportMenu(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [tickets, setTickets] = useState({});


    // show active class on menu
    const currentLocation = useLocation();
    const isMenuItemActive = (path) => {
        return currentLocation.pathname.startsWith(path);
    };


    useEffect(() => {


        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            NoofRecords: 10,

        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Support/GetSupportTicket',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                if (response.data.status_code == 1) {
                    setTickets(response.data);
                }


            })
            .catch(function (error) {
                //console.log(error);
            });



    }, []);



    return (
        <>


            <div className='support-cyan'>
                <ul className='white-cyan'>
                    <li className='support-item'>
                        <Link to="/support/raise-ticket" className={`support-link ${isMenuItemActive('/support/raise-ticket') ? 'active' : ''}`}>
                            <SUPORT_15 />
                            <span>Raise Ticket</span>
                        </Link>
                    </li>
                    <li className='support-item'>
                        <Link to="/support/open" className={`support-link ${isMenuItemActive('/support/open') ? 'active' : ''}`}>
                            <SUPORT_18 />
                            <span>Open</span>
                            <span className="count">{tickets.OpenTickets}</span>
                        </Link>
                    </li>
                    <li className='support-item'>
                        <Link to="/support/inbox" className={`support-link ${isMenuItemActive('/support/inbox') ? 'active' : ''}`}>
                            <SUPORT_17 />
                            <span>Inbox</span>
                            <span className="count waiting">{tickets.AwaitingReply}</span>
                        </Link>
                    </li>
                    <li className='support-item'>
                        <Link to="/support/tickets-close" className={`support-link ${isMenuItemActive('/support/tickets-close') ? 'active' : ''}`}>
                            <SUPORT_16 />
                            <span>Closed</span>
                            <span className="count waiting">{tickets.CloseCount}</span>
                        </Link>
                    </li>

                </ul>
            </div>
        </>
    );
}

export default SupportMenu;