import React, { useState, useEffect } from 'react';
import config from "../../Config";
import axios from "axios";


function VoucherTypesDropDown(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [VoucherTypes, setVoucherTypes] = useState([]);
    
    useEffect(() => {
      
        const apiData = {
           AccessKey: AccessKey,
            UserID: UserID,
MemberType: MemberType,
	        PageNo: 1,
	        PageSize: 1000
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Voucher/GetVouchersTypes',
            headers: {
                'Content-Type': 'application/json'
            },
            data: apiData
        };

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setVoucherTypes(response.data.ListofRecords);
            })
            .catch(function (error) {
                //console.log(error);
            });

    }, []);

    

    return (
        VoucherTypes.map((item, index) => {
            return (
                <option value={item.ID} key={index}>{item.VoucherType}</option>
            )
        })
    );
}

export default VoucherTypesDropDown;