import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import QRCode from "react-qr-code";
import StudentInfo from '../FeeSystem/StudentInfo';
import moment from "moment";
import PrintHeader from '../General/PrintHeader';
import Page from "../General/Page";
import { calculateColumnTotal } from '../General/Utility';
import { scrollToTop } from '../Shared/ScrollOnTop';

const PrintSalaryBill = () => {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [loading, setLoading] = useState(false);


    return (
        <>
            <Page orientation="portrat" size="a4">
                <PrintHeader title={
                    <b>
                        SALARY BILL OF DPS STAFF FOR APRIL, 2024.
                    </b>
                } />
                <div className="salary-sheet">
                    <p><b>Puc:</b> Salary Sheet of DPS staff for April, 2024 refers:-</p>
                    <ol>
                        <li>
                            Requisite break up of salary of staff for the month of April, 2024  is  <b>given as under:-</b>
                        </li>
                    </ol>
                    <div className="salary-box">
                        <ol type="i">
                            <li>
                                Net amount of salary after deduction of other charges vide
                                <br />
                                Cheque No.________________(F/A) = <b>19460416</b>
                                <br />
                                <br />
                                <b>
                                    Deducted payments of contributory share of EOBI, Income Tax &
                                    Welfare Fund on behalf of DPS employees out of their salaries
                                    through Below mentioned Cheques:-
                                </b>
                                <br />
                                <br />
                            </li>
                            <li>
                                Income Tax for 115 employees vide Cheque No._________________ (F/B) = <b>92805</b>
                            </li>
                            <li>
                                EOBI Contribution on behalf of 406 employees vide <br />
                                Cheque No. _________________
                                <b> ( @320/- per head each (406x320=129920/-) (F/C) = 129920</b>
                            </li>
                            <li>
                                Welfare fund contribution for 451 employees
                                vide <br />
                                Cheque No. _________________ (F/D) = <b>73400</b>

                                <br />
                                Deduction of amount beyond entitlement of leave as per salary bill = (-) 44813
                                <br />
                                <b>Total Pay Bill = 19801354</b>
                            </li>
                        </ol>
                    </div>
                    <ol start={2}>
                        <li>
                            Above said 04 cheques pertains to salary of staff while other 02 cheques of following amounts are being released to EOBI and Social Security Departments as monthly contribution of staff out of approved budgetary provisions under respective sub head:-
                            <ol type="I">
                                <li>
                                    Payment of EOBI Contribution for 406 staff on behalf of
                                    employer @ 1600/-each for April, 2024 by crossed
                                    <br />
                                    Cheque No. _______________ (F/E)	 = <b>Rs.649600/-</b>
                                </li>
                                <li>
                                    Payment of Social Security Contribution for 120 staff
                                    on behalf of employer for April, 2024 by crossed
                                    <br />
                                    Cheque No. __________________ (F/F)	 = <b>Rs.238800/-</b>
                                </li>
                            </ol>
                        </li>
                        <li>
                            It is, therefore, requested that requisite salary bill of DPS staff for April, 2024 duly counter signed by all concerned Wing Heads may kindly be sanctioned and 06 No’s cheques be signed please.
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </li>
                        <li>
                            <div className="d-flex w-100 justify-content-between">
                                <b>DC/Chairman BOGs DPS</b>
                                <b>Principal DPS & College, Kasur</b>
                            </div>
                        </li>
                    </ol>
                </div>
                {
                    loading ?
                        <tr>
                            <td className="text-center" colspan="8">
                                <Spinner animation="border" role="status"></Spinner>
                            </td>
                        </tr>
                        :
                        <></>
                }
            </Page>
        </>
    );
}

export default PrintSalaryBill;
