import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import PrintHeader from "../General/PrintHeader";
import Page from "../General/Page";
import { calculateColumnTotal, getMonthName } from "../General/Utility";

const PrintMonthDetails = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [listOfLedger, setLedger] = useState([]);

  const [FYear, setFYear] = useState(urlParams.get("Year"));
  const [SelectedMonth, setSelectedMonth] = useState(urlParams.get("Month"));

  useEffect(() => {
    document.title = "Print Month Detail";

    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: 1,
      pageSize: 1000,
      SchoolID: 1,
      SelectedMonth: SelectedMonth,
      FYear: FYear,
    };

    //console.log(data);
    var api_config = {
      method: "post",
      url: config.base_url + "MonthlyTask/GetMonthDetails",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    //console.log(data);

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{count++}</td>
            <td>{item.Fee_Code}</td>
            <td>{item.Description}</td>
            <td>{item.Debit}</td>
            <td>{item.Credit}</td>
            <td>{item.NoofStudents}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="6">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <Page orientation="portrait" size="legal">
        <table className="table print-main-table">
          <thead>
            <tr>
              <td>
                <PrintHeader
                  title={`Month Detail For ${getMonthName(
                    SelectedMonth
                  )} ${FYear} `}
                />
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <table className="table table-sm table-bordered table-print">
                  <tbody>
                    <tr>
                      <th>Sr. No</th>
                      <th>Fee Code</th>
                      <th>Description</th>
                      <th>Debit</th>
                      <th>Credit</th>
                      <th>No of Students</th>
                    </tr>
                    {loading ? (
                      <tr>
                        <td className="text-center" colspan="16">
                          <Spinner animation="border" role="status"></Spinner>
                        </td>
                      </tr>
                    ) : (
                      <>{renderLedger()}</>
                    )}
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </Page>
    </>
  );
};

export default PrintMonthDetails;
