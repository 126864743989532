import React, { useState, useEffect } from "react";
import config from "../../Config";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import imgstudents from "../../assets/images/illustrations/login/students.svg";
import imgbackground from "../../assets/images/illustrations/login/background.svg";
import imglogo from "../../assets/images/logo/logo.png";
import { Form } from "react-bootstrap";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Login(props) {
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  let navigate = useNavigate();

  useEffect(() => {
    document.title = "Sign In - School System";
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoading(true);
      const signInData = {
        username: event.target.userName.value,
        password: event.target.password.value,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "Members/SignIn",
        headers: {
          "Content-Type": "application/json",
        },
        data: signInData,
      };
      //console.log(signInData);
      axios(api_config)
        .then(function (response) {
          //  //console.log(response.data);
          if (response.data.status_code == 1) {
            localStorage.setItem("AccessKey", response.data.AccessKey);
            localStorage.setItem("ID", response.data.ID);
            localStorage.setItem("FullName", response.data.FullName);
            localStorage.setItem("UserName", response.data.UserName);
            localStorage.setItem("User_Group_ID", response.data.User_Group_ID);
            localStorage.setItem("Group_Name", response.data.Group_Name);
            localStorage.setItem("DefaultSession", response.data.DefaultSessionID);
            localStorage.setItem("DefaultSessionName", response.data.DefaultSession);
            localStorage.setItem("DefaultFYear", response.data.DefaultFYear);
            localStorage.setItem("theme", "theme-light");
            localStorage.setItem("MemberType", response.data.MemberType);
            //console.log(response.data);
            toast.success("You are Logged in..Wait..", {
              position: toast.POSITION.TOP_RIGHT,
            });


            if (response.data.MemberType == "Teacher") {
              navigate('/teacher/dashboard');
            }

            else if (response.data.MemberType == "Student") {
              navigate('/student-panel/dashboard');
            }
            else {
              navigate('/Dashboard');
            }

          } else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }

          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          toast.error("Network Error..", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }

    setValidated(true);
  };
  return (
    <>
      <ToastContainer />
      <div className="content-center">
        <div className="container">
          <div className="row align-content-center">
            <div className="col-md-6 hide-on-mobile">
              <img src={imgstudents} className="img-fluid" alt="" />
            </div>

            <div className="col-md-5">
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className="login-wrap">
                  <img src={imgbackground} alt="" />
                  <div className="login-box">
                    <div className="logo">
                      <img
                        src="https://www.maimasoft.com/images/logo.svg"
                        alt=""
                      />
                    </div>

                    <h2 className="mb-4">Sign In</h2>

                    <div className="form-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="UserName"
                        name="userName"
                        required
                      />
                      <i className="ri-mail-line"></i>
                    </div>
                    <div className="form-group mb-3">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        placeholder="Password"
                        name="password"
                        required
                      />
                      <i className="ri-lock-password-line"></i>
                      <span
                        className={`${showPassword ? "ri-eye-off-line" : "ri-eye-line"
                          }`}
                        onClick={togglePasswordVisibility}
                      ></span>
                    </div>
                    <button
                      type="submit"
                      className="button button-secondary w-100"
                      disabled={loading}
                    >
                      SIGN IN {loading ? '...' : ''}
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
