import React, { useState, useEffect } from "react";

import { Cropper } from "react-cropper";
import { Link, useNavigate } from "react-router-dom";
import SupportMenu from "./SupportMenu";
import Spinner from 'react-bootstrap/Spinner';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Form } from 'react-bootstrap';

import Modal from 'react-bootstrap/Modal';
import config from "../../Config";
import SupportTopMenu from "./SupportTopMenu";
import SupportTicketDropDown from "../Shared/SupportTicketDropDown";

function NewTicket(props) {


    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [TicketsLoading, setTicketsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [imageCropped, setImageCropped] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);
    const [imageSelected, setImageSelected] = useState(false);
    const [image, setImage] = useState('');
    const [cropper, setCropper] = useState();

    const [tickets, setTickets] = useState([]);

    const [AttachmentString, setAttachmentString] = useState("");

    let navigate = useNavigate();

    useEffect(() => {

        setTicketsLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            Status: "Open",

        };

        var api_config = {
            method: 'post',
            url: config.base_url + '/Support/GetTicketTypesByStatusMemberID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                if (response.data.status_code == 1) {
                    setTickets(response.data.ListofTickets);
                }

                setTicketsLoading(false);
            })
            .catch(function (error) {
                //console.log(error);
            });



    }, []);


    const handleShowModal = () => setShowModal(true);

    const handleCloseModal = () => {
        setShowModal(false);
        setImageSelected(false);
        setLoading(false);
    }

    const onChange = (e) => {
        e.preventDefault();
        setImageSelected(true);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
    };

    const getCropData = () => {

        if (typeof cropper !== "undefined") {

            var cropData1 = cropper.getCroppedCanvas().toDataURL();
            setImageCropped(true);
            var cleanerBase64 = cropData1.substring(22);
            setAttachmentString(cleanerBase64);
            handleCloseModal();
            toast.success("File Attached Successfully", {
                position: toast.POSITION.TOP_RIGHT
            });

        }
    };


    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setLoading(true);
            const data = {
                UserID: UserID,
                AccessKey: AccessKey,
                Subject: event.target.subject.value,
                Description: event.target.description.value,
                TicketType: event.target.type.value,
                FileAttached: AttachmentString,
            };
            //console.log(data);
            var api_config = {
                method: 'post',
                url: config.base_url + 'Support/AddSupportTicket',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(api_config)
                .then(function (response) {

                    //console.log(response.data);

                    if (response.data.status_code == 1) {

                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        navigate("/support/open");
                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setLoading(false);

                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });

        }

        setValidated(true);
    };



    return (
        <>
            <ToastContainer />
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload Attachment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="upload-photo-wrap">
                        {
                            imageSelected ?
                                <Cropper

                                    zoomTo={0.5}

                                    preview=".img-preview"
                                    src={image}
                                    dragMode="move"
                                    viewMode={2}
                                    minCropBoxHeight={10}
                                    minCropBoxWidth={10}
                                    background={false}
                                    responsive={true}
                                    autoCropArea={1}
                                    checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                    onInitialized={(instance) => {
                                        setCropper(instance);
                                    }}
                                    guides={true}
                                />
                                :
                                <div className="choose-photo">
                                    <label htmlFor="ChoosePhoto">
                                        <i className="material-symbols-outlined">upload</i>
                                        <span>Browse Photo</span>
                                        <input id="ChoosePhoto" accept="image/jpeg,image/png" type="file" onChange={onChange} />
                                    </label>

                                </div>

                        }


                    </div>
                    <div className="modal-footer justify-content-center">
                        <button className="button button-outline-primary me-2" onClick={handleCloseModal}>Close</button>
                        <button className="button button-primary" onClick={getCropData}>Save Photo</button>
                    </div>

                </Modal.Body>



            </Modal>
            <SupportTopMenu />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item"> <Link to="/support/raise-ticket">Support</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Raise ticket</li>
                                </ol>
                            </nav>
                            <div className="page-title">Raise ticket</div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="container">
                <div className="card">
                    <div className="card-body p-md-4">
                        <SupportMenu />
                        <div className="row">
                            <div className="new-ticket-wrap form_default white_input">

                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                    <div style={{ padding: "20px 40px" }}>
                                        <div className="row gy-3">
                                            <div className="col-md-5">
                                                <div className="form-group">
                                                    <label className="">Subject</label>
                                                    <div className="inputwrap">
                                                        <input type="text" placeholder="Subject" className="form-control" name="subject" maxLength={20} required />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="form-group">
                                                    <label className="">Department</label>
                                                    <div className="inputwrap">
                                                        <select className="form-select" name="type" required>
                                                            <option value="">Select Department</option>
                                                            <SupportTicketDropDown />
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-11">
                                                <div className="form-group">
                                                    <label className="">Message</label>
                                                    <div className="inputwrap">
                                                        <textarea className="form-control" placeholder="Message" name="description" rows="6" required></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <button type="button" className="button button-gradient" onClick={handleShowModal}>
                                                    <span style={{ minWidth: "200px", color: "gray" }}>Attatch File</span>
                                                </button>
                                            </div>
                                            <div className="col-md-4 offset-md-4 text-end">
                                                <button className="button button-primary" disabled={loading}>SUBMIT TICKET {loading ? '...' : ''}</button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>

                    </div>
                </div>
            </div >

        </>
    );
}

export default NewTicket;