import React, { useState, useEffect } from 'react';
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { NavLink } from 'react-router-dom';
import Pagination from "../General/Pagination";
import moment from "moment";
import AccountsMenu from './AccountsMenu';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import calender from '../../assets/images/dashboard_icon/calender.svg'
import Vouchers from '../../assets/images/dashboard_icon/Vouchers.svg'
import Budget from '../../assets/images/dashboard_icon/Budget.svg'
import account from '../../assets/images/dashboard_icon/account.svg'
import { scrollToTop } from '../Shared/ScrollOnTop';

function AccountsDashboard(props) {
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [DefaultFYear, setDefaultFYear] = useState(localStorage.getItem("DefaultFYear"));
    const [dData, setdData] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        document.title = "Account Dashboard";
        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            FYear: DefaultFYear

        };
        //console.log(data);
        var api_config = {
            method: 'post',
            url: config.base_url + 'Accounts/GetAccountsDashboard',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                    setdData(response.data);

                }
                setLoading(false);
            })
            .catch(function (error) {
                setLoading(false);
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });


    }, []);
    return (
        <>
            <AccountsMenu />
            <ToastContainer />

            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><NavLink onClick={scrollToTop} to="/Dashboard">Dashboard</NavLink></li>
                                    <li className="breadcrumb-item active" aria-current="page">Account</li>
                                </ol>
                            </nav>
                            <div className="page-title">Account</div>
                        </div>
                    </div>
                </div>
            </div >

            <div className="page-content">
                <div className="container">
                    <div className="row align-items-center gy-3">
                        <div className="col-md-3 col-sm-6">
                            <Link onClick={scrollToTop} to='/Accounts/ManageAccounts' className="card a-card">
                                <div className="card-body text-center">
                                    <img src={calender} className="pb-3" alt="" />
                                    <p className="m-2">Financial Year</p>
                                    <h4 className="m-2">{dData.FYear}</h4>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <Link onClick={scrollToTop} to='/Accounts/ManageVouchers' className="card m-card">
                                <div className="card-body text-center">
                                    <img src={Vouchers} className="pb-3" alt="" />
                                    <p className="m-2">Total Vouchers</p>
                                    <h4 className="m-2">{dData.TotalVouchers}</h4>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <Link onClick={scrollToTop} to='/Accounts/OpeningBalances' className="card a-card">
                                <div className="card-body text-center">
                                    <img src={Budget} className="pb-3" alt="" />
                                    <p className="m-2">Total Budget for Year</p>
                                    <h4 className="m-2">{dData.TotalBudget}</h4>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <Link onClick={scrollToTop} to='/Accounts/ManageAccounts' className="card m-card">
                                <div className="card-body text-center">
                                    <img src={account} className="pb-3" alt="" />
                                    <p className="m-2">Total Account</p>
                                    <h4 className="m-2">{dData.TotalAccounts}</h4>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AccountsDashboard;