import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { toast, ToastContainer } from 'react-toastify';
import moment from "moment";
import { Form } from "react-bootstrap";
import "cropperjs/dist/cropper.css";
import { useNavigate, useParams } from 'react-router-dom';

const StudentForm = (props) => {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [loading, setLoading] = useState(false);
    const [Student, setStudent] = useState({});

    useEffect(() => {

        if ([props.StudentID] > 0) {

            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                ID: props.StudentID

            };
            setLoading(true);
            var api_config = {
                method: 'post',
                url: config.base_url + 'Student/GetStudentByID',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            //console.log(data)
            axios(api_config)
                .then(function (response) {
                    //console.log(response.data);
                    if (response.data.status_code == 1) {
                        setStudent(response.data.Student);

                    }
                    setLoading(false);
                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });
        }
        else {
            setLoading(false);
        }

    }, [props.StudentID]);


    return (
        <>



            <div className="form-wrap">
                <div className="form-legend">Basic Information</div>
                <div className="row align-items-center">
                    <div className="col-md-9">
                        <div className="row align-items-center g-3 m-0">
                            <label htmlFor="" className="col-md-2 col-form-label col-form-label-sm">Bay Form .</label>
                            <div className="col-md-4">
                                <input type="text" disable className="form-control" value={Student.Bay_Form_No} />
                            </div>
                            <label htmlFor="" className="col-md-2 col-form-label col-form-label-sm">Form No.</label>
                            <div className="col-md-4">
                                <input type="text" disabled numbers-Only className="form-control" value={Student.Form_No} id="" />
                            </div>
                            <label htmlFor="" className="col-md-2 col-form-label col-form-label-sm">Student Name</label>
                            <div className="col-md-4">
                                <input type="text" disabled className="form-control" value={Student.Student_Name} id="" />
                            </div>
                            <label htmlFor="" className="col-md-2 col-form-label col-form-label-sm">Date of Birth</label>
                            <div className="col-md-4">
                                <div className="form-control">
                                    {moment(Student.Date_Of_Birth).format('DD/MM/YYYY')}

                                </div>
                            </div>
                            <label htmlFor="" className="col-md-2 col-form-label col-form-label-sm" > Father Name </label>
                            <div className="col-md-4">
                                <input type="text" disabled className="form-control" value={Student.Father_Name} id="" />
                            </div>
                            <label htmlFor="" className="col-md-2 col-form-label col-form-label-sm">Father CNIC</label>
                            <div className="col-md-4">
                                <input type="text" disabled numbers-Only className="form-control" value={Student.Father_CNIC} id="" />
                            </div>
                            <label htmlFor="" className="col-md-2 col-form-label col-form-label-sm">Gender</label>
                            <div className="col-md-9">
                                <div className="mt-1">

                                    <div className="form-check form-check-inline">
                                        <label className="form-check-label">
                                            <input type="radio" name="rdoResult" checked={Student.Gender} />
                                            Male
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline me-0">
                                        <label className="form-check-label">
                                            <input type="radio" name="rdoResult" checked={!Student.Gender} />
                                            Female
                                        </label>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-3 text-center">
                        <img className="mb-3 m-auto" src={config.StudentImagePath + Student.Registration_No + ".jpg"} style={{ width: "150px", height: "150px", border: "1px solid", borderRadius: "50%", display: "block" }} />
                    </div>
                </div>
                <div className="row align-items-center mt-0">

                    <label htmlFor="" className="col-md-2 col-form-label col-form-label-sm">Vaccination Status</label>
                    <div className="col-md-4">
                        <input type="text" disabled numbers-Only className="form-control" value={Student.Vaccination_Status} id="" />
                    </div>
                    <label htmlFor="" className="col-md-2 col-form-label col-form-label-sm">Vaccination Date</label>
                    <div className="col-md-4">
                        <input type="text" className="form-control" value={Student.Vaccination_Date} id="" />
                    </div>


                </div>
            </div>
            <div className="form-wrap">
                <div className="form-legend">Contact Information</div>
                <div className="row">
                    <label htmlFor="" className="col-md-3 col-form-label col-form-label-sm">Temporary Address</label>
                    <div className="col-md-9">
                        <input type="text" disabled className="form-control" value={Student.Temporary_Address} id="" />
                    </div>
                </div>
                <div className="row">
                    <label htmlFor="" className="col-md-3 col-form-label col-form-label-sm">Permanent Address</label>
                    <div className="col-md-9">
                        <input type="text" disabled className="form-control" value={Student.Permanent_Address} id="" />
                    </div>
                </div>
                <div className="row">
                    <label htmlFor="" className="col-md-3 col-form-label col-form-label-sm">Contact Number</label>
                    <div className="col-md-3">
                        <input type="text" disabled numbers-Only className="form-control" value={Student.Contact_Phone} id="" />
                    </div>
                    <label htmlFor="" className="col-md-3 col-form-label col-form-label-sm">Mobile Number</label>
                    <div className="col-md-3">
                        <input type="text" disabled numbers-Only className="form-control" value={Student.Contact_Mobile} id="" />
                    </div>
                </div>
                <div className="row">
                    <label htmlFor="" className="col-md-3 col-form-label col-form-label-sm">Email Address</label>
                    <div className="col-md-9">
                        <input type="text" disabled className="form-control" value={Student.Contact_Email} id="" />
                    </div>
                </div>
            </div>
            <div className="form-wrap">
                <div className="form-legend">Class &amp; Transport</div>
                <div className="row no-gutters mb-0">
                    <div className="col-md-6">
                        <div className="row">
                            <label htmlFor="" className="col-md-6 col-form-label col-form-label-sm">Wing</label>
                            <div className="col-md-6">
                                <input type="text" disabled numbers-Only className="form-control" value={Student?.SelectedClass?.SelectedWing?.Wing_Name} id="" />
                            </div>
                        </div>
                        <div className="row">
                            <label htmlFor="" className="col-md-6 col-form-label col-form-label-sm">Session</label>
                            <div className="col-md-6">
                                <input type="text" disabled numbers-Only className="form-control" value={Student?.SelectedClass?.Selected_Session?.Session_Name} id="" />
                            </div>
                        </div>
                        <div className="row">
                            <label htmlFor="" className="col-md-6 text-md-end col-form-label">Class:</label>
                            <div className="col-md-6">
                                <input type="text" disabled numbers-Only className="form-control" value={Student?.SelectedClass?.Class_Name} id="" />
                            </div>
                        </div>
                        <div className="row">
                            <label htmlFor="" className="col-md-6 col-form-label col-form-label-sm">Section</label>
                            <div className="col-md-6">
                                <input type="text" disabled numbers-Only className="form-control" value={Student?.SelectedSection?.Section_Name} id="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <label htmlFor="" className="col-md-6 col-form-label col-form-label-sm">Transport Facility</label>
                            <div className="col-md-6">
                                <input type="text" disabled numbers-Only className="form-control" value={Student?.SelectedRoot?.Root_Name} id="" />
                            </div>
                        </div>
                        <div className="row">
                            <label htmlFor="" className="col-md-6 col-form-label col-form-label-sm">Concessions</label>
                            <div className="col-md-6">
                                <input type="text" disabled numbers-Only className="form-control" value={Student?.SelectedConcessionRule?.Concession_Rule_Name} id="" />
                            </div>
                        </div>
                        <div className="row">
                            <label htmlFor="" className="col-md-6 col-form-label col-form-label-sm">Group</label>
                            <div className="col-md-6">
                                <input type="text" disabled numbers-Only className="form-control" value={Student?.SelectedGroup?.Group_Name} id="" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="form-wrap">
                <div className="form-legend">Other Details</div>
                <div className="row align-items-center gy-3">
                    <label htmlFor="" className="col-md-3 col-form-label col-form-label-sm">Previous School Information</label>
                    <div className="col-md-9">
                        <input type="text" disabled className="form-control" value={Student.Previous_School_Info} />
                    </div>

                    <label htmlFor="" className="col-md-3 col-form-label col-form-label-sm">NOC Board Information</label>
                    <div className="col-md-9">
                        <input type="text" disabled className="form-control" value={Student.NOC_Board} />
                    </div>

                    <label htmlFor="" className="col-md-3 col-form-label col-form-label-sm">Remarks</label>
                    <div className="col-md-9">
                        <input type="text" disabled className="form-control" value={Student.Remarks} />
                    </div>
                    <label htmlFor="" className="col-md-3 col-form-label col-form-label-sm">Registration Number</label>
                    <div className="col-md-4">
                        <input type="text" disabled className="form-control" value={Student.Registration_No} />
                    </div>

                </div>

                <div className="row">
                    <div className="col-md-3 offset-md-9 text-md-end">
                        <div className="form-group ">
                            {
                                loading ?
                                    <Spinner animation="border" role="status"></Spinner>

                                    :
                                    <button type="submit" value="Save" className="mt-2 button button-primary w-100 ">SAVE STUDENT</button>

                            }

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StudentForm
