import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import LibraryMenu from './LibraryMenu';
import { NavLink, useParams } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import { scrollToTop } from '../Shared/ScrollOnTop';

function EditBook(props) {
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [hasError, setHasError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [Books, setBooks] = useState('');
    const [CurrentStatus, setCurrentStatus] = useState('');
    const [Binding_Type, setBinding_Type] = useState('');


    let params = useParams();

    let navigate = useNavigate();

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setLoading(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                ID: params.id,
                MemberType: MemberType,
                Book_No: event.target.txtBookNo.value,
                Book_Name: event.target.txtBookName.value,
                Writer_Name: event.target.txtWriterName.value,
                Publisher_Name: event.target.txtPublisherName.value,
                Category: event.target.txtCategory.value,
                Edition: event.target.txtEdition.value,
                Total_Pages: event.target.txtTotalPages.value,
                Binding_Type:Binding_Type,
                Price: event.target.txtPrice.value,
                Purchasing_Source: event.target.txtPurchasingSource.value,
                Title_Image: "",
                Jild_No: event.target.txtJildNo.value,
                Current_Status: CurrentStatus,
                Remarks: event.target.txtRemarks.value,
            };

            //console.log(data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'Library/UpdateLibrary',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };



            axios(api_config)
                .then(function (response) {
                    //console.log(response.data);
                    if (response.data.status_code == 1) {

                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });

                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setLoading(false);


                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });



        }

        setValidated(true);
    };

    useEffect(() => {
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: params.id,
        };


        //console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Library/GetBookByID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                    setBooks(response.data.Book)
                    setBinding_Type(response.data.Book.Binding_Type);
                    setCurrentStatus(response.data.Book.Current_Status);
                    toast.success(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });

                }
                else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }

                setLoading(false);


            })
            .catch(function (error) {
                setLoading(false);
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }, [])

    return (
        <>
            <LibraryMenu />
            <ToastContainer />
            <div className="container body-content">
                <div className="page-head">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                        <li className="breadcrumb-item"><NavLink href="/Library/LibraryDashboard">Library</NavLink></li>
                                        <li className="breadcrumb-item active" aria-current="page">Library Book Record</li>
                                    </ol>
                                </nav>
                                <div className="page-title">Update Library Book Record</div>
                            </div>
                            <div className="col-md-6">
                                <div className="page-right-actions">
                                    <NavLink to="/Library/ViewLibrary" className="button button-white button-icon">
                                        Manage Library
                                        <i className="ri-file-list-2-line"></i>
                                    </NavLink>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body card-form-wrap">
                                        <div className="">
                                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                                <div className="row align-items-end">
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="" className=""> Book No:</label>
                                                            <input type="text" defaultValue={Books.Book_No} className="form-control" name="txtBookNo"
                                                                required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label for="" className=""> Book Name:</label>
                                                            <input type="text" defaultValue={Books.Book_Name} name="txtBookName" className="form-control" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="" className="">Writer Name:</label>
                                                            <input type="text" defaultValue={Books.Writer_Name} name="txtWriterName" className="form-control" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="" className="">
                                                                Publisher
                                                                Name:
                                                            </label>
                                                            <input type="text" defaultValue={Books.Publisher_Name} name="txtPublisherName" className="form-control" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="" className="">
                                                                Enter Classification
                                                                No:
                                                            </label>
                                                            <input type="text" defaultValue={Books.Category} name="txtCategory" className="form-control" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="" className="">Edition:</label>
                                                            <input type="text" defaultValue={Books.Edition} name="txtEdition" className="form-control" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="" className="">No of Pages:</label>
                                                            <input type="number" defaultValue={Books.Total_Pages} name="txtTotalPages" className="form-control" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="" className="">Binding Type:</label>
                                                            <select className="form-select"
                                                                value={Binding_Type}
                                                                onChange={(e) => setBinding_Type(e.target.value)}
                                                                required>
                                                                <option Value="Not Available">Not Available</option>
                                                                <option Value="Tape Binding">Tape Binding</option>
                                                                <option Value="Hard Binding">Hard Binding</option>
                                                                <option Value="Paper Binding">Paper Binding</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="" className="">Book Price:</label>
                                                            <input type="number" defaultValue={Books.Price} name="txtPrice" className="form-control" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="" className=""> Jild No:</label>
                                                            <input type="text" defaultValue={Books.Jild_No} name="txtJildNo" className="form-control" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="" className="">
                                                                Purchasing Source
                                                                :
                                                            </label>
                                                            <input type="text" defaultValue={Books.Purchasing_Source} name="txtPurchasingSource" className="form-control" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="" className="">Status:</label>
                                                            <select className="form-select"
                                                                value={CurrentStatus}
                                                                onChange={(e) => setCurrentStatus(e.target.value)}
                                                                required>
                                                                <option Value="Available">Available</option>
                                                                <option Value="Issued">Issued</option>
                                                                <option Value="Lost">Lost</option>
                                                                <option Value="Disposed">Disposed</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="form-group">
                                                            <label htmlFor="" className="">Remarks:</label>
                                                            <input type="text" defaultValue={Books.Remarks} name="txtRemarks" className="form-control" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 text-end">
                                                        <div className="form-group">
                                                            {
                                                                loading ?
                                                                    <Spinner animation="border" role="status"></Spinner>
                                                                    :
                                                                    <button type="submit" value="Save" className="button button-primary w-100">SAVE</button>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>

                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default EditBook;