import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import { Link } from "react-router-dom";
import SetupMenu from './SetupMenu';
import logo_1 from '../../assets/images/logo/logo_1.svg';
import Spinner from 'react-bootstrap/Spinner';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { scrollToTop } from '../Shared/ScrollOnTop';

const SchoolInfo = () => {

    const [loading, setLoading] = useState(false);
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [validated, setValidated] = useState(false);
    const [school, setSchool] = useState({});


    // photo for the print report logo
    const [isUpdated1, setIsUpdated1] = useState(false);
    const [showModal1, setShowModal1] = useState(false);
    const [image1, setImage1] = useState('');
    const [cropData1, setCropData1] = useState("");
    const [cropper1, setCropper1] = useState(null);
    const [imageSelected1, setImageSelected1] = useState(false);
    const [imageCropped1, setImageCropped1] = useState(false);
    const [imgData1, setImageData1] = useState("");

    // photo 
    const [isUpdated, setIsUpdated] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [image, setImage] = useState('');
    const [cropData, setCropData] = useState("");
    const [cropper, setCropper] = useState(null);
    const [imageSelected, setImageSelected] = useState(false);
    const [imageCropped, setImageCropped] = useState(false);
    const [imgData, setImageData] = useState("");

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => {
        setShowModal(false);
        setImageSelected(false);
        setLoading(false);
    }
    const handleShowModal1 = () => setShowModal1(true);
    const handleCloseModal1 = () => {
        setShowModal1(false);
        setImageSelected1(false);
        setLoading(false);
    }

    useEffect(() => {

        document.title = "School Information";

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'School/GetSchoolInfo',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setSchool(response.data);

            })
            .catch(function (error) {
                //console.log(error);
            });

    }, []);


    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setLoading(true);

            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                School_Code: event.target.School_Code.value,
                School_Name: event.target.School_Name.value,
                School_Address: event.target.School_Address.value,
                School_logo_large: cropData,
                School_logo_small: cropData1,
                Schoole_Phone: event.target.Schoole_Phone.value,
                School_Fax: event.target.School_Fax.value,
                School_Email: event.target.School_Email.value,
                School_Website: event.target.School_Website.value,
            };
            //console.log(data);
            var api_config = {
                method: 'post',
                url: config.base_url + 'School/UpdateSchoolInfo',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };



            axios(api_config)
                .then(function (response) {
                    //console.log(response.data);
                    if (response.data.status_code == 1) {

                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });

                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setLoading(false);


                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });
        }
    }

    const onChange = (e) => {
        e.preventDefault();
        setImageSelected(true);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
    };
    const onChange1 = (e) => {
        e.preventDefault();
        setImageSelected1(true);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage1(reader.result);
        };
        reader.readAsDataURL(files[0]);
    };

    const getCropData = () => {

        if (cropper) {
            const cropData = cropper.getCroppedCanvas().toDataURL();
            var cleanerBase64 = cropData.substring(22);
            setImageCropped(true);
            setCropData(cleanerBase64);
            setImageData(cropData); // Update imgData state with cropped image data
        }
        handleCloseModal();
    };

    const getCropData1 = () => {
        // alert();
        if (cropper1) {

            const LogoData1 = cropper1.getCroppedCanvas().toDataURL();
            var cleanerBase64 = LogoData1.substring(22);
            //console.log(LogoData1);
            setImageCropped1(true);
            setCropData1(cleanerBase64);
            setImageData1(LogoData1); // Update imgData1 state with cropped image data
        }
        else {
            //console.log("Error");
        }
        handleCloseModal1();
    };


    const renderButtons = () => {
        if (imageSelected === true) {
            return (
                <>
                    <div className="modal-footer justify-content-center">
                        <button className="button button-outline-primary me-2" onClick={handleCloseModal}>Close</button>
                        <button className="button button-primary" onClick={getCropData}>Save Photo</button>
                    </div>
                </>
            )
        }
        else if (imageSelected === false && isUpdated === true) {
            return (
                <>
                    <div className="modal-footer justify-content-center">
                        <button className="button button-outline-primary me-2" onClick={handleCloseModal}>Close</button>
                    </div>
                </>
            )
        }
    }
    const renderButtons1 = () => {
        if (imageSelected1 === true) {
            return (
                <>
                    <div className="modal-footer justify-content-center">
                        <button className="button button-outline-primary me-2" onClick={handleCloseModal1}>Close</button>
                        <button className="button button-primary" onClick={getCropData1}>Save Photo</button>
                    </div>
                </>
            );
        } else if (imageSelected1 === false && isUpdated1 === true) {
            return (
                <>
                    <div className="modal-footer justify-content-center">
                        <button className="button button-outline-primary me-2" onClick={handleCloseModal1}>Close</button>
                    </div>
                </>
            );
        }
    };

    return (
        <>
            <SetupMenu />
            <ToastContainer />
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Header Logo  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        isUpdated ?
                            <>
                                <div className="text-center">

                                    <p>Photo Updated Successfully</p>
                                </div>

                            </>
                            :
                            <>
                                <div className="upload-photo-wrap">
                                    {
                                        imageSelected ?
                                            <Cropper

                                                zoomTo={0.5}
                                                aspectRatio={19 / 6}
                                                preview=".img-preview"
                                                src={image}
                                                dragMode="move"
                                                viewMode={2}
                                                minCropBoxHeight={10}
                                                minCropBoxWidth={10}
                                                background={false}
                                                responsive={true}
                                                autoCropArea={1}
                                                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                                onInitialized={(instance) => {
                                                    setCropper(instance);
                                                }}
                                                guides={true}
                                            />
                                            :
                                            <div className="choose-photo">
                                                <label htmlFor="ChoosePhoto">
                                                    <i className="ri-upload-cloud-line"></i>
                                                    <span>Browse Photo</span>
                                                    <input id="ChoosePhoto" accept="image/jpeg,image/png" type="file" onChange={onChange} />
                                                </label>

                                            </div>

                                    }


                                </div>
                            </>

                    }

                </Modal.Body>

                {renderButtons()}

            </Modal>
            <Modal show={showModal1} onHide={handleCloseModal1}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Print Logo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        isUpdated ?
                            <>
                                <div className="text-center">

                                    <p>Photo Updated Successfully</p>
                                </div>

                            </>
                            :
                            <>
                                <div className="upload-photo-wrap">
                                    {
                                        imageSelected1 ?
                                            <Cropper

                                                zoomTo={0.5}
                                                // aspectRatio={8 / 8}
                                                preview=".img-preview"
                                                src={image1}
                                                dragMode="move"
                                                viewMode={2}
                                                minCropBoxHeight={10}
                                                minCropBoxWidth={10}
                                                background={false}
                                                responsive={true}
                                                autoCropArea={1}
                                                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                                onInitialized={(instance) => {
                                                    setCropper1(instance);
                                                }}
                                                guides={true}
                                            />
                                            :
                                            <div className="choose-photo">
                                                <label htmlFor="ChoosePhoto1">
                                                    <i className="ri-upload-cloud-line"></i>
                                                    <span>Browse Photo</span>
                                                    <input id="ChoosePhoto1" accept="image/jpeg,image/png" type="file" onChange={onChange1} />
                                                </label>

                                            </div>

                                    }


                                </div>
                            </>

                    }

                </Modal.Body>

                {renderButtons1()}

            </Modal>

            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item"> <Link to="/Setup/SetupDashboard">Setup</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">School Info</li>
                                </ol>
                            </nav>
                            <div className="page-title">School Information</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <div className="row gy-3 align-items-center">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-md-4">
                                        <div className="row gy-3 align-items-center">
                                            <div className="col-md-2 col-sm-4">
                                                <div className="form-group">
                                                    <label>School Code:</label>
                                                    <input type="text" width="200" name="School_Code" defaultValue={school.School_Code} className="form-control" required />
                                                </div>
                                            </div>
                                            <div className="col-md-10 col-sm-8">
                                                <div className="form-group">
                                                    <label>School Name:</label>
                                                    <input type="text" width="200" name="School_Name" defaultValue={school.School_Name} className="form-control" required />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Address:</label>
                                                    <textarea rows="2" cols="20" name="School_Address" defaultValue={school.School_Address} className="form-control" required></textarea>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-6">
                                                <div className="form-group">
                                                    <label>Phone:</label>
                                                    <input type="text" width="200" name="Schoole_Phone" defaultValue={school.Schoole_Phone} className="form-control" required />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-6">
                                                <div className="form-group">
                                                    <label>Fax No:</label>
                                                    <input type="text" width="200" name="School_Fax" defaultValue={school.School_Fax} className="form-control" required />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-6">
                                                <div className="form-group">
                                                    <label>Email:</label>
                                                    <input type="text" width="200" name="School_Email" defaultValue={school.School_Email} className="form-control" required />
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-sm-6">
                                                <div className="form-group">
                                                    <label>Website:</label>
                                                    <input type="text" width="200" name="School_Website" defaultValue={school.School_Website} className="form-control" required />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 text-center">
                                <div className="card">
                                    <div className="card-body p-md-4">
                                        <div className="row gy-3 align-items-md-end">
                                            <div className="col-md-4 col-sm-6">
                                            <div className="form-group">
                                                    <label>Logo: 210px*40px</label>
                                                </div>
                                                <div className="d-block mt-3 m-auto">
                                                    {
                                                        imgData == "" ?
                                                            <img className="mb-3" src={config.images + school.School_logo_large} style={{ width: "150px" }} />
                                                            :
                                                            <img className="mb-3" src={imgData} style={{ width: "150px" }} />
                                                    }
                                                </div>
                                                <button type="button" onClick={handleShowModal} className="mb-2 button  button-small">
                                                    Choose Logo for Header
                                                </button>
                                            </div>
                                            <div className="col-md-4 col-sm-6">
                                                <div className="d-block mt-3 m-auto">
                                                    {
                                                        imgData1 == "" ?
                                                            <img className="mb-3" src={config.images + school.School_logo_small} style={{ width: "150px" }} />
                                                            :
                                                            <img className="mb-3" src={imgData1} style={{ width: "150px" }} />
                                                    }
                                                </div>

                                                <button type="button" onClick={handleShowModal1} className="mb-2 button  button-small">
                                                    Choose Logo for Print Report
                                                </button>
                                            </div>
                                            <div className="col-md-3 col-sm-12 offset-md-1">
                                                {
                                                    loading ?
                                                        <Spinner animation="border" role="status"></Spinner>

                                                        :
                                                        <button type="submit" value="Update Information" className="w-100 button button-primary">Update Information</button>

                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form >
                </div >
            </div >
        </>
    );
}

export default SchoolInfo;
