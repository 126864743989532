import React, { useState } from 'react';
import { Link } from "react-router-dom";
import PayrollMenu from './PayrollMenu.js';
import SalaryTypeDropDown from '../Shared/SalaryTypeDropDown.js';
import { scrollToTop } from '../Shared/ScrollOnTop';
import axios from 'axios';
import config from '../../Config.js';
import { Form, Spinner } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';

const IncreaseDecreaseSalary = () => {
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [validatedOne, setValidatedOne] = useState(false);
    const [validatedTwo, setValidatedTwo] = useState(false);
    const [validatedThree, setValidatedThree] = useState(false);
    const [validatedFour, setValidatedFour] = useState(false);


    const [OptionOneType, setOptionOneType] = useState('');
    const [OptionOneAmount, setOptionOneAmount] = useState('');
    const [OptionOneLoading, setOptionOneLoading] = useState(false);

    const [OptionTwoType, setOptionTwoType] = useState('');
    const [OptionTwoAmount, setOptionTwoAmount] = useState('');
    const [OptionTwoGrade, setOptionTwoGrade] = useState('');
    const [OptionTwoLoading, setOptionTwoLoading] = useState(false);

    const [OptionThreeType, setOptionThreeType] = useState('');
    const [OptionThreeAmount, setOptionThreeAmount] = useState('');
    const [OptionThreeGrade, setOptionThreeGrade] = useState('');
    const [addAsThree, setaddAsThree] = useState('');
    const [OptionThreeLoading, setOptionThreeLoading] = useState(false);

    const [selectedOption3, setSelectedOption3] = useState('1');

    document.title='Increse Decrease salary'

    const handleRadioChange3 = (event) => {
        setSelectedOption3(event.target.value);
    };

    const [OptionFourType, setOptionFourType] = useState('1');
    const [OptionFourAmount, setOptionFourAmount] = useState('');
    const [OptionFourGrade, setOptionFourGrade] = useState('1');
    const [OptionFourLoading, setOptionFourLoading] = useState(false);

    const [selectedOption4, setSelectedOption4] = useState('1');

    const handleRadioChange4 = (event) => {
        setSelectedOption4(event.target.value);
    };



    const [showFilters, setShowFilters] = useState(true);

    const [loading, setLoading] = useState(false);


    const handleOptionOne = (event) => {

        //console.log(event);
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            setOptionOneLoading(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                SalaryTypeId: OptionOneType,
                Amount: OptionOneAmount
            };

            //console.log(data);

            var api_config = {
                method: "post",
                url: config.base_url + "Payroll/IncreaseSalaryOption1",
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };

            axios(api_config)
                .then(function (response) {
                    setOptionOneLoading(false);
                    toast.success(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setLoading(false);
                })
                .catch(function (error) {
                    //console.log(error);
                });
        }

        setValidatedOne(true);
    };
    const handleOptionTwo = (event) => {

        //console.log(event);
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            setOptionTwoLoading(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                SalaryTypeId: OptionTwoType,
                Amount: OptionTwoAmount,
                SelectedGrade: OptionTwoGrade,
            };

            //console.log(data);

            var api_config = {
                method: "post",
                url: config.base_url + "Payroll/IncreaseSalaryOption2",
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };

            axios(api_config)
                .then(function (response) {
                    setOptionTwoLoading(false);
                    toast.success(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setLoading(false);
                })
                .catch(function (error) {
                    //console.log(error);
                });
        }

        setValidatedTwo(true);
    };
    const handleOptionThree = (event) => {

        //console.log(event);
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            setOptionThreeLoading(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                SalaryTypeId: OptionThreeType,
                Amount: OptionThreeAmount,
                SelectedGrade: OptionThreeGrade,
                addAsThree: addAsThree,
                IncrementType: selectedOption3,
            };

             //console.log(data);

            var api_config = {
                method: "post",
                url: config.base_url + "Payroll/IncreaseSalaryOption3",
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };

            axios(api_config)
                .then(function (response) {
                    //console.log(response.data)
                    setOptionThreeLoading(false);
                    toast.success(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setLoading(false);
                })
                .catch(function (error) {
                    //console.log(error);
                });
        }

        setValidatedThree(true);
    };
    const handleOptionFour = (event) => {

        //console.log(event);
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            setOptionFourLoading(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                SalaryTypeId: OptionFourType,
                Amount: OptionFourAmount,
                SelectedGrade: OptionFourGrade,
                addA: selectedOption4,
            };

            var api_config = {
                method: "post",
                url: config.base_url + "Payroll/IncreaseSalaryOption4",
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };

            axios(api_config)
                .then(function (response) {
                    //console.log(response.data)
                    setOptionFourLoading(false);
                    toast.success(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setLoading(false);
                })
                .catch(function (error) {
                    //console.log(error);
                });
        }

        setValidatedFour(true);
    };
    return (
        <>
            <PayrollMenu />
            <ToastContainer />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="/PayRoll/PayRollDashboard">Payroll</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Increase or Decrease Salaries</li>
                                </ol>
                            </nav>
                            <div className="page-title">Increase or Decrease Salaries</div>
                        </div>
                        <div className="col-md-6">
                            <div className="page-right-actions">
                                <Link to="/PayRoll/ManageDesignations" className="button button-white button-icon">
                                    Manage Designations
                                    <i className="ri-list-check"></i>
                                </Link>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="ng-scope">
                                <div className="card mb-4">
                                    <div className="card-body card-form-wrap">
                                        <Form noValidate validated={validatedOne} onSubmit={handleOptionOne}>
                                            <div className="form-wrap">
                                                <div className="form-legend" style={{ left: "64px" }}>Option 1</div>
                                                <div className="row align-items-center">
                                                    <div className="col-md-12">
                                                        <h5 style={{ fontSize: ".9rem" }}>Increase In Salary Type (%) :</h5>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Select Type</label>
                                                            <select className="form-select"
                                                                required
                                                                value={OptionOneType}
                                                                onChange={(e) => setOptionOneType(e.target.value)}
                                                            >
                                                                <option value="" className="" selected="selected">Please Select</option>
                                                                {
                                                                    <SalaryTypeDropDown />
                                                                }

                                                            </select>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Amount</label>
                                                            <input type="text" className="form-control"
                                                                required
                                                                value={OptionOneAmount}
                                                                onChange={(e) => setOptionOneAmount(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 text-end">
                                                        <div className="form-group">
                                                            {OptionOneLoading ?
                                                                <Spinner animation="border" role="status"></Spinner>
                                                                :
                                                                <button type="submit" className="button button-primary">Save</button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                        <Form noValidate validated={validatedTwo} onSubmit={handleOptionTwo}>
                                            <div className="form-wrap">
                                                <div className="form-legend" style={{ left: "64px" }}>Option 2</div>
                                                <div className="row align-items-center gy-3">
                                                    <div className="col-md-12">
                                                        <h5 style={{ fontSize: ".9rem" }}>Increase In Salary Type (Amount)</h5>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Select Type</label>
                                                            <select className="form-select"
                                                                value={OptionTwoType}
                                                                required
                                                                onChange={(e) => setOptionTwoType(e.target.value)}
                                                            >
                                                                <option value="" className="" selected="selected">Please Select</option>
                                                                {
                                                                    <SalaryTypeDropDown />
                                                                }

                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Grades</label>
                                                            <select className="form-select" required
                                                                value={OptionTwoGrade}
                                                                onChange={(e) => setOptionTwoGrade(e.target.value)}
                                                            >

                                                                <option value="AllGrades">AllGrades</option>
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                                <option value="6">6</option>
                                                                <option value="7">7</option>
                                                                <option value="8">8</option>

                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Amount</label>
                                                            <input type="text" className="form-control"
                                                                required
                                                                value={OptionTwoAmount}
                                                                onChange={(e) => setOptionTwoAmount(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 text-end">
                                                        <div className="form-group">
                                                            {OptionTwoLoading ?
                                                                <Spinner animation="border" role="status"></Spinner>
                                                                :
                                                                <button type="submit" className="button button-primary">Save</button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                        <Form noValidate validated={validatedThree} onSubmit={handleOptionThree}>
                                            <div className="form-wrap">
                                                <div className="form-legend" >Option 3</div>
                                                <div className="row align-items-end gy-3">
                                                    <div className="col-md-12">
                                                        <h5 style={{ fontSize: ".9rem" }}>Add New Column in Salary Type to All Staff</h5>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Select Type</label>
                                                            <select className="form-select"
                                                                required
                                                                value={OptionThreeType}
                                                                onChange={(e) => setOptionThreeType(e.target.value)}
                                                            >
                                                                <option value="" className="" selected>Please Select</option>
                                                                {
                                                                    <SalaryTypeDropDown />
                                                                }

                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Grades</label>
                                                            <select className="form-select" required
                                                                value={OptionThreeGrade}
                                                                onChange={(e) => setOptionThreeGrade(e.target.value)}
                                                            >
                                                                <option value="AllGrades">AllGrades</option>
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                                <option value="6">6</option>
                                                                <option value="7">7</option>
                                                                <option value="8">8</option>

                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Amount</label>
                                                            <input type="text" className="form-control"
                                                                required
                                                                value={OptionThreeAmount}
                                                                onChange={(e) => setOptionThreeAmount(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="d-flex justify-content-between mb-1">
                                                            <Form.Group value={selectedOption3}>
                                                                <Form.Check
                                                                    checked
                                                                    inline
                                                                    label="Percentage (%)"
                                                                    name="Option3"
                                                                    type="radio"
                                                                    id={`Percentage`}
                                                                    value={'1'}
                                                                    onChange={handleRadioChange3}
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    label="Value"
                                                                    name="Option3"
                                                                    type="radio"
                                                                    id={`Value`}
                                                                    value={'2'}
                                                                    onChange={handleRadioChange3}
                                                                />
                                                            </Form.Group>
                                                        </div>

                                                    </div>

                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Add As</label>
                                                            <select className="form-select"
                                                                required
                                                                value={addAsThree}
                                                                onChange={(e) => setaddAsThree(e.target.value)}
                                                            >
                                                                <option value="" className="" selected="selected">Please Select</option>
                                                                {
                                                                    <SalaryTypeDropDown />
                                                                }

                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-9 text-end">
                                                        <div className="form-group">
                                                            {OptionThreeLoading ?
                                                                <Spinner animation="border" role="status"></Spinner>
                                                                :
                                                                <button type="submit" className="button button-primary">Save</button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                        <Form noValidate validated={validatedFour} onSubmit={handleOptionFour}>
                                            <div className="form-wrap">
                                                <div className="form-legend" style={{ left: "64px" }}>Option 4</div>
                                                <div className="row align-items-end gy-3">
                                                    <div className="col-md-12">
                                                        <h5 style={{ fontSize: ".9rem" }}>Decrease Salary As Misc Deduction</h5>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Select Type</label>
                                                            <select className="form-select" required
                                                                value={OptionFourType}
                                                                onChange={(e) => setOptionFourType(e.target.value)}
                                                            >
                                                                <option value="1">Gross</option>
                                                                <option value="2">Basic</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Grades</label>
                                                            <select className="form-select" required
                                                                value={OptionFourGrade}
                                                                onChange={(e) => setOptionFourGrade(e.target.value)}
                                                            >
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                                <option value="6">6</option>
                                                                <option value="7">7</option>
                                                                <option value="8">8</option>
                                                                <option value="9">9</option>
                                                                <option value="10">10</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Amount</label>
                                                            <input type="number" className="form-control"
                                                                required
                                                                value={OptionFourAmount}
                                                                onChange={(e) => setOptionFourAmount(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <div className="d-flex justify-content-between mb-1">
                                                                <Form.Group value={selectedOption4}>
                                                                    <Form.Check
                                                                        checked
                                                                        inline
                                                                        label="Percentage (%)"
                                                                        name="Option4"
                                                                        type="radio"
                                                                        id={`Percentage4`}
                                                                        value={'1'}
                                                                        onChange={handleRadioChange4}
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Value"
                                                                        name="Option4"
                                                                        type="radio"
                                                                        id={`Value4`}
                                                                        value={'2'}
                                                                        onChange={handleRadioChange4}
                                                                    />
                                                                </Form.Group>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 text-end">
                                                        {OptionFourLoading ?
                                                            <Spinner animation="border" role="status"></Spinner>
                                                            :
                                                            <button type="submit" className="button button-primary">Save</button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >

        </>
    );
}

export default IncreaseDecreaseSalary;
