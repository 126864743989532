import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import PrintHeader from "../../General/PrintHeader";
import Page from "../../General/Page";
import { getMonthName } from "../../General/Utility";

const PrintStaffList4 = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [listOfLedger, setLedger] = useState([]);
  const [selectedYear, setselectedYear] = useState("");
  const [selectedMonth, setselectedMonth] = useState("");

  useEffect(() => {
    document.title = "Employee Staff List 4";

    setLoading(true);
    // Get the URL parameters
    const urlParams = new URLSearchParams(window.location.search);

    // Retrieve individual parameter values
    const year = urlParams.get("year");
    const Month = urlParams.get("month");
    const EmployeeTypeId = urlParams.get("EmployeeTypeId");
    const PayScale = urlParams.get("PayScale");

    setselectedYear(year);
    setselectedMonth(Month);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      Fyear: year,
      SelectedMonth: Month,
      EmployeeTypeId: EmployeeTypeId,
      PayScale: PayScale,
    };

    //console.log(data);
    var api_config = {
      method: "post",
      url: config.base_url + "Payroll/GetStaffList4",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    //console.log(data);

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  const renderLedger = () => {
    if (listOfLedger.length > 0) {
      let count = 1;
      let countbreak = 1;
      return listOfLedger.map((item, index) => {
        // const isPageBreak = index > 0 && index % 12 == 0;
        return (
          <tr
            key={index}
            //  className={isPageBreak ? 'page-break' : ''}
          >
            <td>{count++}</td>
            <td>{item.SelectedEmployee?.Code}</td>
            <td>
              <img
                src={
                  config.StaffImagePath + item.SelectedEmployee?.Code + ".jpg"
                }
                style={{ width: "60px", height: "60px" }}
                alt=""
              />{" "}
            </td>
            <td>{item.SelectedEmployee?.Name}</td>
            <td>{item.SelectedEmployee?.Father_Name}</td>
            <td>{item.SelectedEmployee?.SelectedDesignation?.Name}</td>
            <td>{item.SelectedEmployee?.Qualification}</td>
            <td>{item.SelectedEmployee?.GPS_Scale}</td>
            <td>{item.SelectedEmployee?.CNIC_NO}</td>
            <td>
              {moment(item.SelectedEmployee?.Date_Of_Joining).format(
                config.date_format
              )}
            </td>
            <td>{item.SelectedEmployee?.Home_Address}</td>
            <td>{item.SelectedEmployee?.Mobile_No}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="12">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <Page orientation="landscape" size="legal">
        <table className="table print-main-table">
          <thead>
            <tr>
              <td>
                <PrintHeader
                  title={`Staff List For The Month of ${getMonthName(
                    selectedMonth
                  )} ${selectedYear} `}
                />
              </td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <table className="table table-sm table-bordered table-print">
                  <tbody>
                    <tr>
                      <th>Sr.#</th>
                      <th>Code</th>
                      <th>Photo</th>
                      <th>Staff Name </th>
                      <th>Father Name </th>
                      <th>Designation</th>
                      <th>Qualification </th>
                      <th>GPS</th>
                      <th>CNIC No. </th>
                      <th>DOB</th>
                      <th>Address </th>
                      <th>Contact No </th>
                    </tr>
                    {loading ? (
                      <tr>
                        <td className="text-center" colspan="12">
                          <Spinner animation="border" role="status"></Spinner>
                        </td>
                      </tr>
                    ) : (
                      <>{renderLedger()}</>
                    )}
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </Page>
    </>
  );
};

export default PrintStaffList4;
