import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import SessionsDropDown from "../Shared/SessionsDropDown";
import WingsDropDown from "../Shared/WingsDropDown";
import ClassesDropDown from "../Shared/ClassesDropDown";
import SectionDropDown from "../Shared/SectionDropDown";
import ConcessionDropDown from "../Shared/ConcessionDropDown";
import FeeSystemMenu from "./FeeSystemMenu";
import { toast, ToastContainer } from "react-toastify";
import { NavLink } from "react-router-dom";
import moment from "moment";
import Pagination from "../General/Pagination";
import Dropdown from "react-bootstrap/Dropdown";
import { Form, PageItem } from "react-bootstrap";
import { scrollToTop } from "../Shared/ScrollOnTop";
import RightsCheck from "../General/RightsCheck";

function PrintVouchers(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  //const [DefaultSession, setDefaultSession] = useState(localStorage.getItem("DefaultSession"));

  const [showFilters, setShowFilters] = useState(true);

  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [validated, setValidated] = useState(false);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const [NewWingId, setNewWingId] = useState(0);
  const [NewClassId, setNewClassId] = useState(0);
  const [WingId, setWingId] = useState(0);
  const [SessionId, setSessioinId] = useState(
    localStorage.getItem("DefaultSession")
  );
  const [ClassId, setClassId] = useState(0);
  const [SectionID, setSectionID] = useState(0);
  const [ConcessionRuleID, setConcessionRuleID] = useState(0);

  const [isUpdated, setIsUpdated] = useState(false);

  const [Registration_No, SetRegistrationNo] = useState("");
  const [OrderBy, setOrderBy] = useState("Section_Name");

  const [selectedStudents, setselectedStudents] = useState([]);

  // variables for promote section

  const [AdvanceMonths, setAdvanceMonths] = useState(0);
  const [Amount, setAmount] = useState(0);
  const [Description, setDescription] = useState("");
  const [ShowHolidayImage, setHolidayImage] = useState(false);
  const [addconcession, setaddconcession] = useState(false);
  const [noRedCresent, setnoRedCresent] = useState(false);
  const [validatedPrint, setvalidatedPrint] = useState(false);

  useEffect(() => {
    document.title = "Print Vouchers";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: pageSize,
      SessionID: SessionId,
      Wing_Id: NewWingId,
      Class_Id: NewClassId,
      Section_Id: SectionID,
      Concession_Rule_ID: ConcessionRuleID,
      Registration_No: Registration_No,
      OrderBy: OrderBy,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Student/SearchStudents",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [
    pageNo,
    pageSize,
    SessionId,
    NewWingId,
    NewClassId,
    SectionID,
    ConcessionRuleID,
    Registration_No,
    OrderBy,
  ]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const renderLedger = () => {
    if (listOfLedger.length > 0) {
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>
              <div className="form-check">
                <input
                  key={item.Student_ID}
                  data-type-id={item.Student_ID}
                  className="form-check-input checkStudent"
                  type="checkbox"
                  value={item.Student_ID}
                />
              </div>
            </td>
            <td>{item.Registration_No}</td>
            <td>{item.Student_Name}</td>
            <td>{item.Father_Name}</td>
            <td>{moment(item.Date_Of_Birth).format("DD/MM/YYYY")}</td>
            <td>{moment(item.Date_Of_Joining).format("DD/MM/YYYY")}</td>
            <td>{item.Contact_Mobile}</td>
            <td>{item.SelectedClass.SelectedWing.Wing_Name}</td>
            <td>{item.SelectedClass.Class_Name}</td>
            <td>{item.SelectedSection.Section_Name}</td>
            <td>
              <div className="status text-center">
                {item.Concession_Rule_ID > 1 && (
                  <span className="green">
                    <i className="ri-percent-fill"></i>
                  </span>
                )}
                {item.Current_Route_Id > 0 && (
                  <span className="red">
                    <i className="ri-bus-2-fill"></i>
                  </span>
                )}

                <span className="blue">
                  <i className="ri-emotion-normal-line"></i>
                </span>
              </div>
            </td>
            {/* <td>
                                <ul className="inline-action">
                                    <li><NavLink to={'/Students/EditStudent/' + item.Student_ID} className=" green"><i className="ri-edit-line"></i></NavLink></li>
                                    <li> <a className="blue"><i className="ri-printer-line"></i></a></li>
                                    <li> <a className="red"><i className="ri-pages-line"></i></a></li>
                                </ul>
                            </td> */}
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="12">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        pageNo: pageNo,
        pageSize: pageSize,
        SessionID: SessionId,
        Wing_Id: NewWingId,
        Class_Id: NewClassId,
        Section_Id: SectionID,
        Concession_Rule_ID: ConcessionRuleID,
        Registration_No: Registration_No,
        OrderBy: OrderBy,
      };

      //console.log(data);

      var api_config = {
        method: "post",
        url: config.base_url + "Student/SearchStudents",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          setLedger(response.data.ListofRecords);
          setLoading(false);
          setTotalRecords(response.data.totalRecords);
        })
        .catch(function (error) {
          //console.log(error);
        });
    }

    setValidated(true);
  };

  //handlePromote
  const handlePrintVoucher = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoading(true);

      const inputsStudents = document.querySelectorAll("input.checkStudent");
      const valuesSelected = [];
      inputsStudents.forEach((input) => {
        if (input.checked == true) {
          const value = parseInt(input.value);
          if (value > 0) {
            valuesSelected.push(value);
          }
        }
      });

      // window.open('/Students/PrintVoucher/?Class_Id=' + NewClassId + '&ConcessionRuleID=' + ConcessionRuleID , '_blank');

      setLoading(false);
      //console.log(valuesSelected);
    }

    setvalidatedPrint(true);
  };

  return (
    <>
      <FeeSystemMenu />
      <ToastContainer />
      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/Dashboard">Dashboard</NavLink>
                    </li>
                    <li className="breadcrumb-item">
                      <NavLink to="/FeeSystem/FeeSystemDashboard">
                        Fee System
                      </NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Print Vouchers
                    </li>
                  </ol>
                </nav>
                <div className="page-title">Print Vouchers</div>
              </div>

              <div className="col-md-6">
                <div className="page-right-actions">
                  <NavLink
                    to="/students/AddStudent"
                    className="button button-white button-icon"
                  >
                    Add Students
                    <i className="ri-add-fill"></i>
                  </NavLink>
                  <button
                    onClick={() => {
                      setShowFilters((prevIsActive) => !prevIsActive);
                    }}
                    className="button button-white button-icon"
                  >
                    {showFilters ? "Hide Filters" : "Show Filters"}
                    <i className="ri-filter-3-line"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`collapse ${showFilters ? "show" : ""} `}
          id="collapseFilteres"
        >
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="page-content">
              <div className="container">
                <div className="card mb-2">
                  <div className="card-body p-md-4">
                    <div className="row align-items-end gy-3">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Select Session:</label>
                          <select
                            className="form-select"
                            onChange={(e) => setSessioinId(e.target.value)}
                            name="SessionID"
                          >
                            <option value="">---All---</option>
                            <SessionsDropDown selectedSession={SessionId} />
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Select Wing:</label>
                          <select
                            name="Wing_Id"
                            onChange={(e) => setNewWingId(e.target.value)}
                            className="form-select"
                          >
                            <option value="0">---All---</option>
                            <WingsDropDown />
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Select Class:
                          </label>
                          <select
                            className="form-select"
                            onChange={(e) => setNewClassId(e.target.value)}
                            name="Class_ID"
                          >
                            <option value="">Please Select</option>
                            {SessionId != 0 && NewWingId != 0 && (
                              <ClassesDropDown
                                SessionID={SessionId}
                                WingID={NewWingId}
                              />
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Select Section:
                          </label>
                          <select
                            className="form-select"
                            onChange={(e) => setSectionID(e.target.value)}
                            name="Section_Id"
                          >
                            <option value="0">Please Select</option>
                            {NewClassId !== 0 && (
                              <SectionDropDown ClassID={NewClassId} />
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Concession Rule:
                          </label>
                          <select
                            className="form-select"
                            onChange={(e) =>
                              setConcessionRuleID(e.target.value)
                            }
                            name="Concession_Id"
                          >
                            <option value="0">Please Select</option>
                            {<ConcessionDropDown />}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Registration No:
                          </label>
                          <input
                            type="text"
                            name="Reg_No"
                            onChange={(e) => SetRegistrationNo(e.target.value)}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group">
                          <label for="" className="">
                            Order by:
                          </label>
                          <select
                            name="OrderBy"
                            onChange={(e) => setOrderBy(e.target.value)}
                            className="form-select"
                          >
                            <option Selected Value="Student_Name">
                              Student Name
                            </option>
                            <option Value="Registration_No">
                              Registration No
                            </option>
                            <option Value="Wing_Name">Wing Name</option>
                            <option Value="Class_Name">Class</option>
                            <option Value="Student_ID">Student ID Asc</option>
                            <option Value="Student_ID desc">
                              Student ID Desc
                            </option>
                            <option Value="Section_Name">Section</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-2 offset-md-1">
                        <div className="form-group">
                          <button
                            type="submit"
                            value="Search"
                            onClick={handleSubmit}
                            className="button button-primary mt-4 w-100"
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
        <div className="page-content">
          <div className="container">
            <div className="card">
              <div className="card-body">
                {/* <div className="text-end mb-2 me-3">
                                    <b>{{totalRecords}} &nbsp; Records Found!</b>

                                </div> */}
                <table className="table table-theme">
                  <thead>
                    <tr>
                      <th></th>

                      <th>Reg No</th>
                      <th>Name</th>
                      <th>Father Name</th>
                      <th>DOB</th>
                      <th>DOJ</th>
                      <th>Mobile</th>
                      <th>Wing</th>
                      <th>Class</th>
                      <th>Section</th>
                      <th className="text-center">Tags</th>
                      {/* <th></th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td className="text-center" colspan="11">
                          <Spinner animation="border" role="status"></Spinner>
                        </td>
                      </tr>
                    ) : (
                      <>{renderLedger()}</>
                    )}
                  </tbody>
                </table>
                {totalRecords > 10 && (
                  <div className="pagination-wrap">
                    <div className="row align-items-center">
                      <div className="col-md-2 form_default">
                        <div className="d-flex align-items-center">
                          <label className="me-2" style={{ fontSize: "15px" }}>
                            Page Size
                          </label>
                          <div className="form-group">
                            <select
                              className="form-select"
                              style={{ width: "100px" }}
                              onChange={(e) => {
                                setPageNo(1);
                                setPageSize(parseInt(e.target.value));
                              }}
                            >
                              <option value="10" selected>
                                10
                              </option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <Pagination
                        pageNo={pageNo}
                        pageSize={pageSize}
                        totalRecords={totalRecords}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="container">
            <Form
              noValidate
              validated={validatedPrint}
              onSubmit={handlePrintVoucher}
            >
              <div className="card mt-2">
                <div className="card-body">
                  <div className="section-title title-sm title-left">
                    Print Vouchers for Selected Class
                  </div>
                  <div className="row align-items-end gy-3">
                    <div className="col-md-2">
                      <div className="form-group">
                        <label>No. of Months:</label>

                        <select
                          className="form-select"
                          onChange={(e) => setAdvanceMonths(e.target.value)}
                          required
                        >
                          <option value="0">No Advance Fee</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label>Amount:</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setAmount(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="form-group">
                        <label>Description:</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mt-3 form-check">
                        <input
                          type="checkbox"
                          id="Check1"
                          className="form-check-input"
                          onChange={(e) => setHolidayImage(e.target.checked)}
                        />

                        <label className="form-check-label" for="Check1">
                          Show Holidays Image:
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mt-3 form-check">
                        <input
                          type="checkbox"
                          id="Check2"
                          className="form-check-input"
                          onChange={(e) => setaddconcession(e.target.checked)}
                        />
                        <label className="form-check-label" for="Check2">
                          Add Concession:
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mt-3 form-check">
                        <input
                          type="checkbox"
                          id="Check3"
                          className="form-check-input"
                          onChange={(e) => setnoRedCresent(e.target.checked)}
                        />
                        <label className="form-check-label" for="Check3">
                          No concession for Red Cresent Fund:
                        </label>
                      </div>
                    </div>
                    <div className="col-md-2 text-end">
                      {/* <button type="submit" className="button button-primary">Print Vouchers</button> */}
                      <NavLink
                        to={`/Students/PrintVoucher/?Class_Id=${NewClassId}&ConcessionRuleID=${ConcessionRuleID}&OrderBy=${OrderBy}&Registration_No=${Registration_No}&Section_Id=${SectionID}&SessionID=${SessionId}&Wing_Id=${NewWingId}&Description=${Description}&AdvanceMonths=${AdvanceMonths}&ShowHolidayImage=${ShowHolidayImage}&noRedCresent=${noRedCresent}&AddConcession=${addconcession}&amount=${Amount}`}
                        target="_blank"
                        className="button button-primary w-100"
                      >
                        Print Vouchers
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrintVouchers;
