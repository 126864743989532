import React from 'react';

const PayScaleGps = (props) => {
  const options = [];

  for (let i = 0; i <= 10; i++) {
    options.push(<option key={i} value={i} selected={i === props.selected}>{i}</option>);
  }

  return options;
};

export default PayScaleGps;
