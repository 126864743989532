import React, { useState, useEffect } from "react";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import AnnualMenu from "./AnnualMenu";
import { NavLink } from "react-router-dom";
import { scrollToTop } from "../Shared/ScrollOnTop";
import { toast, ToastContainer } from "react-toastify";
import config from "../../Config";

function AddClassesInSessions(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [SessionId, setSessioinId] = useState(
    localStorage.getItem("DefaultSession")
  );

  document.title = "Add Classes In Sections";

  const handleAddClassesInSession = () => {
    setLoading(true);
    const apiData = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      SessionID: SessionId,
    };

    //console.log(apiData);
    var api_config = {
      method: "post",
      url: config.base_url + "AnnualTask/AddClassesInSession",
      headers: {
        "Content-Type": "application/json",
      },
      data: apiData,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setLoading(false);
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  return (
    <>
      <AnnualMenu />
      <ToastContainer />
      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-8">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/Home/Index">Dashboard</NavLink>
                    </li>
                    <li className="breadcrumb-item">
                      <NavLink to="/AnnualTasks/AnnualTasksDashboard">
                        Annual Tasks{" "}
                      </NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Add Classes In Sessions
                    </li>
                  </ol>
                </nav>
                <div className="page-title">
                  Generate Classes And Sections In New Academic Session
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-content">
          <div className="container">
            <div className="card">
              <div className="card-body p-md-4">
                <div className="bg-light p-md-4 rounded-2">
                  <p>
                    This Module will generate new Classes and Sections for the
                    New Academic Year.
                  </p>
                  <b>Pre-Requirements:</b>
                  <p>
                    Add a new session in the tblacademicsessions table. Click on the
                    button below to generate new classes in next session
                    based on current session.
                  </p>
                  <div className="row">
                    <div className="col-md-3 text-end">
                      {loading ? (
                        <Spinner animation="border" role="status"></Spinner>
                      ) : (
                        <button
                          type="submit"
                          onClick={handleAddClassesInSession}
                          value="GenerateClasses"
                          className="button button-primary w-100"
                        >
                          Generate Classes
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddClassesInSessions;
