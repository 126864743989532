import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import Select from 'react-select';
import PayrollMenu from './PayrollMenu';
import EmployeeDropDown from "../Shared/EmployeeDropDown";
import { scrollToTop } from '../Shared/ScrollOnTop';
import moment from "moment";


function AddLoanDeduction(props) {
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [validated, setValidated] = useState(false);
    const [employes, setEmployees] = useState([]);
    const [loading, setLoading] = useState(false);
    const [EmployeeID, setEmployeeID] = useState(0);

    document.title='Add Loan Detuction'

    let navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        const apiData = {
            AccessKey: AccessKey,
            Is_Resigned: false,
            UserID: UserID,
            MemberType: MemberType,
            SchoolID: 1,
            PageNo: 1,
            PageSize: 10000
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Payroll/SearchEmployees',
            headers: {
                'Content-Type': 'application/json'
            },
            data: apiData
        };

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setEmployees(response.data.ListofRecords);
                setLoading(false);
            })
            .catch(function (error) {
                //console.log(error);
            });

    }, []);

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setLoading(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                Employee_ID: EmployeeID,
                Status: event.target.Status.value,
                Loan_Amount: event.target.Loan_Amount.value,
                Deduction: event.target.Deduction.value,
                Dated: event.target.Dated.value,
                Description: event.target.Description.value,

            };

            //console.log(data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'LoanDeduction/AddLoanDeduction',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            axios(api_config)
                .then(function (response) {
                    //console.log(response.data);
                    if (response.data.status_code == 1) {

                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });

                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setLoading(false);


                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });



        }

        setValidated(true);
    };


    const dropdownOptions = employes.map(item => ({
        value: item.ID,
        label: item.Name + '(' + item.Code + ')',
        code: item.Code
    }));


    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: '100%', // Set your desired width here
        }),
    };

    const handleDropdownChange = (selectedOption) => {

        //let label = selectedOption.label;
        setEmployeeID(selectedOption.value);


    };

    return (
        <>
            <PayrollMenu />
            <ToastContainer />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="/PayRoll/PayRollDashboard">Pay Roll</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Add Loan Deduction</li>
                                </ol>
                            </nav>
                            <div className="page-title">Add Loan Deduction Entry</div>
                        </div>
                        <div className="col-md-6">
                            <div className="page-right-actions">
                                <Link to="/PayRoll/ManageLoanDeduction" className="button button-white button-icon">
                                    Manage Loan Deduction
                                    <i className="ri-list-check"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-md-4">
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        <div className="row align-items-center">
                                            <div className="col-md-3">
                                                {/* <div className="form-group mb-3">
                                                    <label htmlFor="">Select Employee:</label>
                                                    <select name="" className="form-control" id="" >
                                                        <option value=""></option>

                                                    </select>
                                                </div> */}
                                                    {/* <input type="text" className="form-control" required name="Employee_ID" /> */}
                                                <div className="form-group mb-3">
                                                    <label>Select Employee:</label>
                                                    <Select
                                                            styles={customStyles}
                                                            onChange={handleDropdownChange}
                                                            options={dropdownOptions}
                                                        />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="" className="">Dated:</label>
                                                    <input type="date" className="form-control" required name="Dated" max={moment().format(config.date_format_input)}  />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="" className="">Status:</label>
                                                    <input type="text" className="form-control" required name="Status" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="" className="">Loan Amount:</label>
                                                    <input type="text" className="form-control" required name="Loan_Amount" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="" className="">Deduction :</label>
                                                    <input type="text" className="form-control" required name="Deduction" />
                                                </div>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="" className="">Description:</label>
                                                    <input type="text" className="form-control" required name="Description" />
                                                </div>
                                            </div>
                                            <div className="col-md-2 mt-md-2 text-end">
                                                <div className="form-group ">
                                                    {
                                                        loading ?
                                                            <Spinner animation="border" role="status"></Spinner>

                                                            :
                                                            <button type="submit" value="Save" className="button button-primary w-100 ">SAVE</button>

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddLoanDeduction;