import React, { useState, useEffect, useRef } from "react";
import config from "../../Config";
import axios from "axios";
import moment from "moment";
import SupportMenu from "./SupportMenu";
import { Link, NavLink, useParams } from "react-router-dom";
import { Cropper } from "react-cropper";
import { Form } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Modal from 'react-bootstrap/Modal';
import { Average, Excellent, Good, Happy, Poor, SUPORT_21 } from "../UserControls/Icons";
import Logo from "../../assets/images/logo/support_logo.png";
import imgUser from "../../assets/images/user.png";
import SupportTopMenu from "./SupportTopMenu";

function TicketsListing(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [replies, setReplies] = useState([]);
    const [ticketType, setTicketType] = useState(null);
    const [ticket, setTicket] = useState({});
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);

    const [imageSelected, setImageSelected] = useState(false);
    const [AttachmentString, setAttachmentString] = useState("");

    const [image, setImage] = useState('');
    const [cropper, setCropper] = useState();
    const [imageCropped, setImageCropped] = useState(false);
    const [replySaved, setReplySaved] = useState(false);
    const [ClosedByAdmin, SetClosedByAdmin] = useState("");
    const [ClosedByUser, SetClosedByUser] = useState("");

    const [loadingTickets, setLoadingTickets] = useState(false);
    const [attachmentImg, setAttachmentImg] = useState(null);
    const [tick, settick] = useState(false);

    const [description, setdescription] = useState("");
    // const status = useRef(null);

    // modals
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    }

    const [showModalAttachment, setShowModalAttachment] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const handleShowModalAttachment = (item) => {
        setAttachmentImg(item);
        setShowModalAttachment(true);
    }

    const handleCloseModalAttachment = () => {
        setShowModalAttachment(false);

    }

    const handleCloseModal = () => {
        setShowModal(false);
        setImageSelected(false);
        setLoading(false);
    }
    const handleShowModal = () => {
        setShowModal(true);
    }

    let params = useParams();

    useEffect(() => {
        setReplySaved(false);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: params.id

        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Support/GetTicketByID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setReplies(response.data.ListofReplies);
                setTicket(response.data);
                SetClosedByAdmin(response.data.ClosedByAdmin);
                SetClosedByUser(response.data.ClosedByUser);
                //console.log(response.data.ListofReplies);
                const element = document.getElementById("chat-thread");
                element.scrollTop = element.scrollHeight;
            })
            .catch(function (error) {
                //console.log(error);
            });

    }, [replySaved]); // Dependency array


    const onChange = (e) => {
        e.preventDefault();
        setImageSelected(true);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
    };

    const getCropData = () => {

        if (typeof cropper !== "undefined") {

            var cropData1 = cropper.getCroppedCanvas().toDataURL();
            setImageCropped(true);
            var cleanerBase64 = cropData1.substring(22);
            setAttachmentString(cleanerBase64);
            handleCloseModal();
            setAttachmentShow(false);
            settick(true);
        }
    };


    const SubmitReply = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setLoadingTickets(true);
            const data = {
                UserID: UserID,
                AccessKey: AccessKey,
                ID: params.id,
                Description: description,
                Status: "",
                FileAttached: AttachmentString,
            };

            var api_config = {
                method: 'post',
                url: config.base_url + 'Support/ReplyTicket',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            axios(api_config)
                .then(function (response) {
                    //console.log(response.data);
                    if (response.data.status_code == 1) {
                        setReplySaved(true);
                        setdescription("");
                        setAttachmentString("");
                        //console.log(response.data)
                        // status.current.value = null;
                        setValidated(false);
                        setLoadingTickets(false);
                        settick(false);
                        // Scroll to the bottom after receiving a message

                        const chatThread = document.getElementById("chat-thread");
                        chatThread.scrollTop = chatThread.scrollHeight;
                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        setLoadingTickets(false);
                    }


                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });
            setValidated(true);
        }
    };
    const renderReplies = () => {
        if (replies != null) {
            return (
                replies.map((item, index) => {

                    return (
                        <li key={index} className={item.Member_ID == 0 ? 'admin-reply' : 'user-reply'}>
                            <div className="comment-item">
                                <div className="thumb">
                                    {
                                        item.Member_ID == 0 ?
                                            <img src={Logo} alt="" className="img-fluid" />
                                            :
                                            (item.Img_File == null ?
                                                <img src={imgUser} alt="" className="img-fluid" />
                                                :
                                                <img src={config.img_path + item.Img_File} alt="" />
                                            )
                                    }
                                </div>
                                <div className="comment">
                                    <div className="comment-message">
                                        <div className="inner">
                                            <h5>{item.Member_ID > 0 ? item.Member_Name : item.Admin_Name}</h5>
                                            <div dangerouslySetInnerHTML={{ __html: item.Description }}></div>
                                        </div>
                                    </div>
                                    <div className="comment-actions">

                                        {
                                            item.FileAttached != null &&
                                            <>
                                                <button className="button button-view-attachment" onClick={(e) => handleShowModalAttachment(item.FileAttached)}>
                                                    <i className="ri-attachment-line"></i> View Attachment
                                                </button>
                                            </>
                                        }
                                        <span className="date">{moment(item.Created_Date).format('ddd, DD MMM YYYY HH:mm:ss')}</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    )
                })
            )
        }
    }

    const [feedback, setfeedback] = useState("");
    const [comment, setcomment] = useState("");

    const handleFeedBack = (event) => {
        if (event.target.checked) {
            setfeedback(event.target.value);

        }
    };

    const sendfeedback = () => {
        setShow(false)
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: params.id,
            Feedback: feedback,
            Comments: comment

        };
        //console.log("feedback", data)
        var api_config = {
            method: 'post',
            url: config.base_url + 'Support/CloseTicket',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(api_config)
            .then(function (response) {
                toast.success(response.data.status_message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                window.location.reload();
            })
            .catch(function (error) {
                //console.log(error);
            });
    }
    const TicketNotClosed = () => {
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: params.id,
        };
        var api_config = {
            method: 'post',
            url: config.base_url + 'support/TicketNotClosed',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                toast.success(response.data.status_message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                window.location.reload();
            })
            .catch(function (error) {
                //console.log(error);
            });
    }
    const rendercloseadmin = () => {
        if (ClosedByUser == true && ClosedByAdmin == false) {
            return (
                <>
                    <li className="d-flex justify-content-center gap-5">
                        <button className="button button-primary" onClick={handleShow}>
                            <span className=""><i className="ri-checkbox-circle-line"></i> Yes</span>
                        </button>
                        <button className="button button-primary" onClick={TicketNotClosed}>
                            <span className=""><i className="ri-close-circle-line"></i> No</span>
                        </button>
                    </li>
                </>
            )
        }
        else if (ClosedByAdmin == true && ClosedByUser == false) {
            return (
                <>
                    <li className="d-flex justify-content-center gap-5">
                        <button className="button button-primary" onClick={handleShow}>
                            <span className=""><i className="ri-checkbox-circle-line"></i> Yes</span>
                        </button>
                        <button className="button button-primary" onClick={TicketNotClosed}>
                            <span className=""><i className="ri-close-circle-line"></i> No</span>
                        </button>
                    </li>
                </>
            )
        }
        else if (ClosedByAdmin == false && ClosedByUser == false) {
            return (
                <>
                </>
            )
        }
        else if (ClosedByAdmin == true && ClosedByUser == true) {
            return (
                <>
                </>
            )
        }
    }

    const [Attachmentshow, setAttachmentShow] = useState(false);
    const handleAttachmentClose = () => setAttachmentShow(false);

    const handleAttachmentshow = () => {
        setAttachmentShow(true);
    }

    const renderreply = () => {
        if (ClosedByUser == true && ClosedByAdmin == false) {
            return (
                <>
                    <div className="reply">
                        <div className="row align-items-center gy-3">
                            <div className="col-md-12">
                                <input
                                    value={description}
                                    className="form-control"
                                    onChange={(e) => setdescription(e.target.value)}
                                    placeholder="Type a Reply" type="text" />
                            </div>

                            <div className="col-2 offset-9">
                                <button
                                    disabled={loadingTickets || description.length == 0}
                                    onClick={SubmitReply}
                                    className="button button-primary submit w-100">
                                    Send {loadingTickets ? '...' : ''}
                                </button>
                            </div>
                            <div className="col-1 text-center">
                                <div className={`attachment-icon ${tick ? "primary" : "gray"}`}
                                    onClick={handleAttachmentshow}>
                                    <i className="ri-attachment-line"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        else if (ClosedByAdmin == true && ClosedByUser == false) {
            return (
                <>
                </>
            )
        }
        else if (ClosedByAdmin == false && ClosedByUser == false) {
            return (
                <>
                    <div className="reply">
                        <div className="row align-items-center gy-3">

                            <div className="col-md-12">
                                <textarea
                                    rows={3}
                                    value={description}
                                    className="form-control"
                                    onChange={(e) => setdescription(e.target.value)}
                                    placeholder="Type a Reply" type="text" />
                            </div>
                            <div className="col-2 offset-9">
                                <button
                                    disabled={loadingTickets || description.length == 0}
                                    onClick={SubmitReply}
                                    className="button button-primary submit w-100">
                                    Send {loadingTickets ? '...' : ''}
                                </button>
                            </div>
                            <div className="col-1 text-center">
                                <div
                                    className={`attachment-icon ${tick ? "primary" : "gray"}`}
                                    onClick={handleShowModal}
                                >
                                    <i className="ri-attachment-line"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        else if (ClosedByAdmin == true && ClosedByUser == true) {
            return (
                <>
                </>
            )
        }
    }
    const renderCloseButton = () => {
        if (ClosedByUser == true && ClosedByAdmin == false) {
            return (
                <>
                    <div className="my-3 text-md-start text-center">
                        <button
                            onClick={handleShow}
                            className="button button-primary">Close Ticket</button>
                    </div>
                </>
            )
        }
        else if (ClosedByAdmin == true && ClosedByUser == false) {
            return (
                <>
                </>
            )
        }
        else if (ClosedByAdmin == false && ClosedByUser == false) {
            return (
                <>
                    <div className="my-3 text-md-start text-center">
                        <button
                            onClick={handleShow}
                            className="button button-primary">Close Ticket</button>
                    </div>
                </>
            )
        }
        else if (ClosedByAdmin == true && ClosedByUser == true) {
            return (
                <>
                </>
            )
        }
    }


    return (
        <>
            <ToastContainer />
            <Modal className="modal-theme modal-small p-0" show={show} onHide={handleClose}>
                <div className="modal-header">
                    <div className="modal-title h2">FeedBack</div>

                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
                </div>
                <Modal.Body>
                    <ul className={`feedback_list`}>
                        <li>
                            <label
                                htmlFor="FeedBackPoor"
                                className={` ${feedback === 'Poor' ? 'active' : ''}`}
                            >
                                <Poor />
                                <span>Poor</span>
                                <input
                                    className=""
                                    id="FeedBackPoor"
                                    className="feedback"
                                    type="radio"
                                    value="Poor"
                                    onChange={handleFeedBack}
                                    checked={feedback === 'Poor'}
                                />
                            </label>
                        </li>
                        <li>
                            <label
                                htmlFor="FeedBackAverage"
                                className={` ${feedback === 'Average' ? 'active' : ''}`}
                            >
                                <Average />
                                <span>Average</span>
                                <input
                                    className=""
                                    id="FeedBackAverage"
                                    className="feedback"
                                    type="radio"
                                    value="Average"
                                    onChange={handleFeedBack}
                                    checked={feedback === 'Average'}
                                />
                            </label>
                        </li>
                        <li>
                            <label
                                htmlFor="FeedBackGood"
                                className={` ${feedback === 'Good' ? 'active' : ''}`}
                            >
                                <Good />
                                <span>Good</span>
                                <input
                                    className=""
                                    id="FeedBackGood"
                                    className="feedback"
                                    type="radio"
                                    value="Good"
                                    onChange={handleFeedBack}
                                    checked={feedback === 'Good'}
                                />
                            </label>
                        </li>
                        <li>
                            <label
                                htmlFor="FeedBackHappy"
                                className={` ${feedback === 'Happy' ? 'active' : ''}`}
                            >
                                <Happy />
                                <span>Happy</span>
                                <input
                                    className=""
                                    id="FeedBackHappy"
                                    className="feedback"
                                    type="radio"
                                    value="Happy"
                                    onChange={handleFeedBack}
                                    checked={feedback === 'Happy'}
                                />
                            </label>
                        </li>
                        <li>
                            <label
                                htmlFor="FeedBackExcellent"
                                className={` ${feedback === 'Excellent' ? 'active' : ''}`}
                            >
                                <Excellent />
                                <span>Excellent</span>
                                <input
                                    className=""
                                    id="FeedBackExcellent"
                                    className="feedback"
                                    type="radio"
                                    value="Excellent"
                                    onChange={handleFeedBack}
                                    checked={feedback === 'Excellent'}
                                />
                            </label>
                        </li>

                        {/* <li onClick={handleClick1} className={`${isActive1 ? "active" : ""}`}>
                            <Poor />
                            <p>Poor</p>
                        </li>
                        <li onClick={handleClick2} className={`${isActive2 ? "active" : ""}`}>
                            <Average />
                            <p>Average</p>
                        </li>
                        <li onClick={handleClick3} className={`${isActive3 ? "active" : ""}`}>
                            <Good />
                            <p>Good</p>
                        </li>
                        <li onClick={handleClick4} className={`${isActive4 ? "active" : ""}`}>
                            <Happy />
                            <p>Happy</p>
                        </li>
                        <li onClick={handleClick5} className={`${isActive5 ? "active" : ""}`}>
                            <Excellent />
                            <p>Excellent</p>
                        </li> */}
                        <li>
                            <div className="form_default w-100">
                                <div className="form-group">
                                    <div className="inputwrap">
                                        <textarea type="text" style={{ boxShadow: "none" }} onChange={(e) => setcomment(e.target.value)} className="form-control" placeholder="Comments" required="" value={comment} />
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <button onClick={sendfeedback}
                                disabled={feedback.length === 0 || comment.length === 0}
                                className="button button-primary w-100"><span>Submit</span></button>
                        </li>
                    </ul>
                </Modal.Body>
            </Modal>
            <Modal
                className="admin-support-modal"
                show={showModalAttachment}
                onHide={handleCloseModalAttachment}
            >
                <Modal.Header closeButton>
                    <Modal.Title>View Attachment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img
                        src={config.root + `SupportTicket/SupportFiles/${attachmentImg}`}
                        className="img-fluid"
                        alt="Attachment"
                    />
                </Modal.Body>
            </Modal>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload Attachment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="upload-photo-wrap">
                        {
                            imageSelected ?
                                <Cropper
                                    zoomTo={0.5}
                                    preview=".img-preview"
                                    src={image}
                                    dragMode="move"
                                    viewMode={2}
                                    minCropBoxHeight={10}
                                    minCropBoxWidth={10}
                                    background={false}
                                    responsive={true}
                                    autoCropArea={1}
                                    checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                    onInitialized={(instance) => {
                                        setCropper(instance);
                                    }}
                                    guides={true}
                                />
                                :
                                <div className="choose-photo">
                                    <label htmlFor="ChoosePhoto">
                                        <i className="ri-chat-poll-line">upload</i>
                                        <span>Browse Photo</span>
                                        <input id="ChoosePhoto" accept="image/jpeg,image/png" type="file" onChange={onChange} />
                                    </label>

                                </div>

                        }
                    </div>
                    <div className="modal-footer justify-content-center">
                        <button className="button button-outline-primary me-2" onClick={handleCloseModal}>Close</button>
                        <button className="button button-primary" onClick={getCropData}>Save Photo</button>
                    </div>
                </Modal.Body>
            </Modal>
            <SupportTopMenu />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item"> <Link to="/support/raise-ticket">Support</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Tickets Details</li>
                                </ol>
                            </nav>
                            <div className="page-title">Tickets Details ({ticket.TicketType})</div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="container">
                <div className="card">
                    <div className="card-body p-md-4">
                        <SupportMenu />
                        <div className="card-gradient">
                            <div className="card-inner bg-white p-0">
                                <div>
                                    <div className={`comment-posts`} >
                                        <div className="post-item">
                                            <ul className="post-info-actions">
                                                <li>
                                                    <i className="ri-coupon-line"> Ticket ID : </i>
                                                    {ticket.TicketID}
                                                </li>
                                                <li>
                                                    <i className="ri-calendar-2-fill"></i> {moment(ticket.Dated).format('ddd, DD MMM YYYY HH:mm:ss')}
                                                </li>
                                                {(ticket.FileAttached != null) ?
                                                    <>
                                                        <li className="laptop-view">
                                                            <i className="ri-attachment-line"></i> <a href="#" onClick={(e) => handleShowModalAttachment(ticket.FileAttached)}>View Attachment</a>
                                                        </li>
                                                        <li className="mobile-view">
                                                            <i className="ri-attachment-line"></i>
                                                            <i onClick={(e) => handleShowModalAttachment(ticket.FileAttached)} className="ri-chat-poll-line">attachment</i>
                                                        </li>
                                                    </>
                                                    :
                                                    <>
                                                        <li>
                                                            <i className="ri-attachment-line"></i> View Attachment
                                                        </li>
                                                    </>
                                                }
                                                <li className="laptop-view">
                                                    <i className="ri-chat-poll-line"></i> {ticket.NoofReplies} Comments
                                                </li>
                                                <li className="mobile-view">
                                                    <i className="ri-chat-poll-line"></i> {ticket.NoofReplies}
                                                </li>
                                            </ul>
                                            <ul className="post-comments" id="chat-thread">
                                                {renderReplies()}
                                                {rendercloseadmin()}
                                            </ul>
                                            <Form noValidate validated={validated} onSubmit={SubmitReply}>
                                                {renderreply()}
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {renderCloseButton()}
                    </div>
                </div>
            </div>
        </>
    );
}

export default TicketsListing;