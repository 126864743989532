import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import PrintHeader from "../General/PrintHeader";
import Page from "../General/Page";

const PrintOpeningBalance = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
 
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [listOfLedger, setLedger] = useState([]);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10000);
  const [totalRecords, setTotalRecords] = useState(0);

  const urlParams = new URLSearchParams(window.location.search);

    const lang = urlParams.get('lang');


   
    const VoucherType = urlParams.get('VoucherTypeID');
    const DefaultFYear = urlParams.get('DefaultYear');




    useEffect(() => {
    document.title = "Print Opening Balance";
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      PageNo: pageNo,
      PageSize: pageSize,
      FYear: DefaultFYear,
      VoucherTypeID: VoucherType,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Accounts/GetOpeningBalancesByYear",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code != 0) {
          setLedger(response.data.ListofRecords);
          setTotalRecords(response.data.totalRecords);
        } else {
          setLedger(null);
          setTotalRecords(0);
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{count++ + pageSize * (pageNo - 1)}</td>
            <td>{item.ID}</td>
            <td>{item.Selected_Account.Account_code}</td>
            <td>{item.Selected_Account.Account_desc}</td>
            <td> {item.DR_Amt}</td>
            <td>{item.CR_Amt}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="7">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <Page orientation="portrait" size="a4">
                <PrintHeader title={`List of Opening Balance`} />
        <table className="table table-sm table-bordered table-print">
          <thead>
          <tr>
              <th colSpan={10} className="text-end">
                Total Records : {totalRecords}{" "}
              </th>
            </tr>
          <tr>
                  <th>Sr#</th>
                  <th>ID</th>
                  <th>Code</th>
                  <th>Account</th>
                  <th>Debit</th>
                  <th>Credit</th>
                </tr>
           
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colspan="8">
                  <Spinner animation="border" role="status"></Spinner>
                </td>
              </tr>
            ) : (
              <>
                
                {renderLedger()}
              </>
            )}
          </tbody>
        </table>
      </Page>
    </>
  );
};

export default PrintOpeningBalance;
