import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import QRCode from "react-qr-code";
import StudentInfo from '../FeeSystem/StudentInfo';
import moment from "moment";
import PrintHeader from '../General/PrintHeader';
import Page from "../General/Page";
import { RenderHTMLString } from "../General/Utility";
import { calculateColumnTotal, getMonthName } from '../General/Utility';
const PrintStAttendenceSummary = () => {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [listOfLedger, setLedger] = useState([]);
    const [attendenceString, setattendenceString] = useState("");
    const [selectedYear, setselectedYear] = useState("");
    const [selectedMonth, setselectedMonth] = useState("");
    const [Month, setMonth] = useState("");
    const [Year, setYear] = useState("");


    const [WingName, setWingName] = useState("");
    const [ClassName, setClassName] = useState("");

    useEffect(() => {
        document.title="Print Attendance Summary"

        setLoading(true);
        // Get the URL parameters
        const urlParams = new URLSearchParams(window.location.search);

        // Retrieve individual parameter values
        const Year = urlParams.get('FYear');
        const Month = urlParams.get('SelectedMonth');
        const Class_Id = urlParams.get('Class_Id'); 
        setWingName(urlParams.get('Wing'));
        setClassName(urlParams.get('Class'));

        setselectedYear(Year);
        setselectedMonth(Month);

        const data = {
           AccessKey: AccessKey,
            UserID: UserID,
MemberType: MemberType,
            pageNo: 1,
            pageSize: 10000,
            Class_Id: Class_Id,
            FYear: Year,
            SelectedMonth: Month,
        };



        //console.log(data);
        var api_config = {
            method: 'post',
            url: config.base_url + 'StudentAttendance/GetAttendanceDetails',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        //console.log(data);

        axios(api_config)
            .then(function (response) {
                //console.log(response.data.attendenceTable);
                setattendenceString(response.data.attendenceTable);
                setLoading(false);
            })
            .catch(function (error) {
                //console.log(error);
            });

    }, []);


    return (
        <>
            <Page orientation="landscape" size="a4">
                <table className="table table-sm table-bordered table-print">
                    <thead>
                        <tr>
                            <th colspan={20}>
                                <PrintHeader title={`Monthly Attendence Register For the Month of  ${getMonthName(selectedMonth)}, ${selectedYear} ${WingName} Class: ${ClassName} `} />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading ?
                                <Spinner animation="border" role="status"></Spinner>
                                :

                                <div className="table-responsive table-attendence white-space-wrap">
                                    {RenderHTMLString(attendenceString)}
                                </div>
                        }

                    </tbody>
                </table>
            </Page>
        </>
    );
}

export default PrintStAttendenceSummary;
