import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PayrollMenu from "./PayrollMenu.js";
import moment from "moment";
import Pagination from "../General/Pagination";
import MonthsDropDown from "../Shared/MonthsDropDown.js";
import YearsDropDown from "../Shared/YearsDropDown.js";
import EmployeeTypeDropDown from "../Shared/EmployeeTypeDropDown";
//import { exportCSV } from 'csv-export';
import csvDownload from "json-to-csv-export";
import { scrollToTop } from "../Shared/ScrollOnTop";

const BankSalalryList = (props) => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );

  const [showFilters, setShowFilters] = useState(true);


  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [selectedYear, setSelectedYear] = useState(moment().format("YYYY"));
  const [selectedMonth, setSelectedMonth] = useState(moment().format("M"));
  const [Loans, setLoans] = useState(false);
  const [Deductions, setDeductions] = useState(false);
  const [EmployeeType, setEmployeeType] = useState(0);
  const [TotalDeductions, setTotalDeductions] = useState(0);
  const [TotalLoans, setTotalLoans] = useState(0);
  const [TotalGross, setTotalGross] = useState(0);
  const [TotalNet, setTotalNet] = useState(0);
  const [OrderBy, setOrderBy] = useState("ID");
  const [loaddata, setLoaddata] = useState(false);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [ledgerRecord, setLedgerRecord] = useState([]);

  useEffect(() => {
    document.title = "Bank Salary List";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: pageSize,
      SchoolID: 1,
      SelectedMonth: selectedMonth,
      FYear: selectedYear,
      EmployeeTypeId: EmployeeType,
      OrderBy: OrderBy,
      Loans: Loans,
      Deduction: Deductions,
    };
    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Payroll/GetBankSalaryList",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          setLedger(response.data.ListofRecords);
          setTotalRecords(response.data.totalRecords);
          setTotalDeductions(response.data.TotalDeductions);
          setTotalLoans(response.data.TotalLoans);
          setTotalGross(response.data.TotalGross);
          setTotalNet(response.data.TotalNet);
          setLoading(false);
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [
    pageNo,
    pageSize,
    isUpdated,
    Loans,
    Deductions,
    selectedMonth,
    selectedYear,
    EmployeeType,
    OrderBy,
  ]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{count++ + pageSize * (pageNo - 1)}</td>
            <td>{item.Code}</td>
            <td>{item.Name}</td>
            <td>{item.Account_No}</td>
            <td>{item.Emp_Dept}</td>
            <td>{item.Designation}</td>
            <td>{item.TotalSalary}</td>
            <td>{item.LoanDeduction}</td>
            <td>{item.OtherDeduction}</td>
            <td>{item.NetSalary}</td>
            <td>{item.DeductionFlag}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="11">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  // const DownloadFile = () => {

  // }

  //     const listOfLedgerNew = listOfLedger.map(({ DeductionFlag, LoanDeduction, OtherDeduction, TotalSalary
  // ,        ...rest }) => rest);

  const handleDownload = () => {
    setLoaddata(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: 10000,
      SchoolID: 1,
      SelectedMonth: selectedMonth,
      FYear: selectedYear,
      EmployeeTypeId: EmployeeType,
      OrderBy: OrderBy,
      Loans: Loans,
      Deduction: Deductions,
    };
    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Payroll/GetBankSalaryList",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          if (response.data.totalRecords > 0) {
           
            let count = 0;
            const listOfArray = response.data.ListofRecords.map(
              ({ LoanDeduction, OtherDeduction, Account_No, ...rest }) => ({
                Serial_No: count++ + pageSize * (pageNo - 1),
                Code: rest.Code,
                Name: rest.Name,
                Account_No: `=T("${Account_No}")`,
                Emp_Dept: rest.Emp_Dept,
                Designation: rest.Designation,
                TotalSalary: rest.TotalSalary,
                LoanDeduction:`=T("${LoanDeduction}")`,
                OtherDeduction:`=T("${OtherDeduction}")`,
                NetSalary: rest.NetSalary,
              })
            );
            //console.log(listOfArray);

            const dataToConvert = {
              data: listOfArray,
              filename: "BankSalaryList",
              delimiter: ",",
              headers: [
                "Serial_No",
                "Code",
                "Staff Name",
                "Account No",
                "Department Name",
                "Designation",
                "Total Salary",
                "Loan Deduction",
                "Other Deduction",
                "Net Salary",
              ],
            };
            csvDownload(dataToConvert);

            setLoaddata(false);
          } else if (response.data.totalRecords === 0) {
            toast.error("There is no data available for download", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoaddata(false);
          }
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoaddata(false);
        }
      })
      .catch(function (error) {
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoaddata(false);
      });
  };

  return (
    <>
      <PayrollMenu />
      <ToastContainer />
      <div className="page-head">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/Dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/PayRoll/PayRollDashboard">PayRoll</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Bank Salary List
                  </li>
                </ol>
              </nav>
              <div className="page-title">Bank Salary List</div>
            </div>
            <div className="col-md-6">
              <div className="page-right-actions">
              <button
                  onClick={() => {
                    setShowFilters((prevIsActive) => !prevIsActive);
                  }}
                  className="button button-white button-icon"
                >
                  {showFilters ? "Hide Filters" : "Show Filters"}
                  <i className="ri-filter-3-line"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`collapse ${showFilters ? "show" : ""} `}
        id="collapseFilteres"
      >
      <div className="collapse show" id="collapseFilteres">
        <div className="container">
          <div className="card mb-3">
            <div className="card-body p-md-3">
              <div className="row align-items-center gy-3">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Select Month</label>
                    <select
                      className="form-select"
                      required=""
                      value={selectedMonth}
                      onChange={(e) => setSelectedMonth(e.target.value)}
                    >
                      <MonthsDropDown />
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Select Year</label>
                    <select
                      className="form-select"
                      value={selectedYear}
                      onChange={(e) => setSelectedYear(e.target.value)}
                      required=""
                    >
                      <YearsDropDown />
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Order By</label>
                    <select
                      className="form-select"
                      required=""
                      onChange={(e) => setOrderBy(e.target.value)}
                    >
                      <option value="ID">Code</option>
                      <option value="Name">Staff Name</option>
                      <option value="Emp_Dept">Type/Department</option>
                      <option value="Designation">Designation</option>
                      <option value="Account_No">Account No</option>
                      <option value="TotalSalary">Salalry Amount</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Department</label>
                    <select
                      className="form-select"
                      required=""
                      onChange={(e) => setEmployeeType(e.target.value)}
                    >
                      <option value="0">Please Select</option>
                      <EmployeeTypeDropDown />
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group mb-3">
                    <label for="" className="">
                      Print Without :
                    </label>
                    <div className="d-flex justify-content-between">
                      <div className="form-check">
                        <label htmlFor="chkLoans">Loans</label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="chkLoans"
                          checked={Loans}
                          onChange={(e) => setLoans(e.target.checked)}
                        />
                      </div>
                      <div className="form-check">
                        <label htmlFor="ChkDeduction">Deductions</label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="ChkDeduction"
                          checked={Deductions}
                          onChange={(e) => setDeductions(e.target.checked)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 offset-md-7">
                  <input
                    type="button"
                    value="Search"
                    className="button button-primary w-100"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div className="page-content">
        <div className="container">
          <div className="card">
            <div className="card-body">
              <table className="table table-theme table-hover">
                <thead>
                  <tr>
                    <th>Sr. No</th>
                    <th>Code</th>
                    <th>Staff Name</th>
                    <th>Account No</th>
                    <th>Department Name</th>
                    <th>Designation</th>
                    <th>Total Salary</th>
                    <th>Loan Deduction</th>
                    <th>Other Deduction</th>
                    <th>Net Salary</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td className="text-center" colspan="11">
                        <Spinner animation="border" role="status"></Spinner>
                      </td>
                    </tr>
                  ) : (
                    <>{renderLedger()}</>
                  )}
                  <tr>
                    <td colSpan={6}></td>
                    <td>{TotalGross}</td>
                    <td>{TotalLoans}</td>
                    <td>{TotalDeductions}</td>
                    <td>{TotalNet}</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              {totalRecords > 10 && (
                <div className="pagination-wrap">
                  <div className="row align-items-center">
                    <div className="col-md-2 form_default">
                      <div className="d-flex align-items-center">
                        <label className="me-2" style={{ fontSize: "15px" }}>
                          Page Size
                        </label>
                        <div className="form-group">
                          <select
                            className="form-select"
                            style={{ width: "100px" }}
                            onChange={(e) => {
                              setPageNo(1);
                              setPageSize(parseInt(e.target.value));
                            }}
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100" selected>
                              100
                            </option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <Pagination
                      pageNo={pageNo}
                      pageSize={pageSize}
                      totalRecords={totalRecords}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="card mt-md-3">
            <div className="card-body">
              <div className="row justify-content-end">
                <div className="col-md-2">
                  <NavLink
                    target="_blank"
                    to={`/PayRoll/PrintBankSalalryList/?month=${selectedMonth}&year=${selectedYear}&Department=${EmployeeType}&loans=${Loans}&Deductions=${Deductions}&OrderBy=${OrderBy}`}
                    className="button button-primary w-100"
                  >
                    Print This Report
                  </NavLink>
                </div>
                {loaddata ?
                    <div className="col-md-2 ">
                      <div className="text-center" >
                        <Spinner animation="border" role="status"></Spinner>
                      </div>
                    </div>

            :    <div className="col-md-2">
                  <button
                    type="button"
                    onClick={handleDownload}
                    className="button button-primary w-100"
                  >
                    Download File
                  </button>
                </div>
}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BankSalalryList;
