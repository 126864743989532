import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
// import StudentMenu from "./StudentMenu";
import { toast, ToastContainer } from "react-toastify";
import { NavLink, Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Pagination from "../General/Pagination";
import Stock_navbar from "./StockNavbar";
import ShowStock from "./ShowStock";

function StockDetail(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(1000);
  const [totalRecords, setTotalRecords] = useState(0);

  let params = useParams();
  const [StockID, setStockID] = useState(params.id);
  // const [StartDate, setStartDate] = useState("2023-01-01");
  // const [EndDate, setEndDate] = useState("2025-06-01");

  let navigate = useNavigate();

  useEffect(() => {
    document.title = "Stock Item Details";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: StockID,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Stock/GetStockItemDetailByItemID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofDetails);
        setLoading(false);
        setTotalRecords(response.data.ListofDetails);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [pageNo, pageSize, isUpdated]);

  const deleteStock = (ID) => {
    const confirm = window.confirm("Are you sure you want to proceed ?");
    if (confirm) {
      setIsUpdated(false);
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        ID: ID,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "Stock/DeleteItemDetailByID",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setIsUpdated(true);
          } else {
            setIsUpdated(false);
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch(function (error) {
          //console.log(error);
        });
    }
  };

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const renderLedger = () => {
    if (listOfLedger.length > 0) {
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.ID}</td>
            <td>{moment(item.Transaction_Date).format("DD/MM/YYYY")}</td>
            <td>{item.Description}</td>
            <td>{item.Item_In}</td>
            <td>{item.Item_Out}</td>
            <td>{item.Item_Balance}</td>
            <td>{item.Custodian}</td>
            <td className="text-center">
              {/* <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                    <li><button className="dropdown-item" onClick={(e) => deleteStudentLedger(item.Transaction_ID)}><i className="ri-close-circle-fill"></i> Delete</button></li>
                                </ul> */}
              <ul
                className="inline-action"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <button onClick={(e) => deleteStock(item.ID)} className="red">
                    <i className="ri-delete-bin-line"></i>
                  </button>
                </li>

                <li>
                  <NavLink
                    target="_blank"
                    to={`/StockManagement/PrintStockDetail?id=${item.ID}&balance=${item.Item_Balance}`}
                    className="blue"
                  >
                    <i className="ri-printer-line"></i>
                  </NavLink>
                </li>
              </ul>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="8">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <Stock_navbar />
      <ToastContainer />

      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/Dashboard">Dashboard</NavLink>
                    </li>
                    <li className="breadcrumb-item">
                      <NavLink to="/student/studentdashboard">Students</NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Stock Register Details
                    </li>
                  </ol>
                </nav>
                <div className="page-title"> Stock Register Details</div>
              </div>
            </div>
          </div>
        </div>

        <ShowStock StockID={StockID} />

        <div className="page-content">
          <div className="container">
            <div className="card">
              <div className="card-body">
                <table className="table table-theme">
                  <thead>
                    {/* <tr>
                                            <th colSpan="9" className="text-end">
                                                {totalRecords} Records Found!
                                            </th>
                                        </tr> */}
                    <tr>
                      <th> ID</th>
                      <th>DATED</th>
                      <th>DESCRIPTION</th>
                      <th>IN</th>
                      <th>OUT</th>
                      <th>BALANCE</th>
                      <th>CUSTODIAN</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td className="text-center" colSpan="8">
                          <Spinner animation="border" role="status"></Spinner>
                        </td>
                      </tr>
                    ) : (
                      <>{renderLedger()}</>
                    )}
                  </tbody>
                </table>
                {totalRecords > 100 && (
                  <div className="pagination-wrap">
                    <div className="row align-items-center">
                      <div className="col-md-2 form_default">
                        <div className="d-flex align-items-center">
                          <label className="me-2" style={{ fontSize: "15px" }}>
                            Page Size
                          </label>
                          <div className="form-group">
                            <select
                              className="form-select"
                              style={{ width: "100px" }}
                              onChange={(e) => {
                                setPageNo(1);
                                setPageSize(parseInt(e.target.value));
                              }}
                            >
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              <option value="500">500</option>
                              <option value="1000" selected>
                                1000
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <Pagination
                        pageNo={pageNo}
                        pageSize={pageSize}
                        totalRecords={totalRecords}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="container">
            <div className="card mt-2">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-2 offset-md-10 text-end">
                    <div className="form-group text-end">
                      <Link
                        to={`/StockManagement/PrintStockDetail?id=${StockID}`}
                        target="_blank"
                        className="w-100 button button-primary"
                      >
                        Print This List
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StockDetail;
