import React, { useState, useEffect } from 'react';
import config from "../../Config";
import axios from "axios";

function VoucherTypeDropdown(props) {
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [ListofRecords, setListofRecords] = useState([]);


    useEffect(() => {

        const data = {
            AccessKey: AccessKey,
            UserID: UserID
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Voucher/GetVouchersTypes',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setListofRecords(response.data.ListofRecords);
               
            })
            .catch(function (error) {
                //console.log(error);
            });

    }, []);



    return (
        ListofRecords.map((item, index) => {
            return (
                <option value={item.ID} key={index} selected={item.ID === props.selected}>{item.VoucherType}</option>
            )
        })
        // <option value="ALI">Ali</option>
    );
}

export default VoucherTypeDropdown;