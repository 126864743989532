import React, { useState } from 'react';
import { Link, NavLink } from "react-router-dom";
import RightsCheck from '../General/RightsCheck';
import { scrollToTop } from '../Shared/ScrollOnTop';

const Stock_navbar = () => {

    return (
        <>
            <RightsCheck />
            <nav className={`navbar navbar-expand-lg navbar-module MenuSticky`}>
                <div className="container-fluid">
                    <div className="collapse navbar-collapse">
                        <ul className="navbar-nav m-auto">
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/StockManagement/AddStockCategory">Add Stock Category</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/StockManagement/ManageStockCategory">Manage Stock Category</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Stock_navbar;
