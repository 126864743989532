import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import SetupMenu from './SetupMenu';
import moment from "moment";
import Pagination from "../General/Pagination";
import { scrollToTop } from '../Shared/ScrollOnTop';

function ManageWings() {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [SessionID, setSessionID] = useState(localStorage.getItem("DefaultSession"));
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    const [isUpdated, setIsUpdated] = useState(false);

    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);


    const [Wing, setWing] = useState("");

    useEffect(() => {

        document.title = "Manage Wings";

        setIsUpdated(false);
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            pageNo: pageNo,
            pageSize: pageSize,
            SchoolID: 1,
            SessionID: SessionID,
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Wings/GetSchoolWingsClassCountSession',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setLedger(response.data.ListofRecords);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);

            })
            .catch(function (error) {
                //console.log(error);
            });

    }, [pageNo, pageSize, isUpdated]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const renderLedger = () => {

        if (listOfLedger.length > 0) {
            return (
                listOfLedger.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{item.Wing_ID}</td>
                            <td>{item.Wing_Code}</td>
                            <td>{item.Wing_Name}</td>
                            <td>{item.Wing_Detail}</td>
                            <td>{item.TotalClasses}</td>
                            <td>
                                <ul className="inline-action" aria-labelledby="dropdownMenuButton1">
                                    <li><NavLink to={'/Setup/EditWings/' + item.Wing_ID} className=" green"><i className="ri-edit-line"></i></NavLink></li>
                                    <li><NavLink
                                        to={`/Setup/ManageClasses?Wing=${item.Wing_ID}`}
                                        // to={'/Setup/ManageClasses/' + item.Wing_ID}
                                        className=" blue">
                                        <i className="ri-list-check"></i></NavLink></li>
                                    <li><button onClick={(e) => deleteWings(item.Wing_ID)} className="red"><i className="ri-delete-bin-line"></i></button></li>
                                </ul>
                            </td>
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colspan="6"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }
    const deleteWings = (ID) => {

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            Wing_ID: ID
        };

        var api_config = {
            method: 'post',
            url: config.base_url + '/Wings/DeleteSchoolWing',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                    setIsUpdated(true);
                }
                // setIsUpdated(false);

            })
            .catch(function (error) {
                //console.log(error);
            });
    }


    return (
        <>
            <SetupMenu />
            <ToastContainer />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item"> <Link to="/Setup/SetupDashboard">Setup</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Manage Wings</li>
                                </ol>
                            </nav>
                            <div className="page-title">Manage Wings</div>
                        </div>
                        <div className="col-md-6">
                            <div className="page-right-actions">
                                <Link to="/Setup/AddWings" className="button button-white button-icon">
                                    Add Wings
                                    <i className="ri-add-line"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="card">
                        <div className="card-body table_card">
                        <div className="">
                            <table className="table table-theme table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            Wing ID
                                        </th>
                                        <th>
                                            Wing Code
                                        </th>
                                        <th>
                                            Wing Name
                                        </th>
                                        <th>
                                            Wing Detail
                                        </th>
                                        <th>
                                            Total Classes
                                        </th>
                                        <th className="text-center">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        loading ?
                                            <tr>
                                                <td className="text-center" colspan="6">
                                                    <Spinner animation="border" role="status"></Spinner>
                                                </td>
                                            </tr>
                                            :
                                            <>
                                                {renderLedger()}
                                            </>

                                    }
                                </tbody>
                            </table>
                            {
                                totalRecords > 10 &&
                                <div className="pagination-wrap">

                                    <div className="row align-items-center">
                                        <div className="col-md-2 form_default">
                                            <div className="d-flex align-items-center">
                                                <label className="me-2" style={{ fontSize: "15px" }}>Page Size</label>
                                                <div className="form-group">
                                                    <select className="form-select"
                                                        style={{ width: "100px" }}
                                                        onChange={(e) => {
                                                            setPageNo(1);
                                                            setPageSize(parseInt(e.target.value));


                                                        }}>
                                                        <option value="10" selected>10</option>
                                                        <option value="20">20</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                        <option value="500">500</option>


                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <Pagination
                                            pageNo={pageNo}
                                            pageSize={pageSize}
                                            totalRecords={totalRecords}
                                            onPageChange={handlePageChange}
                                        />
                                    </div>
                                </div>

                            }
                        </div>
                    </div>
                    </div>
                    <div className="card mt-md-3">
            <div className="card-body">
              <div className="row justify-content-end">
                <div className="col-md-2">
                  <NavLink
                    target="_blank"
                    to={`/Setup/PrintManageWings`}
                    className="button button-primary w-100"
                  >
                    Print This Report
                  </NavLink>
                </div>
       
              </div>
            </div>
          </div>
                </div>
            </div>
        </>
    );
}

export default ManageWings;
