import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import PrintHeader from "../General/PrintHeader";
import Page from "../General/Page";
import RPTAwardListC from "./RPTAwardListC";

function RPTAwardListClass(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [listOfLedger, setLedger] = useState([]);
  const [selectWing, setselectWing] = useState("");
  const [selectClass, setselectClass] = useState("");
  const [selectSection, setselectSection] = useState("");

  let params = useParams();
  const [ClassID, setClassID] = useState(params.id);

  useEffect(() => {
    document.title = "Award List For Examination";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: 1,
      pageSize: 2000,
      Class_Id: ClassID,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Sections/GetAllSections",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
        // setTotalRecords(response.data.totalRecords);
        // setselectWing(response.data.ListofRecords[0].SelectedClass?.SelectedWing?.Wing_Name)
        // setselectClass(response.data.ListofRecords[0].SelectedClass?.Class_Name)
        // setselectSection(response.data.ListofRecords[0].SelectedSection?.Section_Name)
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  const renderLedger = () => {
    if (listOfLedger.length > 0) {
      return (
        //<RPTAwardListC ID={2728} />

        listOfLedger.map((item, index) => {
          return <RPTAwardListC ID={item.Section_ID} />;
        })
      );
    } else {
      return (
        <tr>
          <td colspan="7">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      {loading ? (
        <Spinner animation="border" role="status"></Spinner>
      ) : (
        <>{renderLedger()}</>
      )}
    </>
  );
}

export default RPTAwardListClass;
