import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import { Link } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import moment from "moment";
import UserGroupDropDown from "../Shared/UserGroupDropDown";
import PayrollMenu from './PayrollMenu';
import Select from 'react-select';
import { scrollToTop } from '../Shared/ScrollOnTop';

function EditLoanDeduction(props) {
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [hasError, setHasError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [LoanDeduction, setLoanDeduction] = useState({});
    const [EmployeeID, setEmployeeID] = useState(0);
    const [EmployeeName, setEmployeeName] = useState("");
    const [employes, setEmployees] = useState([]);
    const [isUpdated, setIsUpdated] = useState(false);
    let params = useParams();
    const [LoanDeductionID, setLoanDeductionID] = useState(params.id);

    let navigate = useNavigate();

    useEffect(() => {
        setIsUpdated(false);
        setLoading(true);
        const apiData = {
            AccessKey: AccessKey,
            Is_Resigned: false,
            UserID: UserID,
            MemberType: MemberType,
            SchoolID: 1,
            PageNo: 1,
            PageSize: 10000
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Payroll/SearchEmployees',
            headers: {
                'Content-Type': 'application/json'
            },
            data: apiData
        };

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setEmployees(response.data.ListofRecords);
                setLoading(false);
            })
            .catch(function (error) {
                //console.log(error);
            });

    }, [isUpdated]);

    const dropdownOptions = employes.map(item => ({
        value: item.ID,
        label: item.Name + '(' + item.Code + ')',
        code: item.Code
    }));


    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: '100%', // Set your desired width here
        }),
    };

    const handleDropdownChange = (selectedOption) => {

        //let label = selectedOption.label;
        setEmployeeID(selectedOption.value);


    };

    useEffect(() => {
        document.title = "Add/Update Loan Deduction";
        setIsUpdated(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            ID: LoanDeductionID

        };
        //console.log(data);
        var api_config = {
            method: 'post',
            url: config.base_url + 'LoanDeduction/GetLoanDeductionByID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                    setLoanDeduction(response.data.LoanDeduction);
                    setEmployeeID(response.data.LoanDeduction.Employee_ID)
                    setEmployeeName(response.data.LoanDeduction.Selected_Staff?.Name)
                    //console.log(response.data.LoanDeduction.Selected_Staff?.Name)
                    setLoading(false);
                }




            })
            .catch(function (error) {
                setLoading(false);
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
            setIsUpdated(false);

    }, [isUpdated]);

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setLoading(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                Employee_ID: EmployeeID,
                ID: LoanDeductionID,
                Dated: event.target.txtDated.value,
                Status: event.target.txtStatus.value,
                Loan_Amount: event.target.txtLoan_Amount.value,
                Deduction: event.target.txtDeduction.value,
                Description: event.target.txtDescription.value,

            };

            //console.log(data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'LoanDeduction/UpdateLoanDeduction',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };



            axios(api_config)
                .then(function (response) {
                    setIsUpdated(true);
                    if (response.data.status_code == 1) {

                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });

                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setLoading(false);


                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });

        }

        setValidated(true);
    };

    const defaultValue = {
        value: EmployeeID,
        label: EmployeeName,
    };

    return (
        <>
            <PayrollMenu />
            <ToastContainer />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item"> <Link to="/PayRoll/PayrollDashboard">Payroll</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Edit Loan Deduction</li>
                                </ol>
                            </nav>
                            <div className="page-title">Edit Loan Deduction</div>
                        </div>
                        <div className="col-md-6">
                            <div className="page-right-actions">
                                <Link to="/PayRoll/ManageLoanDeduction" className="button button-white button-icon">
                                    Manage Loan Deductions
                                    <i className="ri-list-check"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="card">

                                <div className="card-body p-md-4">
                                    {
                                        loading ?
                                            "Loading.."
                                            :
                                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                                <div className="row align-items-center">
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="" className="">Select Employee:</label>
                                                            <Select
                                                                styles={customStyles}
                                                                onChange={handleDropdownChange}
                                                                options={dropdownOptions}
                                                                defaultValue={defaultValue}
                                                                selectedOption={LoanDeduction.Employee_ID}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="" className="">Dated:</label>
                                                            <input type="date" defaultValue={moment(LoanDeduction.Dated).format("YYYY-MM-DD")} className="form-control" required name="txtDated" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="" className="">Status: </label>
                                                            <input type="text" defaultValue={LoanDeduction.Status} className="form-control" required name="txtStatus" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="" className="">Loan Amount:</label>
                                                            <input type="text" defaultValue={LoanDeduction.Loan_Amount} className="form-control" required name="txtLoan_Amount" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="" className="">Deduction :</label>
                                                            <input type="text" defaultValue={LoanDeduction.Deduction} className="form-control" required name="txtDeduction" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="" className="">Description:</label>
                                                            <input type="text" defaultValue={LoanDeduction.Description} className="form-control" required name="txtDescription" />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2 text-end">
                                                        <div className="form-group mt-3">
                                                            {
                                                                loading ?
                                                                    <Spinner animation="border" role="status"></Spinner>

                                                                    :
                                                                    <button type="submit" value="Save" className="button button-primary w-100 ">SAVE</button>

                                                            }

                                                        </div>
                                                    </div>

                                                </div>
                                            </Form>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditLoanDeduction;
