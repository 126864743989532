import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import moment from "moment";
import { toast, ToastContainer } from 'react-toastify';
import { NavLink } from "react-router-dom";
import { useNavigate, useParams } from 'react-router-dom';


function ShowStock(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [hasError, setHasError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [Stock, setStock] = useState({});



    let params = useParams();
    // const [StockID, setStockID] = useState(params.id);

    let navigate = useNavigate();

    useEffect(() => {

        if ([props.StockID] > 0) {

            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                // MemberType: MemberType,
                ID: props.StockID

            };
            setLoading(true);
            var api_config = {
                method: 'post',
                url: config.base_url + 'Stock/GetStockItemDetailByItemID',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };


            axios(api_config)
                .then(function (response) {
                    //console.log(response);
                    if (response.data.status_code == 1) {
                        setStock(response.data.StockItem);

                    }
                    setLoading(false);
                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });
        }
        else {
            setLoading(false);
        }

    }, [props.StockID]);


    return (
        <>
            <div className="container body-content">
                <div className="page-content">

                    <div className="row gx-2 mb-3">
                        
                        <div className="col-md-12">
                            <div className="card ">
                                <div className="card-body">
                                    <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <div className="profile-detail">
                                                <h6>Item Code:</h6>
                                                <p>  {Stock.Stock_Code} </p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="profile-detail">
                                                <h6>Item Name:</h6>
                                                <p>{Stock.Item_Name} </p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="profile-detail">
                                                <h6>Available Balance:</h6>
                                                <p> {Stock.Current_Balance} </p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="profile-detail">
                                                <h6>Register Type:</h6>
                                                <p> {Stock.Register_Type} </p>
                                            </div>
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ShowStock;