import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { Link, NavLink } from "react-router-dom";
import WingsDropDown from "../Shared/WingsDropDown";
import SessionsDropDown from "../Shared/SessionsDropDown";
import ClassesDropDown from "../Shared/ClassesDropDown";
import SectionDropDown from "../Shared/SectionDropDown";
import ExamsDropDown from "../Shared/ExamsDropDown";
import SubjectsByExamSectionDropDown from "../Shared/SubjectsByExamSectionDropDown";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Pagination from "../General/Pagination";
import { scrollToTop } from "../Shared/ScrollOnTop";
import TeacherMenu from "./TeacherMenu";
import SectionByTeacherDropDown from "../Shared/SectionByTeacherDropDown";
import { Form, PageItem } from "react-bootstrap";
import GetSubjectsBySectionIDExamIDDropDown from "../Shared/GetSubjectsBySectionIDExamIDDropDown";
import SectionForTeacherSubjectsDropDown from "../Shared/SectionForTeacherSubjectsDropDown";
import SubjectsByExamSectionTeacherDropDown from "../Shared/SubjectsByExamSectionTeacherDropDown";

const Subject_Teacher = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );

  const [SessionId, setSessioinId] = useState(
    localStorage.getItem("DefaultSession")
  );

  const [SectionID, setSectionID] = useState(0);
  const [TeacherID, setTeacherID] = useState(0);
  const [ExamID, setExamID] = useState(0);
  const [Exams, setExams] = useState([]);

  const [SubjectID, setSubjectID] = useState(0);
  const [isUpdated, setIsUpdated] = useState(false);

  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [validated, setValidated] = useState(false);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [TotalRecords, setTotalRecords] = useState(0);

  //

  useEffect(() => {
    document.title = "Exam Data Entry";

 }, [isUpdated, ExamID, SectionID]);

  const handleSearch = () => {
    if (ExamID > 0 && SubjectID > 0 && SectionID > 0) {
      setLoading(true);

      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        ExamID: ExamID,
        SectionSubjectID: SubjectID,
        Section_Id: SectionID,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "Exam/GetStudentsBySectionSubject",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          if (response.data.status_code == 1) {
            setLedger(response.data.ListofRecords);
          } else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          toast.error("Network Error..", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const handleExamChange = (selectedExamID) => {
    setExamID(selectedExamID);
  };
  
  const handleSectionChange = (selectedSectionID) => {
    setSectionID(selectedSectionID);
  };

  const handleSubjectChange = (selectedSubjectID) => {
    setSubjectID(selectedSubjectID);
  };


  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{count++ + pageSize * (pageNo - 1)}</td>
            <td>{item.Class_ID}</td>
            <td>{item.Registration_No}</td>
            <td>{item.Student_Name}</td>
            <td>{item.Father_Name}</td>
            <td>
              <input
                type="number"
                value={item.Obtained_Marks}
                onChange={(e) => handleMarksChange(index, e.target.value, item)}
                style={{ width: "100px" }}
                data-student-id={item.Class_ID}
                className="form-control marks text-center"
                max={item.Contact_Phone}
              />
            </td>
            <td>{item.Contact_Phone}</td>
            <td>{item.Absent_Leave}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="8">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  const handleMarksChange = (recordIndex, newMarks, item) => {

    if (parseInt(newMarks) > parseInt(item.Contact_Phone)) {
      // If the entered value exceeds the maximum allowed value, prevent the change
      // You can also show an error message to the user
      toast.error("Marks Entered are More than Total Marks", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    // Update marks changed in the component's state
    const updatedRecords = [...listOfLedger];
    updatedRecords[recordIndex].Obtained_Marks = newMarks;
    setLedger(updatedRecords);
    //console.log(listOfLedger);
  };
  const handleSaveMarks = (event) => {
    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      ExamID: ExamID,
      SectionSubjectID: SubjectID,
      Section_Id: SectionID,
      ListofStudentRecords: listOfLedger,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Exam/UpdateExamMarks",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });

          handleSearch();
          
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });

    setValidated(true);
  };

  // const handlesubmit = (event) => {
  //   event.preventDefault();
  //   event.stopPropagation();

  //   const form = event.currentTarget;

  //   if (form.checkValidity() === false) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //   } else {
  //     setExamID(event.target.ExamID.value);
  //     setSectionID(event.target.SectionID.value);
  //     setSubjectID(event.target.SubjectID.value);
  //   }

  //   setValidated(true);
  // };

  return (
    <>
      <ToastContainer />
      <TeacherMenu />
      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/teacher/Dashboard">Home</NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Marks Entry as Subject Teacher
                    </li>
                  </ol>
                </nav>
                <div className="page-title">
                  Marks Entry as Subject Teacher
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container body-content">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSearch}
                  >
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label htmlFor="" className="">
                            Select Session:
                          </label>
                          <select
                            className="form-select"
                            name="SessionID"
                            required
                          >
                            <option value="0">---All---</option>
                            <SessionsDropDown selectedSession={SessionId} />
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label htmlFor="" className="">
                            Select Exam:
                          </label>
                          <select
                            name="ExamID"
                            className="form-select"
                            onChange={(e) => setExamID(e.target.value)}
                            required
                          >
                            <option value="0">Please Select</option>
                            <ExamsDropDown SessionID={SessionId} onExamChange={handleExamChange} />
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label htmlFor="" className="">
                            Select Section:
                          </label>
                          <select
                            className="form-select"
                            value={SectionID}
                            name="SectionID"
                            required
                            onChange={(e) => setSectionID(e.target.value)}
                          >
                            <option value="0">Please Select</option>
                            {SessionId !== 0 && (
                              <SectionForTeacherSubjectsDropDown Sessionid={SessionId} ExamID={ExamID} onSectionChange={handleSectionChange}  />
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label htmlFor="" className="">
                            Select Subject:
                          </label>
                          <select
                            className="form-select"
                            value={SubjectID}
                            name="SubjectID"
                            onChange={(e) => setSubjectID(e.target.value)}
                            required
                          >
                            <option value="0">Please Select</option>
                            <SubjectsByExamSectionTeacherDropDown
                              SectionID={SectionID}
                              ExamID={ExamID}
                              TeacherID={UserID}
                              onSubjectChange={handleSubjectChange}
                            />
                          </select>
                        </div>
                      </div>
                      <div className="col-md-2 offset-md-10">
                        <div className="form-group">
                          <button
                            type="button"
                            onClick={handleSearch}
                            className="button button-primary"
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-content mt-4">
        <div className="container">
          <div className="card">
            <div className="card-body">
              <table className="table table-theme">
                <thead>
                  <tr>
                    <th colspan="8">Total {TotalRecords} Records Found!</th>
                  </tr>

                  <tr>
                    <th>Sr. No.</th>
                    <th>ID</th>
                    <th>Registration No</th>
                    <th>Student Name</th>
                    <th>Father Name</th>
                    <th>Obtained Marks</th>
                    <th>Total Marks</th>
                    <th>Pass Marks</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td className="text-center" colspan="8">
                        <Spinner animation="border" role="status"></Spinner>
                      </td>
                    </tr>
                  ) : (
                    <>{renderLedger()}</>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="card mt-md-3">
          <div className="card-body">
            <div className="row offset-11">
              <div className="col-md-2  ">
                <button
                  type="button"
                  onClick={handleSaveMarks}
                  className="button button-primary"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


export default Subject_Teacher;