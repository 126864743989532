import React from 'react';
import { Link} from "react-router-dom";
import ReportsMenu from './ReportsMenu';
import { scrollToTop } from '../Shared/ScrollOnTop';

const FeeCollectionDetail = () => {
    return (
        <>
        <ReportsMenu/>
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="/Reports/ReportsDashboard">Reports</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Fee Collection Detail</li>
                                </ol>
                            </nav>
                            <div className="page-title">Fee Collection Detail</div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default FeeCollectionDetail;
