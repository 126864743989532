import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import AccountsMenu from "./AccountsMenu";
import { NavLink } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import YearsDropDown from "../Shared/YearsDropDown";
import VoucherTypeDropDown from "../Shared/VoucherTypesDropDown";
import Pagination from "../General/Pagination";
import moment from "moment";
import { scrollToTop } from "../Shared/ScrollOnTop";

function GeneralLedger(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [DefaultFYear, setDefaultFYear] = useState(
    localStorage.getItem("DefaultFYear")
  );
  const [VoucherType, setVoucherType] = useState(0);
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  let params = useParams();
  const [AccountCode, setAccountCode] = useState(params.accountcode);

  useEffect(() => {
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      PageNo: pageNo,
      PageSize: pageSize,
      FYear: DefaultFYear,
      AccountCode: AccountCode,
      startDate: StartDate,
      EndDate: EndDate,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Accounts/GetGeneralLedger",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code != 0) {
          setLedger(response.data.ListofRecords);
          setTotalRecords(response.data.totalRecords);
        } else {
          setLedger(null);
          setTotalRecords(0);
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [pageNo, pageSize, DefaultFYear, AccountCode, StartDate, EndDate]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{count++ + pageSize * (pageNo - 1)}</td>
            <td>
              {moment(item.Selected_Voucher.Voucher_date).format(
                config.date_format_input
              )}
            </td>
            <td>{item.Selected_Voucher.Voucher_number}</td>
            <td>
              {item.Selected_Voucher.Voucher_number === "OPV"
                ? "Opening Balance"
                : item.Remarks === ""
                ? item.Selected_Voucher.Remarks
                : item.Remarks}
            </td>
            <td> {item.Dr_Amt}</td>
            <td>{item.Cr_Amt}</td>
            <td>{item.Balance}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="7">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <AccountsMenu />

      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/Dashboard">Dashboard</NavLink>
                    </li>
                    <li className="breadcrumb-item">
                      <NavLink to="/Accounts/AccountsDashboard">
                        Accounts
                      </NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      General Ledger
                    </li>
                  </ol>
                </nav>
                <div className="page-title">General Ledger</div>
              </div>
              <div className="col-md-6">
                <div className="page-right-actions">
                  <NavLink
                    to="/Accounts/ManageAccounts"
                    className="button button-white button-icon"
                  >
                    Manage Accounts
                    <i className="ri-add-line"></i>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-content">
          <div className="container">
            <div className="card">
              <div className="card-body p-md-4">
                <div className="row gy-3">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Selected Account:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={AccountCode}
                        disabled
                      ></input>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="">Start Date:</label>
                      <input
                        type="date"
                        className="form-control"
                        onChange={(e) => setStartDate(e.target.value)}
                        required
                        max={moment().format(config.date_format_input)}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="">End Date:</label>
                      <input
                        type="date"
                        className="form-control"
                        onChange={(e) => setEndDate(e.target.value)}
                        required
                        max={moment().format(config.date_format_input)}
                      />
                    </div>
                  </div>

                  <div className="col-md-5 text-end">
                    <div className="form-group">
                      <input
                        type="button"
                        value="Search"
                        className="mt-md-4 button button-primary"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mt-3">
              <div className="card-body table_card">
                <table className="table table-theme table-hover">
                  <thead>
                    <tr>
                      <th>Sr#</th>
                      <th>Dated</th>
                      <th>Voucher No</th>
                      <th>Narration</th>
                      <th>Debit</th>
                      <th>Credit</th>
                      <th>Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td className="text-center" colspan="7">
                          <Spinner animation="border" role="status"></Spinner>
                        </td>
                      </tr>
                    ) : (
                      <>{renderLedger()}</>
                    )}
                  </tbody>
                </table>

                {totalRecords > pageSize && (
                  <div className="pagination-wrap">
                    <div className="row align-items-center">
                      <div className="col-md-2 form_default">
                        <div className="d-flex align-items-center">
                          <label className="me-2" style={{ fontSize: "15px" }}>
                            Page Size
                          </label>
                          <div className="form-group">
                            <select
                              className="form-select"
                              style={{ width: "100px" }}
                              onChange={(e) => {
                                setPageNo(1);
                                setPageSize(parseInt(e.target.value));
                              }}
                            >
                              <option value="10" selected>
                                10
                              </option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <Pagination
                        pageNo={pageNo}
                        pageSize={pageSize}
                        totalRecords={totalRecords}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GeneralLedger;
