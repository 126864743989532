import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { toast, ToastContainer } from 'react-toastify';
import moment from "moment";
import { Form } from "react-bootstrap";
import user from "../../assets/images/user.png";
import "cropperjs/dist/cropper.css";
import { useNavigate, useParams } from 'react-router-dom';
import PrintHeader from '../General/PrintHeader';

const BioDataForms = (props) => {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [loading, setLoading] = useState(false);
    const [Student, setStudent] = useState({});

    useEffect(() => {

        if ([props.StudentID] > 0) {

            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                ID: props.StudentID

            };
            setLoading(true);
            var api_config = {
                method: 'post',
                url: config.base_url + '/Student/GetStudentByID',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            //console.log(data)
            axios(api_config)
                .then(function (response) {
                    //console.log(response.data);
                    if (response.data.status_code == 1) {
                        setStudent(response.data.Student);

                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        setLoading(false);
                    }

                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });
        }
        else {
            setLoading(false);
        }

    }, [props.StudentID]);


    return (
        <>
            <table className="table table-sm table-bordered table-print white-space-wrap">
                <thead>
                    <tr>
                        <th colSpan={11}>
                            <PrintHeader title={`Student Biodata Details`} />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={{ border: "transparent" }}>
                        <td style={{ width: "75%" }}>
                            <div>
                                <h6 className=""><b>Registration Details</b></h6>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "150px" }}>Form No</td>
                                            <td style={{ width: "10px" }}>:</td>
                                            <td>{Student.Form_No}</td>
                                        </tr>
                                        <tr>
                                            <td>Registration No</td>
                                            <td>:</td>
                                            <td>{Student.Registration_No}</td>
                                        </tr>
                                        <tr>
                                            <td>Student ID</td>
                                            <td>:</td>
                                            <td>{Student.Student_ID}</td>
                                        </tr>
                                        <tr>
                                            <td>Bay Form No</td>
                                            <td>:</td>
                                            <td>{Student.Bay_Form_No}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </td>
                        <td align="right" valign="top">
                            {/* {Student.Image} */}
                            <img src={user} />
                        </td>
                    </tr>
                    <tr style={{ border: "transparent" }}>
                        <td style={{ width: "75%" }}>
                            <div>
                                <h6 className=""><b>Basic Information</b></h6>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "150px" }}>Student Name</td>
                                            <td style={{ width: "10px" }}>:</td>
                                            <td>{Student.Student_Name}</td>
                                        </tr>
                                        <tr>
                                            <td>Father Name</td>
                                            <td>:</td>
                                            <td>{Student.Father_Name}</td>
                                        </tr>
                                        <tr>
                                            <td>Father CNIC No</td>
                                            <td>:</td>
                                            <td>{Student.Father_CNIC}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </td>
                        <td>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Gender</td>
                                        <td>:</td>
                                        <td>{Student.Gender ? 'Male' : 'Female'}</td>
                                    </tr>
                                    <tr>
                                        <td>Date of Birth</td>
                                        <td>:</td>
                                        <td>{moment(Student.Date_Of_Birth).format('DD/MM/YYYY')}</td>
                                    </tr>
                                    <tr>
                                        <td>Group</td>
                                        <td>:</td>
                                        <td>{Student?.SelectedGroup?.Group_Name}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr style={{ border: "transparent" }}>
                        <td colSpan={3}>
                            <h6 className=""><b>Contact Details</b></h6>
                            <table style={{ width: "100%" }}>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "150px" }}>Temporary Address</td>
                                        <td style={{ width: "10px" }}>:</td>
                                        <td>{Student.Temporary_Address}</td>
                                    </tr>
                                    <tr>
                                        <td>Permanent Residence</td>
                                        <td>:</td>
                                        <td>{Student.Permanent_Address}</td>
                                    </tr>
                                    <tr>
                                        <td>Contact Phone</td>
                                        <td>:</td>
                                        <td>{Student.Contact_Phone}</td>
                                    </tr>
                                    <tr>
                                        <td>Mobile</td>
                                        <td>:</td>
                                        <td>{Student.Contact_Mobile}</td>
                                    </tr>
                                    <tr>
                                        <td>Email Address</td>
                                        <td>:</td>
                                        <td>{Student.Contact_Email}</td>
                                    </tr>
                                    <tr>
                                        <td>Class Details</td>
                                        <td>:</td>
                                        <td>{Student?.SelectedClass?.Class_Name}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr style={{ border: "transparent" }}>
                        <td colSpan={2}>
                            <h6 className=""><b>Facilities Taking</b></h6>
                            <table style={{ width: "100%" }}>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "150px" }}>Transport Facility</td>
                                        <td style={{ width: "10px" }}>:</td>
                                        <td>{Student?.SelectedRoot?.Root_Name}</td>
                                    </tr>
                                    <tr>
                                        <td>Concession Rule</td>
                                        <td>:</td>
                                        <td>{Student?.SelectedConcessionRule?.Concession_Rule_Name}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr style={{ border: "transparent" }}>
                        <td colSpan={2}>
                            <h6 className=""><b>Other Information</b></h6>
                            <table style={{ width: "100%" }}>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "150px" }}>Prev. School Info</td>
                                        <td style={{ width: "10px" }}>:</td>
                                        <td>{Student.Previous_School_Info}</td>
                                    </tr>
                                    <tr>
                                        <td>NOC Board</td>
                                        <td>:</td>
                                        <td>{Student.NOC_Board}</td>
                                    </tr>
                                    <tr>
                                        <td>Remarks</td>
                                        <td>:</td>
                                        <td>{Student.Remarks}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr style={{ border: "transparent" }}>
                        <td colSpan={2}>
                            <h6 className="" style={{marginTop:"10px"}}><b>Affidavit</b></h6>
                            <tr>
                                <td style={{color:"black"}} colSpan={3}>I certify that the information provided above is according to the birth certificate / bay form. i am fully responsible for any mistake / mismatch of record.
                                    <br />
                                    Parent / Guardian Name:_____________________________________________ Parent / Guardian Signature:_______________________________________________
                                    <br /><br />
                                    Student Name: _______________________________________________________ Students Signature :_______________________________________________________
                                </td>
                            </tr>
                            <tr style={{ border: "1px solid", height: "125pt" }}>
                                <td style={{ verticalAlign: "top" }} valign="top" colSpan={3}>Remarks</td>
                            </tr>
                        </td>
                    </tr>
                </tbody>
            </table>
            {/* <div style={{ pageBreakAfter:"always"}}>
            </div> */}
        </>
    )
}

export default BioDataForms
