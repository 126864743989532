import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import DesignationDropDown from '../Shared/DesignationDropDown';
import EmployeeTypeDropDown from "../Shared/EmployeeTypeDropDown";
import moment from "moment";
import Pagination from "../General/Pagination";
import Dropdown from 'react-bootstrap/Dropdown';
import PayScaleBasic from "../Shared/PayScaleBasic";
import { Form } from "react-bootstrap";
import SMSMenu from "./SMSMenu";
import { scrollToTop } from '../Shared/ScrollOnTop';

const SendtoSinglePerson = () => {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [isUpdated, setIsUpdated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    const [showFilters, setShowFilters] = useState(true);
    const [RetireMentStatus, setRetireMentStatus] = useState(false);


    const [value, setValue] = useState('');

    const handleChange = (event) => {
        const newText = event.target.value;
        if (newText.length <= 300) {
            setValue(newText);
        }
    };

    const textLength = value.length;


    return (
        <>
            <SMSMenu />
            <ToastContainer />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="/SMSAlert/SMSAlertDashboard">SMS Alert</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Send Alert To Staff</li>
                                </ol>
                            </nav>
                            <div className="page-title">Send Alert To Staff</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="card">
                        <div className="card-body">
                            <div className="col-md-4">
                                <div className="form-group mb-3">
                                    <label>Mobile No:</label>
                                    <input
                                        placeholder="923001234567"
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group mb-3">
                                    <label>SMS Text: {textLength}/300</label>
                                    <textarea
                                        rows={4}
                                        name=""
                                        className="form-control"
                                        value={value}
                                        onChange={handleChange}
                                    />
                                    <label className="mt-2">SMS Charges will be applied</label>
                                </div>
                            </div>
                            <div className="col-md-2 offset-md-10">
                                <div className="form-group mb-3">
                                    <div className="form-group text-end">
                                        {
                                            loading ?
                                                <Spinner animation="border" role="status"></Spinner>

                                                :
                                                <button type="submit" value="Send SMS" className="button button-primary w-100">
                                                    Send SMS
                                                </button>

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}

export default SendtoSinglePerson