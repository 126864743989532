import React, { useState, useEffect } from 'react';
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import AccountsMenu from './AccountsMenu';
import { NavLink } from 'react-router-dom';
import YearsDropDown from '../Shared/YearsDropDown';
import Pagination from "../General/Pagination";
import moment from "moment";
import { scrollToTop } from '../Shared/ScrollOnTop';

function TrialBalanceHeadWise(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [DefaultFYear, setDefaultFYear] = useState(localStorage.getItem("DefaultFYear"));
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    const [ClosingDebitTotal, setClosingDebitTotal] = useState("");
    const [ClosingCreditTotal, setClosingCreditTotal] = useState("");
    const [AccountLevel, setAccountLevel] = useState("0");
    const [EndDate, setEndDate] = useState("");

  document.title='Trial Balance Head Wise'

    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            PageNo: pageNo,
            PageSize: 1000,
            FYear: DefaultFYear,
            EndDate: EndDate,
            AccountLevel: AccountLevel,
            ShowZeroAccounts: true,
            ShowThirdOnly: false

        };

        //console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Accounts/GetTrialBalanceHeadWise',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code != 0) {
                    setLedger(response.data.ListofRecords);
                    setTotalRecords(response.data.totalRecords)
                    setClosingDebitTotal(response.data.ClosingDebitTotal);
                    setClosingCreditTotal(response.data.ClosingCreditTotal);

                }
                else {
                    setLedger(null);
                    setTotalRecords(0)
                }

            })
            .catch(function (error) {
                //console.log(error);
            });

    }, [pageNo, pageSize, DefaultFYear, AccountLevel]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const renderLedger = () => {

        if (listOfLedger != null && listOfLedger.length > 0) {
            let count = 1;
            return (
                listOfLedger.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{count++ + (pageSize * (pageNo - 1))}</td>
                            <td>{item.Selected_Account.Account_code}</td>
                            <td>{item.Selected_Account.Account_desc}</td>
                            <td>{item.ClosingDebit}</td>
                            <td>{item.ClosingCredit}</td>
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colspan="5"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }


    return (
        <>
            <AccountsMenu />
            <div className="container body-content">

                <div className="page-head">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                        <li className="breadcrumb-item"><NavLink to="/Accounts/AccountsDashboard">Accounts</NavLink></li>
                                        <li className="breadcrumb-item active" aria-current="page">Trial Balance Head Wise</li>
                                    </ol>
                                </nav>
                                <div className="page-title">Trial Balance Head Wise</div>
                            </div>
                            <div className="col-md-6">

                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-content">
                    <div className="container">
                        <div className="card">
                            <div className="card-body p-md-4">
                                <div className="row gy-3 align-items-center">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="">Select Level:</label>
                                            <select className="form-select" onChange={(e) => setAccountLevel(e.target.value)} >
                                                <option selected value="0">Select Level</option>
                                                <option selected value="1">1st Level</option>
                                                <option selected value="2">2st Level</option>
                                                <option selected value="3">3st Level</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="">As On:</label>
                                            <input type="date" width="200" className="form-control" onChange={(e) => setEndDate(e.target.value)} required 
                                            max={moment().format(config.date_format_input)}  />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group ">
                                            <div className="form-check mt-md-4">
                                                <input className="form-check-input" type="checkbox" id="flexCheckDefault" />
                                                <label className="form-check-label" for="flexCheckDefault">
                                                    Show Zero Accounts:
                                                </label>
                                            </div>
                                            {/* <label htmlFor="">Show Zero Accounts:</label>
                                            <input type="checkbox" ng-model="chkZeroAccounts" /> */}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <div className="form-check mt-md-4">
                                                <input className="form-check-input" type="checkbox" id="flexCheckDefault" />
                                                <label className="form-check-label" for="flexCheckDefault">
                                                    Show 3rd Level Only:
                                                </label>
                                            </div>
                                            {/* <label htmlFor="">Show 3rd Level Only:</label>
                                            <input type="checkbox" ng-model="chkShowThirdOnly" /> */}
                                        </div>
                                    </div>
                                    <div className="col-md-2 text-end">
                                        <div className="form-group">
                                            <input type="button" value="Search"
                                                className="button button-primary"
                                            />
                                            <input type="button" value="Print"
                                                className="button button-primary ms-2"
                                            />
                                            <input type="button" value="Transfer As Opening Balance"
                                                className="button button-white d-none"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='card mt-2'>
                            <div className='card-body'>
                                <table className="table table-theme table-hover">
                                    <tbody>
                                        <tr>
                                            <th>Sr. No.</th>
                                            <th>
                                                Account Code
                                            </th>
                                            <th>
                                                Account Name
                                            </th>

                                            <th colspan="2" className="text-center">
                                                Closing
                                            </th>
                                        </tr>

                                        <tr>
                                            <th>

                                            </th>
                                            <th>

                                            </th>
                                            <th>

                                            </th>
                                            <th>
                                                Debit
                                            </th>
                                            <th className="text-end">
                                                Credit
                                            </th>

                                        </tr>
                                        {
                                            loading ?
                                                <tr>
                                                    <td className="text-center" colspan="5">
                                                        <Spinner animation="border" role="status"></Spinner>
                                                    </td>
                                                </tr>
                                                :
                                                <>
                                                    {renderLedger()}
                                                </>

                                        }

                                        <tr>
                                            <td>
                                            </td>
                                            <td>
                                            </td>
                                            <td>
                                            </td>
                                            <td className="text-left">
                                                {ClosingDebitTotal}
                                            </td>
                                            <td className="text-right">
                                                {ClosingCreditTotal}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div >
                </div >
            </div >
        </>
    );
}

export default TrialBalanceHeadWise;